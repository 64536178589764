import { useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Divider, Drawer, Stack, Typography, IconButton } from '@mui/material';
// import Jobs from 'src/pages/Jobs/Jobs';
// import JobReport from 'src/pages/Reports/JobReport';
import NewChatPopUp from 'src/pages/ChatBot/NewChat/ChatBotPopUp'
import Iconify from 'src/components/iconify';
// utils
import { bgBlur } from '../../../utils/cssStyles';
//
import ChatBotToggleButton from './ChatBotToggleButton';

// ----------------------------------------------------------------------

const SPACING = 2.5;

export default function ChatBotDrawer() {

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {!open && <ChatBotToggleButton open={open} onToggle={handleToggle} />}

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          sx: {
            ...bgBlur({ color: theme.palette.background.default, opacity: 0.9 }),
            width: 1100,
            boxShadow: `-24px 12px 40px 0 ${alpha(
              theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
              0.16
            )}`,
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 2, pr: 1, pl: SPACING }}
        >
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            InfoBot
          </Typography>

          <IconButton onClick={handleClose} sx={{color:"aliceblue"}}>
            <Iconify icon="eva:close-fill" color="gray"  />
          </IconButton>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Jobs /> */}
        <NewChatPopUp />

      </Drawer>
    </>
  );
}
