import { SavedSearchOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import axios from 'src/utils/axios';

interface ArtificialLightingInterface {
  artificial_lightning_id: number;
  artificial_lightning_type: string;
  fitting: string;
  job_record_id: number;
  mounting: string;
  quantity: string;
  total: string;
  wattage: string;
}

export default function ArtificialLighting({
  reportData,
  saveReportData,
  setSaveReportData,
  invalidFields,
}: any) {
  const [classOneDwelling, setClassOneDwelling] = useState<ArtificialLightingInterface[]>([]);
  const [outDoorArea, setOutDoorArea] = useState<ArtificialLightingInterface[]>([]);
  const [classTenTotalArea, setClassTenTotalArea] = useState<ArtificialLightingInterface[]>([]);

  const [sumOfTotalClass1, setSumOfTotalClass1] = useState(0);
  const [sumOfTotalClass10, setSumOfTotalClass10] = useState(0);
  const [sumOfTotalOutdoor, setSumOfTotalOutdoor] = useState(0);

  useEffect(() => {
    let class1Total = 0;
    if (saveReportData && saveReportData.ClassOne) {
      const filteredClassOneDwelling = saveReportData?.ClassOne;
      setClassOneDwelling(filteredClassOneDwelling);
      saveReportData?.ClassOne.forEach((element: any) => {
        class1Total += parseFloat(element.total || 0);
      });
      setSumOfTotalClass1(class1Total);
    }
    let outdoorTotal = 0;
    if (saveReportData && saveReportData.OutDoor) {
      const filteredOutDoorArea = saveReportData.OutDoor;
      setOutDoorArea(filteredOutDoorArea);
      if (saveReportData?.OutDoor) {
        saveReportData?.OutDoor.forEach((element: any) => {
          outdoorTotal += parseFloat(element.total || 0);
        });
      }
      setSumOfTotalOutdoor(outdoorTotal);
    }
    let class10Total = 0;
    if (saveReportData && saveReportData.Class10a) {
      const filteredClassTenTotalArea = saveReportData?.Class10a;
      setClassTenTotalArea(filteredClassTenTotalArea);
      if (saveReportData?.Class10a) {
        saveReportData?.Class10a.forEach((element: any) => {
          class10Total += parseFloat(element.total || 0);
        });
      }
      setSumOfTotalClass10(class10Total);
    }

    let isPass = true;
    if (
      (class1Total / saveReportData.ClassOneTotalArea).toFixed(2) === '0.00' ||
      class1Total / saveReportData.ClassOneTotalArea > 5.0
    ) {
      isPass = false;
    }
    setClass1PassOrFail(isPass);

    isPass = true;
    if (
      (class10Total / saveReportData.ClassTenATotalArea).toFixed(2) === '0.00' ||
      class10Total / saveReportData.ClassTenATotalArea > 3.0
    ) {
      isPass = false;
    }
    setClass10PassOrFail(isPass);

    isPass = true;
    if (
      (outdoorTotal / saveReportData.TotalOutdoorArea).toFixed(2) === '0.00' ||
      outdoorTotal / saveReportData.TotalOutdoorArea > 4.0
    ) {
      isPass = false;
    }
    setOutdoorPassOrFail(isPass);
  }, [saveReportData]);

  const handleAddClass1Dwelling = () => {
    setClassOneDwelling([
      ...classOneDwelling,
      {
        fitting: '',
        mounting: '',
        quantity: '',
        wattage: '',
        total: '',
      } as ArtificialLightingInterface,
    ]);
  };

  const handleChangeField = (
    index: number,
    field: keyof ArtificialLightingInterface,
    value: string
  ) => {
    const updatedClassOneDwelling = [...classOneDwelling];
    updatedClassOneDwelling[index] = {
      ...updatedClassOneDwelling[index],
      [field]: value,
    };

    // Calculate the new total
    const newTotal =
      Number(updatedClassOneDwelling[index].quantity) *
      Number(updatedClassOneDwelling[index].wattage);

    // Update the total and classOneDwelling
    updatedClassOneDwelling[index].total = String(newTotal);
    setClassOneDwelling(updatedClassOneDwelling);

    // Calculate and update the sum of totals
    const newSumOfTotals = updatedClassOneDwelling.reduce(
      (acc, item) => acc + (Number(item.total) || 0),
      0
    );
    setSumOfTotalClass1(newSumOfTotals);

    // Update the SaveReportData
    setSaveReportData((prevData: any) => ({
      ...prevData,
      ClassOne: updatedClassOneDwelling,
    }));

    setClass1PassOrFail(passOrFail('Class1'));
  };

  const handleRemoveClass1Dwelling = (classOneDwellingData: any, index: number) => {
    console.log(`Deleted class one dwelling: ${JSON.stringify(classOneDwellingData)}`);
    if (classOneDwellingData.artificial_lightning_id) {
      axios
        .delete(`/core/removeclassonedwelling`, { data: classOneDwellingData })
        .then((response) => {
          console.log('Report data = ', reportData);
          if (response.status === 200) {
            const updatedClassOneDwelling = classOneDwelling.filter((v: any, i: number) => {
              if (
                v.artificial_lightning_id === undefined ||
                v.artificial_lightning_id !== classOneDwellingData.artificial_lightning_id
              ) {
                return true;
              }
              return false;
            });
            setClassOneDwelling(updatedClassOneDwelling);
            setSaveReportData((prevData: any) => ({
              ...prevData,
              ClassOne: updatedClassOneDwelling,
            }));
          }
        })
        .catch((error) => {
          console.error('Error fetching getJob:', error);
        });
    } else {
      const updatedClassOneDwelling = classOneDwelling.filter((v: any, i: number) => {
        if (i !== index) {
          return true;
        }
        return false;
      });
      setClassOneDwelling(updatedClassOneDwelling);
      setSaveReportData((prevData: any) => ({
        ...prevData,
        ClassOne: updatedClassOneDwelling,
      }));
    }
  };
  // ===================================================================================
  const handleAddOutdoorArea = () => {
    setOutDoorArea([
      ...outDoorArea,
      {
        fitting: '',
        mounting: '',
        quantity: '',
        wattage: '',
        total: '',
      } as ArtificialLightingInterface,
    ]);
  };

  const handleChangeOutDoorArea = (
    index: number,
    field: keyof ArtificialLightingInterface,
    value: string
  ) => {
    const updatedOutDoorArea = [...outDoorArea];
    updatedOutDoorArea[index] = {
      ...updatedOutDoorArea[index],
      [field]: value,
    };

    const newTotal =
      Number(updatedOutDoorArea[index]?.quantity) * Number(updatedOutDoorArea[index]?.wattage);

    // Update the total and classOneDwelling
    updatedOutDoorArea[index].total = String(newTotal);
    setOutDoorArea(updatedOutDoorArea);

    // Calculate and update the sum of totals
    const newSumOfTotals = updatedOutDoorArea.reduce(
      (acc, item) => acc + (Number(item.total) || 0),
      0
    );
    setSumOfTotalOutdoor(newSumOfTotals);
    console.log('Out door area = ', outDoorArea);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      OutDoor: updatedOutDoorArea,
    }));

    setOutdoorPassOrFail(passOrFail('Outdoor'));
  };

  const handleRemoveOutdoorArea = (outDoorAreaData: any, index: number) => {
    console.log(`Deleted out door area: ${JSON.stringify(outDoorAreaData)}`);
    if (outDoorAreaData.artificial_lightning_id) {
      axios
        .delete(`/core/removeoutdoorarea`, { data: outDoorAreaData })
        .then((response) => {
          console.log('Report data = ', reportData);
          if (response.status === 200) {
            const updatedOutDoorArea = outDoorArea.filter((v: any, i: number) => {
              if (
                v.artificial_lightning_id === undefined ||
                v.artificial_lightning_id !== outDoorAreaData.artificial_lightning_id
              ) {
                return true;
              }
              return false;
            });
            setOutDoorArea(updatedOutDoorArea);
            setSaveReportData((prevData: any) => ({
              ...prevData,
              OutDoor: updatedOutDoorArea,
            }));
          }
        })
        .catch((error) => {
          console.error('Error fetching getJob:', error);
        });
    } else {
      const updatedOutDoorArea = outDoorArea.filter((v: any, i: number) => {
        if (i !== index) {
          return true;
        }
        return false;
      });
      setOutDoorArea(updatedOutDoorArea);
      setSaveReportData((prevData: any) => ({
        ...prevData,
        OutDoor: updatedOutDoorArea,
      }));
    }
  };

  // ====================================================================================
  const handleAddClass10aTotalArea = () => {
    setClassTenTotalArea([
      ...classTenTotalArea,
      {
        fitting: '',
        mounting: '',
        quantity: '',
        wattage: '',
        total: '',
      } as ArtificialLightingInterface,
    ]);
  };

  const handleChangeClass10aTotalArea = (
    index: number,
    field: keyof ArtificialLightingInterface,
    value: string
  ) => {
    const updatedClass10aTotalArea = [...classTenTotalArea];
    updatedClass10aTotalArea[index] = {
      ...updatedClass10aTotalArea[index],
      [field]: value,
    };

    const newTotal =
      Number(updatedClass10aTotalArea[index]?.quantity) *
      Number(updatedClass10aTotalArea[index]?.wattage);

    // Update the total and classOneDwelling
    updatedClass10aTotalArea[index].total = String(newTotal);
    setClassTenTotalArea(updatedClass10aTotalArea);

    classTenTotalArea[index].total = String(newTotal);

    const newSumOfTotals = updatedClass10aTotalArea.reduce(
      (acc, item) => acc + (Number(item.total) || 0),
      0
    );

    setSumOfTotalClass10(newSumOfTotals);
    setClassTenTotalArea(updatedClass10aTotalArea);
    console.log('Class 10 total area = ', classTenTotalArea);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Class10a: updatedClass10aTotalArea,
    }));

    setClass10PassOrFail(passOrFail('Class10'));
  };

  const handleRemoveClass10aTotalArea = (classTenTotalAreaData: any, index: number) => {
    console.log(`Deleted out door area: ${JSON.stringify(classTenTotalAreaData)}`);
    if (classTenTotalAreaData.artificial_lightning_id) {
      axios
        .delete(`/core/removeclasstena`, { data: classTenTotalAreaData })
        .then((response) => {
          console.log('Report data = ', reportData);
          if (response.status === 200) {
            const updatedClassTenTotalArea = outDoorArea.filter((v: any, i: number) => {
              if (
                v.artificial_lightning_id === undefined ||
                v.artificial_lightning_id !== classTenTotalAreaData.artificial_lightning_id
              ) {
                return true;
              }
              return false;
            });
            setClassTenTotalArea(updatedClassTenTotalArea);
            setSaveReportData((prevData: any) => ({
              ...prevData,
              Class10a: updatedClassTenTotalArea,
            }));
          }
        })
        .catch((error) => {
          console.error('Error fetching getJob:', error);
        });
    } else {
      const updatedClassTenTotalArea = classTenTotalArea.filter((v: any, i: number) => {
        if (i !== index) {
          return true;
        }
        return false;
      });
      setClassTenTotalArea(updatedClassTenTotalArea);
      setSaveReportData((prevData: any) => ({
        ...prevData,
        Class10a: updatedClassTenTotalArea,
      }));
    }
  };

  const [class1PassOrFail, setClass1PassOrFail] = useState(true);
  const [class10PassOrFail, setClass10PassOrFail] = useState(true);
  const [outdoorPassOrFail, setOutdoorPassOrFail] = useState(true);

  const passOrFail = (type: string) => {
    let isPass = true;
    if (type === 'Class1') {
      if (
        (sumOfTotalClass1 / saveReportData.ClassOneTotalArea).toFixed(2) === '0.00' ||
        sumOfTotalClass1 / saveReportData.ClassOneTotalArea > 5.0
      ) {
        isPass = false;
      }
    } else if (type === 'Class10') {
      if (
        (sumOfTotalClass10 / saveReportData.ClassTenATotalArea).toFixed(2) === '0.00' ||
        sumOfTotalClass10 / saveReportData.ClassTenATotalArea > 3.0
      ) {
        isPass = false;
      }
    } else if (type === 'Outdoor') {
      if (
        (sumOfTotalClass1 / saveReportData.TotalOutdoorArea).toFixed(2) === '0.00' ||
        sumOfTotalClass1 / saveReportData.TotalOutdoorArea > 4.0
      ) {
        isPass = false;
      }
    }
    return isPass;
  };

  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE LIGHTING CALCULATIONS</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            {/* <CardHeader title="Class 1 Dwelling" sx={{ textAlign: 'center', color: '#17a986' }} /> */}
            <Stack
              spacing={2}
              alignItems="flex-end"
              flexDirection="row"
              justifyContent="space-between"
              sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
            >
              <Stack
                direction="row"
                sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
              >
                <Box sx={{ color: '#17a986', typography: 'h6' }}>&nbsp;</Box>
              </Stack>

              <Stack
                direction="row"
                sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
              >
                <Box sx={{ color: '#17a986', typography: 'h6' }}>Class 1 Dwelling</Box>
              </Stack>

              <Button
                size="small"
                color="primary"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={handleAddClass1Dwelling}
                sx={{ flexShrink: 0 }}
              >
                Add Class 1 Dwelling
              </Button>
            </Stack>
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Mounting
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Fitting
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Wattage
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Quantity
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Total
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {classOneDwelling.map((class1Dwelling, index) => (
                      <TableRow>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="mounting"
                            value={class1Dwelling.mounting}
                            onChange={(e) => handleChangeField(index, 'mounting', e.target.value)}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassOneMounting`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="">Select Mounting</MenuItem>
                            <MenuItem value="Recessed">Recessed</MenuItem>
                            <MenuItem value="Ceiling">Ceiling</MenuItem>
                            <MenuItem value="Wall">Wall</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="fitting"
                            value={class1Dwelling.fitting}
                            onChange={(e) => handleChangeField(index, 'fitting', e.target.value)}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassOneFitting`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="">Select Fitting</MenuItem>
                            <MenuItem value="Compact Fluoro">Compact Fluoro</MenuItem>
                            <MenuItem value="LED">LED</MenuItem>
                            <MenuItem value="Halogen">Halogen</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="wattage"
                            value={class1Dwelling.wattage}
                            onChange={(e) => handleChangeField(index, 'wattage', e.target.value)}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassOneWattage`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="quantity"
                            value={class1Dwelling.quantity}
                            onChange={(e) => handleChangeField(index, 'quantity', e.target.value)}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassOneQuantity`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="total"
                            value={Number(class1Dwelling.wattage) * Number(class1Dwelling.quantity)}
                            onChange={(e) => {
                              handleChangeField(index, 'total', e.target.value);
                            }}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassOneTotal`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            color="error"
                            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                            onClick={() => handleRemoveClass1Dwelling(class1Dwelling, index)}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        &nbsp;
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        &nbsp;
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Total
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Wattage/m<sup>2</sup>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: '#ffffff',
                          backgroundColor: !class1PassOrFail ? '#ff0000' : '#17a986',
                        }}
                      >
                        {!class1PassOrFail ? 'FAILS' : 'PASS'}
                      </TableCell>
                      <TableCell align="center">
                        Class 1 Dwelling Area (m<sup>2</sup>)
                      </TableCell>
                      <TableCell align="center">{saveReportData?.ClassOneTotalArea}</TableCell>
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">&nbsp;</TableCell>
                      <TableCell align="center">Project (W)</TableCell>
                      <TableCell align="center">{sumOfTotalClass1}</TableCell>
                      <TableCell align="center">
                        {saveReportData?.ClassOneTotalArea
                          ? (sumOfTotalClass1 / saveReportData.ClassOneTotalArea).toFixed(2)
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">&nbsp;</TableCell>
                      <TableCell align="center">Allowance (W)</TableCell>
                      <TableCell align="center">
                        {saveReportData?.ClassOneTotalArea
                          ? (saveReportData.ClassOneTotalArea * 5).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">5.00</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            {/* <CardHeader title="Outdoor Areas" sx={{ textAlign: 'center', color: '#17a986' }} /> */}
            <Stack
              spacing={2}
              alignItems="flex-end"
              flexDirection="row"
              justifyContent="space-between"
              sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
            >
              <Stack
                direction="row"
                sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
              >
                <Box sx={{ color: '#17a986', typography: 'h6' }}>&nbsp;</Box>
              </Stack>

              <Stack
                direction="row"
                sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
              >
                <Box sx={{ color: '#17a986', typography: 'h6' }}>Outdoor Areas</Box>
              </Stack>

              <Button
                size="small"
                color="primary"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={handleAddOutdoorArea}
                sx={{ flexShrink: 0 }}
              >
                Add Outdoor Area
              </Button>
            </Stack>
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Mounting
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Fitting
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Wattage
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Quantity
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Total
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {outDoorArea.map((outdoorArea, index) => (
                      <TableRow>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="mounting"
                            value={outdoorArea.mounting}
                            onChange={(e) =>
                              handleChangeOutDoorArea(index, 'mounting', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightOutDoorAMounting`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="">Select Mounting</MenuItem>
                            <MenuItem value="Recessed">Recessed</MenuItem>
                            <MenuItem value="Ceiling">Ceiling</MenuItem>
                            <MenuItem value="Wall">Wall</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="fitting"
                            value={outdoorArea.fitting}
                            onChange={(e) =>
                              handleChangeOutDoorArea(index, 'fitting', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightOutDoorFitting`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="">Select Fitting</MenuItem>
                            <MenuItem value="Compact Fluoro">Compact Fluoro</MenuItem>
                            <MenuItem value="LED">LED</MenuItem>
                            <MenuItem value="Halogen">Halogen</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="wattage"
                            value={outdoorArea.wattage}
                            onChange={(e) =>
                              handleChangeOutDoorArea(index, 'wattage', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightOutDoorWattage`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="quantity"
                            value={outdoorArea.quantity}
                            onChange={(e) =>
                              handleChangeOutDoorArea(index, 'quantity', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightOutDoorQuantity`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="total"
                            value={outdoorArea.total}
                            onChange={(e) =>
                              handleChangeOutDoorArea(index, 'total', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightOutDoorTotal`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            color="error"
                            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                            onClick={() => handleRemoveOutdoorArea(outdoorArea, index)}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        &nbsp;
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        &nbsp;
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Total
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Wattage/m<sup>2</sup>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: '#ffffff',
                          backgroundColor: !outdoorPassOrFail ? '#ff0000' : '#17a986',
                        }}
                      >
                        {!outdoorPassOrFail ? 'FAILS' : 'PASS'}
                      </TableCell>
                      <TableCell align="center">
                        Outdoor Areas (m<sup>2</sup>)
                      </TableCell>
                      <TableCell align="center">{saveReportData?.TotalOutdoorArea}</TableCell>
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">&nbsp;</TableCell>
                      <TableCell align="center">Project (W)</TableCell>
                      <TableCell align="center">{sumOfTotalOutdoor}</TableCell>
                      <TableCell align="center">
                        {saveReportData?.TotalOutdoorArea
                          ? (sumOfTotalOutdoor / saveReportData.TotalOutdoorArea).toFixed(2)
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">&nbsp;</TableCell>
                      <TableCell align="center">Allowance (W)</TableCell>
                      <TableCell align="center">
                        {saveReportData?.TotalOutdoorArea
                          ? (saveReportData.TotalOutdoorArea * 4).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">4.00</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            {/* <CardHeader title="Class 10a Total Area" sx={{ textAlign: 'center', color: '#17a986' }} /> */}
            <Stack
              spacing={2}
              alignItems="flex-end"
              flexDirection="row"
              justifyContent="space-between"
              sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
            >
              <Stack
                direction="row"
                sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
              >
                <Box sx={{ color: '#17a986', typography: 'h6' }}>&nbsp;</Box>
              </Stack>

              <Stack
                direction="row"
                sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
              >
                <Box sx={{ color: '#17a986', typography: 'h6' }}>Class 10a Total Area</Box>
              </Stack>

              <Button
                size="small"
                color="primary"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={handleAddClass10aTotalArea}
                sx={{ flexShrink: 0 }}
              >
                Add Class 10a Total Area
              </Button>
            </Stack>
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Mounting
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Fitting
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Wattage
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Quantity
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Total
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {classTenTotalArea.map((class10aTotalArea, index) => (
                      <TableRow>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="mounting"
                            value={class10aTotalArea.mounting}
                            onChange={(e) =>
                              handleChangeClass10aTotalArea(index, 'mounting', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassTenAMounting`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="">Select Mounting</MenuItem>
                            <MenuItem value="Recessed">Recessed</MenuItem>
                            <MenuItem value="Ceiling">Ceiling</MenuItem>
                            <MenuItem value="Wall">Wall</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="fitting"
                            value={class10aTotalArea.fitting}
                            onChange={(e) =>
                              handleChangeClass10aTotalArea(index, 'fitting', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassTenAFitting`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="">Select Fitting</MenuItem>
                            <MenuItem value="Compact Fluoro">Compact Fluoro</MenuItem>
                            <MenuItem value="LED">LED</MenuItem>
                            <MenuItem value="Halogen">Halogen</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="wattage"
                            value={class10aTotalArea.wattage}
                            onChange={(e) =>
                              handleChangeClass10aTotalArea(index, 'wattage', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassTenAWattage`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="quantity"
                            value={class10aTotalArea.quantity}
                            onChange={(e) =>
                              handleChangeClass10aTotalArea(index, 'quantity', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassTenAQuantity`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            name="total"
                            value={class10aTotalArea.total}
                            onChange={(e) =>
                              handleChangeClass10aTotalArea(index, 'total', e.target.value)
                            }
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-LightClassTenATotal`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',
                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            color="error"
                            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                            onClick={() => handleRemoveClass10aTotalArea(class10aTotalArea, index)}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        &nbsp;
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        &nbsp;
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Total
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        Wattage/m<sup>2</sup>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: '#ffffff',
                          backgroundColor: !class10PassOrFail ? '#ff0000' : '#17a986',
                        }}
                      >
                        {!class10PassOrFail ? 'FAILS' : 'PASS'}
                      </TableCell>
                      <TableCell align="center">
                        Class 10a Dwelling Area (m<sup>2</sup>)
                      </TableCell>
                      <TableCell align="center">{saveReportData?.ClassTenATotalArea}</TableCell>
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">&nbsp;</TableCell>
                      <TableCell align="center">Project (W)</TableCell>
                      <TableCell align="center">{sumOfTotalClass10}</TableCell>
                      <TableCell align="center">
                        {saveReportData?.ClassTenATotalArea
                          ? (sumOfTotalClass10 / saveReportData.ClassTenATotalArea).toFixed(2)
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">&nbsp;</TableCell>
                      <TableCell align="center">Allowance (W)</TableCell>
                      <TableCell align="center">
                        {saveReportData?.ClassTenATotalArea
                          ? (saveReportData.ClassTenATotalArea * 3).toFixed(2)
                          : 0}
                      </TableCell>
                      <TableCell align="center">3.00</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              <img
                src="./ea-report-footer.png"
                alt="Logo"
                style={{ width: '20vh', height: '10vh' }}
              />
            </Box>
            <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
            <Stack direction="column">
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                energy@energyadvance.com.au
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                ACN: 60 9332014 | 1300 850 228
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}
