import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'src/utils/axios';
// import { useAuthContext } from 'src/auth/useAuthContext';
import { useEffect } from 'react';
// form
import { useSnackbar } from 'src/components/snackbar';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
// routes
// import { PATH_AUTH } from '../../routes/paths';
// components
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
};

export default function AuthResetPasswordForm({
  email,
}: any) {

  
  const navigate = useNavigate();
  // const { forgotpassword } = useAuthContext();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().required('Username is required'),
  });
  
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: '' },
  });
  const { enqueueSnackbar } = useSnackbar();
  
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;
  
    useEffect(() => {
      setValue("email",email)
    },[email, setValue])
  
  const onSubmit = async (data: FormValuesProps) => {
    await axios.post('/core/forgotpassword', {
     email: data.email,
    }).then((response) => {
      enqueueSnackbar(response.data);
      navigate("/login")
    })
    .catch((error) => {
      console.error(error)
      enqueueSnackbar(error.error,{variant:'error'});
    })
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField name="email" label="Username" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ mt: 3 }}
      >
        Send Request
      </LoadingButton>
    </FormProvider>
  );
}
