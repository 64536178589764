import {
  Button,
  Card,
  // CardContent,
  // CardHeader,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Container,
  // Dialog,
  // DialogTitle,
  // Avatar,
  FormControlLabel,
  // FormLabel,
  Grid,
  // InputLabel,
  // LinearProgress,
  // Link,
  // ListItem,
  // MenuItem,
  // Radio,
  // RadioGroup,
  // Select,
  // SelectChangeEvent,
  // Switch,
  TextField,
  // alpha,
  // useTheme,
  Stack,
  Typography,
  // IconButton,
  // Tabs,
  // Tab,
  // FormControl,
  // FormGroup,
  Checkbox,
} from '@mui/material';
// import { Box, styled, width } from '@mui/system';
// import TextareaAutosize from '@mui/base/TextareaAutosize';
import {
  // DateTimePicker,
  // DesktopDatePicker,
  LocalizationProvider,
  // StaticDatePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import {
  //  SetStateAction, useCallback,
  useEffect,
  useState,
} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Iconify from 'src/components/iconify';
// import Label from 'src/components/label/Label';
import { useSnackbar } from 'src/components/snackbar';
import { useAuthContext } from 'src/auth/useAuthContext';
// import Editor from 'src/components/editor';
// import _mock from 'src/_mock/_mock';
// import { m, AnimatePresence } from 'framer-motion';
// import * as Yup from 'yup';
// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import Upload from 'src/components/upload/Upload';
// import { TreeItem, TreeItemProps, TreeView, LoadingButton } from "@mui/lab";
// import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
// import { fDate } from 'src/utils/formatTime';
// import FileThumbnail from 'src/components/file-thumbnail';
// import { varFade } from 'src/components/animate';
// import dayjs from 'dayjs';
// import { DatePicker } from '@mui/lab';
// import TextMaxLine from 'src/components/text-max-line';
// import { TextFieldProps } from '@material-ui/core';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router';

interface Schedul {
  day: string;
  fromTime: string;
  toTime: string;
  status: string;
}

export default function ScheduleTab({ id, edit }: any) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDays, setSelectedDays] = useState<Schedul[]>([]);
  const [emptyDays, setEmptyDays] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  if (user?.UserRole === 'Assessor') {
    edit = false;
  }

  useEffect(() => {
    axios
      .get(`/core/getAssessorSchedule?Id=${id}`)
      .then((response) => {
        console.log(
          response.data.map((day: Schedul) => ({
            day: day.day,
            fromTime: `1970-01-01T${day.fromTime}`,
            toTime: `1970-01-01T${day.toTime}`,
            status: day.status,
          }))
        );
        setSelectedDays(
          response.data.map((day: Schedul) => ({
            day: day.day,
            fromTime: `1970-01-01T${day.fromTime}`,
            toTime: `1970-01-01T${day.toTime}`,
            status: day.status,
          })) as Schedul[]
        );
      })
      .catch((error) => {
        console.error('Error fetching assessor schedule:', error);
      });
  }, [id]);
  console.log('During Read : ', selectedDays);
  const updateSchedule = () => {
    console.log('Inside UpdateSchedule :', selectedDays);
    if (selectedDays.length === 0) {
      setEmptyDays(true);
    } else {
      setEmptyDays(false);
      axios
        .post(
          `/core/updateAssessorSchedule?Id=${id}&userid=${user?.ID}`,
          selectedDays.map((day) => ({
            day: day.day,
            fromTime: new Date(day.fromTime).toTimeString().split(' ')[0].toString(),
            toTime: new Date(day.toTime).toTimeString().split(' ')[0].toString(),
            status: day.status,
          }))
        )
        .then((response) => {
          enqueueSnackbar(response.data, { variant: 'success' });
        })
        .catch((error) => {
          console.error('Error updating assessor:', error);
          enqueueSnackbar(error, { variant: 'error' });
        });
    }
  };

  const handleClick = (event: any) => {
    const { value, checked } = event.target;
    console.log(value, checked);
    if (!checked) {
      setSelectedDays(selectedDays.filter((val: Schedul) => val.day !== value));
    } else {
      const existingSchedul: Schedul = {
        day: value,
        // fromTime:  "2023-07-27T09:00:00-04:00",
        // toTime: "2023-07-27T17:00:00-04:00" ,
        fromTime: '2023-07-27T08:00:00',
        toTime: '2023-07-27T15:00:00',
        status: 'enable',
      };
      setEmptyDays(false);
      setSelectedDays([...selectedDays, existingSchedul]);
    }
  };

  function handleFromChange(value: any, day: any) {
    setSelectedDays((prevScheduls) =>
      prevScheduls.map((Schedul, i) =>
        Schedul.day === day ? { ...Schedul, fromTime: value } : Schedul
      )
    );
    console.log(selectedDays);
  }

  function handleToChange(value: any, day: any) {
    console.log(value);
    setSelectedDays((prevScheduls) =>
      prevScheduls.map((Schedul, i) =>
        Schedul.day === day ? { ...Schedul, toTime: value } : Schedul
      )
    );
  }

  return (
    <>
      {/* <Container> */}
      <Grid
        container
        display="flow"
        sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Grid item xs={12} md={12}>
          {/* <Card sx={{ py: 3, px: 3, textAlign: 'center', height: '100%', width: '100%' }}> */}
          <Grid item xs={12} md={12}>
            <Grid item md={4}>
              <Typography variant="h6" sx={{ marginLeft: 4 }}>
                Assessor Work Schedule
              </Typography>
            </Grid>
            {emptyDays && (
              <Grid item md={4} sx={{ float: 'right' }}>
                <Typography variant="h6" sx={{ color: 'red' }}>
                  Please Set Atleast One Schedule Day.
                </Typography>
              </Grid>
            )}
          </Grid>

          {edit && (
            <Grid container direction="row" spacing={3} lg={12} sx={{ margin: 1 }}>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
                (day) => (
                  <Grid item lg={3} key={day}>
                    <FormControlLabel
                      value={day}
                      control={<Checkbox />}
                      label={day}
                      labelPlacement="end"
                      checked={
                        selectedDays ? selectedDays.some((Schedul) => Schedul.day === day) : false
                      }
                      onChange={handleClick}
                    />
                  </Grid>
                )
              )}
            </Grid>
          )}
          <Grid
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            spacing={3}
            sx={{ marginLeft: 'auto', marginRight: 'auto', margin: 1, maxWidth: 'fit-content' }}
          >
            {selectedDays.map((day: Schedul) => (
              <Card
                key={day.day}
                sx={{
                  paddingLeft: '10px',
                  paddingTop: '10px',
                  pb: '10px',
                  paddingRight: '10px',
                  marginLeft: '10px',
                  marginTop: '10px',
                  maxWidth: '160px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: 2, marginLeft: '30px', marginRight: 'auto' }}
                >
                  {day.day}
                </Typography>
                <Stack spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm
                      label="Start Time-"
                      disabled={!edit}
                      value={day.fromTime}
                      onChange={(newValue) => {
                        handleFromChange(newValue, day.day);
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} margin="normal" />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm
                      label="End Time-"
                      value={day.toTime}
                      disabled={!edit}
                      onChange={(newValue: any) => {
                        console.log(newValue);
                        handleToChange(newValue, day.day);
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} margin="normal" />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Card>
            ))}
          </Grid>
          {/* </Card> */}
          <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  navigate('/assessorlist');
                }}
                sx={{ mr: 1 }}
                color="primary"
              >
                Back
              </Button>
              {edit && (
                <Button variant="contained" onClick={updateSchedule} color="primary">
                  Update
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* </Container> */}
    </>
  );
}
