import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

interface Props {
  reportData: any;
  saveReportData: any;
}

export default function Regulations22019({ reportData, saveReportData }: Props) {
  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE REGULATIONS</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack>
          <Box sx={{ typography: 'caption' }}>
            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.5 Artificial Lighting
            </Typography>
            <Typography variant="body2">(a) Lamp Power Density:</Typography>
            <Typography variant="body2">(i) 5 W/m² in a Class 1 building</Typography>
            <Typography variant="body2">
              (ii) 4 W/m² on a Verandah, balcony, or the like attached to a Class 1 building
            </Typography>
            <Typography variant="body2">
              (iii) 3 W/m² in a Class 10a building associated with a Class 1 building
            </Typography>
            <Typography variant="body2">
              (b) The illumination power density allowance in (a) may be increased by dividing it by
              the relevant illumination power density adjustment factor for a control device in (f)
              as applicable
            </Typography>
            <Typography variant="body2">
              (c) Power of proposed installation must be used rather than nominal allowances
            </Typography>
            <Typography variant="body2">
              (d) If halogen lamps are installed, they must be separately switched from fluorescent
              lamps
            </Typography>
            <Typography variant="body2">
              (e) Perimeter Lighting: (i) Controlled by a daylight sensor; (ii) Average light source
              efficacy of not less than 40 Lumens/W; (f) Control Devices:
            </Typography>
            <Typography variant="body2">
              (i) Lighting timer for corridor lighting: 0.7; (ii) Motion detector: Varied values
              based on conditions; (iii) Manual dimming: 0.85; (iv) Programmable dimming: 0.85; (v)
              Dynamic dimming: 0.9 for fluorescent lights, 0.8 for high-pressure discharge lights;
              (vi) Fixed dimming: Calculated value; (vii) Daylight sensor and dynamic lighting
              control: 0.5-0.6; (g-h) Definitions for manual and programmed dimming
            </Typography>
            <Typography variant="body2">(i) Definition for dynamic dimming</Typography>
            <Typography variant="body2">(j) Definition for fixed dimming</Typography>
            <Typography variant="body2">(k) Limitations on adjustment factors</Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.6 Water Heater in a Heated Water Supply System
            </Typography>
            <Typography variant="body2">
              Must be designed and installed following Part B2 of NCC Volume Three — Plumbing Code
              of Australia
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.7 Swimming Pool Heating and Pumping
            </Typography>
            <Typography variant="body2">(a) Heating Options:</Typography>
            <Typography variant="body2">
              (i) Solar heater; (ii) Heater using reclaimed energy; (iii) Gas heater; (iv) Heat
              pump; (v) Combination of (i) to (iv)
            </Typography>
            <Typography variant="body2">
              (b) Additional Requirements: (i) A cover unless located in a conditioned space; (ii)
              Time switch for heater operation
            </Typography>
            <Typography variant="body2">(c) Time switch for circulation pump</Typography>
            <Typography variant="body2">(d) Excludes spa pool</Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.8 Spa Pool Heating and Pumping
            </Typography>
            <Typography variant="body2">(a) Heating Options:</Typography>
            <Typography variant="body2">
              (i) Solar heater; (ii) Heater using reclaimed energy; (iii) Gas heater; (iv) Heat
              pump; (v) Combination of (i) to (iv)
            </Typography>
            <Typography variant="body2">
              (b) Additional Requirements: (i) A cover; (ii) Push button and time switch for heater
              operation; (c) Time switch for circulation pump with capacity of 680 L or more
            </Typography>

            {saveReportData?.State === 'Western Australia' && (
              <>
                <Divider sx={{ borderStyle: 'none', py: '2rem' }} />
                <Typography variant="body2" sx={{ color: '#17a986' }}>
                  WA 2.3.1 Water Use Efficiency
                </Typography>
                <Typography variant="body2">
                  (a) All tap fittings other than bath outlets and garden taps must be a minimum of
                  4 stars WELS rated.
                </Typography>
                <Typography variant="body2">
                  (b) All showerheads must be a minimum of 3 stars WELS rated.
                </Typography>
                <Typography variant="body2">
                  (c) All sanitary flushing systems must be a minimum of 4 stars WELS rated dual
                  flush.
                </Typography>
                <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

                <Typography variant="body2" sx={{ color: '#17a986' }}>
                  WA 2.3.2 Swimming Pool Covers And Blankets
                </Typography>
                <Typography variant="body2">
                  An outdoor private swimming pool or spa associated with a Class 1 building must be
                  supplied with a cover, blanket or the like that
                </Typography>
                <Typography variant="body2">
                  (a) is designed to reduce water evaporation and
                </Typography>
                <Typography variant="body2">
                  (b) is accredited under the Smart Approved Watermark Scheme governed by the
                  Australian Water Association, the Irrigation Association of Australia, the Nursery
                  and Garden Industry Australia and the Water Services Association of Australia.
                </Typography>
                <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

                <Typography variant="body2" sx={{ color: '#17a986' }}>
                  WA 2.3.3 Heated Water Use Efficiency
                </Typography>
                <Typography variant="body2">
                  All internal heated water outlets (such as taps, showers and washing machine water
                  supply fittings) must be connected to a heated water system or a recirculating
                  heated water system with pipes installed and insulated in accordance with AS/NZS
                  3500: Plumbing and Drainage, Part 4 Heated Water Services. The pipe from the
                  heated water system or re-circulating heated water system to the furthest heated
                  water outlet must not be more than 20 m in length or 2 litres of internal volume.
                </Typography>
              </>
            )}
            <Divider sx={{ borderStyle: 'none', py: '5rem' }} />

            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '10px' }}>
                  <img
                    src="./ea-report-footer.png"
                    alt="Logo"
                    style={{ width: '20vh', height: '10vh' }}
                  />
                </Box>
                <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
                <Stack direction="column" sx={{ marginTop: '45px' }}>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    energy@energyadvance.com.au
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    ACN: 60 9332014 | 1300 850 228
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                  borderColor: '#919eab3d',
                  p: '0.5rem',
                  padding: '3rem',
                }}
              >
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                  COMPLIANCE STAMP NOT{' '}
                </Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}
