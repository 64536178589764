import { Card, Container, Typography, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import { 
  // useCallback, 
  useEffect, 
  useState 
} from 'react';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';

import axios from 'src/utils/axios';
// import _mock from 'src/_mock/_mock';
// import * as Yup from 'yup';
// form
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { TreeItem, TreeItemProps, TreeView, LoadingButton } from "@mui/lab";
import { useLocation } from 'react-router-dom';
import { useSettingsContext } from 'src/components/settings';
import ProfileWorkflow from './ProfileWorkflow';
// eslint-disable-next-line import/no-named-as-default
// interface ViewException {
//   handleClose: () => void;
//   data: Props;
// }

// type FormValuesProps = {
//   comment: string;
//   name: string;
//   email: string;
// };

// type Props = {
//   id: number;
//   name: string;
//   email: string;
//   lastLogin: Date;
//   occupency: number;
//   phoneNumber: string;
//   status: string;
//   address: string;
//   // lastName: string;
//   // firstName: string;
//   createdDate: Date;
// };
// type skillSetProps = {
//   id: any;
//   basix: string;
//   natHers: string;
//   varification: string;
//   urgentJobs: string;
//   bess: string;
//   dts: string;
// };
// interface ViewJobDialogProp {
//   handleClose: () => void;
//   data: Props;
//   edit: boolean;
// }

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
type WorkflowProfile = {
  UserProfilePic: any;
  UserUsername: string;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | 'deleted' | any;
  UserStreetAddr: string;
  UserCountry: 'Australia' | 'United States' | any;
  UserCreatedAt:string;
};
export default function ViewWorkflow() {
  const [workflow, setWorkflow] = useState<WorkflowProfile | null>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const edit = queryParams.get('edit');
  useEffect(()=>{
    axios.get(`/core/getWorkflow?Id=${id}`)
    .then(response => {
      setWorkflow(response.data as WorkflowProfile);
    })
    .catch(error => {
      console.error('Error fetching workflow:', error);
    });
  },[id])
  
  const TABS: any[] = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="mdi:account-circle" />,
      component: (workflow&&id&& edit&&<ProfileWorkflow  workflow={workflow} id={id} edit={JSON.parse(edit.toLowerCase())} />),
    },
  ];
  const [currentTab, setCurrentTab] = useState('profile');
  const { themeStretch } = useSettingsContext();

  return (
          <Container sx={{ maxWidth: '100% !important' ,paddingTop:'50px',paddingBottom:'50px'}}>
                <Helmet>
                    <title> Workflow: View Workflow | Energy Advance</title>
                </Helmet>


                <Container maxWidth={themeStretch ? false : 'lg'}>
                    {edit && JSON.parse(edit.toLowerCase()) &&<CustomBreadcrumbs
                    heading="Edit Workflow"
                    links={[
                       
                        {
                        name: 'Workflow',
                        href: "/workflowUserList",
                        },
                        { name: 'Edit Workflow' },
                    ]}
                    /> }
                    {edit && !JSON.parse(edit.toLowerCase()) &&<CustomBreadcrumbs
                    heading="View Workflow"
                    links={[
                       
                        {
                        name: 'Workflow',
                        href: "/workflowUserList",
                        },
                        { name: 'View Workflow' },
                    ]}
                    /> }
                  <Card sx={{ textAlign: 'center',borderRadius:1}}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Tabs
                        textColor="primary"
                        value={currentTab}
                        onChange={(event, newValue) => setCurrentTab(newValue)}
                      >
                        {TABS.map((tab) => (
                          <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                        ))}
                      </Tabs>
                    </div>
                  </Card>

                {TABS.map(
                  (tab) =>
                    tab.value === currentTab && (
                      <Box key={tab.value} sx={{ mt: 5 ,ml:'auto',mr:'auto'}}>
                        {tab.component}
                      </Box>
                    )
                )}
      </Container>
    </Container>

  );
}

// const publicDocuments = [
//   { fileName: 'public_report', fileType: 'pdf' },
//   { fileName: 'public_presentation', fileType: 'pptx' },
//   { fileName: 'public_budget', fileType: 'xlsx' },
//   { fileName: 'public_policy', fileType: 'docx' },
// ];
// const confidentialDocuments = [
//   { fileName: 'confidential_contract', fileType: 'pdf' },
//   { fileName: 'confidential_strategy', fileType: 'docx' },
//   { fileName: 'confidential_financials', fileType: 'xlsx' },
//   { fileName: 'confidential_prototype', fileType: 'zip' },
// ];
