// import { Helmet } from 'react-helmet-async';
import { 
  JSXElementConstructor, 
  // MouseEventHandler,useState, 
  ReactElement, 
  ReactFragment, 
  ReactPortal 
} from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import useOffSetTop from 'src/hooks/useOffSetTop';
// import { HEADER } from 'src/config-global';

// @mui
import {
   Button, DialogContentText, DialogTitle,
   Dialog, DialogActions, DialogContent, 
  //  Link, Stack, Typography, Container,
   useMediaQuery, useTheme 
  } from '@mui/material';
// routes
// import { PATH_AUTH } from '../routes/paths';
// // components
// import Iconify from '../components/iconify';
// // sections
// import AuthNewPasswordForm from '../sections/auth/AuthNewPasswordForm';
// // assets
// import { SentIcon } from '../assets/icons';
// import Header from './Header';  

interface Prop {
    open: boolean,
    setOpen: (arg0: boolean) => void,
    title: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined,
    content: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined, 
    onSave: (arg0:any) => void,
    onCancel: () => void,
}
// ----------------------------------------------------------------------
export default function ResponsiveDialog(
   {  open,
    setOpen,
    title,
    content,
    onSave,
    onCancel,
}:Prop
) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            
            <Button variant="contained" color="success" onClick={onSave} autoFocus>
              Yes
            </Button>
            <Button variant="outlined" autoFocus onClick={onCancel}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }