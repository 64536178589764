import {  useState } from 'react';
import * as Yup from 'yup';
// import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'src/utils/axios';
// form

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
import { useSnackbar } from '../../components/snackbar';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {

  email: any;
  password: string;
  confirmPassword: string;
};

export default function AuthNewPasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');
  // const { ressetpassword } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);



  const VerifyCodeSchema = Yup.object().shape({
   
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long.')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const defaultValues = {
    email  ,
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  function validatePassword(password: any) {
    // Minimum 8 characters in length
    if (password.length < 8) {
      return 'Password must be at least 8 characters long.';
    }

    // Password must start with a character
    if (!/^[a-zA-Z]/.test(password)) {
      return 'Password must start with a character.';
    }

    // At least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    }

    // At least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter.';
    }

    // At least one numeric digit
    if (!/\d/.test(password)) {
      return 'Password must contain at least one numeric digit.';
    }

    // At least one special character (@ | * | $)
    if (!/[@*$]/.test(password)) {
      return 'Password must contain at least one of the following special characters: @, *, $.';
    }

    // Password meets all criteria
    return null;
  }

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const validationError = validatePassword(data.confirmPassword);

      if (validationError) {
        enqueueSnackbar('Please follow the password policy.', { variant: 'error' });
      }else{
          await axios.post('/core/updatepassword', {
          email:data.email,
          token,
          password:data.password,
          confirm_password: data.confirmPassword,
        }).then((response) => {
          enqueueSnackbar('Changed password success!');
          navigate(PATH_DASHBOARD.root);
        }).catch((error) => {
          enqueueSnackbar(error.error);
        })
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="email"
          label="Email"
          disabled
          InputLabelProps={{ shrink: true }}
        />

        

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Confirm New Password"
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                  <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          Update Password
        </LoadingButton>
      </Stack>
    </FormProvider>
    <span
          style={{
            fontSize: '15px',
            color: '#ff0000',
            fontFamily: 'cursive',
          }}
        >
          Note: On successful change of password you will be logged out and you need to login again.
    </span>
    <div style={{ marginTop: '10px' }}>
      <span style={{ fontSize: '18px', fontFamily: 'monospace', marginBottom: '10px' }}>
        The password must adhere to the following policy:
        <br />
      </span>
    </div>
      <div style={{ fontSize: '15px' }}>
        - Minimum 8 characters in length.
        <br />
        - Password must start with character.
        <br />
        - At least one uppercase letter.
        <br />
        - At least one lowercase letter.
        <br />
        - At least one numeric digit.
        <br />
        - At least one special character(@ | * | $)
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
