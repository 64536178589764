import {
  Button,
  Grid,
  TextField,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'src/components/snackbar';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  // useCallback,
  useEffect,
  useState,
} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Label from 'src/components/label/Label';
// import _mock from 'src/_mock/_mock';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import { Helmet } from 'react-helmet-async';
import { useAuthContext } from 'src/auth/useAuthContext';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
// import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
// import { fData } from 'src/utils/formatNumber';
import DeleteIcon from '@mui/icons-material/Delete';

import axios from 'src/utils/axios';
import { useNavigate } from 'react-router';

type RequestorProfile = {
  // UserProfilePic: any;
  ID: string;
  ReqName: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: 'active' | 'inactive' | 'deleted' | any;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  ReqJobTypes:
    | 'None'
    | 'ASSR/ADSR/BSSR/BDSR - NATHers'
    | 'ASSR+BXSUB+BX/ADSR+BXSUB+BX/BSBX/BDBX - Basix'
    | 'AVSR/AVDR - Verification'
    | 'ASQD/ADQD - Deemed to Satisfy'
    | 'MJH/JGK - MHSSR/MHDSR/MHSBX/MHDBX'
    | any;

  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
  NewRequestor: boolean;
};
interface ViewJobDialogProp {
  requestor: RequestorProfile;
  id: string;
  edit: boolean;
}

export default function ProfileRequestor({ requestor, id, edit }: ViewJobDialogProp) {

  // const [values, setValues] = useState<Date | null>(new Date());
  // const [image, setImage] = useState<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [personToDelete, setPersonToDelete] = useState('');
  const [newPerson, setNewPerson] = useState('');
  const [createdDate, setCreatedDate] = useState<Date | null>(new Date());
  const [disableDate, setDisable] = useState(true);
  const { user } = useAuthContext();

  const handleAddPerson = () => {
    if (newPerson.trim() !== '') {
      setSelectedPeople((prevSelectedPeople) => [...prevSelectedPeople, newPerson.trim()]);
      setNewPerson('');
    }
  };

  const handleDeleteClick = (person: string) => {
    setPersonToDelete(person);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    setSelectedPeople((prevSelectedPeople) =>
      prevSelectedPeople.filter((person) => person !== personToDelete)
    );
    setDeleteConfirmOpen(false);
    setPersonToDelete('');
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setPersonToDelete('');
  };

  const [NewRequestor, setNewRequestor] = useState(false);

  const handleCheckboxChange = (event: { target: { checked: any } }) => {
    setNewRequestor(event.target.checked);
  };

  // if (requestor?.NewRequestor === 'yes') {
  //   setNewRequestor(true);
  // } else {
  //   setNewRequestor(false);
  // }

  const CommentSchema = Yup.object().shape({
    ReqName: Yup.string().required('Requestor name is required'),
    // ReqSubloc: Yup.string().required('Sublocation is required'),
    // ReqEmailDomain: Yup.string().required('Email domain is required'),
    ReqEmailDomain: Yup.string()
      .matches(/^@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email domain')
      .required('Email Domain is required'),
    // ReqContactPhone: Yup.string().required('Phone Number is required'),
    ReqType: Yup.string().required('Request type is required'),
    ReqJobTypes: Yup.string().required('Request job type is required'),
    // Notes: Yup.string().required('Notes is required'),
    ReqStatus: Yup.string().required('Request status is required'),
    IsDefaultFinal: Yup.string().required('Default final is required'),
    IsPrelimFinalMentionedSubject: Yup.string().required('Prelim final is required'),

    // ReqContactPhone: Yup.string()
    //   .required('Phone Number is required. Minimum 8 Maximum 10')
    //   .min(8)
    //   .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
    //     if (!value) return false;
    //     return /^(\+61-|\+1-){0,1}\d+$/.test(value);
    //   }),
    ReqContactPhone: Yup.string()
      .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
        if (!value) return true;
        return /^(\+61-|\+1-){0,1}\d+$/.test(value);
      })
      .test('min-length', 'Invalid phone number, only digits accepted min 8 max 10', (value) => {
        if (!value) return true;
        return value.length >= 8;
      }),
  });

  const defaultValues = {
    ReqName: '',
    ReqSubloc: '',
    ReqCreatedDatetime: '',
    ReqEmailDomain: '',
    ReqContactPhone: '',
    ReqStatus: '',
    ReqType: '',
    ReqJobTypes: '',
    NewRequestor: false,
    // UserProfilePic: '',
  };

  const methods = useForm<RequestorProfile>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    setValue,
    // reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    if (requestor.PeopleListed !== '') {
      const peopleList = requestor.PeopleListed.split(','); // Split the string by comma
      setSelectedPeople(peopleList.map((person) => person.trim()));
    }

    if (requestor) {
      setValue('ReqName', requestor.ReqName);
      setValue('ReqSubloc', requestor.ReqSubloc);
      setValue('ReqEmailDomain', requestor.ReqEmailDomain);
      requestor.ReqContactPhone = requestor.ReqContactPhone.replace('+61-', '');
      setValue('ReqContactPhone', requestor.ReqContactPhone);
      setValue('ReqStatus', requestor.ReqStatus || 'active');
      setValue('Notes', requestor.Notes);
      setValue('ReqType', requestor.ReqType);
      setValue('ReqJobTypes', requestor.ReqJobTypes || 'None');
      setValue('IsDefaultFinal', requestor.IsDefaultFinal);
      setValue('IsPrelimFinalMentionedSubject', requestor.IsPrelimFinalMentionedSubject);
      setValue('ReqEmailResponse', requestor.ReqEmailResponse);
      setValue('ReqCreatedDatetime', requestor.ReqCreatedDatetime);
      setNewRequestor(requestor?.NewRequestor);
    }
  }, [requestor, setValue]);

  const goToRequestorList = () => {
    navigate('/requestors');
  };
  // const jobTypeMappings: { [key: string]: string } = {
  //   'ASSR/ADSR - NATHers': 'ASSR, ADSR',
  //   'ASSR/BXSUB/BX/ADSR/BXSUB/BX - Basix': 'ASSR,BXSUB,BX, ADSR,BXSUB,BX',
  //   'AVSR/AVDR - Verification': 'AVSR, AVDR',
  //   'ASQD/ADQD - Deemed to Satisfy': 'ASQD, ADQD',
  //   'None': '',
  // };
  const jobTypeMappings: { [key: string]: string } = {
    'ASSR/ADSR/BSSR/BDSR - NATHers': 'ASSR,ADSR,BSSR,BDSR',
    'ASSR+BXSUB+BX/ADSR+BXSUB+BX/BSBX/BDBX - Basix': 'ASSR+BXSUB+BX,ADSR+BXSUB+BX,BSBX,BDBX',
    'AVSR/AVDR - Verification': 'AVSR,AVDR',
    'ASQD/ADQD - Deemed to Satisfy': 'ASQD,ADQD',
    'MJH/JGK-': 'MHSSR,MHDSR,MHSBX,MHDBX',
    None: '',
  };
  const onSubmit = async (dat: RequestorProfile) => {
    try {
      await CommentSchema.validate(dat, { abortEarly: false });
      if (dat.ReqContactPhone.trim() !== '') {
        const contactNumber = dat.ReqContactPhone?.replace('+61-', '');
        dat.ReqContactPhone = `+61-${contactNumber}`;
      } else {
        dat.ReqContactPhone = '';
      }
      if (dat.ReqJobTypes in jobTypeMappings) {
        dat.ReqJobTypes = jobTypeMappings[dat.ReqJobTypes];
      }

      const peopleString = selectedPeople.join(', ');
      console.log('People listed = ', peopleString);
      dat.PeopleListed = peopleString;
      const values = dat as any;

      if (NewRequestor === true) {
        values.NewRequestor = 'yes';
      } else {
        values.NewRequestor = 'no';
      }
      const formDataObj = new FormData();

      // formDataObj.append('file', image);
      formDataObj.append('requestor', JSON.stringify(values));
      axios
        .post(`/core/updateRequestor?Id=${id}&UserId=${user?.ID}`, formDataObj)
        .then((response) => {
          enqueueSnackbar(response.data, { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <Container> */}
      <Grid container display="flow">
        <Typography typography="h5" color="#788490">
          Requestor Id: {requestor?.ID} &nbsp;&nbsp;&nbsp;
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} md={12} xl={12}>
            {/* <Grid item xs={12} md={4}> */}
            {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
            {/* <Stack
                spacing={2}
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <RHFUploadAvatar
                  name="UserProfilePic"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  disabled={!edit}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 4,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />

                <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                  Role : Requestor
                </Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                    label="Created Date"
                    disabled
                    value={Date.now()}
                    onChange={setValues}
                  />
                </LocalizationProvider>
              </Stack> */}
            {/* </Card> */}
            {/* </Grid> */}
            <Grid item xs={12} md={12} sx={{ marginTop: '60px' }}>
              {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
              <Box
                rowGap={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="ReqName" label="Requestor Name*" disabled={!edit} />
                <RHFTextField name="ReqSubloc" label="Sub Location" disabled={!edit} />
                <RHFTextField name="ReqEmailDomain" label="Email Domain*" disabled={!edit} />
                {/* <RHFTextField name="ReqContactPhone" label="Phone Number" disabled={!edit} /> */}
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  <TextField
                    name="CountryCode"
                    label="Country Code"
                    disabled={!edit}
                    sx={{ flex: 1 }}
                    InputProps={{
                      value: '+61', // Set the initial value to +61
                      readOnly: true, // Make it non-editable
                    }}
                  />
                  <RHFTextField
                    name="ReqContactPhone"
                    label="Phone Number"
                    disabled={!edit}
                    sx={{ flex: 5 }} // Adjust the flex value to control the width
                    InputProps={{ inputProps: { maxLength: 10 } }} // Adjust max length as needed
                  />
                </Stack>
                {/* <RHFTextField name="ReqType" disabled={!edit} label="Request Type" /> */}
                <RHFSelect
                  native
                  name="ReqType"
                  label="Request Type*"
                  // placeholder="Request Type"
                  disabled={!edit}
                >
                  <option key="residential" value="residential">
                    Residential
                  </option>
                  <option key="commercial" value="commercial">
                    Commercial
                  </option>
                  <option key="both" value="both">
                    Both
                  </option>
                </RHFSelect>
                <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField name="Notes" multiline rows={3} label="Notes" disabled={!edit} />
                </Stack>
                {/* <RHFTextField name="ReqJobTypes" disabled={!edit} label="Job Types*" /> */}

                <RHFSelect native name="ReqJobTypes" label="Request Job Type*" disabled={!edit}>
                  <option key="none" value="None">
                    None
                  </option>
                  <option key="ASSR/ADSR/BSSR/BDSR - NATHers" value="ASSR,ADSR,BSSR,BDSR">
                    ASSR/ADSR/BSSR/BDSR - NATHers
                  </option>
                  <option
                    key="ASSR+BXSUB+BX/ADSR+BXSUB+BX/BSBX/BDBX - Basix"
                    value="ASSR+BXSUB+BX,ADSR+BXSUB+BX,BSBX,BDBX"
                  >
                    ASSR+BXSUB+BX/ADSR+BXSUB+BX/BSBX/BDBX - Basix
                  </option>
                  <option key="AVSR/AVDR - Verification" value="AVSR,AVDR">
                    AVSR/AVDR - Verification
                  </option>
                  <option key="ASQD/ADQD - Deemed to Satisfy" value="ASQD,ADQD">
                    ASQD/ADQD - Deemed to Satisfy
                  </option>
                  <option key="MJH/JGK" value="MHSSR,MHDSR,MHSBX,MHDBX">
                    MJH/JGK- MHSSR/MHDSR/MHSBX/MHDBX
                  </option>
                </RHFSelect>

                <RHFSelect
                  native
                  name="ReqStatus"
                  label="Status*"
                  disabled={!edit}
                  placeholder="Status"
                >
                  <option key="active" value="active">
                    Active
                  </option>
                  <option key="inactive" value="inactive">
                    Inactive
                  </option>
                </RHFSelect>
                <RHFSelect
                  disabled={!edit}
                  native
                  name="IsDefaultFinal"
                  label="Is default final or prelim*"
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="" />
                  <option key="final" value="final">
                    Final
                  </option>
                  <option key="prelim" value="prelim">
                    Prelim
                  </option>
                </RHFSelect>

                <RHFSelect
                  disabled={!edit}
                  native
                  name="IsPrelimFinalMentionedSubject"
                  label="Is prelim final mentioned in subject line*"
                  // placeholder="Status"
                  // value="active"
                >
                  <option value="" />
                  <option key="y" value="y">
                    Yes
                  </option>
                  <option key="n" value="n">
                    No
                  </option>
                </RHFSelect>
                <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField
                    disabled={!edit}
                    name="ReqEmailResponse"
                    multiline
                    label="Email Response"
                  />
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    disabled={disableDate}
                    renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                    label="Created Date"
                    value={requestor.ReqCreatedDatetime}
                    onChange={setCreatedDate}
                  />
                </LocalizationProvider>
                {/* <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField
                    disabled={!edit}
                    name="PeopleListed"
                    multiline
                    label="People Listed"
                  />
                </Stack> */}
                {/* <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField
                    disabled={!edit}
                    name="PeopleListed"
                    multiline
                    label="People Listed"
                  />
                </Stack> */}
                {edit && (
                  <TextField
                    label="Add People"
                    value={newPerson}
                    onChange={(event) => setNewPerson(event.target.value)}
                  />
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={NewRequestor}
                      name="NewRequestor"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="New Requestor"
                />

                <Dialog open={deleteConfirmOpen} onClose={handleCancelDelete}>
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete {personToDelete}?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleConfirmDelete} color="primary">
                      Yes
                    </Button>
                    <Button onClick={handleCancelDelete} color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
              {edit && (
                <Tooltip title="This button provides feature to Add members from same requestor manually." placement="top-end">
                <Button
                  variant="contained"
                  onClick={handleAddPerson}
                  sx={{ width: '10%', float: 'right', marginTop: '20px', marginLeft: '20px' }}
                >
                  Add People
                </Button>
                </Tooltip>
              )}
              <div style={{ marginTop: '65px' }}>
                <Typography sx={{ marginBottom: '10px' }} variant="h6">
                  People Listed
                </Typography>
                <Paper style={{ maxHeight: 150, overflow: 'auto', border: 'solid 1px' }}>
                  <List style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {selectedPeople.map((person, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={person} />
                        {edit && (
                          <ListItemSecondaryAction>
                            <IconButton onClick={() => handleDeleteClick(person)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </div>

              {/* </Card> */}
              <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                <Grid item>&nbsp;</Grid>
                {!edit && (
                  <Button variant="contained" onClick={goToRequestorList} color="primary">
                    Back
                  </Button>
                )}
                {edit && (
                  <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      onClick={goToRequestorList}
                      color="secondary"
                      sx={{ marginLeft: '10px' }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </>
  );
}
