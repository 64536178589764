// @mui
import { Tooltip, IconButton, Paper, PaperProps } from '@mui/material';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  onArchive?: VoidFunction;
  onDelete?: VoidFunction;
  onMarkRead?: VoidFunction;
  onRename?: VoidFunction;
  onDownload?: VoidFunction;
  onView?: any;
  publi:boolean;
  className?: string;
}

export default function FileAction({
  onArchive,
  onRename,
  onDelete,
  onMarkRead,
  onDownload,
  publi,
  onView,
  sx,
  ...other
}: Props) {
  const actions = [
    { name: 'View Document', icon: 'carbon:view-filled', action: onView },
    { name: 'Rename', icon: 'bx:rename', action: onRename },
    { name: 'download', icon: 'ph:download-fill', action: onDownload },    
    publi?{ name: 'Mark as Confidential', icon: 'mdi:confidential-mode', action: onArchive }:{ name: 'Mark as Public', icon: 'fluent:folder-public-20-filled', action: onArchive },
    { name: 'Delete', icon: 'eva:trash-2-outline', action: onDelete },
  ];

  return (
    <Paper
      variant="outlined"
      sx={{
        top: 12,
        right: 12,
        bottom: 12,
        zIndex: 99,
        opacity: 0,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        boxShadow: (theme) => theme.customShadows.z8,
        transition: (theme) => theme.transitions.create('opacity'),
        ...sx,
      }}
      {...other}
    >
      {actions.map((action) => (
        <Tooltip key={action.name} title={action.name}>
          <IconButton
            size="small"
            onClick={action.action}
            sx={{
              mx: 0.75,
              '&:hover':  {
                color: action.icon ===  'eva:trash-2-outline' ? 'text.secondary' : 'text.primary',
              },
            }}
          >
            <Iconify icon={action.icon} width={24} />
          </IconButton>
        </Tooltip>
      ))}
    </Paper>
  );
}
