// // @mui
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------
export default function StyledNotistack() {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        '#root': {
          '.SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: isLight ? theme.palette.common.white : theme.palette.grey[800],
            // backgroundColor: isLight ? theme.palette.grey[900] : theme.palette.common.white,
            [theme.breakpoints.up('md')]: {
              minWidth: 380,
            },
          },
          '.SnackbarItem-variantSuccess': {
            color: 'white',
            backgroundColor: '#2e8b57'
            // backgroundColor: theme.palette.background.paper, // Maintain the background color
          },
          '.SnackbarItem-variantError': {
            color: 'white',
            backgroundColor: 'red'
            // backgroundColor: theme.palette.background.paper, // Maintain the background color
          },
          
          '.SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '17px',
          },
          '.SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': {
              width: 30,
              height: 30,
            },
          },
        },
      }}
    />
  );

  return inputGlobalStyles;
}
