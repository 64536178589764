import {
  MouseEvent,
  useEffect,
  //  SetStateAction,
  useState,
} from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Stack,
  Typography,
  Box,
  Rating,
  LinearProgress,
  IconButton,
  MenuItem,
  Menu,
  Switch,
  FormControlLabel,
  Grid,
  Link,
  // Backdrop,
  // CircularProgress,
  // Grid,
} from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import {
  DataGrid,
  GridColDef,
  // GridToolbar,
  GridRowSelectionModel,
  getGridNumericOperators,
  GridFilterInputValueProps,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridRowParams,
  GridCsvExportMenuItem,
} from '@mui/x-data-grid';
// utils
import Iconify from 'src/components/iconify';
import { Helmet } from 'react-helmet-async';
// import { CustomAvatar } from 'src/components/custom-avatar';
import Label from 'src/components/label';
import './UrgentJobAssessorColor.css';
import { useAuthContext } from 'src/auth/useAuthContext';
import { fPercent } from 'src/utils/formatNumber';
import axios from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
// import { useNavigate } from 'react-router';

// components

// ----------------------------------------------------------------------

interface ActionsMenuProps {
  onView: (item: boolean, edit: boolean) => void;
}

function ActionsMenu({ onView }: ActionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (edit: boolean) => {
    onView(true, edit);
    handleClose();
  };
  // const navigate = useNavigate();
  //     const goToAssessorDashboard = () => {
  //       navigate('/assessorDashboard');
  //     };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem
          onClick={() => {
            handleDelete(false);
          }}
        >
          <Iconify icon="carbon:view-filled" sx={{ marginRight: 1 }} /> View
        </MenuItem> */}
        {userrole !== 'Assessor' && (
          <MenuItem
            onClick={() => {
              handleDelete(true);
            }}
          >
            <Iconify icon="bx:edit" sx={{ marginRight: 1 }} /> Edit
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
// ----------------------------------------------------------------------

// type Props = {
//   id: number;
//   name: string;
//   email: string;
//   lastLogin: Date;
//   occupency: number;
//   phoneNumber: string;
//   status: string;
//   address: string;
//   // lastName: string;
//   // firstName: string;
//   createdDate: Date;
// }[];

type Assessors = {
  id: number;
  Username: string;
  FirstName: string;
  LastName: string;
  Gender: string;
  Email: string;
  Phone: string;
  StreetAddress: string;
  State: string;
  Zipcode: string;
  Country: string;
  Status: string;
  ProfilePicture: string;
  Role: string;
  PasswordID: number;
  Timezone: string;
  LastLogin: string; // It can be converted to Date if needed
  CreatedAt: string; // It can be converted to Date if needed
  CreatedBy: number;
  ResetToken: string;
  ResetTokenExpiry: string; // It can be converted to Date if needed
}[];
interface ChildProps {
  data: Assessors;
  sendData: (data: string, edit: boolean) => void;
}

// const EnableDisableStatus = () => {
//   axios
//     .get(`/core/getEnableDisableStatus?userId=${id}`)
//     .then((response) => {
//       console.log("Enable status = ", response?.data?.status)
//       if (response?.data?.status === "enable") {
//         setIsEnabled(true);
//         // Continue with your logic here
//       } else {
//         setIsEnabled(false);
//         // Handle disabled state
//       }
//     })
//     .catch((error) => {
//       console.error('Error fetching assessor enable/disable status:', error);
//     });
// };
interface RowState {
  [key: string]: boolean;
}

interface Row {
  id: string;
  // Other properties of your row object
}
// const specificDate = new Date('Thu Jan 01 1970 05:30:00 GMT+0530');
export default function DataGridAssessor({ data, sendData }: ChildProps) {
  // console.log('Assessor data = ', data);
  // const [isEnabled, setIsEnabled] = useState(false);
  const [isEnabled, setIsEnabled] = useState<any>({});
  const [isEnabledMap, setIsEnabledMap] = useState<{ [key: number]: boolean }>({});
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const id = user?.ID;
  const [rowStates, setRowStates] = useState<RowState>({});

  useEffect(() => {
    const processedIsEnabled: { [key: string]: boolean } = {};
    data.forEach((row) => {
      processedIsEnabled[row.id] = row.Status === 'enable' || row.Status === '';
    });
    setIsEnabled(processedIsEnabled);
  }, [data]);

  const toggleEnableDisable = (row: string, event: any) => {
    const temp_data = {
      userId: String(row),
      loginUserId: String(user?.ID),
    };

    setIsEnabled((prevState: any) => ({
      ...prevState,
      [row]: event.target.checked,
    }));
    // console.log('Is enabled= ', isEnabled);
    // console.log('Checked = ', event.target.checked);
    const apiUrl = event.target.checked ? `/core/enableAutoAssign` : `/core/disableAutoAssign`;

    axios
      .post(apiUrl, temp_data)
      .then((response) => {
        enqueueSnackbar(response.data, { variant: 'success' });
      })
      .catch((error) => {
        console.error('Error toggling feature:', error);
        enqueueSnackbar('Error toggling feature:', { variant: 'error' });
      });
  };
  const renderEnableDisableSwitch = (row: any) => (
    <FormControlLabel
      control={
        <Tooltip title={row?.Status === 'enable' ? 'Enable' : 'Disable'}>
          <Switch
            checked={isEnabled[row?.id] || false}
            onChange={(event) => toggleEnableDisable(row?.id, event)}
            disabled={row?.UserStatus !== 'active'}
          />
        </Tooltip>
      }
      label=""
    />
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <Link underline='always'>{params.value}</Link>,
    },

    {
      field: 'UserUsername',
      headerName: 'User Name',
      align: 'left',
      headerAlign: 'left',
      // flex: 1,
      width: 280,
      renderCell: (params) => <Link underline='always'>{params.value}</Link>,
    },
    {
      field: 'UserFname',
      headerName: 'First Name',
      align: 'center',
      headerAlign: 'center',
      width: 150,
    },
    {
      field: 'UserLname',
      headerName: 'Last Name',
      align: 'left',
      headerAlign: 'left',
      // flex: 1,
      width: 150,
    },

    // {
    //   field: 'UserPhone',
    //   headerName: 'Phone',
    //   width: 150,
    //   valueFormatter: ({ value }) => `'${value}`,
    //   renderCell: (params) => (
    //     <Typography variant="body2" noWrap>
    //       {params.row.UserPhone}
    //     </Typography>
    //   ),
    // },
    // {
    //   field: 'FirstName',
    //   headerName: 'First Name',
    //   align: 'left',
    //   headerAlign: 'left',
    //   // flex: 1,
    //   width: 150,
    //   renderCell: (params) => (
    //     <Typography variant="body2" sx={{ textDecoration: 'underline' }} noWrap>
    //       {params.row.UserFname}
    //     </Typography>
    //   ),
    // },
    {
      field: 'UserCountry',
      headerName: 'Country',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserCreatedAt',
      headerName: 'Created At',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserCreatedBy',
      headerName: 'Created By',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserState',
      headerName: 'State',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserStreetAddr',
      headerName: 'Address',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    {
      field: 'UserZipcode',
      headerName: 'Zip Code',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 250,
    },
    // {
    //   field: 'StreetAddress',
    //   type: 'string',
    //   headerName: 'Address',
    //   width: 200,
    //   renderCell: (params) => (
    //     <Typography variant="body2" noWrap>
    //       {`${params.row.UserStreetAddr}, ${params.row.UserState}, ${params.row.UserCountry} - ${params.row.UserZipcode}`}
    //     </Typography>
    //   ),
    // },
    {
      field: 'UserStatus',
      type: 'singleSelect',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Status',
      valueOptions: ['active', 'inactive'],
      width: 160,
      renderCell: (params) => RenderStatus(params.row.UserStatus),
    },

    // {
    //   field: 'UserOccupency',
    //   type: 'number',
    //   headerName: 'Occupancy',
    //   align: 'center',
    //   headerAlign: 'center',
    //   width: 180,
    //   renderCell: (params) => (
    //     <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
    //       <LinearProgress
    //         value={params.row.UserOccupency}
    //         variant="determinate"
    //         color={
    //           (params.row.UserOccupency < 30 && 'error') ||
    //           (params.row.UserOccupency > 30 && params.row.UserOccupency < 70 && 'warning') ||
    //           'primary'
    //         }
    //         sx={{ width: 1, height: 6 }}
    //       />
    //       <Typography variant="caption" sx={{ width: 80 }}>
    //         {fPercent(params.row.UserOccupency)}
    //       </Typography>
    //     </Stack>
    //   ),
    // },
    {
      field: 'UserOccupency',
      type: 'number',
      headerName: 'Occupancy',
      align: 'center',
      headerAlign: 'left',
      width: 180,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          {params.row.UserOccupency >= 100 && params.row.UserOccupency <= 1000000 ? (
            <div style={{ width: '100%', height: '6px', backgroundColor: 'red' }} />
          ) : (
            <LinearProgress
              value={params.row.UserOccupency}
              variant="determinate"
              color={params.row.UserOccupency <= 99 ? 'success' : 'primary'}
              sx={{ width: 1, height: 6 }}
            />
          )}
          <Typography variant="caption" sx={{ width: 80 }}>
            {fPercent(params.row.UserOccupency)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'UserLastLogin',
      type: 'string',
      headerName: 'Last Login',
      align: 'left',
      headerAlign: 'left',
      width: 200,
      // renderCell: (params) => (
      //   <Typography variant="body2" noWrap>
      //     {params.row.UserLastLogin === ''
      //       ? 'Yet To Login'
      //       : new Date(params.row.UserLastLogin).toLocaleString()}
      //   </Typography>
      // ),
      valueFormatter: ({ value }) =>
        value === '1970-01-01 00:00:00' ? 'Not Yet Logged In' : `${value}`,
      // renderCell: (params) => (
      //   <Typography variant="body2" noWrap>
      //     {params.row.UserLastLogin.getTime() === specificDate.getTime()
      //       ? 'Yet To Login'
      //       : params.row.UserLastLogin.toLocaleString()}
      //   </Typography>
      // ),
    },
    // {
    //   field: 'UserLastLogin',
    //   type: 'dateTime',
    //   headerName: 'Last login',
    //   align: 'left',
    //   headerAlign: 'right',
    //   width: 170,
    //   editable: true,
    // },
    {
      field: 'TotalJobs',
      headerName: 'Current Jobs',
      width: 130,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'TotalCompleted',
      headerName: 'Completed Jobs',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'UserAvailable',
      headerName: 'User Available',
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <ActionsMenu
          onView={(val, edit) => {
            handleView(params.row, edit);
          }}
        />
      ),
    },

    {
      field: '',
      headerName: 'Enable/Disable',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => renderEnableDisableSwitch(params?.row),
    },
  ];
  const getRowClassName = (params: GridRowParams) => {
    if (params.row.JobIsUrgent === 'urgent') {
      return 'Urgentstatus-RowColor';
    }
    if (params.row.UserAvailable === false) {
      return 'AvailableUsersStatus-RowColor';
    }
    return '';
  };
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  // const [menu, setMenu] = useState<null | HTMLElement>(null);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setMenu(event.currentTarget);
  // };
  const handleView = (row: any, edit: boolean) => {
    sendData(row.id, edit);
  };

  if (columns.length > 0) {
    const ratingColumn = columns.find((column) => column.field === 'rating')!;
    const ratingColIndex = columns.findIndex((col) => col.field === 'rating');

    const ratingFilterOperators = getGridNumericOperators().map((operator) => ({
      ...operator,
      InputComponent: RatingInputValue,
    }));
    columns[ratingColIndex] = {
      ...ratingColumn,
      filterOperators: ratingFilterOperators,
    };
  }

  // const selected = data.filter((row) => selectionModel.includes(row.UserID));

  return (
    <>
      <Helmet>
        <title> EA Assessors</title>
      </Helmet>
      <DataGrid
        // checkboxSelection
        disableRowSelectionOnClick
        onRowDoubleClick={(params) => handleView(params.row, true)}
        rows={data}
        columns={columns}
        getRowClassName={getRowClassName}
        // columns={[...columns, { field: 'action', disableExport: true }]}
        initialState={{
          columns: {
            columnVisibilityModel: {
              UserCountry: false,
              UserCreatedBy: false,
              UserState: false,
              UserStreetAddr: false,
              UserZipcode: false,
              UserCreatedAt: false,
              UserLname: false,
              UserFname: true,
              UserAvailable: false,
            },
          },
        }}
        // pagination
        sx={{ height: '700px' }}
        onRowSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        slots={{
          toolbar: MyExportButton,
        }}
      />
    </>
  );
}

// ----------------------------------------------------------------------
function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Assessor_Data_${formattedDateTime}`;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fileName: file_Name,
            fields: [
              'id',
              'UserUsername',
              'UserFname',
              'UserLname',
              'UserPhone',
              'UserEmail',
              'UserCountry',
              'UserCreatedAt',
              'UserCreatedBy',
              'ReqType',
              'UserState',
              'UserStreetAddr',
              'UserZipcode',
              'UserStatus',
              'UserOccupency',
              'UserLastLogin',
              'TotalJobs',
              'TotalCompleted',
            ],
            // delimiter: ';',
            utf8WithBom: true,
            allColumns: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
function RenderStatus(getStatus: string) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Label
      variant={isLight ? 'soft' : 'filled'}
      color={((getStatus === 'inactive' || getStatus === 'deleted') && 'error') || 'success'}
      sx={{ mx: 'auto' }}
    >
      {getStatus}
    </Label>
  );
}

// ----------------------------------------------------------------------

function RatingInputValue({ item, applyValue }: GridFilterInputValueProps) {
  return (
    <Box sx={{ p: 1, height: 1, alignItems: 'flex-end', display: 'flex' }}>
      <Rating
        size="small"
        precision={0.5}
        placeholder="Filter value"
        value={Number(item.value)}
        onChange={(event, newValue) => {
          applyValue({ ...item, value: newValue });
        }}
      />
    </Box>
  );
}
