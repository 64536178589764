import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, InputAdornment, TextField, Box } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import axios from 'src/utils/axios';
import { Helmet } from 'react-helmet-async';
import DataGridRequestor from './DataGridCustom';

export default function ListRequestor() {
  const { user } = useAuthContext();
  const [showRequestorNameColumn, setShowRequestorNameColumn] = useState(true);
  const userrole = `${user?.UserRole ?? ''}`;
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [currentExpandedButton, setCurrentExpandedButton] = useState<string | null>(null);

  const [requestors, setRequestors] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [expandedReqNames, setExpandedReqNames] = useState<string[]>([]);
  const [showAllData, setShowAllData] = useState(true);
  const [groupedReqNameSearch, setGroupedReqNameSearch] = useState<string>('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleGroupedReqNameSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setGroupedReqNameSearch(value);
  };

  const handleToggleReqName = (reqName: string) => {
    if (currentExpandedButton === reqName) {
      // Collapse the currently expanded button
      setCurrentExpandedButton(null);
    } else {
      // Expand the clicked button
      setCurrentExpandedButton(reqName);
    }
  };

  const handleToggleShowAllData = () => {
    // If there is an expanded grouped button, collapse it
    if (currentExpandedButton) {
      setCurrentExpandedButton(null);
      setShowRequestorNameColumn(true); // Show Requestor Name column when collapsing
    }

    // Toggle between tree format and table format
    setShowAllData((prev) => !prev);
    setExpandedReqNames([]);
  };

  useEffect(() => {
    const fetchRequestors = async () => {
      try {
        const response = await axios.get('/core/getAllRequestors');
        const requestorsArray = response.data.map(({ ID, ...rest }: any) => ({
          id: ID,
          ...rest,
        }));
        setRequestors(requestorsArray);
      } catch (error) {
        console.error('Error fetching Requestors:', error);
      }
    };
    fetchRequestors();
  }, []);

  const groupedRequestors: { [key: string]: any[] } = {};
  if (requestors) {
    requestors.forEach((requestor: any) => {
      const reqName: string = requestor.ReqName.toLowerCase();
      if (!groupedRequestors[reqName]) {
        groupedRequestors[reqName] = [];
      }
      groupedRequestors[reqName].push(requestor);
    });
  }

  const filteredRequestors = searchQuery
    ? requestors.filter((requestor: any) => {
        const searchLower = searchQuery.toLowerCase();
        const searchUpper = searchQuery.toUpperCase();

        const idMatch =
          requestor.id.toString().toLowerCase().includes(searchLower) ||
          requestor.id.toString().toUpperCase().includes(searchUpper);
        const reqNameMatch =
          requestor.ReqName.toLowerCase().includes(searchLower) ||
          requestor.ReqName.toUpperCase().includes(searchUpper);
        const emailMatch =
          requestor.ReqEmailDomain.toLowerCase().includes(searchLower) ||
          requestor.ReqEmailDomain.toUpperCase().includes(searchUpper);
        const subLocationMatch =
          requestor.ReqSubloc.toLowerCase().includes(searchLower) ||
          requestor.ReqSubloc.toUpperCase().includes(searchUpper);

        return idMatch || reqNameMatch || emailMatch || subLocationMatch;
      })
    : requestors;

  const goToAddRequestor = () => {
    navigate('/addrequestor', { state: { requestors } });
  };

  const handleView = (value: string, edit: boolean) => {
    goToViewWorkRequestor(value, edit);
  };

  const goToViewWorkRequestor = (id: string, edit: boolean) => {
    navigate(`/viewrequestor?id=${id}&edit=${edit}`);
  };

  const filteredGroupedReqNames = groupedReqNameSearch
    ? Object.keys(groupedRequestors).filter((reqName) =>
        reqName.toLowerCase().includes(groupedReqNameSearch.toLowerCase())
      )
    : Object.keys(groupedRequestors);
  // const filteredGroupedReqNames = groupedReqNameSearch
  // ? Object.keys(groupedRequestors).filter((reqName) =>
  //     reqName.toLowerCase().includes(groupedReqNameSearch.toLowerCase())
  //   )
  //   // Additionally, check if the search query is present in any of the IDs
  //   .filter((reqName) =>
  //     groupedRequestors[reqName].some((requestor) =>
  //       requestor.id.toString().toLowerCase().includes(groupedReqNameSearch.toLowerCase())
  //     )
  //   )
  // : Object.keys(groupedRequestors);

  return (
    <div>
      <Helmet>
        <title> EA Requestor Data</title>
      </Helmet>
      <Container sx={{ maxWidth: '1710px !important', marginTop: 2 }}>
        {!isVisible && (
          <Grid container sx={{ marginTop: 2, marginBottom: 2 }} justifyContent="space-between">
            <Grid item>
              {(userrole === 'Management' || userrole === 'Workflow') && (
                <>
                  <Tooltip title="This button provides feature to Create a requestor manually." placement="top-end">
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={goToAddRequestor}
                    sx={{ borderRadius: 0, marginRight: 0 }}
                  >
                    Add Requestor
                  </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      showAllData
                        ? 'Click here to view Tree Format'
                        : 'Click here to view Table Format'
                    }
                    arrow
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={handleToggleShowAllData}
                      sx={{ borderRadius: 0, marginLeft: 2 }}
                    >
                      {showAllData ? 'Tree Format' : 'Table Format'}
                    </Button>
                  </Tooltip>
                </>
              )}
            </Grid>
            <Grid item>
              {showAllData ? (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search Requestor"
                  type="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="bi:search" width={24} />
                      </InputAdornment>
                    ),
                  }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  title="Search will be applicable for Id, Requestor Name, Sub Location, Email Domain"
                />
              ) : (
                !currentExpandedButton && ( // Added condition to hide when a group is expanded
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search Group Name"
                    type="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="bi:search" width={24} />
                        </InputAdornment>
                      ),
                    }}
                    value={groupedReqNameSearch}
                    onChange={handleGroupedReqNameSearchChange}
                    title="Search will be applicable for Grouped Requestor Name"
                  />
                )
              )}
            </Grid>
          </Grid>
        )}

        {showAllData ? (
          <DataGridRequestor
            data={filteredRequestors}
            expandedReqNames={expandedReqNames}
            onToggleReqName={handleToggleReqName}
            sendData={(value, edit) => {
              handleView(value, edit);
            }}
            showAllData={showAllData}
          />
        ) : (
          filteredGroupedReqNames.map((reqName, index) => (
            <div key={reqName} style={{ marginBottom: '10px' }}>
              <Button
                onClick={() => handleToggleReqName(reqName)}
                sx={{
                  borderRadius: '5px',
                  backgroundColor: '#535659',
                  width: '262px',
                  height: '55px',
                  color: 'white',
                  padding: '10px',
                  fontSize: '16px',
                  marginRight: index < filteredGroupedReqNames.length - 1 ? '10px' : '0',
                  display:
                    currentExpandedButton === null || currentExpandedButton === reqName
                      ? 'block'
                      : 'none',
                  '&:hover': {
                    backgroundColor: '#222233',
                  },
                }}
              >
                {reqName}
                {currentExpandedButton === reqName && (
                  <RemoveIcon
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      // backgroundColor: 'orange'
                    }}
                  />
                )}
                {currentExpandedButton !== reqName && (
                  <AddIcon
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      // backgroundColor: 'black'
                    }}
                  />
                )}
              </Button>

              {currentExpandedButton === reqName && (
                <DataGridRequestor
                  data={groupedRequestors[reqName]}
                  expandedReqNames={expandedReqNames}
                  onToggleReqName={handleToggleReqName}
                  sendData={(value, edit) => {
                    handleView(value, edit);
                  }}
                  showAllData={showAllData}
                />
              )}
            </div>
          ))
        )}
      </Container>
      {isVisible && (
        <Box sx={{ marginTop: 4, marginLeft: 5 }}>{/* ... (ViewAssessor component) */}</Box>
      )}
    </div>
  );
}
