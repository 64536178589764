import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Chart from 'src/components/chart';
import { position } from 'stylis';
import Image from '../../components/image';
import './AnalysisCharts.css';

interface Props {
  reportData: any;
}

export default function Analysis2({ reportData, saveReportData, setSaveReportData }: any) {
  let total_direction_size = 0;
  (reportData?.windowsDirectionData || []).forEach((item: { area?: number }) => {
    total_direction_size += item.area || 0;
  });

  let total_area_size = 0;
  total_area_size =
    (reportData?.areasData[0]?.size || 0) +
    (reportData?.areasData[1]?.size || 0) -
    (reportData?.areasData[2]?.size || 0);
  console.log('total_area_size = ', total_area_size);
  const total_percentage = ((total_direction_size / total_area_size) * 100).toFixed(1);

  let percentage_color = '';
  const percentage = parseFloat(total_percentage);

  if (percentage <= 21) {
    percentage_color = '#00B050';
  } else if (percentage > 21 && percentage <= 22) {
    percentage_color = '#84AC04';
  } else if (percentage > 22 && percentage <= 24) {
    percentage_color = '#B9CC00';
  } else if (percentage > 24 && percentage <= 26) {
    percentage_color = '#FFA500';
  } else if (percentage > 26 && percentage <= 30) {
    percentage_color = '#FF4500';
  } else if (percentage > 30) {
    percentage_color = '#9A0000';
  }

  console.log('Percentage = ', percentage);
  console.log('Percentage color = ', percentage_color);

  const [mixedChartSeries, SetMixedChartSeries] = useState<any>([]);
  const [mixedChartOptions, SetMixedChartOptions] = useState<any>();
  const [jobRecordId, SetJobRecordId] = useState<number>(0);

  const [averageGlazingRatio, SetAverageGlazingRatio] = useState<number>(0);
  const [windowDoorSeries, SetWindowDoorSeries] = useState<any>([]);
  const [windowDoorOptions, SetWindowDoorOptions] = useState<any>();

  useEffect(() => {
    if (reportData) {
      if (reportData.zonesData) {
        const zoneData: any[] = reportData.zonesData;
        // Plot Mixed Chart
        plotMixedChart(zoneData, reportData.projectInfoData?.NccType);
        plotWindowFloorAreaRationChart(zoneData);
      }

      if (reportData.windowsDirectionData) {
        const windowsDirectionData: any[] = reportData.windowsDirectionData;
        // Plot Window & Door Orientation Horizontal Bar Chart
        plotWindowDoorBarChart(windowsDirectionData, reportData?.areasData[0].size);
      }

      SetJobRecordId(reportData?.projectInfoData?.job_record_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  function plotMixedChart(zoneData: any[], nccType: string) {
    const totalHeatingArray: number[] = [];
    const totalCoolingArray: number[] = [];
    const roomAreas: number[] = [];
    const labels: string[] = [];

    let maxValue: number = 0;
    let maxAreaValue: number = 0;

    zoneData.map((zone: any) => {
      if (zone.totalHeating > 0 && zone.totalCooling > 0) {
        totalHeatingArray.push(zone.totalHeating);
        totalCoolingArray.push(zone.totalCooling);
        roomAreas.push(zone.area);
        labels.push(zone.zone);

        const value = Math.max(zone.totalHeating, zone.totalCooling);
        if (value > maxValue) {
          maxValue = Math.round(value);
        }

        if (zone.area > maxAreaValue) {
          maxAreaValue = Math.round(zone.area);
        }
      }
      return zone;
    });

    const remainder: number = maxValue % 200;
    if (remainder !== 0) {
      maxValue = maxValue + 200 - remainder;
    }
    maxValue = Math.ceil(maxValue);

    const areaRemainder: number = maxAreaValue % 2;
    if (areaRemainder !== 0) {
      maxAreaValue = maxAreaValue + 2 - areaRemainder;
    }
    maxAreaValue = Math.ceil(maxAreaValue);

    let curveType = 'straight';
    if (nccType === '2019') {
      curveType = 'smooth';
    }

    const series = [
      {
        name: 'Total Heating (MJ)',
        type: 'column',
        data: totalHeatingArray,
      },
      {
        name: 'Total Cooling (MJ)',
        type: 'column',
        data: totalCoolingArray,
      },
      {
        name: 'Room Areas',
        type: 'line',
        data: roomAreas,
      },
    ];
    SetMixedChartSeries(series);

    const options = {
      chart: {
        id: 'mixedChart',
        height: '100%',
        type: 'line',
        stacked: false,
        background: '#ffffff',
        toolbar: {
          show: false,
        },
      },
      colors: ['#ffa5a4', '#ccb9e9', '#f5a96b'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 4],
        curve: curveType,
        colors: ['#c05a57', '#7d629e', '#f5a96b'],
      },
      xaxis: {
        categories: labels,
        labels: {
          rotate: -26,
          rotateAlways: true,
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#808080',
          },
          labels: {
            style: {
              colors: '#808080',
            },
          },
          title: {
            text: 'ENERGY LOADS (MJ)',
            style: {
              color: '#808080',
            },
          },
          tooltip: {
            enabled: true,
          },
          min: 0,
          max: maxValue,
          stepSize: 200,
          decimalsInFloat: 0,
          // tickAmount: maxValue / 200
        },
        {
          show: false,
          seriesName: 'Total Heating (MJ)',
          opposite: false,
        },
        {
          seriesName: 'RoomAreas',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#808080',
          },
          labels: {
            style: {
              colors: '#808080',
            },
          },
          title: {
            text: '',
            style: {
              color: '#808080',
            },
          },
          min: 0,
          max: maxAreaValue,
          stepSize: 2,
          decimalsInFloat: 0,
          // tickAmount: maxAreaValue / 2
        },
      ],
      tooltip: {
        fillSeriesColor: true,
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
        theme: 'dark',
      },
      legend: {
        horizontalAlign: 'center',
        position: 'bottom',
        labels: {
          colors: '#000000',
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: '60%',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          inverseColors: true,
        },
      },
    };
    SetMixedChartOptions(options);
  }

  function plotWindowDoorBarChart(windowsDirectionData: any[], area: number) {
    let windowData: number[] = [];
    const labels: string[] = [];

    let glazing = 0;
    let maxRange = 0;

    windowsDirectionData.map((windowDirection: any) => {
      windowData.push(windowDirection.area);
      if (windowDirection.area > maxRange) {
        maxRange = windowDirection.area;
      }
      labels.push(windowDirection.direction);

      glazing += windowDirection.area;
      return windowDirection;
    });

    const remainder: number = maxRange % 2;
    if (remainder !== 0) {
      maxRange = maxRange + 2 - remainder;
    }
    maxRange = Math.ceil(maxRange);

    windowData.reverse();
    labels.reverse();
    const updatedData = windowData.map((entry) =>
      parseFloat(((entry / total_area_size) * 100).toFixed(2))
    );
    windowData = updatedData;

    SetWindowDoorSeries([
      {
        name: 'Area',
        data: windowData,
      },
    ]);
    const options = {
      chart: {
        id: 'windowDoorChart',
        type: 'bar',
        toolbar: {
          show: false,
        },
        background: '#ffffff',
      },
      colors: ['#b3a2c7', '#95b3d7', '#31859c', '#d99694'],
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          barHeight: '20%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: labels,
        title: {
          text: 'AREA RATIO (%)',
          style: {
            fontWeight: '100',
          },
        },
        max: maxRange,
        min: 0,
        stepSize: 2,
        tickAmount: maxRange / 2,
        decimalsInFloat: 0,
      },
      legend: {
        show: false,
      },
      tooltip: {
        fillSeriesColor: true,
        theme: 'dark',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };
    SetWindowDoorOptions(options);
    // Average Glazing Ratio
    if (area > 0) {
      const ratio = (glazing / area) * 100;
      SetAverageGlazingRatio(ratio);
    }
  }

  const [windowFloorAreaRatioSeries, SetWindowFloorAreaRatioSeries] = useState<any>([]);
  const [windowFloorAreaRatioOptions, SetWindowFloorAreaRatioOptions] = useState<any>();

  function plotWindowFloorAreaRationChart(zoneData: any[]) {
    const zoneDataDesc = [...zoneData].sort((a, b) => b.area - a.area);

    const areaRatios: number[] = [];
    const label: string[] = [];
    let index: number = 0;
    zoneDataDesc.map((zone: any) => {
      if (zone.areaRatio > 0 && index < 8) {
        areaRatios.push(zone.areaRatio);
        label.push(zone.zone);
        index += 1;
      }
      return zone;
    });

    // SetWindowFloorAreaRatioSeries(areaRatios);
    SetWindowFloorAreaRatioSeries([
      {
        name: 'Floor Area Ratio',
        data: areaRatios,
      },
    ]);
    // const value = (opts.w.config.labels[opts.seriesIndex]+" "+opts.w.config.series[opts.seriesIndex] as string);
    const windowFloorOptions = {
      chart: {
        id: 'windowFloorChart',
        type: 'bar',
        toolbar: {
          show: false,
        },
        height: '100%',
      },
      labels: label,
      responsive: [
        {
          breakpoint: 480,
        },
      ],
      legend: {
        show: false,
      },
      stroke: {
        show: false,
      },
      // dataLabels: {
      //   // enabled: true,
      //   dropShadow: {
      //     enabled: false,
      //   },
      //   formatter: (val: any, opts: any) => [
      //     `${opts.w.config.labels[opts.seriesIndex]}`,
      //     `${opts.w.config.series[opts.seriesIndex]}`,
      //   ],
      //   style: {
      //     fontWeight: 'bold',
      //     colors: ['#808080'],
      //   },
      // },
      fill: {
        colors: [
          '#4F81BD',
          '#C0504D',
          '#9BBB59',
          '#8064A2',
          '#4BACC6',
          '#F79646',
          '#2C4D75',
          '#772C2A',
        ],
      },
      plotOptions: {
        // pie: {
        //   donut: {
        //     labels: {
        //       show: true,
        //       total: {
        //         show: true,
        //         showAlways: true,
        //         label: 'Top 8 Window to Floor\nArea Ratios',
        //         color: '#ffffff',
        //         fontSize: '16px',
        //         formatter: (w: any) =>
        //           w.globals.seriesTotals.reduce((a: any, b: any) => parseFloat((a + b).toFixed(2))),
        //       },
        //     },
        //   },
        //   dataLabels: {
        //     offset: 50,
        //   },
        // },
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          barHeight: '40%',
        },
      },
      colors: [
        '#4F81BD',
        '#C0504D',
        '#9BBB59',
        '#8064A2',
        '#4BACC6',
        '#F79646',
        '#2C4D75',
        '#772C2A',
      ],
      dataLabels: {
        enabled: false,
      },
    };
    SetWindowFloorAreaRatioOptions(windowFloorOptions);
  }

  function exportAsImage(chartId: string) {
    const chart = ApexCharts.getChartByID(chartId);
    chart?.dataURI().then((base64: any) => {
      console.log('Base 64 :: ', base64.imgURI);
    });
  }

  if (jobRecordId <= 0) return <></>;

  function getMessage(total_percentages: any) {
    switch (true) {
      case Number(total_percentages) <= 21:
        return (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              This dwelling’s glazing ratio is fantastic, helping this dwelling maintain a
              consistent temperature without excessive heating or cooling, which means energy
              savings and a better environmental footprint. If the dwelling is getting a
              less-than-compliant rating, it may be beneficial to look at the orientation chart
              below and move some window/doors to other walls.
            </Box>
          </Stack>
        );
      case Number(total_percentages) <= 22:
        return (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              Given the glazing-to-floor area ratio of this dwelling, it receives a good amount of
              natural light without exceeding a good overall glass area, which contributes to lower
              energy costs and achieves a good balance for daily comfort.
            </Box>
          </Stack>
        );
      case Number(total_percentages) <= 24:
        return (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              The glazing in this dwelling provides plenty of light while effectively managing
              energy use. Small, smart adjustments could enhance overall efficiency and also
              possibly help to reduce compliance upgrade costs.
            </Box>
          </Stack>
        );
      case Number(total_percentages) <= 26:
        return (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              The current glazing ratio, while not excessively high, does suggest opportunities to
              improve the dwellings thermal comfort. Modifying some window or door sizes could
              result in greater heating and cooling cost savings and improved liveability.
            </Box>
          </Stack>
        );
      case Number(total_percentages) <= 28:
        return (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              This dwelling, with its fairly high glazing-to-floor area ratio, has the potential to
              improve its energy performance. Reductions to some of the sizes of certain windows or
              doors could lead to a cozier space in the cold and a cooler one in the warmer summer
              months.
            </Box>
          </Stack>
        );
      default:
        return (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              This homes fairly high glazing-to-floor area ratio means that there is considerable
              heat loss, through the glass (conductance) in the colder months but also unwanted heat
              gain in the hot months. This results in high heating and cooling costs throughout the
              year. Considering size reductions could significantly improve energy efficiency and
              help manage costs more effectively.
            </Box>
          </Stack>
        );
    }
  }

  return (
    <Grid item xs={12} sm={12} md={9}>
      {/* <Button
                        variant="contained"
                        size="medium"
                        sx={{ borderRadius: 0 }}
                        onClick={() => exportAsImage('windowFloorChart')}
                    >
                        Download PDF
                </Button> */}
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE ANALYSIS</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack textAlign="center">
          <Box sx={{ color: '#17a986', typography: 'caption' }}>
            <Typography typography="h3">Understanding the Window Ratio</Typography>
          </Box>
        </Stack>

        <Stack textAlign="center">
          <Box sx={{ typography: 'h6', fontWeight: '100' }}>
            The ratio below shows the percentage of the dwellings exterior thats made up of glass
            compared to the floor area. Ideally, a lower percentage contributes to better thermal
            efficiency, reducing energy loss and lowering energy bills.
          </Box>
        </Stack>

        {getMessage(total_percentage)}

        {/* {Number(total_percentage) < 21 && (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              This dwelling’s glazing ratio is fantastic, helping this dwelling maintain a
              consistent temperature & without excessive heating or cooling, which means energy
              savings and a better environmental footprint. & If the dwelling is getting a
              less-than-compliant rating, it may be beneficial to look at the orientation & chart
              below and move some window/doors to other walls.
            </Box>
          </Stack>
        )}

        {Number(total_percentage) <= 22 && (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              Given the glazing-to-floor area ratio of this dwelling, it receives a good amount of
              natural light & without exceeding a good overall glass area, which contributes to
              lower energy costs and achieves a good & balance for daily comfort.
            </Box>
          </Stack>
        )}

        {Number(total_percentage) <= 24 && (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              The glazing in this dwelling provides plenty of light while effectively managing
              energy use. & Small, smart adjustments could enhance overall efficiency and also
              possibly help to reduce compliance & upgrade costs.
            </Box>
          </Stack>
        )}

        {Number(total_percentage) <= 26 && (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              The current glazing ratio, while not excessively high, does suggest opportunities to
              improve the dwellings & thermal comfort. Modifying some window or door sizes could
              result in greater heating and cooling cost & savings and improved liveability.
            </Box>
          </Stack>
        )}

        {Number(total_percentage) <= 28 && (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              This dwelling, with its fairly high glazing-to-floor area ratio, has the potential to
              improve its & energy performance. Reductions to some of the sizes of certain windows
              or doors could lead to a cozier & space in the cold and a cooler one in the warmer
              summer months.
            </Box>
          </Stack>
        )}

        {Number(total_percentage) > 28 && (
          <Stack textAlign="center">
            <Box sx={{ typography: 'h6', fontWeight: '100', marginTop: '30px' }}>
              This homes fairly high glazing-to-floor area ratio means that there is considerable
              heat loss, & through the glass (conductance) in the colder months but also unwanted
              heat gain in the hot months. & This results in high heating and cooling costs
              throughout the year. & Considering size reductions could & significantly improve
              energy efficiency and help manage costs more effectively.
            </Box>
          </Stack>
        )} */}

        <Stack spacing={2} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={8} sx={{ marginTop: '20px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              textAlign="center"
              sx={{ minWidth: 400, flex: '0 1', fontSize: '5rem' }}
            >
              <Typography sx={{ fontSize: '7rem', color: percentage_color }}>
                {total_percentage} %
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 300, flex: '1 1' }}>
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src="/assets/images/about/Image3.png"
                sx={{ maxWidth: '100%', height: '280px' }}
              />
            </Stack>
          </Stack>
        </Stack>
        {reportData.projectInfoData?.NccType === '2022' && (
          <>
            <Stack textAlign="center">
              <Box sx={{ color: '#17a986', typography: 'caption', marginTop: '40px' }}>
                <Typography typography="h4">Top 8 Window to Floor Area Ratios per Room</Typography>
              </Box>
            </Stack>
            <Grid item xs={12} sm={12} md={3} border="1px solid" sx={{ height: '350px' }}>
              <Chart
                type="bar"
                series={windowFloorAreaRatioSeries}
                options={windowFloorAreaRatioOptions}
                height={350}
              />
            </Grid>
          </>
        )}

        <Stack textAlign="center">
          <Box sx={{ color: '#17a986', typography: 'caption', marginTop: '40px' }}>
            <Typography typography="h4">Mapping Out Glass: Orientation Analysis</Typography>
          </Box>
        </Stack>

        <Stack textAlign="center">
          <Box sx={{ typography: 'h6', fontWeight: '100' }}>
            The chart maps out the distribution of glass, showing their orientation around the house
            as percentages of the total floor area.
          </Box>
        </Stack>

        <Stack textAlign="center">
          <Box sx={{ typography: 'h6', fontWeight: '100' }}>
            <b>NORTH:</b> Increase the proportion of minimally shaded glass facing north: between
            10-15%
            <br />
            <b>EAST: </b>East-facing glass should be kept to under 8%.
            <br /> <b>SOUTH: </b>South-facing glass should be reduced as much as possible; aiming
            for 5% or less.
            <br />
            <b>WEST: </b> Aim to limit glass on the west to less than 5% of the floor area to
            prevent excess heat gain.
          </Box>
        </Stack>

        <Stack textAlign="center">
          <Box sx={{ typography: 'h6', fontWeight: '100' }}>
            These are guides to help enhance your homes thermal efficiency. For detailed shading
            strategies, refer to the floor plans.
          </Box>
        </Stack>

        <Grid item xs={12} sm={12} md={3} border="1px solid">
          <Chart type="bar" series={windowDoorSeries} options={windowDoorOptions} height={300} />
        </Grid>

        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          sx={{ marginTop: '22px' }}
        >
          <Stack direction="row">
            <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '22px' }}>
              <img
                src="./ea-report-footer.png"
                alt="Logo"
                style={{ width: '20vh', height: '10vh' }}
              />
            </Box>
            <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
            <Stack direction="column" sx={{ marginTop: '45px' }}>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                energy@energyadvance.com.au
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                ACN: 60 9332014 | 1300 850 228
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: '#919eab3d',
              p: '0.5rem',
              padding: '3rem',
            }}
          >
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>COMPLIANCE STAMP NOT </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}
