import React, { useState, useEffect, useRef } from 'react';
import {
  // Box,
  // Button,
  Card,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  // Stack,
  // Typography,
  styled,
} from '@mui/material';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Avatar from '@mui/material/Avatar';
// import PersonIcon from '@mui/icons-material/Person';
// import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import Iconify from 'src/components/iconify';
import ChatScreen from '../Documents/ChatScreen';
import DocumentScreen from '../Documents/DocumentScreen';
import HistoryScreen from '../ChatHistory/HistoryScreen';

export default function NewChat() {
  const [screen, setScreen] = useState<string>('chatScreen');
  const [selectedScreen, setSelectedScreen] = useState<string>('chatScreen');

  const handleScreenChange = (sscreen: string) => {
    setSelectedScreen(sscreen);
  };

  const isButtonSelected = (sscreen: string) => selectedScreen === sscreen;

  return (
    <Grid container spacing={2} sx={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Grid item xs={12} sm={12} md={10}>
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            {
              <Grid container direction="row" spacing={1} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={3}>
                  {/* <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                      <Iconify icon="carbon:task-complete" width={24} />
                    </Card>{' '} */}
                </Grid>
              </Grid>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={12} md={3}> */}
              <Card
                sx={{
                  // maxWidth: 200,
                  marginTop: '11px',
                  width: 200,
                  height: 200,
                  borderRadius: 2,
                  // marginLeft:'90px'
                }}
              >
                <List>
                  {/* <ListItemButton onClick={() => setScreen('chatScreen')}> */}
                  <ListItemButton
                    onClick={() => handleScreenChange('chatScreen')}
                    selected={isButtonSelected('chatScreen')}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="clarity:new-solid" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="New Chat" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => handleScreenChange('documentScreen')}
                    selected={isButtonSelected('documentScreen')}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="mdi:assignment" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Documents" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => handleScreenChange('chatHistoryScreen')}
                    selected={isButtonSelected('chatHistoryScreen')}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="ic:baseline-assessment" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Chat History" />
                  </ListItemButton>
                </List>
              </Card>
              {/* </Grid> */}
              <Grid item xs={12} sm={12} md={9}>
                {selectedScreen === 'chatScreen' && <ChatScreen />}
                {selectedScreen === 'documentScreen' && <DocumentScreen />}
                {selectedScreen === 'chatHistoryScreen' && <HistoryScreen />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const CHART_HEIGHT = 459;
const LEGEND_HEIGHT = 70;

const StyledChartWrapper = styled('div')(({ theme }: any) => ({
  height: CHART_HEIGHT,

  // marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-graphical': {
    transform: 'translate(0, 0) !important',
  },

  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center !important',
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));
