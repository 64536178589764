import {  Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
// import { useState } from "react";
// import Iconify from "src/components/iconify";
// import AddAssessorProfile from "./Profile Tab";
// import AddAssessorScheduleTab from "./Schedule Tab";
// import AddAssessorSkillsetTab from "./Skillset Tab";
import HorizontalLinearStepper from "./HorizontalLinearStepper";
// import image from "../src/assets/energy-advance.png";

export default function AddAssessorUser() {
    // const TABS = [
    //     {
    //         value: 'profile',
    //         label: 'Profile',
    //         icon: <Iconify icon="mdi:account-circle" />,
    //         component: <AddAssessorProfile />,
    //     },
    //     {
    //         value: 'schedule',
    //         label: 'Schedule',
    //         icon: <Iconify icon="uis:schedule" />,
    //         component: <AddAssessorScheduleTab  />,
    //     },
    //     {
    //         value: 'skillset',
    //         label: 'Skillset',
    //         icon: <Iconify icon="material-symbols:overview" />,
    //         component: <AddAssessorSkillsetTab  />,
    //     },

    // ];
    // const [currentTab, setCurrentTab] = useState('profile');
   
    const { themeStretch } = useSettingsContext();

    return (
       
            <Container sx={{ maxWidth: '100% !important' ,paddingTop:'50px',paddingBottom:'50px'}}>
                <Helmet>
                    <title> Assessor: Create Assessor | Energy Advance</title>
                </Helmet>


                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <CustomBreadcrumbs
                    heading="Create New Assessor"
                    links={[
                       
                        {
                        name: 'Assessor',
                        href: "/assessorlist",
                        },
                        { name: 'New Assessor' },
                    ]}
                    />
                <HorizontalLinearStepper  />
                
                </Container>
            </Container>




    )
}




