import React, { useEffect, useState, useRef } from 'react';
import {
  Stack,
  Card,
  Divider,
  List,
  ListItem,
  // ListItemText,
  Typography,
  Modal,
  Link,
  Container,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useAuthContext } from 'src/auth/useAuthContext';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useSnackbar } from 'notistack';
import DocViewer, { DocViewerRef, DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import { writeText } from 'clipboard-polyfill';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import axios from 'src/utils/axios';
import Scrollbar from 'src/components/scrollbar';

interface ChatHistoryItem {
  quest_id: number;
  question: string;
  ansresponse: string;
  answer: string;
  reference_docs: any;
  qtime: string;
  atime: string;
}
// interface DocumentData {
//   DocLocation: string;
// }

// interface ActionsMenuProps {
//   onView: (item: boolean, edit: boolean) => void;
//   onDownload: () => void;
//   data: DocumentData;
//   fetchDocument: () => void;
// }

// interface ChatHistoryResponse {
//   userid: number;
//   id: number;
//   status: string;
//   status_msg: string;
//   data: ChatHistoryItem[];
// }



const ChatHistory: React.FC = () => {
  const { user } = useAuthContext();

  const [chatHistory, setChatHistory] = useState<ChatHistoryItem[]>([]);
  const [loading, setLoading] = useState(false);
  // const [chatHistoryPayload, setChatHistoryPayload] = useState<any>(null);
  const userid = user?.ID ?? '';
  // const userrole = `${user?.UserRole ?? ''}`;
  // const userid_str = `${user?.ID ?? '0'}`;
  // const userid = parseInt(userid_str, 10);
  // const [apiResponse, setApiResponse] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [openFile, setFileOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleFileClose = () => setFileOpen(false);
  const docViewerRef = useRef<DocViewerRef>(null);

  const handleSourceDocument = async (loc : string) => {

    const checkFileExtension = (fileName: string) => {
      // Regular expression to match file extensions
      const extensionRegex = /\.(pdf|txt)$/i;
      return extensionRegex.test(fileName);
    };

    setLoading(true);

    if (checkFileExtension(loc)){
      console.log('File does not have one of the allowed extensions: docx, xlsx, csv');
      // await handleViewSourceDocument(loc);
      await handleViewSDocument(loc);
    }else{
      console.log('File has one of the allowed extensions: docx, xlsx, csv');
      await handleDownload(loc);
    }

    setTimeout(() => {
      // After some delay, set loading state back to false
      setLoading(false);

      // Perform additional operations if necessary (e.g., navigation)
      // history.push(sourceloc);
    }, 10000); // Simulating a 2-second delay

  };

  const handleCopyClick = async (content : string) => {
    try {
      // await navigator.clipboard.writeText(content);
      writeText(content)
      .then(() => {
        console.log('Text copied to clipboard');
        setCopied(true);
      })
      .catch((error:any) => {
        console.error('Failed to copy text: ', error);
      });      
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
    }
  };

  const handleDownload = async (loc: string) => {

    function extractFilename(path: string) {
      // Split the path based on both forward slash (/) and backward slash (\)
      const parts = path.split(/[/\\]/);

      // Return the last part which is the filename
      return parts[parts.length - 1];
    }

    try {
      console.log("Inside download")
      await axios.post(
        `/core/getChatBotFileName?loc=${loc}`,
        {},
        {
          responseType: 'arraybuffer',
        }
      ).then((response) => {

      // const filenameMatch = extractFilename(loc);
      // console.log("Filename Match :", filenameMatch)
      // const filename = filenameMatch || 'document.pdf'; // Default filename if not found
      // console.log("Filename :", filename)
        console.log("Response :", response) 
        const fileName = response.headers['content-disposition']?.split('filename=')[1];
        const contentType = response.headers['content-type'];

        console.log("Filename : ", fileName)
  
        if (fileName) {
          const blob = new Blob([response.data], {
            type: contentType,
          });
    
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = decodeURIComponent(fileName);
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          // If the Content-Disposition header is not present or does not contain a filename, set a default file name
          const blob = new Blob([response.data], {
            type: contentType,
          });
          const filenameMatch = extractFilename(loc);
          console.log("Filename Match :", filenameMatch) 
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filenameMatch;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log("Inside error while trying to download")
        // console.log("Error : ", string(error))
        // console.error('Error downloading report:', error);
        enqueueSnackbar("File is Missing or not able to Access.", { variant: 'error' });
        setLoading(false)
      });
      // handleClose();
    } catch (error) {
      // console.error('Error downloading report:', error);
      // enqueueSnackbar(error, { variant: 'error' });
      console.log("Error downloading report")
      enqueueSnackbar("File is Missing or not able to Access.", { variant: 'error' });
      setLoading(false)
    }
  };

  const handleViewSDocument = async (loc: string) => {
    console.log('In view');
    axios
      .post(
        `/core/getChatBotFileName?loc=${loc}`,
        {},
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        console.log('Report response data:', response.data);
        const blob = response.data;
        const objectUrl = URL.createObjectURL(blob);
        const filename = loc || 'document.pdf'; // Default filename if not found
        setDocuments([{ uri: objectUrl,fileName: filename}]);
        setFileOpen(true);
        setLoading(false)
      })
      .catch((error) => {
        // console.error('Error downloading report:', error);
        // enqueueSnackbar(error, { variant: 'error' });
        console.log("Error in View report")
        enqueueSnackbar("File is Missing or not able to Access.", { variant: 'error' });
        setLoading(false)
      });
  };

  useEffect(() => {
    const chatHistory_payload = {
      userid,
    };

    const fetchChatHistory = async () => {
      try {
        // console.error('Error fetching chat history:', userid);
        const response = await axios.post('/core/chathist', chatHistory_payload);
        console.log('response : ', response);
        console.log('Response status :', response.status);
        console.log('response data', response.data);
        if (response.data.status === 'success') {
          console.log('Response status success');
          setChatHistory(response.data.data);
        } else {
          console.error('In else of response status :', response.data.status_msg);
        }
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    };

    fetchChatHistory();

    return () => {
      // Perform cleanup if necessary
    };
  }, [userid]);

  //   useEffect(() => {

  //   if (chatHistory.length === 0){
  //     console.log("Chat History :", chatHistory[0])
  //     const chatHistoryItem: ChatHistoryItem = {
  //       quest_id: 1,
  //       question: "What is the capital of France?",
  //       ansresponse: "The capital of France is Paris.",
  //       answer: "Paris",
  //       reference_docs: null,
  //       qtime: "2024-02-23 10:00:00",
  //       atime: "2024-02-23 10:01:00"
  //     };

  //     console.log(chatHistoryItem);
  //     setChatHistory((prevMessages) => [...prevMessages, chatHistoryItem]);
  //   }
  //   // setChatHistory(chatHistoryItem);
  // },[chatHistory]);

  // useEffect(() => {
  //   if (userid) {
  //     // Ensure userid is present before updating chatHistoryPayload
  //     setChatHistoryPayload({ userid }); // Update chatHistoryPayload with userid
  //   }
  // }, [userid]);

  return (
    <>
    <Container
    sx={{
      maxWidth: '100% !important',
      marginLeft: '430px',
    }}
  >
    <Modal
      open={openFile}
      onClose={handleFileClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Scrollbar>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',color: 'black' }}>
          <Box
            sx={{
              height: '100%',
              width: '900px',
            }}
          >
            {' '}
            <IconButton
              onClick={handleFileClose}
              sx={{
                position: 'fixed',
                top: '10px',
                right: '10px',
                color: 'aliceblue'
                // color: 'rgba(0, 0, 0, 0.5)',
                // backgroundColor: 'grey'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DocViewer
              documents={documents}
              ref={docViewerRef}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: false,
                  retainURLParams: false,
                },
                csvDelimiter: ',',
                pdfZoom: {
                  defaultZoom: 1.1,
                  zoomJump: 0.2,
                },

                pdfVerticalScrollByDefault: false,
              }}
              pluginRenderers={DocViewerRenderers}
            />
          </Box>
        </Box>
      </Scrollbar>
    </Modal>

    {/* <CustomBreadcrumbs
            heading="Add New Document"
            links={[
                {
                    href: '/managementUserList',
                },
            ]} /> */}
  </Container>

    <Card
      sx={{
        marginLeft: '8px',
        width: '1279px',
        height: '620px',
        display: 'flex',
        borderColor: 'red',
        borderWidth: '10px',
        flexDirection: 'column',
      }}
    >
      {/* <Modal
        open={openFile}
        onClose={handleFileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Scrollbar>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                height: '100%',
                width: '900px',
              }}
            >
              {' '}
              <IconButton
                onClick={handleFileClose}
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                <CloseIcon />
              </IconButton>
              <DocViewer
                documents={documents}
                ref={docViewerRef}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                    retainURLParams: false,
                  },
                  csvDelimiter: ',',
                  pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2,
                  },

                  pdfVerticalScrollByDefault: false,
                }}
                pluginRenderers={DocViewerRenderers}
              />
            </Box>
          </Box>
        </Scrollbar>
      </Modal> */}

      {chatHistory && chatHistory.length > 0 ?(
        <Scrollbar
        style={{
          marginTop: '10px',
          height: '600px',
          // width: '100%',
          // overflowX: 'hidden',
          // msOverflowStyle: "none",
        }}
      >
          <List sx={{ width: '92%', bgcolor: 'background.paper' }}>
            {chatHistory.map((item) => (
              <React.Fragment key={item.quest_id}>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 1.5, flexGrow: 1 }}>
                  <Avatar sx={{ width: 24, height: 24 }}>
                    <PersonIcon sx={{ fontSize: 20 }} />
                  </Avatar>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>You: </Typography>
                  <span style={{ marginLeft: '10px', fontSize: '12px' }}>
                    {item.qtime && `${item.qtime}`}
                  </span>
                </Stack>
                <ListItem alignItems="flex-start" sx={{ marginLeft: '70px' }}>
                  <Typography>{item.question}</Typography>
                </ListItem>

                <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 1.5, flexGrow: 1 }}>
                  <Avatar sx={{ width: 24, height: 24 }}>
                    <ChatBubbleOutlineIcon sx={{ fontSize: 20 }} />
                  </Avatar>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>Chatbot: </Typography>
                  <span style={{ marginLeft: '10px', fontSize: '12px' }}>
                    {item.atime && `${item.atime}`}
                  </span>
                </Stack>
                <ListItem alignItems="flex-start" sx={{ marginLeft: '70px' }}>
                  <Typography>{item.answer}</Typography>
                  {item.reference_docs !== ""  && (
                  <div>
                 <Tooltip title='Copy Response'>
                  {/* <Typography
                    variant="button"
                    component="button"
                    onClick={handleCopyClick}
                    sx={{ cursor: 'pointer', display: 'flex', marginLeft: '40px',alignItems: 'center' }}
                  > */}
                    <IconButton
                      aria-label="Copy to Clipboard"
                      // sx={{ display: 'flex', marginLeft: '70px',alignItems: 'center' }}
                      onClick={() => handleCopyClick(item.answer ? item.answer : '')}
                      size="small"
                    >
                    <FileCopyIcon sx={{ marginLeft: 1 }} />
                    </IconButton>
                  </Tooltip>
                  </div>)}
                </ListItem>
                {/* <ListItem alignItems="flex-start" sx={{marginLeft: '70px' }}>
                <strong>Source Document: </strong> 
                <span style={{ marginLeft: '10px'}} />
                {item.reference_docs !== "" && (
                <Typography>{item.reference_docs}</Typography> )}
            </ListItem> */}

                <ListItem alignItems="flex-start" sx={{ marginLeft: '30px' }} >
                  <Typography
                    variant="body1"
                    sx={{
                      p: 1,
                      textAlign: 'left',
                      marginLeft: '34px',
                      fontSize: '14px',
                      mb: 0.1,
                    }}
                  >
                    <strong>Source Document : </strong>
                    {item.reference_docs !== '' && (
                     loading ? (
                      <div>Loading Document...</div>
                    ) : (
                      <Link
                        variant="body2"
                        // onClick={() => handleViewSDocument(item.reference_docs)}
                        onClick={() => handleSourceDocument(item.reference_docs)}
                        sx={{ cursor: 'pointer' }}
                        color="inherit"
                        underline="always"
                      >
                        {item.reference_docs}
                      </Link>
                    )
                    )}
                  </Typography>
                </ListItem>

                {/* <ListItem alignItems="flex-start">
            <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 3, flexGrow: 1 }}>
                  <Avatar sx={{ width: 24, height: 24 }}>
                  <ChatBubbleOutlineIcon sx={{ fontSize: 20 }} />
                  </Avatar>
                  <span style={{ fontWeight: 'bold' }}>Chatbot:</span> <span>{item.answer}</span>
            </Stack>
            </ListItem> */}
                <Divider variant="inset" component="li" sx={{ p: 1, width: '98%' }} />
              </React.Fragment>
            ))}
          </List>
        </Scrollbar>
      ) : (
        <div
        style={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
          // padding: '16px',
          // borderRadius: '5px',
          // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', // Add box shadow for depth
        }}
      >
        <h2 style={{ marginBottom: '16px' }}>No Messages to display.</h2>
          {/* If chatHistory is empty, render a general message */}
        </div>
      )} 
    </Card></>
  );
};

export default ChatHistory;
