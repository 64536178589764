import {
  Button,
  Card,
  Container,
  // DialogTitle,
  FormControl,
  FormControlLabel,
  // FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  // Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'src/utils/axios';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router';
// import { CSVLink } from 'react-csv';
import { Controller, useForm } from 'react-hook-form';
import { useSettingsContext } from 'src/components/settings';
import Label from 'src/components/label';
import { Helmet } from 'react-helmet-async';
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSnackbar } from '../../components/snackbar';
// import DownloadPDFButton from './GeneratePdf';

export default function ExceptionReport() {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Job ID',
      width: 100,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobNumber',
      headerName: 'Job No.',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },

    {
      field: 'JobPrelimFinal',
      type: 'text',
      headerName: 'Prelim/Final',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },
    {
      field: 'ReqName',
      type: 'text',
      headerName: 'Requestor Name',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },
    {
      field: 'ReqSubLocation',
      type: 'text',
      headerName: 'Requestor Sub Location',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },
    {
      field: 'JobType',
      type: 'singleSelect',
      headerName: 'Job Type',
      // valueOptions: ['New', 'Existing'],
      align: 'left',
      headerAlign: 'left',
      width: 150,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.JobType}
        </Typography>
      ),
    },
    // {
    //   field: 'JobRating',
    //   headerName: 'Job Rating',
    //   width: 100,
    //   align: 'left',

    //   headerAlign: 'left',
    // },
    {
      field: 'JobAssessorID',
      headerName: 'Assessor ID',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'UserFname',
      headerName: 'Assessor First Name',
      width: 160,
      align: 'left',

      headerAlign: 'left',
    },
    {
      field: 'UserLname',
      headerName: 'Assessor Last Name',
      width: 180,
      align: 'left',

      headerAlign: 'left',
    },
    {
      field: 'JobCode',
      headerName: 'Job Code',
      width: 80,

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobDwelling',
      headerName: 'Job Dwelling',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },
    {
      field: 'JobAddJobcode',
      type: 'text',
      headerName: 'Additional Job COde',
      align: 'left',
      headerAlign: 'left',
      width: 130,
    },
    // {
    //   field: 'JobSlaTime',
    //   type: 'text',
    //   headerName: 'JobSla Time',
    //   align: 'left',
    //   headerAlign: 'left',
    //   width: 130,
    // },
    {
      field: 'AWOH_VALID',
      headerName: 'WOH Status',
      align: 'left',
      headerAlign: 'left',
      width: 100,
        },
        {
          field: 'Ncc_Type',
          headerName: 'Ncc Type',
          align: 'left',
          headerAlign: 'left',
          width: 100,
        },
    {
      field: 'JobStatus',
      type: 'singleSelect',
      headerName: 'Status',
      valueOptions: [
        '',
        'new',
        'assigned',
        'assessment started',
        'workflow to action',
        'pending validation',
        // 'reassess initiated',
        // 'reassess started',
        // 'reassess completed',
        'marked as complete',
        'cancelled',
        'on hold',
        'exception',
      ],
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => RenderStatus(params.row.JobStatus),
    },
    {
      field: 'JobCreatedAt',
      // type: 'dateTime',
      headerName: 'Created Date',
      align: 'left',
      headerAlign: 'left',
      width: 180,
    },

    // -------------
    {
      field: 'EmailRecordID',
      headerName: 'Email Record ID',

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobAddrState',
      headerName: 'Job State',
      align: 'left',
      headerAlign: 'left',
    },

    {
      field: 'JobEmailMsgID',
      headerName: 'Job Msg ID',

      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'JobFloors',
    //   headerName: 'Job Floors',

    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'JobIsUrgent',
      headerName: 'Urgent',

      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'JobPostAssessmentFlag',
    //   headerName: 'Post Assessment Flag',

    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobProcessedAt',
    //   headerName: 'Processed At',

    //   align: 'left',
    //   headerAlign: 'left',
    // },
    // {
    //   field: 'JobProcessedFlag',
    //   headerName: 'Processed Flag',

    //   align: 'left',
    //   headerAlign: 'left',
    // },

    {
      field: 'JobRequestorID',
      headerName: 'Requestor ID',

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobSiteAddr',
      headerName: 'Job Site Address',

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'ExceptionId',
      headerName: 'Exception Id',

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'ExceptionReason',
      headerName: 'Exception Reason',
      width: 180,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'ExceptionStatus',
      headerName: 'Exception Status',

      align: 'left',
      headerAlign: 'left',
    },

    {
      field: 'ExceptionCreatedBy',
      headerName: 'Exception Created By',

      align: 'left',
      headerAlign: 'left',
    },

    {
      field: 'ExceptionReprocessedBy',
      headerName: 'Exception Processed By',

      align: 'left',
      headerAlign: 'left',
    },

    {
      field: 'ReprocessedByUserFname',
      headerName: 'Reprocessed By',

      align: 'left',
      headerAlign: 'left',
    },

    {
      field: 'ReprocessedByUserLname',
      headerName: 'Reprocessed By',

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'ExceptionCreatedDate',
      headerName: 'Exception Created Date',
      width: 180,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'ExceptionReprocessedDate',
      headerName: 'Exception Reprossed Date',

      align: 'left',
      headerAlign: 'left',
    },
  ];
  // const getFormattedToday = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
  //   const day = String(today.getDate()).padStart(2, '0');
  //   return `${day}-${month}-${year}`;
  // };
  //   const { data } = location.state;
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  // const [selectedDate, setSelectedDate] = useState('');
  // const [assessorName, setAssessorName] = useState('Jayvion Saimon');
  // const [assessorId, setSelectedAssessorIdTemp] = useState<any>(null);
  // const [singleAssessorId, setSingleAssessorId] = useState(null);
  // const [leaveType, setLeaveType] = useState('leave');
  // const [startDate, setStartDate] = useState(getFormattedToday);
  // const [endDate, setEndDate] = useState(getFormattedToday);
  // const [remark, setRemark] = useState('');
  const [jobStatusDropdown, setJobStatusDropdown] = useState<any>();
  const [jobPeriodDropdown, setJobPeriodDropdown] = useState<any>();
  // const [allAssessorSelected, setAllAssessorSelected] = useState(false);
  // const [leaveData, setLeaveData] = useState<any[]>([]);
  // const [assessorLeaveData, setAssessorLeaveData] = useState<any[]>([]);
  // const [assessorHolidayData, setAssessorHolidayData] = useState<any[]>([]);
  // const [activeAssessors, setActiveAssessors] = useState([]);
  // const [user_id, setUserId] = useState<any>(null);
  // const [start_date, setStartDateTemp] = useState<any>(null);
  // const [open, setOpen] = useState(false);
  // const [rowData, setRowData] = useState(null);
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const [, setStartDateGreater] = useState(false);
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const [jobReportData, setJobReportData] = useState<any[]>([]);
  const [outstandingJobs, setOutstandingJobs] = useState('');
  const [exceptionType, setExceptionType] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const goToReports = () => {
    navigate('/reports');
  };

  const handleDisableJobStatus = (event: any) => {
    console.log('Radio value = ', event.target.value);
    setJobStatusDropdown(false);
    setOutstandingJobs(event.target.value);
    setExceptionType(event.target.value);
  };

  const handleDisablePeriod = (event: any) => {
    setJobPeriodDropdown(false);
  };

  const onSubmit = (data: any) => {

    if (data?.period === 'custom'){
      data.period = ''
    }
    if (jobPeriodDropdown === false) {
      data.period = '';
    }
    data.userId = user?.ID;
    if (exceptionType === '') {
      data.exceptionType = 'active';
    } else {
      data.exceptionType = exceptionType;
    }
    if (data?.period !== ''){
      data.startDate = '';
      data.endDate = '';
    }
    console.log('Exception report data = ', data);
    axios
      .post('/core/createExceptionJobReport', data)
      .then((response) => {
        console.log('Response from backend:', response.data);
        if (response.data === null) {
         // enqueueSnackbar('No Exception Data Available', { variant: 'error' });
          setJobReportData([]);
        }
        const modifiedData = response.data.map(({ JobRecordID, ...job }: any) => ({
          id: JobRecordID,
          ...job,
        }));
        console.log('Exception report data from backend = ', modifiedData);
        setJobReportData(modifiedData as any[]);
      })
      .catch((error) => {
        console.error('Error sending data to backend:', error);
        // setShowErrorMessage(true);
        enqueueSnackbar(error?.error, { variant: 'error' });
      });
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleReset = () => {
    setJobStatusDropdown(null);
    setJobPeriodDropdown(null);
    setOutstandingJobs('');
    setJobReportData([]);
    reset();
  };

  return (
    <Container sx={{ maxWidth: '80% !important', paddingTop: '20px', paddingBottom: '10px' }}>
      <Helmet>
        <title> Job Report | Energy Advance</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ float: 'left' }}>
        <CustomBreadcrumbs
          heading="Exception Report"
          sx={{ marginTop: '50px' }}
          links={[
            {
              name: 'Reports',
              href: '/reports',
            },
            { name: 'Exception Report' },
          ]}
        />
      </Container>
      <Card sx={{ py: 4, px: 5, textAlign: 'center', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
            <Grid item xs={2} md={6} sx={{ alignItems: 'center' }}>
              <div>
                <Typography variant="body1">Job Status</Typography>
                <FormControl sx={{ width: '400px' }}>
                  <Controller
                    name="jobStatus"
                    control={control}
                    defaultValue="exception"
                    rules={{ required: 'Please select Job Status.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        disabled
                        onChange={(event) => {
                          field.onChange(event);
                          setJobStatusDropdown(true);
                        }}
                      >
                        <MenuItem value="exception">Exception</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>

              <FormControl sx={{ marginTop: '60px' }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="active"
                  name="radio-buttons-group"
                  onChange={handleDisableJobStatus}
                >
                  <FormControlLabel value="all" control={<Radio />} label="All" />
                  <FormControlLabel value="active" control={<Radio />} label="Active Exceptions" />
                  <FormControlLabel
                    value="reprocessed"
                    control={<Radio />}
                    label="Reprocessed Exceptions"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={2} md={6}>
              <div>
                <Typography variant="body1">Select Period or Date range</Typography>
                <FormControl sx={{ width: '400px' }} error={!!errors.selectedAssessorId}>
                  <Controller
                    name="period"
                    control={control}
                    defaultValue="daily"
                    rules={{ required: 'Please select Period.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                       // disabled={jobPeriodDropdown === false}
                        onChange={(event) => {
                          field.onChange(event);
                          setJobPeriodDropdown(event.target.value !== 'custom');
                        }}
                      >
                        <MenuItem key="custom" value="custom">
                        Custom
                        </MenuItem>
                        <MenuItem key="daily" value="daily">
                          Daily
                        </MenuItem>
                        <MenuItem key="yesterday" value="yesterday">
                          Yesterday
                        </MenuItem>
                        <MenuItem key="weekly" value="weekly">
                          Weekly
                        </MenuItem>
                        <MenuItem key="monthly" value="monthly">
                          Monthly
                        </MenuItem>
                      </Select>
                    )}
                  />
                  {/* <FormHelperText>{errors.selectedAssessorId?.message}</FormHelperText> */}
                </FormControl>
              </div>
              {jobPeriodDropdown === false  && (
              <>
              <Typography variant="h5" sx={{ marginTop: '30px' }}>
                {/* --- OR --- */}
              </Typography>
              {/* <Grid item xs={2} md={12}> */}
              <div style={{ marginTop: '30px' }}>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue=""
                  // rules={{ required: 'Please select a Start Date.' }}
                  render={({ field }) => (
                    <TextField
                      // disabled={jobPeriodDropdown === true}
                     // sx={{ marginTop: '20px', marginRight: '20px' }}
                      {...field}
                      id="date"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: '20px', marginRight: '20px' }}
                      error={!!errors.startDate}
                      /* onChange={(event) => {
                         field.onChange(event); // This ensures the form state is updated
                         handleDisablePeriod(event);
                       }} */
                    />
                  )}
                />

                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  // rules={{ required: 'Please select an End Date.' }}
                  render={({ field }) => (
                    <TextField
                    //  disabled={jobPeriodDropdown === true}
                      {...field}
                      id="date"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: '20px' }}
                      error={!!errors.endDate}
                    />
                  )}
                />
              </div>
              </>
            )}
              {/* </Grid> */}
            </Grid>

            <Grid
              container
              xs={12}
              md={12}
              textAlign="center"
              justifyContent="space-between"
              sx={{ marginTop: '20px' }}
            >
              <Grid item md={9} />
              <Grid item md={1} textAlign="center">
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
                {/* <CSVLink
                    data={csvData}
                    headers={headers}
                    filename="job_report.csv"
                    className="btn btn-primary"
                  >
                    Download as CSV
                  </CSVLink> */}
              </Grid>
              <Grid item md={0.7} textAlign="center">
                <Button variant="contained" color="primary" onClick={handleReset}>
                  Reset
                </Button>
              </Grid>
              <Grid item md={0.7} textAlign="center">
                <Button variant="contained" color="secondary" onClick={goToReports}>
                  Cancel
                </Button>
              </Grid>
              {/* <DownloadPDFButton data={jobReportData} /> */}
            </Grid>
          </Grid>
        </form>
      </Card>

      <DataGrid
        // checkboxSelection
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: 'id', sort: 'desc' }],
        //   },
        // }}
        sx={{ marginTop: '20px', height: '500px' }}
        disableRowSelectionOnClick
        rows={jobReportData}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              JobRecordID: false,
              JobNumber: true,
              EmailRecordID: false,
              JobEmailSubject: false,
              JobEmailMsgID: false,
             // JobFloors: true,
              JobRequestorID: false,
              ReqName: false,
              ReqSubLocation: false,
              JobSiteAddr: false,
              JobAddrState: false,
              JobIsUrgent: false,
              JobCode: true,
              JobType: false,
              JobPrelimFinal: false,
              JobAssessorID: true,
              UserFname: true,
              UserLname: true,
              JobDwelling: false,
              JobStatus: true,
             // JobProcessedFlag: false,
             // JobProcessedAt: false,
              JobCreatedAt: true,
             //  JobRating: false,
            //  JobPostAssessmentFlag: false,
              ExceptionId: false,
              ExceptionReason: true,
              ExceptionStatus: false,
              ExceptionCreatedBy: false,
              ExceptionReprocessedBy: false,
              ReprocessedByUserFname: false,
              ReprocessedByUserLname: false,
              ExceptionCreatedDate: true,
              ExceptionReprocessedDate: false,
            },
          },
        }}
        pagination
        onRowSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        slots={{
          toolbar: MyExportButton,
        }}
      />
    </Container>
  );
}

function RenderStatus(getStatus: string) {
  let backgroundColor = '';
  switch (getStatus) {
    case 'exception':
      backgroundColor = 'tomato';
      break;
    case 'pending validation':
    case 'marked as complete':
      backgroundColor = 'seagreen';
      break;
    // case 'reassess initiated':
    //   backgroundColor = '#B7A57A';
    //   break;
    // case 'reassess started':
    //   backgroundColor = '#1997D1';
    //   break;
    // case 'reassess completed':
    //   backgroundColor = '#AD66BB';
    //   break;

    case 'assessment started':
      backgroundColor = 'steelblue';
      break;
    case 'workflow to action':
      backgroundColor = 'limegreen';
      break;
    case 'new':
      backgroundColor = 'grey';
      break;
    case 'assigned':
      backgroundColor = 'mediumslateblue';
      break;
    case 'on hold':
    case 'cancelled':
      backgroundColor = 'orange';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStatus}
      </Label>
    </div>
  );
}
function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Exception_Report_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fields: [
              'id',
              'JobNumber',
              'EmailRecordID',
              'JobEmailSubject',
            //  'JobFloors',
              'JobRequestorID',
              'ReqName',
              'ReqSubLocation',
              'JobSiteAddr',
              'JobAddrState',
              'JobIsUrgent',
              'JobCode',
              'JobType',
              'JobPrelimFinal',
              'JobAssessorID',
              'UserFname',
              'UserLname',
              'JobDwelling',
              'JobAddJobcode',
            //  'JobSlaTime',
              'AWOH_VALID',
              'Ncc_Type',
              'JobStatus',
           //   'JobProcessedFlag',
           //   'JobProcessedAt',
              'JobCreatedAt',
           //   'JobRating',
           //   'JobPostAssessmentFlag',
              'ExceptionId',
              'ExceptionReason',
              'ExceptionStatus',
              'ExceptionCreatedBy',
              'ExceptionReprocessedBy',
              'ReprocessedByUserFname',
              'ReprocessedByUserLname',
              'ExceptionCreatedDate',
              'ExceptionReprocessedDate',
            ],
            fileName: file_Name,
            allColumns: true,
            // delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
