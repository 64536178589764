import {
  Button,
  Grid,
  TextField,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
  InputLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'src/components/snackbar';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Label from 'src/components/label/Label';
// import _mock from 'src/_mock/_mock';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
import { fData } from 'src/utils/formatNumber';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router';

type ManagementProfile = {
  UserProfilePic: any;
  UserUsername: string;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | any;
  UserStreetAddr: string;
  UserCountry: 'Australia' | any;
  UserCreatedAt: string;
};
interface ViewJobDialogProp {
  management: ManagementProfile;
  id: string;
  edit: boolean;
}

export default function ProfileManagement({ management, id, edit }: ViewJobDialogProp) {
  console.log('Data = ', management);

  const [values, setValues] = useState<Date | null>(new Date());
  const [image, setImage] = useState<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountryCode, setSelectedCountryCode] = useState('+61');

  const CommentSchema = Yup.object().shape({
    UserFname: Yup.string().required('First Name is required'),
    UserLname: Yup.string().required('Last Name is required'),
    // UserEmail: Yup.string()
    //   .required('Email is required')
    //   .email('Email must be a valid email address'),
    // UserPhone: Yup.string().required('Phone Number is required'),
    UserEmail: Yup.string()
      // .required('Email is required')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        'Email must be a valid email address in the format name@example.com'
      ),
    UserPhone: Yup.string()
      .required('Phone Number is required. Minimum 8 Maximum 10')
      .min(8)
      .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
        if (!value) return false;
        return /^(\+61-){0,1}\d+$/.test(value);
        // return /^(\+61-|\+1-){0,1}\d+$/.test(value);
      }),

    // UserGender: Yup.string().required('Gender is required'),
    // UserState: Yup.string().required('State is required'),
    // UserZipcode: Yup.string().required('ZipCode is required'),
    UserStatus: Yup.string().required('Status is required'),
    // UserStreetAddr: Yup.string().required('Address is required'),
    UserCountry: Yup.string().required('Country is required'),
    UserProfilePic: Yup.string(),
  });

  const defaultValues = {
    UserUsername: '',
    UserFname: '',
    UserLname: '',
    UserEmail: '',
    UserPhone: '',
    UserGender: '',
    UserState: '',
    UserZipcode: '',
    UserStatus: '',
    UserStreetAddr: '',
    UserCountry: 'Australia',
    UserProfilePic: '',
  };

  const methods = useForm<ManagementProfile>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    setValue,
    // reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    console.log('Management data:', management);
    if (management) {
      setValue('UserPhone', management.UserPhone);
      if (management.UserPhone.startsWith('+61')) {
        setSelectedCountryCode('+61');
        // } else if (management.UserPhone.startsWith('+1')) {
        //   setSelectedCountryCode('+1');
      } else {
        setSelectedCountryCode(' ');
      }
      // management.UserPhone = management.UserPhone.replace('+61-', '');
      // management.UserPhone = management.UserPhone.replace('+1-', '');
      const indexofHyphen = management.UserPhone.indexOf('-');
      if (indexofHyphen !== -1) {
        setValue('UserPhone', management.UserPhone.substring(indexofHyphen + 1));
      } else {
        setValue('UserPhone', management.UserPhone);
      }
      setValue('UserUsername', management.UserUsername);
      setValue('UserFname', management.UserFname);
      setValue('UserLname', management.UserLname);
      setValue('UserEmail', management.UserEmail);

      if (management.UserGender === 'M') {
        setValue('UserGender', 'Male');
      } else if (management.UserGender === 'F') {
        setValue('UserGender', 'Female');
      } else {
        setValue('UserGender', '');
      }

      setValue('UserState', management.UserState);
      setValue('UserZipcode', management.UserZipcode);
      setValue('UserStatus', management.UserStatus || 'active');
      setValue('UserStreetAddr', management.UserStreetAddr);
      setValue('UserCountry', management.UserCountry);
    }
    if (management.UserProfilePic) {
      axios
        .get(`/core/getAssessorProfilePic?loc=${management.UserProfilePic}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const blob = response.data;
          const objectUrl = URL.createObjectURL(blob);
          setValue('UserProfilePic', objectUrl, { shouldValidate: true });
        })
        .catch((error) => {
          console.error('Error fetching management:', error);
        });
    }
  }, [management, setValue]);

  console.log('Post set Phone number : ', management.UserPhone);
  console.log('Post Set Country Code : ', selectedCountryCode);

  const goToManagementList = () => {
    navigate('/managementUserList');
  };

  const onSubmit = async (dat: ManagementProfile) => {
    try {
      if (dat.UserPhone.trim() !== '') {
        dat.UserPhone = dat.UserPhone.replace('+61-', '');
        // dat.UserPhone = dat.UserPhone.replace('+1-', '');
        if (selectedCountryCode === '+61') {
          if (!dat.UserPhone.startsWith('+61')) {
            dat.UserPhone = `+61-${dat.UserPhone}`;
          }
          // } else if (selectedCountryCode === '+1') {
          //   if (!dat.UserPhone.startsWith('+1')) {
          //     dat.UserPhone = `+1-${dat.UserPhone}`;
          //   }
        }
      }
      // setValue('UserPhone', dat.UserPhone);

      console.log('Management data = ', dat);
      await CommentSchema.validate(dat, { abortEarly: false });
      const formDataObj = new FormData();
      formDataObj.append('file', image);
      formDataObj.append('management', JSON.stringify(dat));
      axios
        .post(`/core/updateManagement?id=${id}`, formDataObj)
        .then((response) => {
          enqueueSnackbar(response.data, { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setImage(file);
        setValue('UserProfilePic', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  return (
    <>
      {/* <Container> */}
      <Grid container display="flow">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} md={12} xl={12}>
            <Grid item xs={12} md={4}>
              {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
              <Stack
                spacing={2}
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <RHFUploadAvatar
                  name="UserProfilePic"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  disabled={!edit}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 4,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />

                <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                  Role : Management
                </Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                    label="Created Date"
                    disabled
                    value={management?.UserCreatedAt}
                    onChange={setValues}
                  />
                </LocalizationProvider>
              </Stack>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} md={8} sx={{}}>
              {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
              <Box
                rowGap={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="UserUsername" label="User Name*" disabled />
                <RHFTextField name="UserFname" label="First Name*" disabled={!edit} />
                <RHFTextField name="UserLname" label="Last Name*" disabled={!edit} />
                <RHFTextField name="UserEmail" label="Email Address*" disabled={!edit} />
                {/* <RHFTextField name="UserPhone" label="Phone Number" disabled={edit} /> */}
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  {/* <RHFTextField
                      name="CountryCode"
                      label="Country Code"
                      // disabled={!edit}
                      sx={{ flex: 1 }}
                      InputProps={{
                        inputProps: { maxLength: 4 },
                        value: '+61',
                        readOnly: true, // non-editable
                      }}
                    /> */}
                  <FormControl>
                    <InputLabel>Country Code</InputLabel>
                    <Select
                      name="CountryCode"
                      label="Country Code"
                      value={selectedCountryCode}
                      // onChange={(event) => setSelectedCountryCode(event.target.value)}
                      onChange={(event: SelectChangeEvent) => {
                        const selectcountrycode = event.target.value as string;

                        if (selectcountrycode === '+61') {
                          methods.setValue('UserCountry', 'Australia');
                          // } else if (selectcountrycode === '+1') {
                          //   methods.setValue('UserCountry', 'United States');
                        }

                        setSelectedCountryCode(event.target.value);
                      }}
                      disabled={!edit}
                    >
                      <MenuItem value="+61">+61</MenuItem>
                      {/* <MenuItem value="+1">+1</MenuItem> */}
                    </Select>
                  </FormControl>

                  {/* <RHFTextField
                      name="UserPhone"
                      label="Phone Number**"
                      // disabled={!edit}
                      sx={{ flex: 5 }}
                      InputProps={{ inputProps: { maxLength: 10 } }}
                    /> */}
                  <RHFTextField
                    name="UserPhone"
                    label="Phone Number*"
                    disabled={!edit}
                    sx={{ flex: 5 }}
                    InputProps={{ inputProps: { maxLength: 10 } }}
                  />
                </Stack>

                <RHFSelect name="UserGender" label="Gender" disabled={!edit}>
                  {/* <MenuItem value="">None</MenuItem> */}
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </RHFSelect>

                <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField
                    name="UserStreetAddr"
                    disabled={!edit}
                    multiline
                    rows={3}
                    label="Address"
                  />
                </Stack>
                {/* <RHFTextField name="UserState" disabled={edit} label="State/Region" /> */}
                <RHFSelect
                  name="UserState"
                  label="State/Region"
                  placeholder="State"
                  disabled={!edit}
                >
                  {/* <MenuItem value="">None</MenuItem> */}
                  <MenuItem value="NSW - New South Wales">NSW - New South Wales</MenuItem>
                  <MenuItem value="VIC - Victoria">VIC - Victoria</MenuItem>
                  <MenuItem value="QLD - Queensland">QLD - Queensland</MenuItem>
                  <MenuItem value="WA  - Western Australia">WA - Western Australia</MenuItem>
                  <MenuItem value="SA  - South Australia">SA - South Australia</MenuItem>
                  <MenuItem value="TAS - Tasmania">TAS - Tasmania</MenuItem>
                </RHFSelect>
                {/* <RHFSelect
                    native
                    name="UserCountry"
                    label="Country*"
                    disabled={edit}
                    placeholder="Country"
                  >
                    <option key="Australia" value="Australia">
                      Australia
                    </option>
                    <option key="United States" value="United States">
                      United States
                    </option>
                  </RHFSelect> */}
                <FormControl>
                  <InputLabel>Country*</InputLabel>
                  <Select
                    name="UserCountry"
                    label="Country*"
                    disabled={!edit}
                    placeholder="Country"
                    value={methods.watch('UserCountry')} // Get the selected country from form data
                    onChange={(event: SelectChangeEvent) => {
                      const selectedCountry = event.target.value as string;

                      if (selectedCountry === 'Australia') {
                        setSelectedCountryCode('+61');
                        // } else if (selectedCountry === 'United States') {
                        //   setSelectedCountryCode('+1');
                      }

                      // Call the form control's onChange event to update the selected country
                      methods.setValue('UserCountry', selectedCountry);
                    }}
                  >
                    <MenuItem value="Australia">Australia</MenuItem>
                    {/* <MenuItem value="United States">United States</MenuItem> */}
                  </Select>
                </FormControl>
                {/* <RHFTextField name="UserCountry" label="Country" value="Austriliya" /> */}
                <RHFTextField name="UserZipcode" disabled={!edit} label="Postal Code" />
                <RHFSelect
                  native
                  name="UserStatus"
                  label="Status*"
                  disabled={!edit}
                  placeholder="Status"
                >
                  <option key="active" value="active">
                    Active
                  </option>
                  <option key="inactive" value="inactive">
                    Inactive
                  </option>
                  {/* <option key="deleted" value="deleted">
                      deleted
                    </option> */}
                </RHFSelect>
              </Box>
              {/* </Card> */}
              <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                <Grid item>&nbsp;</Grid>
                {/* <Button variant="contained" onClick={goToManagementList} color="primary">
                    Back
                  </Button> */}

                {edit && (
                  <Grid item>
                    <Button variant="contained" type="submit" color="primary">
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      onClick={goToManagementList}
                      color="secondary"
                      sx={{ marginLeft: '10px' }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </>
  );
}
