import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import axios from 'src/utils/axios';
import {  Button, Container, Grid, InputAdornment, TextField, Tooltip } from '@mui/material';
// import _mock, { randomInArray } from 'src/_mock';
import { 
  // useLocation, 
  useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import DataGridWorkflow from './DataGridWorkflow';

export default function AccessorList() {
  // const location = useLocation();
  // const { skillsetData } = location.state || [];
  // const { skillsetData } = location.state ?? { skillsetData: [] };
  // const skillsetData = filteredSkillsetData.length > 0 ? filteredSkillsetData[0] : undefined;
  const [isVisible, setIsVisible] = useState(false);
  // const [assessor, setAssessor] = useState<any>(null);
  const [workflow, setWorkflow] = useState<any>(null);
  // const [oneAssessor, setSingleAssessor] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredWorkflow = workflow
    ? workflow.filter((item: any) => {
        const searchLower = searchQuery.toLowerCase();
        const searchUpper = searchQuery.toUpperCase();
        const idMatch = item.id.toString().toLowerCase().includes(searchLower) || item.id.toString().toUpperCase().includes(searchUpper);
        const fNameMatch = item.UserFname.toLowerCase().includes(searchLower) || item.UserFname.toUpperCase().includes(searchUpper);
        const lNameMatch = item.UserLname.toLowerCase().includes(searchLower) || item.UserLname.toUpperCase().includes(searchUpper);
        const emailMatch = item.UserEmail.toLowerCase().includes(searchLower) || item.UserEmail.toUpperCase().includes(searchUpper);
        
        return idMatch || fNameMatch || lNameMatch || emailMatch  ;
      })
    : workflow

  // const [rowId, setRowId] = useState<Number | null>(null);
  // const [edits, setEdit] = useState(false);
  const navigate = useNavigate();
  // const userlocal = localStorage.getItem('user');
  // console.log(userlocal);
  // const handleClose = () => {
  //   // setOpen(false)
  //   setIsVisible(false);
  // };

  const goToAddWorkflowUserPage = () => {
    navigate('/addWorkflowUser');
  };

  const goToLeaveManagementWorkflow = () => {
    if (userrole === "Workflow") {
      navigate('/leavesworkflow' , { state: { data: workflow } });
    } else {
      navigate('/leavemanagementworkload' , { state: { data: workflow } });
    }
  };

  useEffect(() => {
    const fetchWorkflow = async () => {
      try {

        const response = await axios.get('/core/getAllWorkflow');
        const workflowArray = response.data.map(({ ID, ...rest }: any) => ({
          id: ID,
          ...rest,
        }));

        console.log(workflowArray);
        const newWorkflowArray = workflowArray.map((userv: any) => ({
          ...userv,
 //         UserLastLogin: convertStringToDate(userv.UserLastLogin), // Convert LastLogin to Date
 //           UserLastLogin: (userv.UserLastLogin === null || userv.UserLastLogin ===undefined || userv.UserLastLogin === "0") ? '1970-01-01 00:00:00' : convertStringToDate(userv.UserLastLogin),
 //           UserCreatedAt: convertStringToDate(userv.UserCreatedAt), // Convert CreatedAt to Date
              UserLastLogin: (userv.UserLastLogin === null || userv.UserLastLogin ===undefined || userv.UserLastLogin === "0") ? '1970-01-01 00:00:00' : userv.UserLastLogin,
              UserCreatedAt: userv.UserCreatedAt, // Convert CreatedAt to Date
            }));
        console.log(newWorkflowArray);

        setWorkflow(newWorkflowArray);
      } catch (error) {
        console.error('Error fetching getAllWorkflow:', error);
      }
    };
    fetchWorkflow();
  }, []);

  // function convertStringToDate(dateString: string): Date {
  //   return new Date(dateString);
  // }

  const handleView = (value: string, edit: boolean) => {
    goToViewWorkflow(value, edit);
  };

  const goToViewWorkflow = (id: any, edit: any) => {
    navigate(`/viewworkflow?id=${id}&edit=${edit}`);
  };

  return (
    <Container sx={{ maxWidth: '1710px !important', marginTop: 2 }}>
      {!isVisible && (
        <Grid container sx={{ marginTop: 2, marginBottom: 2 }} justifyContent="space-between">
          <Grid item>
          { userrole === 'Management' && (
            <Tooltip title="This button provides feature to Create a Workflow User manually." placement="top-end">
            <Button
              variant="contained"
              size="medium"
              onClick={goToAddWorkflowUserPage}
              sx={{ borderRadius: 0 }}
            >
              Add Workflow User
            </Button>
            </Tooltip>
          )}
          </Grid>
          <Grid item>
          <Tooltip
              title="This button provides feature to Manage Leaves/Holidays Of Assessors"
              placement="top"
            ><Button
              variant="contained"
              size="medium"
              onClick={goToLeaveManagementWorkflow}
              sx={{ borderRadius: 0 }}
            >
              Leave Management
            </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search Workflow"
              type="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="bi:search" width={24} />
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
                onChange={handleSearchChange}
                title="Search will be applicable for ID,Username,Firstname, Last name and Email"

              />
          </Grid>
        </Grid>
      )}

      {!isVisible && filteredWorkflow !== null && (
          <DataGridWorkflow
            data={filteredWorkflow} // Use the filtered workflow
            sendData={(value, edit) => {
              handleView(value, edit);
            }}
          />
        )}

    </Container>
  );
}