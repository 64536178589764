import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
// form

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Grid,
  // Card,
  Stack,
  Typography,
  Button,
  TextField,
  // TextFieldProps,
  Dialog,
  // DialogTitle,
  InputLabel,
  // Avatar,
  // DialogActions,
  DialogContent,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  // IconButton,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import FormProvider, {
  RHFSelect,
  // RHFSwitch,
  RHFTextField,
} from 'src/components/hook-form';
// import { CustomFile, Upload } from 'src/components/upload';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import { fData } from 'src/utils/formatNumber';
import { useForm } from 'react-hook-form';
// import { countries } from 'src/assets/data';
import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
import Label from 'src/components/label';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LoadingButton } from '@mui/lab';
// import TextMaxLine from 'src/components/text-max-line';
// import _mock from 'src/_mock';
// import Iconify from 'src/components/iconify';
// import FileThumbnail from 'src/components/file-thumbnail';
import axios from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
// import { varFade } from 'src/components/animate';
// import { m } from 'framer-motion';
import ChangePassword from './ChangePassword';
// ----------------------------------------------------------------------

// type FormValuesProps = {
//   displayName: string;
//   email: string;
//   photoURL: CustomFile | string | null;
//   phoneNumber: string | null;
//   country: string | null;
//   address: string | null;
//   state: string | null;
//   city: string | null;
//   zipCode: string | null;
//   about: string | null;
//   isPublic: boolean;
// };

type UserProfile = {
  UserProfilePic: any;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | any;
  UserStreetAddr: string;
  UserCountry: 'Australia' | any;
  UserLastLogin: any;
  UserCreatedAt: string;
};

export default function Profile() {
  const { user } = useAuthContext();
  // const [value, setLastLogin] = useState<Date | null>(new Date());
  const [open, setOpen] = useState(false);
  // const [values, setValues] = useState<Date | null>(new Date());
  // const [currentPassword, setCurrentPassword] = useState('');
  // const [newPassword, setNewPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  // const [expanded, setExpanded] = useState<string | false>(false);
  const [assessor, setAssessor] = useState<UserProfile | null>(null);

  useEffect(() => {
    axios
      .get(`/core/getAssessor?Id=${user?.ID}`)
      .then((response) => {
        setAssessor(response.data as UserProfile);
        // console.log("Logged in user data = " , assessor);
      })
      .catch((error) => {
        console.error('Error fetching getAssessor:', error);
      });
  }, [user?.ID]);

  const CommentSchema = Yup.object().shape({
    UserFname: Yup.string().required('Name is required'),
    UserLname: Yup.string().required('Name is required'),
    UserEmail: Yup.string()
      .required('Email is required')
      .email('Email must be a valid email address'),

    UserPhone: Yup.string()
      .required('Phone Number is required. Minimum 8 Maximum 10')
      .min(8)
      .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
        if (!value) return false;
        return /^(\+61-|\+1-){0,1}\d+$/.test(value);
      }),

    // UserGender: Yup.string().required('Gender is required'),
    // UserState: Yup.string().required('State is required'),
    // UserZipcode: Yup.string().required('ZipCode is required'),
    UserStatus: Yup.string().required('Status is required'),
    // UserStreetAddr: Yup.string().required('Address is required'),
    UserCountry: Yup.string().required('Country is required'),
    UserProfilePic: Yup.string(),
  });

  const defaultValues = {
    UserFname: '',
    UserLname: '',
    UserEmail: '',
    UserPhone: '',
    UserGender: '',
    UserState: '',
    UserZipcode: '',
    UserStatus: '',
    UserStreetAddr: '',
    UserCountry: 'Australia',
    UserProfilePic: '',
  };

  const methods = useForm<UserProfile>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    setValue,
    // reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    if (assessor) {
      // setValue('UserPhone', assessor.UserPhone);
      // if (assessor.UserPhone.startsWith('+61')) {
      //   setSelectedCountryCode('+61');
      // } else if (assessor.UserPhone.startsWith('+1')) {
      //   setSelectedCountryCode('+1');
      // } else {
      //   setSelectedCountryCode(' ');
      // }
      setSelectedCountryCode('+61');
      const indexofHyphen = assessor.UserPhone.indexOf('-');
      if (indexofHyphen !== -1) {
        setValue('UserPhone', assessor.UserPhone.substring(indexofHyphen + 1));
      } else {
        setValue('UserPhone', assessor.UserPhone);
      }
      setValue('UserCountry', assessor.UserCountry);
      // const indexofHyphen = assessor.UserPhone.indexOf('-');
      // if (indexofHyphen !== -1) {
      //   setValue('UserPhone', assessor.UserPhone.substring(indexofHyphen + 1));
      // } else {
      //   setValue('UserPhone', assessor.UserPhone);
      // }
      setValue('UserFname', assessor.UserFname);
      setValue('UserLname', assessor.UserLname);
      setValue('UserEmail', assessor.UserEmail);

      // setValue('UserGender', assessor.UserGender === 'M' ? 'Male' : 'Female');
      if (assessor.UserGender === 'M') {
        setValue('UserGender', 'Male');
      } else if (assessor.UserGender === 'F') {
        setValue('UserGender', 'Female');
      } else {
        setValue('UserGender', '');
      }
      setValue('UserState', assessor.UserState);
      setValue('UserZipcode', assessor.UserZipcode);
      setValue('UserStatus', assessor.UserStatus || 'active');
      setValue('UserStreetAddr', assessor.UserStreetAddr);
      setValue('UserCountry', assessor.UserCountry);
      setLastLogin(assessor.UserLastLogin);
    }
    if (assessor?.UserProfilePic) {
      axios
        .get(`/core/getAssessorProfilePic?loc=${assessor?.UserProfilePic}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const blob = response.data;
          const objectUrl = URL.createObjectURL(blob);
          setValue('UserProfilePic', objectUrl, { shouldValidate: true });
        })
        .catch((error) => {
          console.error('Error fetching getAssessorProfilePic:', error);
        });
    }
  }, [assessor, setValue]);

  // const handleAccordianChange =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  const [values, setValues] = useState<Date | null>(new Date());
  const [image, setImage] = useState<any>();
  const [lastLogin, setLastLogin] = useState<any>();
  const [selectedCountryCode, setSelectedCountryCode] = useState('+61');
  const navigate = useNavigate();

  // const back = () => {
  //   navigate('/dashboard');
  // };

  const back = () => {
    if (user?.UserRole === 'Management' || user?.UserRole === 'Workflow') {
      navigate('/dashboard');
    } else if (user?.UserRole === 'Assessor') {
      console.log('Assessor role');
      console.log(user?.UserID);
      navigate(`/viewassessor?id=${user?.ID}&edit=true&section=overview`);
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  // const UpdateUserSchema = Yup.object().shape({
  //   displayFirstName: Yup.string().required('First name is required'),
  //   displayLastName: Yup.string().required('Last name is required'),
  //   email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  //   phoneNumber: Yup.string().required('Phone number is required'),
  //   address: Yup.string().required('Address is required'),
  //   state: Yup.string().required('State is required'),
  //   city: Yup.string().required('City is required'),
  //   // zipCode: Yup.string().required('Zip code is required'),
  // });

  // const onSubmit = async (data: UserProfile) => {
  //   try {
  //     // await new Promise((resolve) => setTimeout(resolve, 500));
  //     enqueueSnackbar('Update success!');
  //     console.log('DATA', data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onSubmit = async (dat: UserProfile) => {
    try {
      await CommentSchema.validate(dat, { abortEarly: false });

      if (dat.UserPhone.trim() !== '') {
        dat.UserPhone = dat.UserPhone.replace('+61-', '');
        dat.UserPhone = `+61-${dat.UserPhone}`;
      }

      const formDataObj = new FormData();
      formDataObj.append('file', image);
      formDataObj.append('assessor', JSON.stringify(dat));
      axios
        .post(`/core/updateAssessor?id=${user?.ID}`, formDataObj)
        .then((response) => {
          enqueueSnackbar(response.data, { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    } catch (error) {
      console.error('error in catch updateAssessor', error);
    }
  };

  // const handleDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const file = acceptedFiles[0];

  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });

  //     if (file) {
  //       setValue('photoURL', newFile, { shouldValidate: true });
  //     }
  //   },
  //   [setValue]
  // );

  // const [files, setFiles] = useState<(File | string)[]>([]);
  // const handleDropMultiFile = useCallback(
  //   (acceptedFiles: File[]) => {
  //     setFiles([
  //       ...files,
  //       ...acceptedFiles.map((newFile) =>
  //         Object.assign(newFile, {
  //           preview: URL.createObjectURL(newFile),
  //         })
  //       ),
  //     ]);
  //   },
  //   [files]
  // );

  // const handleRemoveFile = (inputFile: File | string) => {
  //   const filesFiltered = files.filter((fileFiltered) => fileFiltered !== inputFile);
  //   setFiles(filesFiltered);
  // };

  // const handleRemoveAllFiles = () => {
  //   setFiles([]);
  // };

  // const handleUpdate = () => {
  //   // Handle password update logic here
  // };

  // const handleBack = () => {
  //   window.history.back(); // Go back to the previous screen
  // };

  // const publicDocuments = [
  //   { fileName: 'public_report', fileType: 'pdf' },
  //   { fileName: 'public_presentation', fileType: 'pptx' },
  //   { fileName: 'public_budget', fileType: 'xlsx' },
  //   { fileName: 'public_policy', fileType: 'docx' },
  // ];
  // const confidentialDocuments = [
  //   { fileName: 'confidential_contract', fileType: 'pdf' },
  //   { fileName: 'confidential_strategy', fileType: 'docx' },
  //   { fileName: 'confidential_financials', fileType: 'xlsx' },
  //   { fileName: 'confidential_prototype', fileType: 'zip' },
  // ];

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setImage(file);
        setValue('UserProfilePic', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );
  const handleOpen = () => {
    setOpen(true);
  };

  const goToSignature = () => {
    navigate('/signature');
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container display="flow">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} md={12} xl={12} sx={{ marginTop: '50px' }}>
            <Grid item xs={12} md={3}>
              {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
              <Stack
                spacing={2}
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <RHFUploadAvatar
                  name="UserProfilePic"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 4,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
                {user?.UserRole === 'Workflow' && (
                  <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                    Role : Workflow
                  </Label>
                )}
                {user?.UserRole === 'Management' && (
                  <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                    Role : Management
                  </Label>
                )}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                    label="Created Date"
                    disabled
                    value={assessor?.UserCreatedAt}
                    onChange={setValues}
                  />
                  {lastLogin && (
                    <DateTimePicker
                      inputFormat="DD-MM-YYYY HH:mm:ss"
                      renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                      label="Last Login"
                      disabled
                      value={lastLogin}
                      onChange={setLastLogin}
                    />
                  )}
                  {/* {!lastLogin && <Typography variant="body2" color="textSecondary">
          Not Yet Login
        </Typography>} */}
                </LocalizationProvider>
              </Stack>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} md={8}>
              {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
              <Box
                rowGap={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField
                  name="UserFname"
                  label="First Name*"
                  //               disabled={user?.UserRole === 'Assessor'}
                />
                <RHFTextField
                  name="UserLname"
                  label="Last Name*"
                  //             disabled={user?.UserRole === 'Assessor'}
                />
                <RHFTextField
                  name="UserEmail"
                  label="Email Address*"
                  disabled={user?.UserRole === 'Assessor'}
                />
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  <FormControl>
                    <InputLabel>Country Code</InputLabel>
                    <Select
                      name="CountryCode"
                      label="Country Code"
                      value={selectedCountryCode}
                      // onChange={(event) => setSelectedCountryCode(event.target.value)}
                      onChange={(event: SelectChangeEvent) => {
                        // const selectcountrycode = event.target.value as string;

                        setSelectedCountryCode(event.target.value);
                      }}
                      // disabled={edit}
                      disabled={user?.UserRole === 'Assessor'}
                    >
                      <MenuItem value="+61">+61</MenuItem>
                    </Select>
                  </FormControl>

                  <RHFTextField
                    name="UserPhone"
                    label="Phone Number*"
                    // disabled={edit}
                    disabled={user?.UserRole === 'Assessor'}
                    sx={{ flex: 5 }}
                    InputProps={{ inputProps: { minlength: 8, maxLength: 10 } }}
                  />
                </Stack>
                <RHFSelect
                  name="UserGender"
                  label="Gender"
                  placeholder="Gender"
                  //                disabled={user?.UserRole === 'Assessor'}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </RHFSelect>
                <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField
                    name="UserStreetAddr"
                    multiline
                    rows={3}
                    label="Address"
                    //                 disabled={user?.UserRole === 'Assessor'}
                  />
                </Stack>
                {/* <RHFTextField name="UserState" disabled={edit} label="State/Region" /> */}
                <RHFSelect
                  name="UserState"
                  label="State/Region"
                  placeholder="State"
                  //                 disabled={user?.UserRole === 'Assessor'}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="NSW - New South Wales">NSW - New South Wales</MenuItem>
                  <MenuItem value="VIC - Victoria">VIC - Victoria</MenuItem>
                  <MenuItem value="QLD - Queensland">QLD - Queensland</MenuItem>
                  <MenuItem value="WA  - Western Australia">WA - Western Australia</MenuItem>
                  <MenuItem value="SA  - South Australia">SA - South Australia</MenuItem>
                  <MenuItem value="TAS - Tasmania">TAS - Tasmania</MenuItem>
                </RHFSelect>
                <FormControl>
                  <InputLabel>Country*</InputLabel>
                  <Select
                    name="UserCountry"
                    label="Country*"
                    // disabled={edit}
                    disabled={user?.UserRole === 'Assessor'}
                    placeholder="Country"
                    value={methods.watch('UserCountry')} // Get the selected country from form data
                    onChange={(event: SelectChangeEvent) => {
                      const selectedCountry = event.target.value as string;

                      if (selectedCountry === 'Australia') {
                        setSelectedCountryCode('+61');
                        // } else if (selectedCountry === 'United States') {
                        //   setSelectedCountryCode('+1');
                      }

                      // Call the form control's onChange event to update the selected country
                      methods.setValue('UserCountry', selectedCountry);
                    }}
                  >
                    <MenuItem value="Australia">Australia</MenuItem>
                  </Select>
                </FormControl>
                {/* <RHFTextField name="UserCountry" label="Country" value="Austriliya" /> */}
                <RHFTextField
                  name="UserZipcode"
                  label="Postal Code"
                  //              disabled={user?.UserRole === 'Assessor'}
                />
                <RHFSelect
                  native
                  name="UserStatus"
                  label="Status*"
                  placeholder="Status"
                  disabled={user?.UserRole !== 'Management'}
                >
                  <option key="active" value="active">
                    Active
                  </option>
                  <option key="inactive" value="inactive">
                    Inactive
                  </option>
                </RHFSelect>
              </Box>
              {/* </Card> */}
              <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                <Grid item>&nbsp;</Grid>
                <Grid item>
                  <Button variant="contained" onClick={back} sx={{ mr: 1 }} color="primary">
                    Back
                  </Button>

                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    color="primary"
                    sx={{ marginRight: '10px' }}
                  >
                    Change Password
                  </Button>

                  <Button variant="contained" type="submit" color="primary">
                    Update
                  </Button>

                  <Button
                    variant="contained"
                    onClick={goToSignature}
                    color="primary"
                    sx={{ marginLeft: '10px' }}
                  >
                    Add Signature
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <ChangePassword onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}
