import { useEffect, useState, useCallback } from 'react';
import axios from 'src/utils/axios';
// import { useSnackbar } from 'src/components/snackbar';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// @mui
import {
  Stack,
  Dialog,
  Button,
  // TextField,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  Typography,
  // styled,
  // Checkbox,
  MenuItem,
  // Box,
  Paper,
} from '@mui/material';
// import { useAuthContext } from 'src/auth/useAuthContext';
// import label from 'src/components/label';
// import FileThumbnail from 'src/components/file-thumbnail';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, {
  // RHFRadioGroup,RHFTextField,
  RHFSelect,
} from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
// components
// import Editor from 'src/components/editor';
// import Iconify from '../../components/iconify';
// import { Upload } from '../../components/upload';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  setEditor: (value: any) => void;
  job_data: any;
}

interface Signature {
  UserID: number;
  Signature: string;
  EntryBy?: number;
}

type FormValuesProps = {
  Template: string;
  Signature: string;
};
interface Templates {
  ID: number;
  TemplateName: string;
  TemplateText: string;
  EntryBy: number | null;
  CreatedDate: string;
  UpdatedDate: string;
}

export default function SelectTemplate({ open, onClose, setEditor, job_data }: Props) {
  console.log('Job data in select template = ', job_data);
  // const { user } = useAuthContext();
  const [data, setData] = useState<Templates[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const CommentSchema = Yup.object().shape({
    Template: Yup.string(),
  });
  const defaultValues = {
    Template: '',
    Signature: '',
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const {
    // reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    axios
      .get(`/core/getAllTemplates`)
      .then((response) => {
        //        console.log('Job status date 1 = ', response.data);
        setData(response.data as Templates[]);
        console.table(response.data);
      })
      .catch((error) => {
        console.error('Error fetching getAllTemplates:', error);
      });
  }, [setData]);

  const [signature, setSignature] = useState<Signature[]>([]);

  //     useEffect(() => {
  //       axios
  //     .get(`/core/getallusersigns`)
  //     .then((response) => {
  //       setSignature(response.data as Signature[])
  // //      console.log(signature,response.data)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching status date:', error);
  //     })
  //   }, [setSignature, signature]);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleSelectChange = (event: any) => {
    const selectedTemplateName = event.target.value;

    // Find the TemplateText that corresponds to the selected TemplateName
    const selectedTemplateText =
      data.find((item) => item.TemplateName === selectedTemplateName)?.TemplateText || '';

    // Update the selectedTemplate state (if needed)
    setSelectedTemplate(selectedTemplateName);

    // Set the value of the "Template" field to the corresponding TemplateText
    setValue('Template', selectedTemplateText);

    console.log('Selected TemplateName:', selectedTemplateName);
    console.log('Matching TemplateText:', selectedTemplateText);
  };

  const [isloading, setisLoading] = useState(false);
  const onSubmit = useCallback(
    async (dat: FormValuesProps) => {
      try {
        const requestBody = {
          jobId: job_data?.JobRecordID,
          templateText: dat.Template,
        };
        await axios.put(`/core/useTemplate`, requestBody).then((response) => {
          // enqueueSnackbar(response?.data?.result);
          console.log('template response: ', response?.data);
          setEditor(response?.data?.result);
        });
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
        console.error('Error with Template Processing:', error);
      } finally {
        try {
          // setEditor(dat.Template + dat.Signature);
          onClose();
        } catch (error) {
          console.error(error);
        }
      }
    },

    [job_data?.JobRecordID, enqueueSnackbar, setEditor, onClose]
  );

  useEffect(() => {
    setValue('Template', '');
    setValue('Signature', '');
  }, [setValue, onSubmit, onClose]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> Select Template </DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel> */}
            <RHFSelect
              name="Template"
              label="Template"
              placeholder="template"
              value={selectedTemplate}
              onChange={handleSelectChange}
            >
              <MenuItem value="">None</MenuItem>
              {data &&
                data.map((item) => (
                  <MenuItem key={item.ID} value={item.TemplateName}>
                    {item.TemplateName}
                  </MenuItem>
                ))}
            </RHFSelect>
            {/* <RHFSelect name="Signature" label="Signature" placeholder="template">
                <MenuItem value="">None</MenuItem>
                  {signature &&
                    signature.map((signatur) => (
                      <MenuItem key={signatur.EntryBy} value={signatur.Signature}>
                        {signatur.EntryBy}
                      </MenuItem>
                    ))}
                </RHFSelect> */}
            <Typography variant="subtitle1">Template Content</Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: values.Template + values.Signature }}
              >
                {/* {email.EmailSummary} */}
              </Typography>
            </Paper>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isloading} type="submit">
            Use Template
          </LoadingButton>

          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
