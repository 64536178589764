import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

interface Props {
  reportData: any;
}

export default function Regulations1({ reportData }: Props) {
  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE REGULATIONS</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack>
          <Box sx={{ typography: 'caption' }}>
            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.1 Application of Part 13.7
            </Typography>
            <Typography variant="body2">
              (1) This Part applies to (a)a Class 1 building, (b)a Class 10a building, and (c)a
              Class 10b swimming pool associated with a Class 1 or 10a building.
            </Typography>
            <Typography variant="body2">
              (2) Part 13.7 must be applied as directed in H6D2(2).
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.2 Insulation of services
            </Typography>
            <Typography variant="body2">
              Thermal insulation for central heating water piping and heating and cooling ductwork
              must—
            </Typography>
            <Typography variant="body2">
              (a) be protected against the effects of weather and sunlight, and (b) be able to
              withstand the temperatures within the piping or ductwork; and (c) use thermal
              insulation material by AS/NZS 4859.1.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.3 Central heating water piping
            </Typography>
            <Typography variant="body2">
              (1) Central heating water piping that is not within a conditioned space must be
              thermally insulated to achieve the minimum material R-Values as set out in (2) to (6).
            </Typography>
            <Typography variant="body2">
              (2) Internal piping including— (a) flow and return piping that is— (i) within an
              unventilated wall space or{' '}
            </Typography>
            <Typography variant="body2">
              (ii) within an internal floor between storeys; or (iii) between ceiling insulation and
              a ceiling and
            </Typography>
            <Typography variant="body2">
              (b) heated water piping encased within a concrete floor slab (except that which is
              part of a floor heating system), must, in all climate zones, have a minimum material
              R-Value of 0.4.
            </Typography>
            <Typography variant="body2">
              (3) Piping located within a ventilated wall space, an enclosed building subfloor or a
              roof space, including—
            </Typography>
            <Typography variant="body2">
              (a) flow and return piping; and (b) cold water supply piping within 500 mm of the
              connection to the central water heating system; and (c) relief valve piping within 500
              mm of the connection to the central water heating system, must have a minimum material
              R-Value by (5).
            </Typography>
            <Typography variant="body2">
              (4) Piping located outside the building or in an unenclosed building subfloor or roof
              space, including—
            </Typography>
            <Typography variant="body2">
              (a) flow and return piping; and (b) cold water supply piping within 500 mm of the
              connection to the central water heating system; and (c) relief valve piping within 500
              mm of the connection to the central water heating system, must have a minimum material
              R-Value by (6).
            </Typography>
            <Typography variant="body2">
              (5) Piping referred to in (3) must have a minimum material R-Value of— (a) in climate
              zones 1, 2, 3 and 5 — 0.6; and
            </Typography>
            <Typography variant="body2">
              (b) in climate zones 4, 6 and 7 — 0.9; and (c) in climate zone 8 — 1.3.
            </Typography>
            <Typography variant="body2">
              (6) Piping referred to in (4) must have a minimum material R-value of—
            </Typography>
            <Typography variant="body2">
              (a) in climate zones 1, 2, 3 and 5 — 0.6; and (b) in climate zones 4, 6 and 7 — 1.3;
              and (c) in climate zone 8 — 1.3.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.4 Heating and cooling ductwork
            </Typography>
            <Typography variant="body2">
              (1) Heating and cooling ductwork and fittings must—{' '}
            </Typography>
            <Typography variant="body2">
              (a) achieve the material R-Value in (4), and (b) be sealed against air loss— (i) by
              closing all openings in the surface, joints and seams of ductwork with adhesives,
              mastics, sealants or gaskets by AS 4254.1 and AS 4254.2 for a Class C seal; or (ii)
              for flexible ductwork, with a draw band in conjunction with a sealant or adhesive
              tape.
            </Typography>
            <Typography variant="body2">
              (2) Duct insulation must— (a)abut adjoining duct insulation to form a continuous
              barrier and
            </Typography>
            <Typography variant="body2">
              (b) be installed so that it maintains its position and thickness, other than at
              flanges and supports; and where located outside the building, under a suspended floor,
              in an attached Class 10a building or in a roof space— (i) be protected by an outer
              sleeve of protective sheeting to prevent the insulation becoming damp, and (ii) have
              the outer protective sleeve sealed with adhesive tape not less than 48 mm wide
              creating an airtight and waterproof seal.
            </Typography>
            <Typography variant="body2">
              (3) The requirements of (1) do not apply to heating and cooling ductwork and fittings
              located within the insulated building envelope including a service riser within the
              conditioned space, internal floors between storeys and the like.
            </Typography>
            <Typography variant="body2">
              (4) The material R-Value required by (1)(a) must be determined by the following: (a)
              In a heating-only system or cooling-only system including an evaporative cooling
              system— (i) ductwork must have a minimum material R-Value of— (A) in climate zones 1
              to 7 — 1.0; and (B) in climate zone 8 — 1.5; and (ii) fittings must have a minimum
              material R-Value of 0.4.
            </Typography>
            <Typography variant="body2">
              (b) In a combined heating and refrigerated cooling system— (i) ductwork must have a
              minimum material R-Value of— (A) in climate zones 1, 3, 4, 6 and 7 — 1.5; and (B) in
              climate zones 2 and 5 — 1.0; and (C) in climate zone 8 — 1.5; and (ii) fittings must
              have a minimum material R-Value of 0.4.
            </Typography>
            <Typography variant="body2">
              (c) For (b)(i), the minimum material R-value required for ductwork may be reduced by
              0.5 for combined heating and refrigerated cooling systems in climate zones 1, 3, 4, 6
              and 7 if the ducts are— (i) under a suspended floor with an enclosed perimeter; or in
              a roof space that has an insulation of greater than or equal to R0.5 directly beneath
              the roofing.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '5rem' }} />

            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '10px' }}>
                  <img
                    src="./ea-report-footer.png"
                    alt="Logo"
                    style={{ width: '20vh', height: '10vh' }}
                  />
                </Box>
                <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
                <Stack direction="column" sx={{ marginTop: '45px' }}>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    energy@energyadvance.com.au
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    Energy Advance Australia Pty Ltd | Units 4, 6 and 9/30 Dellamarta Road WANGARA
                    6065
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    ACN: 60 9332014 | 1300 850 228
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                  borderColor: '#919eab3d',
                  p: '0.5rem',
                  padding: '3rem',
                }}
              >
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                  COMPLIANCE STAMP NOT{' '}
                </Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}
