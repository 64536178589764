import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

interface Props {
  reportData: any;
}

export default function Regulations({ reportData }: Props) {
  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE REGULATIONS</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack>
          <Box sx={{ typography: 'caption' }}>
            <Typography variant="body2" sx={{ color: '#17a986' }}>
              NSW 3.12.1 Application of NSW Part 3.12.1
            </Typography>
            <Typography variant="body2">
              (a) Compliance with NSW 3.12.1.1 satisfies NSW P2.6.1(a) for thermal insulation and
              thermal breaks.
            </Typography>
            <Typography variant="body2">
              (b) NSW PART 3.12.1 only applies to thermal insulation in a Class 1 or 10 building
              where a development consent specifies that the insulation is to be provided as part of
              the development.
            </Typography>
            <Typography variant="body2">
              (c) In (b), the term development consent has the meaning given by the Environmental
              Planning and Assessment Act 1979.
            </Typography>
            <Typography variant="body2">
              (d) The Deemed-to-Satisfy Provisions of this Part for thermal breaks apply to all
              Class 1 and Class 10a buildings with a conditioned space.
            </Typography>
            <Typography variant="body2">NSW 3.12.1.1 Compliance with NCC Provisions</Typography>
            <Typography variant="body2">
              (a) Thermal insulation in a building must comply with the national BCA provisions of
              3.12.1.1.
            </Typography>
            <Typography variant="body2">
              (b) A thermal break must be provided between the external cladding and framing by
              national BCA provisions of—
            </Typography>
            <Typography variant="body2">
              (i) 3.12.1.2(c) for a metal framed roof; and (ii) 3.12.1.4(b) for a metal framed wall.
            </Typography>
            <Typography variant="body2">
              (c) Compensation for reducing ceiling insulation must comply with the national BCA
              provisions of 3.12.1.2(e).
            </Typography>
            <Typography variant="body2">
              (d) A floor with an in-slab or in-screed heating or cooling system must comply with
              the national BCA provisions of—
            </Typography>
            <Typography variant="body2">
              (i) 3.12.1.5(a)(ii), (iii) and (e) for a suspended floor; or (ii) 3.12.1.5(c), (d) and
              (e) for a concrete slab-on-ground.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              NSW 3.12.3 Application of NSW Part 3.12.3
            </Typography>
            <Typography variant="body2">
              (a) Compliance with NSW 3.12.3.1 satisfies NSW P2.6.1(b) for building sealing.
            </Typography>
            <Typography variant="body2">(b) NSW Part 3.12.3 does not apply to—</Typography>
            <Typography variant="body2">
              (i) existing buildings being relocated; or (ii) Class 10a buildings—
            </Typography>
            <Typography variant="body2">
              (A) without a conditioned space or (B) for the accommodation of vehicles or
            </Typography>
            <Typography variant="body2">
              (iii) parts of buildings that cannot be fully enclosed; or
            </Typography>
            <Typography variant="body2">
              (iv) a permanent building opening, in a space where a gas appliance is located, that
              is necessary for the safe operation of a gas appliance; or
            </Typography>
            <Typography variant="body2">
              (v) a building in climate zones 2 and 5 where the only means of air-conditioning is by
              using an evaporative cooler.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              NSW 3.12.3.1 Compliance with NCC Provisions
            </Typography>
            <Typography variant="body2">
              The sealing of a building must comply with the national BCA provisions 3.12.3.1 to
              3.12.3.6.
            </Typography>
            <Typography variant="body2">
              NSW 3.12.5 SERVICES: APPLICATION OF NSW PART 3.12.5
            </Typography>
            <Typography variant="body2">
              (a) Compliance with NSW 3.12.5.1 satisfies NSW P2.6.2 for services.
            </Typography>
            <Typography variant="body2">
              (b) NSW Part 3.12.5 does not apply to existing services associated with existing
              buildings being relocated.
            </Typography>
            <Typography variant="body2">NSW 3.12.5.1 Compliance with NCC Provisions</Typography>
            <Typography variant="body2">
              Services must comply with the national BCA provisions 3.12.5.0 to 3.12.5.3.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              NSW P2.6.1(a) Building fabric
            </Typography>
            <Typography variant="body2">
              (i) Thermal insulation in a building must be installed and have characteristics that
              facilitate the efficient use of energy for artificial heating and cooling.
            </Typography>
            <Typography variant="body2">
              (ii) A building must have, to the degree necessary, thermal breaks installed between
              the framing and external cladding, to facilitate efficient thermal performance of the
              building envelope.
            </Typography>
            <Typography variant="body2">Application:</Typography>
            <Typography variant="body2">
              NSW P2.6.1(a) only applies to thermal insulation in a Class 1 or 10 building where a
              development consent specifies that the insulation is to be provided as part of the
              development.
            </Typography>
            <Typography variant="body2">
              In (a), the term development consent has the meaning given by the Environmental
              Planning and Assessment Act 1979.
            </Typography>
            <Typography variant="body2">
              NSW P2.6.1(a)(ii) only applies to a metal framed roof and a metal framed wall.
            </Typography>
            <Typography variant="body2">NSW P2.6.1(b) Building sealing</Typography>
            <Typography variant="body2">
              A building must have, to the degree necessary, a level of building sealing against air
              leakage to facilitate the efficient use of energy for artificial heating and cooling
              appropriate to—
            </Typography>
            <Typography variant="body2">(i) the function and use of the building; and</Typography>
            <Typography variant="body2">(ii) the internal environment; and</Typography>
            <Typography variant="body2">(iii) the geographic location of the building.</Typography>
            <Typography variant="body2">NSW P2.6.1(b) does not apply to—</Typography>
            <Typography variant="body2">
              Existing buildings being relocated; or Class 10a buildings without a conditioned
              space; or for the accommodation of vehicles; or parts of buildings that cannot be
              fully enclosed; or a permanent building opening, in a space where a gas appliance is
              located, that is necessary for the safe operation of a gas appliance; or a building in
              climate zones 2 and 5 where the only means of air-conditioning is by using an
              evaporative cooler.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              NSW P2.6.2 Services
            </Typography>
            <Typography variant="body2">
              Domestic services, including any associated distribution system and components must,
              to the degree necessary, have features that facilitate the efficient use of energy
              appropriate to—
            </Typography>
            <Typography variant="body2">
              (a) the domestic service and its usage, and (b) the geographic location of the
              building, and (c) the location of the domestic service and (d) the energy sources.
            </Typography>

            <Divider sx={{ borderStyle: 'none', py: '5rem' }} />

            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '10px' }}>
                  <img
                    src="./ea-report-footer.png"
                    alt="Logo"
                    style={{ width: '20vh', height: '10vh' }}
                  />
                </Box>
                <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
                <Stack direction="column" sx={{ marginTop: '45px' }}>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    energy@energyadvance.com.au
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    ACN: 60 9332014 | 1300 850 228
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                  borderColor: '#919eab3d',
                  p: '0.5rem',
                  padding: '3rem',
                }}
              >
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                  COMPLIANCE STAMP NOT{' '}
                </Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}
