import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Grid,
  Tab,
  Tabs,
  Icon,
  useTheme,
  styled
} from '@mui/material';

// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

// import PersonIcon from '@mui/icons-material/Person';
// import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Avatar from '@mui/material/Avatar';
import Iconify from 'src/components/iconify';
import HistoryScreenPopUp from '../ChatHistory/HistoryScreenPopUp';
import DocumentScreenPopUp from '../Documents/DocumnetScreenPopUp';
// import HistoryScreen from '../ChatHistory/HistoryScreen';
import ChatScreenPopUp from '../Documents/ChatScreenPopUp';

export default function NewChatPopUp() {
  const [screen, setScreen] = useState<string>('chatScreen');
  const [selectedScreen, setSelectedScreen] = useState<string>('chatScreen');
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();


  const handleTabChange = (event: React.SyntheticEvent | React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleScreenChange = (sscreen: string) => {
    setSelectedScreen(sscreen);
  };

  const isButtonSelected = (sscreen: string) => selectedScreen === sscreen;

  return (
    <Grid container spacing={2} sx={{ width: '75%'}}>
      <Grid item xs={12} sm={12} md={10}>
          <Tabs
                    // value={value}
                    // onChange={handleChange}
                    // textColor="secondary"
                    color="primary"
                    aria-label="basic tabs example"
                    sx={{ p: '10px' }}
                    value={selectedTab}
                    indicatorColor="primary"
                    onChange={handleTabChange}
                  >
                    <Tab
                      label="New Chat"
                      onClick={() => handleScreenChange('chatScreen')}
                      // onSelect={() => isButtonSelected('chatScreen')}
                      sx={{
                        ...(isButtonSelected('chatScreen') && {
                          color: 'primary',
                          bgcolor: 'primary',// Change color of the selected tab
                        }),
                      }}                      
                      icon={
                        <Avatar>
                          <Iconify icon="clarity:new-solid" width={24} />
                        </Avatar>
                      }

                    />  
                    <Tab
                      label="Documents"
                      onClick={() => handleScreenChange('documentScreen')}
                      // onSelect={() => isButtonSelected('documentScreen')}
                      sx={{
                        ...(isButtonSelected('documentScreen') && {
                          color: 'primary',
                          bgcolor: 'primary', // Change color of the selected tab
                        }),
                      }}
                      icon={
                        <Avatar>
                          <Iconify icon="mdi:assignment" width={24} />
                        </Avatar>
                      }
                    /> 
                    <Tab
                      label="Chat History"
                      onClick={() => handleScreenChange('chatHistoryScreen')}
                      // onSelect={() => isButtonSelected('chatHistoryScreen')}
                      sx={{
                        ...(isButtonSelected('chatHistoryScreen') && {
                          color: 'primary',
                          bgcolor: 'primary', // Change color of the selected tab
                        }),
                      }}
                      icon={
                        <Avatar>
                          <Iconify icon="ic:baseline-assessment" width={24} />
                        </Avatar>
                      }
                    /> 
                </Tabs>

              <Grid item xs={12} sm={12} md={9}>
                {selectedScreen === 'chatScreen' && <ChatScreenPopUp />}
                {selectedScreen === 'documentScreen' && <DocumentScreenPopUp />}
                {selectedScreen === 'chatHistoryScreen' && <HistoryScreenPopUp />}
              </Grid>
      </Grid>
    </Grid>
  );
}

