import useOffSetTop from 'src/hooks/useOffSetTop';
import { HEADER } from 'src/config-global';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Container, Link, Stack, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../routes/paths';
// components
import Iconify from '../components/iconify';
// sections
import AuthResetPasswordForm from '../sections/auth/AuthResetPasswordForm';
// assets
import { PasswordIcon } from '../assets/icons';
import Header from './Header';

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  const location = useLocation();

  const { data } = location.state;

  return (
    <>
    <Header isOffset={isOffset} />

    <Container component="main">
      <Stack
        sx={{
          py: 12,
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
      <Helmet>
        <title> Reset Password | Minimal UI</title>
      </Helmet>

      <PasswordIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        Forgot your password?
      </Typography>

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        Please enter the email address associated with your account and We will email you a link to
        reset your password.
      </Typography>

      <AuthResetPasswordForm  email={data}/>

      <Link
        component={RouterLink}
        to={PATH_AUTH.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          mt: 3,
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Return to sign in
      </Link>
      </Stack>
      </Container>
    </>
  );
}
