import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

interface Props {
  reportData: any;
}

export default function Regulations2({ reportData }: Props) {
  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE REGULATIONS</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack>
          <Box sx={{ typography: 'caption' }}>
            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.5 Electric resistance space heating
            </Typography>
            <Typography variant="body2">
              An electric resistance space heating system that serves more than one room must have—
              (a) separate isolating switches for each room and{' '}
            </Typography>
            <Typography variant="body2">
              (b) a separate temperature controller and time switch for each group of rooms with
              common heating needs and{' '}
            </Typography>
            <Typography variant="body2">
              (c) power loads of not more than 110 W/m2 for living areas, and 150 W/m2 for
              bathrooms.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.6 Artificial lighting
            </Typography>
            <Typography variant="body2">
              (1) The lamp power density or illumination power density of artificial lighting,
              excluding heaters that emit light, must not exceed the allowance of— (a) 5 W/m2 in a
              Class 1 building and (b) 4 W/m2 on a Verandah, balcony or the like attached to a Class
              1 building; and (c) 3 W/m2 in a Class 10a building associated with a Class 1 building.
            </Typography>
            <Typography variant="body2">
              (2) The illumination power density allowance in (1) may be increased by dividing it by
              the relevant illumination power density adjustment factor for a control device in (6)
              as applicable.
            </Typography>
            <Typography variant="body2">
              (3) When designing the lamp power density or illumination power density, the power of
              the proposed installation must be used rather than nominal allowances for exposed
              batten holders or luminaires.
            </Typography>
            <Typography variant="body2">
              (4) If halogen lamps are installed, they must be separately switched from fluorescent
              lamps.
            </Typography>
            <Typography variant="body2">
              (5) Artificial lighting around the perimeter of a building must— (a) be controlled by
              a daylight sensor or (b) have an average light source efficacy of not less than 40
              Lumens/W.
            </Typography>
            <Typography variant="body2">
              (6) The following illumination power density adjustment factors apply to control
              devices for artificial lighting:
            </Typography>
            <Typography variant="body2">
              (a) Lighting timer for corridor lighting: 0.7. Motion detector —(i)(A) at least 75% of
              the area of space is controlled by one or more motion detectors; or{' '}
            </Typography>
            <Typography variant="body2">
              (b) an area of less than 200 m2 is switched as a block by one or more motion
              detectors; and (i) 0.7, where up to 6 lights are switched as a block by one or more
              detectors; and (ii) 0.55, where up to 2 lights are switched as a block by one or more
              detectors.
            </Typography>
            <Typography variant="body2">
              (c) Manual dimming system where not less than 75% of the space area is controlled by
              manually operated dimmers: 0.85.
            </Typography>
            <Typography variant="body2">
              (d) Programmable dimming system where not less than 75% of the space area is
              controlled by programmable dimmers: 0.85.
            </Typography>
            <Typography variant="body2">
              (e) Dynamic dimming system, with automatic compensation for lumen depreciation, the
              design lumen depreciation factor is not less than — (i) 0.9 for fluorescent lights or
              (ii) 0.8 for high-pressure discharge lights.
            </Typography>
            <Typography variant="body2">
              (f) Fixed dimming where at least 75% of the area is controlled by fixed dimmers that
              reduce the overall lighting level and the power consumption of the lighting — equal to
              the % of full power to which the dimmer is set divided by 0.95.
            </Typography>
            <Typography variant="body2">
              (g) Daylight sensor and dynamic lighting control device, with dimmed or stepped
              switching of lights adjacent to windows: (i) Lights within the space adjacent to
              windows other than roof lights for a distance from the window equal to the depth of
              the floor at window head height: 0.5. (ii) Lights within the space adjacent to roof
              lights: 0.6.
            </Typography>
            <Typography variant="body2">
              (7) For (6)(c), manual dimming is where lights are controlled by a knob, slider, or
              other mechanism or where there are pre-selected scenes that are manually selected.
            </Typography>
            <Typography variant="body2">
              (8) For (6)(d), programmed dimming is where pre-selected scenes or levels are
              automatically selected by the time of day, photoelectric cell, or occupancy sensor.
            </Typography>
            <Typography variant="body2">
              (9) For (6)(e), dynamic dimming is where the lighting level is varied automatically by
              a photoelectric cell to either proportionately compensate for the availability of
              daylight or the lumen depreciation of the lamps.
            </Typography>
            <Typography variant="body2">
              (10) For (6)(f), fixed dimming is where lights are controlled to a level, and that
              level cannot be adjusted by the user.
            </Typography>
            <Typography variant="body2">
              (11) For (6)(g)(i) and (ii), the illumination power density adjustment factor is only
              applied to lights controlled by that item — this adjustment factor does not apply to
              tungsten halogen or other incandescent sources.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.7 Water heater in a heated water supply system
            </Typography>
            <Typography variant="body2">
              A water heater in a heated water supply system must be designed and installed by Part
              B2 of NCC Volume Three — Plumbing Code of Australia.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.8 Swimming pool heating and pumping
            </Typography>
            <Typography variant="body2">
              (1) Heating for a swimming pool must be by— (a) a solar heater not boosted by electric
              resistance heating or{' '}
            </Typography>
            <Typography variant="body2">
              (b) a heater using reclaimed energy, (c) a gas heater, or (d) a heat pump, or (e) a
              combination of (a) to (d).{' '}
            </Typography>
            <Typography variant="body2">
              (2) Where some or all of the heating required by (1) is by a gas heater or a heat
              pump, the swimming pool must have— (a) a cover with a minimum R-Value of 0.05 unless
              located in a conditioned space and (b) a time switch to control the operation of the
              heater.
            </Typography>
            <Typography variant="body2">
              (3) A time switch must be provided to control the operation of a circulation pump for
              a swimming pool.
            </Typography>
            <Typography variant="body2">
              (4) For the purposes of 13.7.8, a swimming pool does not include a spa pool.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              13.7.9 Spa pool heating and pumping
            </Typography>
            <Typography variant="body2">
              (1) Heating for a spa pool that shares a water recirculation system with a swimming
              pool must be by— (a) a solar heater or{' '}
            </Typography>
            <Typography variant="body2">
              (b) a heater using reclaimed energy or a gas heater, or (d) (e) a combination of (a)
              to (d).
            </Typography>
            <Typography variant="body2">
              (2) Where some or all of the heating required by (1) is by a gas heater or a heat
              pump, the spa pool must have— (a) a cover and (b) a push button and a time switch to
              control the operation of the heater.
            </Typography>
            <Typography variant="body2">
              (3) A time switch must be provided to control the operation of a circulation pump for
              a spa pool having a capacity of 680 L or more.
            </Typography>

            <Divider sx={{ borderStyle: 'none', py: '5rem' }} />
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '10px' }}>
                  <img
                    src="./ea-report-footer.png"
                    alt="Logo"
                    style={{ width: '20vh', height: '10vh' }}
                  />
                </Box>
                <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
                <Stack direction="column" sx={{ marginTop: '45px' }}>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    energy@energyadvance.com.au
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065 6065
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    ACN: 60 9332014 | 1300 850 228
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                  borderColor: '#919eab3d',
                  p: '0.5rem',
                  padding: '3rem',
                }}
              >
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                  COMPLIANCE STAMP NOT{' '}
                </Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}
