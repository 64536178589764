import { Box, Divider, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  reportData: any;
}

export default function Summary({
  reportData,
  saveReportData,
  setSaveReportData,
  invalidFields,
}: any) {
  const [class1Allowance, SetClass1Allowance] = useState<number>(5.0);
  const [class1MaxWatts, SetClass1MaxWatts] = useState<number>();

  const [class10Allowance, SetClass10Allowance] = useState<number>(3.0);
  const [class10MaxWatts, SetClass10MaxWatts] = useState<number>();

  const [outdoorAllowance, SetOutdoorAllowance] = useState<number>(4.0);
  const [outdoorMaxWatts, SetOutdoorMaxWatts] = useState<number>();

  const [qdcStarCreditApplied, SetQdcStarCreditApplied] = useState<string>(
    saveReportData?.QDCOneStarCreditApplied || ''
  );
  const [groundFloorType, SetGroundFloorType] = useState<string>(
    saveReportData?.GroundFloorType || ''
  );

  const [energyHeatingTarget, SetEnergyHeatingTarget] = useState<number>();
  const [energyCoolingTarget, SetEnergyCoolingTarget] = useState<number>();
  const [energyTotalTarget, SetEnergyTotalTarget] = useState<number>();

  const [state, SetState] = useState<string>('');
  const [climateZone, SetClimateZone] = useState<string>('');

  const [maximumPenetration, SetMaximumPenetration] = useState<string>('');

  const heatingTargetQLDQdcConcrete2022 = [
    { key: '7', value: 18 },
    { key: '9', value: 51 },
    { key: '10', value: 24 },
  ];
  const heatingTargetQLDQdcOther2022 = [
    { key: '7', value: 28 },
    { key: '9', value: 50 },
    { key: '10', value: 29 },
  ];
  const heatingTargetNSWConcrete2022 = [
    { key: '8', value: 58.5 },
    { key: '9', value: 39.5 },
    { key: '10', value: 12 },
    { key: '11', value: 27.8 },
    { key: '14', value: 119 },
    { key: '20', value: 90.1 },
    { key: '24', value: 117 },
    { key: '27', value: 75.9 },
    { key: '4', value: 4 },
    { key: '6', value: 40 },
    { key: '7', value: 12 },
    { key: '8', value: 59 },
    { key: '9', value: 37 },
    { key: '10', value: 16 },
    { key: '11', value: 27.8 },
    { key: '12', value: 25 },
    { key: '13', value: 53 },
    { key: '14', value: 141 },
    { key: '15', value: 47.1 },
    { key: '16', value: 54 },
    { key: '17', value: 26 },
    { key: '18', value: 56.2 },
    { key: '19', value: 48 },
    { key: '20', value: 87 },
    { key: '21', value: 48 },
    { key: '22', value: 110 },
    { key: '24', value: 129 },
    { key: '27', value: 71 },
    { key: '28', value: 56 },
    { key: '40', value: 20 },
    { key: '41', value: 20 },
    { key: '42', value: 19 },
    { key: '43', value: 29 },
    { key: '44', value: 45 },
    { key: '45', value: 66 },
    { key: '47', value: 78 },
    { key: '48', value: 72.6 },
    { key: '49', value: 103 },
    { key: '50', value: 66 },
    { key: '51', value: 57 },
    { key: '52', value: 26 },
    { key: '53', value: 51 },
    { key: '54', value: 29 },
    { key: '55', value: 40 },
    { key: '56', value: 25 },
    { key: '57', value: 80 },
    { key: '58', value: 59 },
    { key: '59', value: 195 },
    { key: '60', value: 95 },
    { key: '61', value: 116 },
    { key: '62', value: 80 },
    { key: '63', value: 116 },
    { key: '64', value: 96 },
    { key: '65', value: 150 },
    { key: '66', value: 169 },
  ];
  const heatingTargetNSWSuspended2022 = [
    { key: '8', value: 57.3 },
    { key: '9', value: 38.3 },
    { key: '10', value: 15.2 },
    { key: '11', value: 32.5 },
    { key: '14', value: 116.5 },
    { key: '20', value: 85.1 },
    { key: '24', value: 114 },
    { key: '27', value: 70.8 },
    { key: '4', value: 9 },
    { key: '6', value: 48 },
    { key: '7', value: 17 },
    { key: '8', value: 57 },
    { key: '9', value: 37 },
    { key: '10', value: 21 },
    { key: '11', value: 32.5 },
    { key: '12', value: 26 },
    { key: '13', value: 34 },
    { key: '14', value: 125 },
    { key: '15', value: 39.3 },
    { key: '16', value: 43 },
    { key: '17', value: 27 },
    { key: '18', value: 53.4 },
    { key: '19', value: 49 },
    { key: '20', value: 72 },
    { key: '21', value: 47 },
    { key: '22', value: 105 },
    { key: '24', value: 123 },
    { key: '27', value: 69 },
    { key: '28', value: 53.6 },
    { key: '40', value: 18 },
    { key: '41', value: 32 },
    { key: '42', value: 32 },
    { key: '43', value: 44 },
    { key: '44', value: 46 },
    { key: '45', value: 62 },
    { key: '47', value: 56 },
    { key: '49', value: 87 },
    { key: '50', value: 65 },
    { key: '51', value: 51 },
    { key: '52', value: 22 },
    { key: '53', value: 45 },
    { key: '54', value: 23 },
    { key: '55', value: 36 },
    { key: '56', value: 25.6 },
    { key: '57', value: 70 },
    { key: '58', value: 57 },
    { key: '59', value: 191 },
    { key: '60', value: 92 },
    { key: '61', value: 110 },
    { key: '62', value: 79 },
    { key: '63', value: 116 },
    { key: '64', value: 94 },
    { key: '65', value: 149.8 },
    { key: '66', value: 163 },
  ];
  const heatingTargetOther2022 = [
    { key: '4', value: 4 },
    { key: '6', value: 40 },
    { key: '7', value: 12 },
    { key: '8', value: 59 },
    { key: '9', value: 37 },
    { key: '10', value: 16 },
    { key: '11', value: 27.8 },
    { key: '12', value: 25 },
    { key: '13', value: 53 },
    { key: '14', value: 141 },
    { key: '15', value: 47.1 },
    { key: '16', value: 54 },
    { key: '17', value: 26 },
    { key: '18', value: 56.2 },
    { key: '19', value: 48 },
    { key: '20', value: 87 },
    { key: '21', value: 48 },
    { key: '22', value: 110 },
    { key: '24', value: 129 },
    { key: '27', value: 71 },
    { key: '28', value: 56 },
    { key: '40', value: 20 },
    { key: '41', value: 20 },
    { key: '42', value: 19 },
    { key: '43', value: 29 },
    { key: '44', value: 45 },
    { key: '45', value: 66 },
    { key: '47', value: 78 },
    { key: '48', value: 72.6 },
    { key: '49', value: 103 },
    { key: '50', value: 66 },
    { key: '51', value: 57 },
    { key: '52', value: 26 },
    { key: '53', value: 51 },
    { key: '54', value: 29 },
    { key: '55', value: 40 },
    { key: '56', value: 25 },
    { key: '57', value: 80 },
    { key: '58', value: 59 },
    { key: '59', value: 195 },
    { key: '60', value: 95 },
    { key: '61', value: 116 },
    { key: '62', value: 80 },
    { key: '63', value: 116 },
    { key: '64', value: 96 },
    { key: '65', value: 150 },
    { key: '66', value: 169 },
  ];

  const coolingTargetNSWConcrete2022 = [
    { key: '8', value: 51.7 },
    { key: '9', value: 50 },
    { key: '10', value: 55.5 },
    { key: '11', value: 26.6 },
    { key: '14', value: 22.1 },
    { key: '20', value: 32.6 },
    { key: '24', value: 30 },
    { key: '27', value: 49 },
    { key: '4', value: 50 },
    { key: '6', value: 75 },
    { key: '7', value: 92 },
    { key: '8', value: 52 },
    { key: '9', value: 43 },
    { key: '10', value: 39 },
    { key: '11', value: 26.6 },
    { key: '12', value: 36 },
    { key: '13', value: 34 },
    { key: '14', value: 12 },
    { key: '15', value: 30.1 },
    { key: '16', value: 37 },
    { key: '17', value: 17.5 },
    { key: '18', value: 29.8 },
    { key: '19', value: 56 },
    { key: '20', value: 34 },
    { key: '21', value: 41 },
    { key: '22', value: 12 },
    { key: '24', value: 34 },
    { key: '27', value: 43 },
    { key: '28', value: 37.5 },
    { key: '40', value: 86 },
    { key: '41', value: 73 },
    { key: '42', value: 71 },
    { key: '43', value: 68 },
    { key: '44', value: 39 },
    { key: '45', value: 32 },
    { key: '47', value: 37 },
    { key: '48', value: 31.6 },
    { key: '49', value: 27 },
    { key: '50', value: 28 },
    { key: '51', value: 41 },
    { key: '52', value: 33 },
    { key: '53', value: 38 },
    { key: '54', value: 26 },
    { key: '55', value: 15 },
    { key: '56', value: 18 },
    { key: '57', value: 28 },
    { key: '58', value: 6 },
    { key: '59', value: 13 },
    { key: '60', value: 27 },
    { key: '61', value: 15 },
    { key: '62', value: 22 },
    { key: '63', value: 11 },
    { key: '64', value: 10 },
    { key: '65', value: 17.9 },
    { key: '66', value: 21 },
  ];
  const coolingTargetNSWSuspended2022 = [
    { key: '8', value: 56.1 },
    { key: '9', value: 55 },
    { key: '10', value: 54.5 },
    { key: '11', value: 24 },
    { key: '14', value: 32.7 },
    { key: '20', value: 41.1 },
    { key: '24', value: 35 },
    { key: '27', value: 58.6 },
    { key: '4', value: 50 },
    { key: '6', value: 72 },
    { key: '7', value: 93 },
    { key: '8', value: 58 },
    { key: '9', value: 38 },
    { key: '10', value: 38 },
    { key: '11', value: 24 },
    { key: '12', value: 33 },
    { key: '13', value: 41 },
    { key: '14', value: 19 },
    { key: '15', value: 33.3 },
    { key: '16', value: 46 },
    { key: '17', value: 19.7 },
    { key: '18', value: 35.1 },
    { key: '19', value: 57 },
    { key: '20', value: 37 },
    { key: '21', value: 39 },
    { key: '22', value: 18 },
    { key: '24', value: 41 },
    { key: '27', value: 58 },
    { key: '28', value: 43.4 },
    { key: '40', value: 91 },
    { key: '41', value: 72 },
    { key: '42', value: 68 },
    { key: '43', value: 67 },
    { key: '44', value: 49 },
    { key: '45', value: 38 },
    { key: '47', value: 45 },
    { key: '49', value: 38 },
    { key: '50', value: 31 },
    { key: '51', value: 48 },
    { key: '52', value: 40 },
    { key: '53', value: 47 },
    { key: '54', value: 34 },
    { key: '55', value: 24 },
    { key: '56', value: 18.9 },
    { key: '57', value: 44 },
    { key: '58', value: 9 },
    { key: '59', value: 26 },
    { key: '60', value: 36 },
    { key: '61', value: 23 },
    { key: '62', value: 37 },
    { key: '63', value: 23 },
    { key: '64', value: 18 },
    { key: '65', value: 38.5 },
    { key: '66', value: 43 },
  ];
  const coolingTargetQLDConcrete2022 = [
    { key: '7', value: 115 },
    { key: '9', value: 55 },
    { key: '10', value: 49 },
    { key: '4', value: 50 },
    { key: '6', value: 75 },
    { key: '7', value: 92 },
    { key: '8', value: 52 },
    { key: '9', value: 43 },
    { key: '10', value: 39 },
    { key: '11', value: 26.6 },
    { key: '12', value: 36 },
    { key: '13', value: 34 },
    { key: '14', value: 12 },
    { key: '15', value: 30.1 },
    { key: '16', value: 37 },
    { key: '17', value: 17.5 },
    { key: '18', value: 29.8 },
    { key: '19', value: 56 },
    { key: '20', value: 34 },
    { key: '21', value: 41 },
    { key: '22', value: 12 },
    { key: '24', value: 34 },
    { key: '27', value: 43 },
    { key: '28', value: 37.5 },
    { key: '40', value: 86 },
    { key: '41', value: 73 },
    { key: '42', value: 71 },
    { key: '43', value: 68 },
    { key: '44', value: 39 },
    { key: '45', value: 32 },
    { key: '47', value: 37 },
    { key: '48', value: 31.6 },
    { key: '49', value: 27 },
    { key: '50', value: 28 },
    { key: '51', value: 41 },
    { key: '52', value: 33 },
    { key: '53', value: 38 },
    { key: '54', value: 26 },
    { key: '55', value: 15 },
    { key: '56', value: 18 },
    { key: '57', value: 28 },
    { key: '58', value: 6 },
    { key: '59', value: 13 },
    { key: '60', value: 27 },
    { key: '61', value: 15 },
    { key: '62', value: 22 },
    { key: '63', value: 11 },
    { key: '64', value: 10 },
    { key: '65', value: 17.9 },
    { key: '66', value: 21 },
  ];
  const coolingTargetQLDSuspended2022 = [
    { key: '7', value: 112 },
    { key: '9', value: 48 },
    { key: '10', value: 42 },
    { key: '4', value: 50 },
    { key: '6', value: 72 },
    { key: '7', value: 93 },
    { key: '8', value: 58 },
    { key: '9', value: 38 },
    { key: '10', value: 38 },
    { key: '11', value: 24 },
    { key: '12', value: 33 },
    { key: '13', value: 41 },
    { key: '14', value: 19 },
    { key: '15', value: 33.3 },
    { key: '16', value: 46 },
    { key: '17', value: 19.7 },
    { key: '18', value: 35.1 },
    { key: '19', value: 57 },
    { key: '20', value: 37 },
    { key: '21', value: 39 },
    { key: '22', value: 18 },
    { key: '24', value: 41 },
    { key: '27', value: 58 },
    { key: '28', value: 43.4 },
    { key: '40', value: 91 },
    { key: '41', value: 72 },
    { key: '42', value: 68 },
    { key: '43', value: 67 },
    { key: '44', value: 49 },
    { key: '45', value: 38 },
    { key: '47', value: 45 },
    { key: '49', value: 38 },
    { key: '50', value: 31 },
    { key: '51', value: 48 },
    { key: '52', value: 40 },
    { key: '53', value: 47 },
    { key: '54', value: 34 },
    { key: '55', value: 24 },
    { key: '56', value: 18.9 },
    { key: '57', value: 44 },
    { key: '58', value: 9 },
    { key: '59', value: 26 },
    { key: '60', value: 36 },
    { key: '61', value: 23 },
    { key: '62', value: 37 },
    { key: '63', value: 23 },
    { key: '64', value: 18 },
    { key: '65', value: 38.5 },
    { key: '66', value: 43 },
  ];
  const coolingTargetOther2022 = [
    { key: '4', value: 50 },
    { key: '6', value: 75 },
    { key: '7', value: 92 },
    { key: '8', value: 52 },
    { key: '9', value: 43 },
    { key: '10', value: 39 },
    { key: '11', value: 26.6 },
    { key: '12', value: 36 },
    { key: '13', value: 34 },
    { key: '14', value: 12 },
    { key: '15', value: 30.1 },
    { key: '16', value: 37 },
    { key: '17', value: 17.5 },
    { key: '18', value: 29.8 },
    { key: '19', value: 56 },
    { key: '20', value: 34 },
    { key: '21', value: 41 },
    { key: '22', value: 12 },
    { key: '24', value: 34 },
    { key: '27', value: 43 },
    { key: '28', value: 37.5 },
    { key: '40', value: 86 },
    { key: '41', value: 73 },
    { key: '42', value: 71 },
    { key: '43', value: 68 },
    { key: '44', value: 39 },
    { key: '45', value: 32 },
    { key: '47', value: 37 },
    { key: '48', value: 31.6 },
    { key: '49', value: 27 },
    { key: '50', value: 28 },
    { key: '51', value: 41 },
    { key: '52', value: 33 },
    { key: '53', value: 38 },
    { key: '54', value: 26 },
    { key: '55', value: 15 },
    { key: '56', value: 18 },
    { key: '57', value: 28 },
    { key: '58', value: 6 },
    { key: '59', value: 13 },
    { key: '60', value: 27 },
    { key: '61', value: 15 },
    { key: '62', value: 22 },
    { key: '63', value: 11 },
    { key: '64', value: 10 },
    { key: '65', value: 17.9 },
    { key: '66', value: 21 },
  ];

  // 2019
  const heatingTargetQLDQdcConcrete2019 = [
    { key: '7', value: 23 },
    { key: '9', value: 45 },
    { key: '10', value: 38 },
  ];
  const heatingTargetQLDQdcOther2019 = [
    { key: '7', value: 24 },
    { key: '9', value: 47 },
    { key: '10', value: 40 },
  ];
  const heatingTargetNSWConcrete2019 = [
    { key: '8', value: 46.4 },
    { key: '9', value: 25 },
    { key: '14', value: 141.1 },
    { key: '15', value: 54 },
    { key: '20', value: 127.9 },
    { key: '24', value: 179.9 },
    { key: '4', value: 8 },
    { key: '6', value: 57 },
    { key: '7', value: 13 },
    { key: '8', value: 71 },
    { key: '9', value: 33 },
    { key: '10', value: 24 },
    { key: '11', value: 24.5 },
    { key: '12', value: 35 },
    { key: '13', value: 57 },
    { key: '14', value: 121 },
    { key: '15', value: 54 },
    { key: '16', value: 67 },
    { key: '17', value: 25 },
    { key: '18', value: 73.5 },
    { key: '19', value: 52 },
    { key: '20', value: 114 },
    { key: '21', value: 96 },
    { key: '22', value: 123 },
    { key: '24', value: 154 },
    { key: '27', value: 78.7 },
    { key: '28', value: 55.7 },
    { key: '40', value: 16 },
    { key: '41', value: 28 },
    { key: '42', value: 20 },
    { key: '43', value: 35 },
    { key: '44', value: 52 },
    { key: '45', value: 66 },
    { key: '47', value: 87 },
    { key: '49', value: 85 },
    { key: '50', value: 57 },
    { key: '51', value: 57 },
    { key: '52', value: 32 },
    { key: '53', value: 62 },
    { key: '54', value: 53 },
    { key: '55', value: 54 },
    { key: '56', value: 40 },
    { key: '57', value: 96 },
    { key: '58', value: 79 },
    { key: '59', value: 226 },
    { key: '60', value: 126 },
    { key: '61', value: 139 },
    { key: '62', value: 115 },
    { key: '63', value: 140 },
    { key: '64', value: 119 },
    { key: '65', value: 262.4 },
    { key: '66', value: 189 },
  ];
  const heatingTargetNSWSuspended2019 = [
    { key: '8', value: 51.1 },
    { key: '9', value: 27.4 },
    { key: '14', value: 150.5 },
    { key: '15', value: 59.7 },
    { key: '20', value: 140.7 },
    { key: '24', value: 191.2 },
    { key: '4', value: 15 },
    { key: '6', value: 64 },
    { key: '7', value: 18 },
    { key: '8', value: 65 },
    { key: '9', value: 34 },
    { key: '10', value: 28 },
    { key: '11', value: 29.4 },
    { key: '12', value: 37 },
    { key: '13', value: 38 },
    { key: '14', value: 113 },
    { key: '15', value: 59.7 },
    { key: '16', value: 55 },
    { key: '17', value: 26.8 },
    { key: '18', value: 78.4 },
    { key: '19', value: 55 },
    { key: '20', value: 93 },
    { key: '21', value: 88 },
    { key: '22', value: 117 },
    { key: '24', value: 143 },
    { key: '27', value: 87 },
    { key: '28', value: 61.8 },
    { key: '40', value: 14 },
    { key: '41', value: 42 },
    { key: '42', value: 31 },
    { key: '43', value: 48 },
    { key: '44', value: 52 },
    { key: '45', value: 62 },
    { key: '47', value: 68 },
    { key: '49', value: 72 },
    { key: '50', value: 58 },
    { key: '51', value: 51 },
    { key: '52', value: 28 },
    { key: '53', value: 55 },
    { key: '54', value: 42 },
    { key: '55', value: 49 },
    { key: '56', value: 47.8 },
    { key: '57', value: 85 },
    { key: '58', value: 77 },
    { key: '59', value: 220 },
    { key: '60', value: 121 },
    { key: '61', value: 132 },
    { key: '62', value: 109 },
    { key: '63', value: 138 },
    { key: '64', value: 113 },
    { key: '65', value: 275.6 },
    { key: '66', value: 181 },
  ];
  const heatingTargetOther2019 = [
    { key: '4', value: 8 },
    { key: '6', value: 57 },
    { key: '7', value: 13 },
    { key: '8', value: 71 },
    { key: '9', value: 33 },
    { key: '10', value: 24 },
    { key: '11', value: 24.5 },
    { key: '12', value: 35 },
    { key: '13', value: 57 },
    { key: '14', value: 121 },
    { key: '15', value: 54 },
    { key: '16', value: 67 },
    { key: '17', value: 25 },
    { key: '18', value: 73.5 },
    { key: '19', value: 52 },
    { key: '20', value: 114 },
    { key: '21', value: 96 },
    { key: '22', value: 123 },
    { key: '24', value: 154 },
    { key: '27', value: 78.7 },
    { key: '28', value: 55.7 },
    { key: '40', value: 16 },
    { key: '41', value: 28 },
    { key: '42', value: 20 },
    { key: '43', value: 35 },
    { key: '44', value: 52 },
    { key: '45', value: 66 },
    { key: '47', value: 87 },
    { key: '49', value: 85 },
    { key: '50', value: 57 },
    { key: '51', value: 57 },
    { key: '52', value: 32 },
    { key: '53', value: 62 },
    { key: '54', value: 53 },
    { key: '55', value: 54 },
    { key: '56', value: 40 },
    { key: '57', value: 96 },
    { key: '58', value: 79 },
    { key: '59', value: 226 },
    { key: '60', value: 126 },
    { key: '61', value: 139 },
    { key: '62', value: 115 },
    { key: '63', value: 140 },
    { key: '64', value: 119 },
    { key: '65', value: 262.4 },
    { key: '66', value: 189 },
  ];

  const coolingTargetQLDQdcConcrete2019 = [
    { key: '7', value: 106 },
    { key: '9', value: 67 },
    { key: '10', value: 42 },
  ];
  const coolingTargetQLDQdcOther2019 = [
    { key: '7', value: 106 },
    { key: '9', value: 62 },
    { key: '10', value: 37 },
  ];
  const coolingTargetNSWConcrete2019 = [
    { key: '8', value: 72.5 },
    { key: '9', value: 64.8 },
    { key: '11', value: 33.7 },
    { key: '14', value: 27.8 },
    { key: '20', value: 50 },
    { key: '24', value: 36 },
    { key: '4', value: 50 },
    { key: '6', value: 91 },
    { key: '7', value: 85 },
    { key: '8', value: 57 },
    { key: '9', value: 52 },
    { key: '10', value: 31 },
    { key: '11', value: 33.7 },
    { key: '12', value: 44 },
    { key: '13', value: 39 },
    { key: '14', value: 26 },
    { key: '15', value: 32 },
    { key: '16', value: 52 },
    { key: '17', value: 28.2 },
    { key: '18', value: 31.5 },
    { key: '19', value: 66 },
    { key: '20', value: 47 },
    { key: '21', value: 45 },
    { key: '22', value: 27 },
    { key: '24', value: 38 },
    { key: '27', value: 64.2 },
    { key: '28', value: 56.2 },
    { key: '40', value: 122 },
    { key: '41', value: 89 },
    { key: '42', value: 63 },
    { key: '43', value: 91 },
    { key: '44', value: 32 },
    { key: '45', value: 57 },
    { key: '47', value: 40 },
    { key: '49', value: 47 },
    { key: '50', value: 44 },
    { key: '51', value: 40 },
    { key: '52', value: 19 },
    { key: '53', value: 43 },
    { key: '54', value: 39 },
    { key: '55', value: 25 },
    { key: '56', value: 26 },
    { key: '57', value: 40 },
    { key: '58', value: 13 },
    { key: '59', value: 14 },
    { key: '60', value: 31 },
    { key: '61', value: 20 },
    { key: '62', value: 24 },
    { key: '63', value: 22 },
    { key: '64', value: 21 },
    { key: '65', value: 25 },
    { key: '66', value: 26 },
  ];
  const coolingTargetNSWSuspended2019 = [
    { key: '8', value: 80 },
    { key: '9', value: 69.6 },
    { key: '11', value: 33.7 },
    { key: '14', value: 31.2 },
    { key: '20', value: 55 },
    { key: '24', value: 38.3 },
    { key: '4', value: 49 },
    { key: '6', value: 89 },
    { key: '7', value: 86 },
    { key: '8', value: 63 },
    { key: '9', value: 47 },
    { key: '10', value: 31 },
    { key: '11', value: 33.7 },
    { key: '12', value: 41 },
    { key: '13', value: 46 },
    { key: '14', value: 40 },
    { key: '15', value: 33.3 },
    { key: '16', value: 59 },
    { key: '17', value: 30.8 },
    { key: '18', value: 34.6 },
    { key: '19', value: 64 },
    { key: '20', value: 47 },
    { key: '21', value: 47 },
    { key: '22', value: 39 },
    { key: '24', value: 47 },
    { key: '27', value: 64.4 },
    { key: '28', value: 61.8 },
    { key: '40', value: 124 },
    { key: '41', value: 86 },
    { key: '42', value: 61 },
    { key: '43', value: 88 },
    { key: '44', value: 42 },
    { key: '45', value: 61 },
    { key: '47', value: 50 },
    { key: '49', value: 63 },
    { key: '50', value: 44 },
    { key: '51', value: 47 },
    { key: '52', value: 24 },
    { key: '53', value: 51 },
    { key: '54', value: 47 },
    { key: '55', value: 33 },
    { key: '56', value: 26.7 },
    { key: '57', value: 56 },
    { key: '58', value: 18 },
    { key: '59', value: 24 },
    { key: '60', value: 43 },
    { key: '61', value: 31 },
    { key: '62', value: 34 },
    { key: '63', value: 36 },
    { key: '64', value: 31 },
    { key: '65', value: 26.2 },
    { key: '66', value: 48 },
  ];
  const coolingTargetOtherConcrete2019 = [
    { key: '4', value: 50 },
    { key: '6', value: 91 },
    { key: '7', value: 85 },
    { key: '8', value: 57 },
    { key: '9', value: 52 },
    { key: '10', value: 31 },
    { key: '11', value: 33.7 },
    { key: '12', value: 44 },
    { key: '13', value: 39 },
    { key: '14', value: 26 },
    { key: '15', value: 32 },
    { key: '16', value: 52 },
    { key: '17', value: 28.2 },
    { key: '18', value: 31.5 },
    { key: '19', value: 66 },
    { key: '20', value: 47 },
    { key: '21', value: 45 },
    { key: '22', value: 27 },
    { key: '24', value: 38 },
    { key: '27', value: 64.2 },
    { key: '28', value: 56.2 },
    { key: '40', value: 122 },
    { key: '41', value: 89 },
    { key: '42', value: 63 },
    { key: '43', value: 91 },
    { key: '44', value: 32 },
    { key: '45', value: 57 },
    { key: '47', value: 40 },
    { key: '49', value: 47 },
    { key: '50', value: 44 },
    { key: '51', value: 40 },
    { key: '52', value: 19 },
    { key: '53', value: 43 },
    { key: '54', value: 39 },
    { key: '55', value: 25 },
    { key: '56', value: 26 },
    { key: '57', value: 40 },
    { key: '58', value: 13 },
    { key: '59', value: 14 },
    { key: '60', value: 31 },
    { key: '61', value: 20 },
    { key: '62', value: 24 },
    { key: '63', value: 22 },
    { key: '64', value: 21 },
    { key: '65', value: 25 },
    { key: '66', value: 26 },
  ];
  const coolingTargetOtherSuspended2019 = [
    { key: '4', value: 49 },
    { key: '6', value: 89 },
    { key: '7', value: 86 },
    { key: '8', value: 63 },
    { key: '9', value: 47 },
    { key: '10', value: 31 },
    { key: '11', value: 33.7 },
    { key: '12', value: 41 },
    { key: '13', value: 46 },
    { key: '14', value: 40 },
    { key: '15', value: 33.3 },
    { key: '16', value: 59 },
    { key: '17', value: 30.8 },
    { key: '18', value: 34.6 },
    { key: '19', value: 64 },
    { key: '20', value: 47 },
    { key: '21', value: 47 },
    { key: '22', value: 39 },
    { key: '24', value: 47 },
    { key: '27', value: 64.4 },
    { key: '28', value: 61.8 },
    { key: '40', value: 124 },
    { key: '41', value: 86 },
    { key: '42', value: 61 },
    { key: '43', value: 88 },
    { key: '44', value: 42 },
    { key: '45', value: 61 },
    { key: '47', value: 50 },
    { key: '49', value: 63 },
    { key: '50', value: 44 },
    { key: '51', value: 47 },
    { key: '52', value: 24 },
    { key: '53', value: 51 },
    { key: '54', value: 47 },
    { key: '55', value: 33 },
    { key: '56', value: 26.7 },
    { key: '57', value: 56 },
    { key: '58', value: 18 },
    { key: '59', value: 24 },
    { key: '60', value: 43 },
    { key: '61', value: 31 },
    { key: '62', value: 34 },
    { key: '63', value: 36 },
    { key: '64', value: 31 },
    { key: '65', value: 26.2 },
    { key: '66', value: 48 },
  ];

  const [heatingBenchmark, SetHeatingBenchmark] = useState<number>();
  const [coolingBenchmark, SetCoolingBenchmark] = useState<number>();
  const [dwelingType, SetDwellingType] = useState(saveReportData?.DwellingType);

  useEffect(() => {
    if (reportData && reportData.projectInfoData) {
      const postcodes = [
        '0200',
        '0221',
        '2600',
        '2601',
        '2602',
        '2603',
        '2604',
        '2605',
        '2606',
        '2607',
        '2608',
        '2609',
        '2610',
        '2611',
        '2612',
        '2614',
        '2615',
        '2616',
        '2617',
        '2900',
        '2901',
        '2902',
        '2903',
        '2904',
        '2905',
        '2906',
        '2911',
        '2912',
        '2913',
        '2914',
      ];
      const address = reportData.projectInfoData?.Address;
      const postcode = address.substring(address.length - 4);
      let siteState = 'No State Found';
      if (postcodes.indexOf(postcode) >= 0) {
        siteState = 'Australian Capital Territory';
      } else if (!Number.isNaN(postcode) && postcode.length === 4) {
        if (postcode.charAt(0) === '1' || postcode.charAt(0) === '2') {
          siteState = 'New South Wales';
        } else if (postcode.charAt(0) === '3') {
          siteState = 'Victoria';
        } else if (postcode.charAt(0) === '4') {
          siteState = 'Queensland';
        } else if (postcode.charAt(0) === '5') {
          siteState = 'South Australia';
        } else if (postcode.charAt(0) === '6') {
          siteState = 'Western Australia';
        } else if (postcode.charAt(0) === '7') {
          siteState = 'Tasmania';
        } else if (postcode.charAt(0) === '8') {
          siteState = 'Northern Territory';
        }
      }

      let zone = reportData.projectInfoData?.Climate;
      zone = zone.substring(0, 2);

      if (reportData.projectInfoData.NccType === '2019') {
        // Calculate Energy Heating Target
        calculateEnergyHeatingTarget2019(siteState, zone, reportData.projectInfoData.EnergyHeating);

        // Calculate Energy Cooling Target
        calculateEnergyCoolingTarget2019(siteState, zone, reportData.projectInfoData.EnergyCooling);
      } else {
        // Calculate Energy Heating Target
        calculateEnergyHeatingTarget2022(siteState, zone, reportData.projectInfoData.EnergyHeating);

        // Calculate Energy Cooling Target
        calculateEnergyCoolingTarget2022(siteState, zone, reportData.projectInfoData.EnergyCooling);
      }
    }
    calculateSummaryFields();
  });

  function calculateSummaryFields() {
    SetClass1MaxWatts(
      saveReportData.ClassOneTotalArea !== ''
        ? saveReportData.ClassOneTotalArea * class1Allowance
        : undefined
    );

    const a = saveReportData.ClassOneTotalArea * 0.005;
    const max_penitration = Math.ceil(a * 100) / 100;

    SetMaximumPenetration(max_penitration.toString());

    SetClass10MaxWatts(
      saveReportData.ClassTenATotalArea !== ''
        ? saveReportData.ClassTenATotalArea * class10Allowance
        : undefined
    );

    SetOutdoorMaxWatts(
      saveReportData.TotalOutdoorArea !== ''
        ? saveReportData.TotalOutdoorArea * outdoorAllowance
        : undefined
    );
  }

  function calculateEnergyHeatingTarget2019(
    siteState: string,
    zone: string,
    heatingProposed: number
  ) {
    let heatingTarget;
    if (siteState === 'Queensland' && qdcStarCreditApplied === '1 star QDC Credit Applied') {
      if (groundFloorType === 'Concrete Slab-on-Ground') {
        const foundObject = heatingTargetQLDQdcConcrete2019.find((obj) => obj.key === zone);
        if (foundObject) {
          heatingTarget = foundObject.value;
        }
      } else {
        const foundObject = heatingTargetQLDQdcOther2019.find((obj) => obj.key === zone);
        if (foundObject) {
          heatingTarget = foundObject.value;
        }
      }
    } else if (siteState === 'New South Wales') {
      if (groundFloorType === 'Concrete Slab-on-Ground') {
        const foundObj = heatingTargetNSWConcrete2019.find((obj) => obj.key === zone);
        if (foundObj) {
          heatingTarget = foundObj.value;
        }
      } else {
        const foundObj = heatingTargetNSWSuspended2019.find((obj) => obj.key === zone);
        if (foundObj) {
          heatingTarget = foundObj.value;
        }
      }
    } else {
      const foundObj = heatingTargetOther2019.find((obj) => obj.key === zone);
      if (foundObj) {
        heatingTarget = foundObj.value;
      }
    }

    SetEnergyHeatingTarget(heatingTarget);

    let benchmark = 0;
    if (heatingTarget && heatingProposed) {
      benchmark =
        ((heatingTarget - heatingProposed) / ((heatingTarget + heatingProposed) / 2)) * 100;
    }
    SetHeatingBenchmark(benchmark);
  }

  function calculateEnergyCoolingTarget2019(
    siteState: string,
    zone: string,
    coolingProposed: number
  ) {
    let coolingTarget;
    if (siteState === 'Queensland' && qdcStarCreditApplied === '1 star QDC Credit Applied') {
      if (groundFloorType === 'Concrete Slab-on-Ground') {
        const foundObject = coolingTargetQLDQdcConcrete2019.find((obj) => obj.key === zone);
        if (foundObject) {
          coolingTarget = foundObject.value;
        }
      } else {
        const foundObject = coolingTargetQLDQdcOther2019.find((obj) => obj.key === zone);
        if (foundObject) {
          coolingTarget = foundObject.value;
        }
      }
    } else if (siteState === 'New South Wales') {
      if (groundFloorType === 'Concrete Slab-on-Ground') {
        const foundObj = coolingTargetNSWConcrete2019.find((obj) => obj.key === zone);
        if (foundObj) {
          coolingTarget = foundObj.value;
        }
      } else {
        const foundObj = coolingTargetNSWSuspended2019.find((obj) => obj.key === zone);
        if (foundObj) {
          coolingTarget = foundObj.value;
        }
      }
    } else if (groundFloorType === 'Concrete Slab-on-Ground') {
      const foundObj = coolingTargetOtherConcrete2019.find((obj) => obj.key === zone);
      if (foundObj) {
        coolingTarget = foundObj.value;
      }
    } else {
      const foundObj = coolingTargetOtherSuspended2019.find((obj) => obj.key === zone);
      if (foundObj) {
        coolingTarget = foundObj.value;
      }
    }
    SetEnergyCoolingTarget(coolingTarget);

    let benchmark = 0;
    if (coolingTarget && coolingProposed) {
      benchmark =
        ((coolingTarget - coolingProposed) / ((coolingTarget + coolingProposed) / 2)) * 100;
    }
    SetCoolingBenchmark(benchmark);

    SetState(siteState);
    SetClimateZone(zone);
  }

  function calculateEnergyHeatingTarget2022(
    siteState: string,
    zone: string,
    heatingProposed: number
  ) {
    let heatingTarget;
    if (siteState === 'Queensland' && qdcStarCreditApplied === '1 star QDC Credit Applied') {
      if (groundFloorType === 'Concrete Slab-on-Ground') {
        const foundObject = heatingTargetQLDQdcConcrete2022.find((obj) => obj.key === zone);
        if (foundObject) {
          heatingTarget = foundObject.value;
        }
      } else {
        const foundObject = heatingTargetQLDQdcOther2022.find((obj) => obj.key === zone);
        if (foundObject) {
          heatingTarget = foundObject.value;
        }
      }
    } else if (siteState === 'New South Wales') {
      if (groundFloorType === 'Concrete Slab-on-Ground') {
        const foundObj = heatingTargetNSWConcrete2022.find((obj) => obj.key === zone);
        if (foundObj) {
          heatingTarget = foundObj.value;
        }
      } else {
        const foundObj = heatingTargetNSWSuspended2022.find((obj) => obj.key === zone);
        if (foundObj) {
          heatingTarget = foundObj.value;
        }
      }
    } else {
      const foundObj = heatingTargetOther2022.find((obj) => obj.key === zone);
      if (foundObj) {
        heatingTarget = foundObj.value;
      }
    }
    SetEnergyHeatingTarget(heatingTarget);

    let benchmark = 0;
    if (heatingTarget && heatingProposed) {
      benchmark =
        ((heatingTarget - heatingProposed) / ((heatingTarget + heatingProposed) / 2)) * 100;
    }
    SetHeatingBenchmark(benchmark);
  }

  function calculateEnergyCoolingTarget2022(
    siteState: string,
    zone: string,
    coolingProposed: number
  ) {
    let coolingTarget;
    if (siteState === 'New South Wales') {
      if (groundFloorType === 'Concrete Slab-on-Ground') {
        const foundObj = coolingTargetNSWConcrete2022.find((obj) => obj.key === zone);
        if (foundObj) {
          coolingTarget = foundObj.value;
        }
      } else {
        const foundObj = coolingTargetNSWSuspended2022.find((obj) => obj.key === zone);
        if (foundObj) {
          coolingTarget = foundObj.value;
        }
      }
    } else if (siteState === 'Queensland' && qdcStarCreditApplied === '1 star QDC Credit Applied') {
      if (groundFloorType === 'Concrete Slab-on-Ground') {
        const foundObj = coolingTargetQLDConcrete2022.find((obj) => obj.key === zone);
        if (foundObj) {
          coolingTarget = foundObj.value;
        }
      } else {
        const foundObj = coolingTargetQLDSuspended2022.find((obj) => obj.key === zone);
        if (foundObj) {
          coolingTarget = foundObj.value;
        }
      }
    } else {
      const foundObj = coolingTargetOther2022.find((obj) => obj.key === zone);
      if (foundObj) {
        coolingTarget = foundObj.value;
      }
    }
    SetEnergyCoolingTarget(coolingTarget);

    let benchmark = 0;
    if (coolingTarget && coolingProposed) {
      benchmark =
        ((coolingTarget - coolingProposed) / ((coolingTarget + coolingProposed) / 2)) * 100;
    }
    SetCoolingBenchmark(benchmark);

    SetState(siteState);
    SetClimateZone(zone);
  }

  const handleDepositedPlanNumberChange = (event: any) => {
    const newDepositedPlanNumber = parseFloat(event.target.value);
    console.log('DP Number : ', newDepositedPlanNumber);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      DPNumber: newDepositedPlanNumber,
    }));
  };

  const handleRatingChange = (event: any) => {
    const newRating = parseFloat(event.target.value);

    // Check if the new rating is a number and within the valid range
    if (!Number.isNaN(newRating) && newRating >= 0 && newRating <= 10) {
      setSaveReportData((prevData: any) => ({
        ...prevData,
        Rating: newRating,
      }));
    } else {
      // If the new rating is out of range, set it to  10.
      const boundedRating = Math.max(0, Math.min(newRating, 10));
      setSaveReportData((prevData: any) => ({
        ...prevData,
        Rating: boundedRating,
      }));
    }
  };

  const handleSummaryChange = (type: string, event: any) => {
    // SetDwellingType(event.target.value);
    console.log('Type of summary  : ', event.target.value);
    let value = event.target.value;
    if (
      type === 'ClassOneTotalArea' ||
      type === 'ClassTenATotalArea' ||
      type === 'TotalOutdoorArea'
    ) {
      value = parseFloat(value);
    }
    setSaveReportData((prevData: any) => ({
      ...prevData,
      EnergyHeatingTarget: energyHeatingTarget,
      EnergyCoolingTarget: energyCoolingTarget,
      [type]: value,
    }));
  };

  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE SUMMARY</Box>
          </Stack>
        </Stack>

        {reportData?.projectInfoData?.ClientName.includes('Metricon') &&
          reportData?.projectInfoData?.State === 'Queensland' && (
            <Stack
              spacing={2}
              alignItems="flex-end"
              sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
            >
              <Stack
                direction="row"
                sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
              >
                <Box sx={{ color: 'orange', typography: 'h5' }}>STAR RATING TARGET : 6 STARS</Box>
              </Stack>
            </Stack>
          )}

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Address
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 72, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {reportData?.projectInfoData?.Address}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Dwelling Type
                <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 200, flex: '1 1' }}>
              <Select
                variant="outlined"
                name="dwellingType"
                value={saveReportData?.DwellingType || ''}
                onChange={(event) => {
                  SetDwellingType(event.target.value);
                  handleSummaryChange('DwellingType', event);
                }}
                sx={{
                  width: '100%',
                  border:
                    invalidFields.indexOf('DwellingType') >= 0
                      ? '3px solid red'
                      : '1px solid black',
                  background: 'cadetblue',
                }}
              >
                <MenuItem value="Single Storey">Single Storey</MenuItem>
                <MenuItem value="Double Storey">Double Storey</MenuItem>
                <MenuItem value="Multi-Level Dwelling">Multi-Level Dwelling</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                State
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 72, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {saveReportData?.State}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Site Exposure
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 72, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {reportData?.projectInfoData?.SiteExposure}
              </Typography>
            </Stack>

            {reportData?.projectInfoData?.NccType === '2022' && (
              <Stack spacing={3} sx={{ px: 3,}} alignItems="end">
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{ marginTop: '20px', marginLeft: '240px' }}
                >
                  <Stack
                    direction="row"
                    justifyContent="end"
                    sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
                  >
                    <Typography typography="h6" variant="subtitle2">
                      Facade Facing<span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" sx={{ minWidth: 200, flex: '1 1' }}>
                    <Select
                      variant="outlined"
                      name="FacadeFacing"
                      sx={{
                        width: '100%',
                        border:
                          invalidFields.indexOf('FacadeFacing') >= 0
                            ? '3px solid red'
                            : '1px solid black',
                        background: 'cadetblue',
                      }}
                      value={saveReportData?.FacadeFacing || ''}
                      onChange={(event) => {
                        handleSummaryChange('FacadeFacing', event);
                      }}
                    >
                      <MenuItem value="North">North</MenuItem>
                      <MenuItem value="North-East">North-East</MenuItem>
                      <MenuItem value="East">East</MenuItem>
                      <MenuItem value="East">South-East</MenuItem>
                      <MenuItem value="South">South</MenuItem>
                      <MenuItem value="South-West">South-West</MenuItem>
                      <MenuItem value="West">West</MenuItem>
                      <MenuItem value="North-West">North-West</MenuItem>
                    </Select>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Ground Floor Type
                <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 200, flex: '1 1' }}>
              <Select
                variant="outlined"
                name="groundFloorType"
                value={saveReportData?.GroundFloorType || ''}
                onChange={(event) => {
                  SetGroundFloorType(event.target.value);
                  handleSummaryChange('GroundFloorType', event);
                }}
                sx={{
                  width: '100%',
                  border:
                    invalidFields.indexOf('GroundFloorType') >= 0
                      ? '3px solid red'
                      : '1px solid black',
                  background: 'cadetblue',
                }}
              >
                <MenuItem value="Concrete Slab-on-Ground">Concrete Slab-on-Ground</MenuItem>
                <MenuItem value="Suspended Floor">Suspended Floor</MenuItem>
              </Select>
            </Stack>

            {reportData?.projectInfoData?.NccType === '2022' && (
              <Stack spacing={3} sx={{ px: 3 }} alignItems="end">
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{ marginTop: '20px', marginLeft: '110px' }}
                >
                  <Stack
                    direction="row"
                    justifyContent="end"
                    sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
                  >
                    <Typography typography="h6" variant="subtitle2">
                      Garage Side (View From Front)<span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" sx={{ minWidth: 200, flex: '1 1' }}>
                    <Select
                      variant="outlined"
                      name="GarageSide"
                      sx={{
                        width: '100%',
                        border:
                          invalidFields.indexOf('GarageSide') >= 0
                            ? '3px solid red'
                            : '1px solid black',
                        background: 'cadetblue',
                      }}
                      value={saveReportData?.GarageSide || ''}
                      onChange={(event) => {
                        handleSummaryChange('GarageSide', event);
                      }}
                    >
                      <MenuItem value="Left-Hand Side">Left-Hand Side</MenuItem>
                      <MenuItem value="Right-Hand Side">Right-Hand Side</MenuItem>
                      <MenuItem value="No Garage">No Garage</MenuItem>
                      <MenuItem value="Detached Garage">Detached Garage</MenuItem>
                      <MenuItem value="Rear">Rear</MenuItem>
                    </Select>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>

        {saveReportData?.State === 'Queensland' && (
          <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
            <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
              >
                <Typography typography="h6" variant="subtitle2">
                  QDC 1-Star Credit Applied?<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" sx={{ minWidth: 200, flex: '1 1' }}>
                <Select
                  variant="outlined"
                  name="qdcStarCreditApplied"
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('QDCOneStarCreditApplied') >= 0
                        ? '3px solid red'
                        : '1px solid black',
                    background: 'cadetblue',
                  }}
                  value={saveReportData?.QDCOneStarCreditApplied || ''}
                  onChange={(event) => {
                    handleSummaryChange('QDCOneStarCreditApplied', event);
                  }}
                >
                  <MenuItem value="0.5 star QDC Credit Applied">
                    0.5 star QDC Credit Applied
                  </MenuItem>
                  <MenuItem value="1 star QDC Credit Applied">1 star QDC Credit Applied</MenuItem>
                  <MenuItem value="1.5 star QDC Credit Applied">
                    1.5 star QDC Credit Applied
                  </MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Stack>
        )}

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '10px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                NatHERS Climate Zone
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 400, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {climateZone}
              </Typography>
            </Stack>

            <Box display="block">
              <Stack sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}>
                <Typography typography="h6" variant="subtitle2">
                  Modelled Wall Colour
                </Typography>
              </Stack>

              <Stack sx={{ minWidth: 72, flex: '1 1' }}>
                <Typography typography="h6" variant="body2">
                  Wall Colour :{' '}
                  {saveReportData?.Walls?.wall_color &&
                    saveReportData.Walls.wall_color.charAt(0).toUpperCase() +
                      saveReportData.Walls.wall_color.slice(1)}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>

        {/* <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
            <Stack direction="row" sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}>
              <Typography typography="h6" variant="subtitle2" sx={{ marginLeft: '90px' }}>
                FirstRate 5 Engine
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 300, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                Chenath Engine 3.22
              </Typography>
            </Stack>

            <Box display="block" sx={{ float: 'right' }}>
              <Stack sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}>
                <Typography typography="h6" variant="subtitle2">
                  Modelled Roof Color
                </Typography>
              </Stack>

              <Stack sx={{ minWidth: 72, flex: '1 1' }}>
                <Typography typography="h6" variant="body2">
                  {saveReportData?.Roofs?.additional_note}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack> */}

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '10px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                FirstRate 5 Engine
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 400, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                Chenath Engine 3.22
              </Typography>
            </Stack>

            <Box display="block">
              <Stack sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}>
                <Typography typography="h6" variant="subtitle2">
                  Modelled Roof Colour
                </Typography>
              </Stack>

              <Stack sx={{ minWidth: 72, flex: '1 1' }}>
                <Typography typography="h6" variant="body2">
                  {saveReportData?.Roofs?.additional_note}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Certificate Number
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 400, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {reportData?.projectInfoData?.CertificateID}
              </Typography>
            </Stack>

            <Box display="block">
              <Stack sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}>
                <Typography typography="h6" variant="subtitle2">
                  Modelled Glazing Frame Color
                </Typography>
              </Stack>

              <Stack sx={{ minWidth: 72, flex: '1 1' }}>
                <Typography typography="h6" variant="body2">
                  Glazing Frame Color :{' '}
                  {saveReportData?.Walls?.glazing_frame_color
                    ? saveReportData.Walls.glazing_frame_color.charAt(0).toUpperCase() +
                      saveReportData.Walls.glazing_frame_color.slice(1)
                    : ''}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '20px' }}>
            {saveReportData?.State === 'New South Wales' &&
            (saveReportData?.NatHersClimateZone === '9' ||
              saveReportData?.NatHersClimateZone === '10' ||
              saveReportData?.NatHersClimateZone === '11') ? (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
                >
                  <Typography typography="h6" variant="subtitle2">
                    Deposited Plan (DP) Number
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" sx={{ minWidth: 400, flex: '1 1' }}>
                  <Typography typography="h4" color="#17a986">
                    <TextField
                      type="number"
                      name="DepositedPlanNumber"
                      value={saveReportData?.DPNumber || ''}
                      onChange={handleDepositedPlanNumberChange}
                      sx={{
                        border:
                          invalidFields.indexOf('DPNumber') >= 0
                            ? '3px solid red'
                            : '1px solid black',
                        background: 'cadetblue',
                        borderRadius: '6px',
                      }}
                    />
                  </Typography>
                </Stack>
              </>
            ) : (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  sx={{ minWidth: 250, flex: '0 1', color: '#17a986', marginTop: '10px' }}
                >
                  <Typography typography="h6" variant="subtitle2">
                    Accredited Star Rating
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" sx={{ minWidth: 400, flex: '1 1' }}>
                  <Typography typography="h4" color="#17a986">
                    <TextField
                      type="number"
                      name="Rating"
                      // value={saveReportData?.Rating}
                      value={saveReportData?.Rating}
                      onChange={handleRatingChange}
                      sx={{
                        border:
                          invalidFields.indexOf('Rating') >= 0
                            ? '3px solid red'
                            : '1px solid black',
                        background: 'cadetblue',
                        borderRadius: '6px',
                      }}
                    />
                  </Typography>
                  {/* <Typography variant="body2" sx={{ color: 'red' }}>
                    (NOTE:Range min 0 max 10)
                  </Typography> */}
                </Stack>

                {/* <Stack direction="row" alignItems="center" sx={{ minWidth: 72, flex: '1 1' }}>
                  <Typography typography="h6" variant="body2">
                    {saveReportData?.Rating}
                  </Typography>
                </Stack> */}
              </>
            )}
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '5px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Conditioned Floor Area (m<sup>2</sup>)
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 72, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {reportData?.areasData[0]?.size.toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '5px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Unconditioned Floor (m<sup>2</sup>)
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 72, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {reportData?.areasData[1]?.size !== undefined &&
                reportData?.areasData[2]?.size !== undefined
                  ? (reportData.areasData[1].size - reportData.areasData[2].size).toFixed(2)
                  : 0}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'solid', width: '50%' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '5px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Total (m<sup>2</sup>)
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 72, flex: '1 1' }}>
              <Typography typography="h6" variant="subtitle2">
                {reportData?.areasData[1]?.size !== undefined &&
                reportData?.areasData[0]?.size !== undefined
                  ? (
                      reportData.areasData[1].size -
                      reportData.areasData[2].size +
                      reportData.areasData[0].size
                    ).toFixed(2)
                  : 'N/A'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />
        <Divider sx={{ borderStyle: 'solid' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              &nbsp;
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 180, flex: '1 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Area (m<sup>2</sup>)
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 232, flex: '1 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Allowance (W/m<sup>2</sup>)
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 200, flex: '1 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Total Maximum Watts
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '5px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Class 1 Total Area
                <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 180, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                <TextField
                  type="number"
                  name="class1TotalArea"
                  value={saveReportData?.ClassOneTotalArea}
                  onChange={(event) => {
                    SetClass1MaxWatts(
                      event.target.value !== ''
                        ? parseFloat(event.target.value) * class1Allowance
                        : undefined
                    );
                    handleSummaryChange('ClassOneTotalArea', event);
                    SetMaximumPenetration(
                      event.target.value !== ''
                        ? (parseFloat(event.target.value) * 0.005).toFixed(2)
                        : ''
                    );
                  }}
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('ClassOneTotalArea') >= 0
                        ? '3px solid red'
                        : '1px solid black',
                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                />
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 232, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {class1Allowance.toFixed(1)}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 200, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {class1MaxWatts?.toFixed(1)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '10px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Class 10a Total Area
                <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 180, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                <TextField
                  type="number"
                  name="class10aTotalArea"
                  value={saveReportData?.ClassTenATotalArea}
                  onChange={(event) => {
                    handleSummaryChange('ClassTenATotalArea', event);
                    SetClass10MaxWatts(
                      event.target.value !== ''
                        ? parseFloat(event.target.value) * class10Allowance
                        : undefined
                    );
                  }}
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('ClassTenATotalArea') >= 0
                        ? '3px solid red'
                        : '1px solid black',
                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                />
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 232, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {class10Allowance.toFixed(1)}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 200, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {class10MaxWatts?.toFixed(1)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '10px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Total Outdoor Areas
                <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 180, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                <TextField
                  type="number"
                  name="outdoorArea"
                  value={saveReportData?.TotalOutdoorArea}
                  onChange={(event) => {
                    handleSummaryChange('TotalOutdoorArea', event);
                    SetOutdoorMaxWatts(
                      event.target.value !== ''
                        ? parseFloat(event.target.value) * outdoorAllowance
                        : undefined
                    );
                  }}
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('TotalOutdoorArea') >= 0
                        ? '3px solid red'
                        : '1px solid black',
                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                />
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 232, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {outdoorAllowance.toFixed(1)}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 200, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {outdoorMaxWatts?.toFixed(1)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Maximum Ceiling
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 180, flex: '1 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Maximum Allowance
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 232, flex: '1 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Maximum Penetration (m<sup>2</sup>)
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Insulation Penetration
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 180, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                0.5%
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 232, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {maximumPenetration}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack textAlign="center">
          <Box sx={{ color: 'text.disabled', typography: 'caption', fontSize: '0.65rem' }}>
            If approved fireproof downlight covers, which can be fully covered by insulation, are
            specified and noted on the electrical plan by the building designer or architect or if
            IC4-rated downlights are installed, then there is no need to allow for the ceiling
            penetration.
          </Box>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack textAlign="center">
          <Box sx={{ color: '#17a986', typography: 'caption', fontSize: '0.65rem' }}>
            <Typography typography="h6" variant="body2">
              ASSESSMENT CALCULATIONS & SOFTWARE RESULTS
            </Typography>
          </Box>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              &nbsp;
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 180, flex: '1 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Target (MJ/m<sup>2</sup>.pa)
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 232, flex: '1 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Proposed (MJ/m<sup>2</sup>.pa)
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 200, flex: '1 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="body2">
                Efficiency Benchmark
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Heating
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 180, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {energyHeatingTarget}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 232, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {reportData?.projectInfoData?.EnergyHeating}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                minWidth: 200,
                flex: '1 1',
                color:
                  energyHeatingTarget &&
                  reportData?.projectInfoData?.EnergyHeating <= energyHeatingTarget
                    ? '#17a986'
                    : '#FF0000',
              }}
            >
              <Typography typography="h6" variant="body2">
                {energyHeatingTarget &&
                reportData?.projectInfoData?.EnergyHeating <= energyHeatingTarget
                  ? 'Pass'
                  : 'Fail'}
              </Typography>
              <Typography
                typography="h6"
                variant="body2"
                sx={{
                  color:
                    energyHeatingTarget &&
                    reportData?.projectInfoData?.EnergyHeating <= energyHeatingTarget
                      ? '#17a986'
                      : '#FF0000',
                }}
              >
                {heatingBenchmark?.toFixed(1)}%
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Cooling
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 180, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {energyCoolingTarget}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 232, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {reportData?.projectInfoData?.EnergyCooling}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                minWidth: 200,
                flex: '1 1',
                color:
                  energyCoolingTarget &&
                  reportData?.projectInfoData?.EnergyCooling <= energyCoolingTarget
                    ? '#17a986'
                    : '#FF0000',
              }}
            >
              <Typography typography="h6" variant="body2">
                {energyCoolingTarget &&
                reportData?.projectInfoData?.EnergyCooling <= energyCoolingTarget
                  ? 'Pass'
                  : 'Fail'}
              </Typography>
              <Typography
                typography="h6"
                variant="body2"
                sx={{
                  color:
                    energyCoolingTarget &&
                    reportData?.projectInfoData?.EnergyCooling <= energyCoolingTarget
                      ? '#17a986'
                      : '#FF0000',
                }}
              >
                {coolingBenchmark?.toFixed(1)}%
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ borderTopStyle: 'solid', borderTopWidth: 'thin', borderColor: '#919eab3d' }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2">
                Total
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 180, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {energyHeatingTarget !== undefined && energyCoolingTarget !== undefined
                  ? (energyHeatingTarget + energyCoolingTarget).toFixed(1)
                  : 'N/A'}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 232, flex: '1 1' }}>
              <Typography typography="h6" variant="body2">
                {reportData?.projectInfoData?.EnergyTotal}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '22px' }}>
              <img
                src="./ea-report-footer.png"
                alt="Logo"
                style={{ width: '20vh', height: '10vh' }}
              />
            </Box>
            <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
            <Stack direction="column" sx={{ marginTop: '45px' }}>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                energy@energyadvance.com.au
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                ACN: 60 9332014 | 1300 850 228
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: '#919eab3d',
              p: '0.5rem',
              padding: '3rem',
            }}
          >
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>COMPLIANCE STAMP NOT </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}
