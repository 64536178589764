import { Navigate, useRoutes } from 'react-router-dom';
import DashBoard from 'src/pages/Dashboard/DashBoard';
import AccessorList from 'src/pages/Assessor/AssessorList';
import ListRequestor from 'src/pages/Requestor/ListRequestor';
import AssessorDashBoard from 'src/pages/Assessor/AssessorDashBoard';
import DashboardLayout from 'src/layouts/dashboard';
import ViewJobs from 'src/pages/Jobs/viewJobs';
import AddJob from 'src/pages/Jobs/AddJob';
import Jobs from 'src/pages/Jobs/Jobs';
import MainLayout from 'src/pages/Layout/MainLayout';
import Reports from 'src/pages/Reports/Reports';
import RegisterPage from 'src/pages/RegisterPage';
import ResetPasswordPage from 'src/pages/ResetPasswordPage';
// import Login from 'src/sections/auth/Login';
import Profile from 'src/pages/Profile/Profile';
import NewPasswordPage from 'src/pages/NewPasswordPage';
import AddAssessorUser from 'src/pages/Assessor/Add Assessor User/Add Assessor User';
import AuthGuard from 'src/auth/AuthGuard';
// import AddDocuments from 'src/pages/ChatBot/Documents/AddDocuments';
import DocumentScreen from 'src/pages/ChatBot/Documents/DocumentScreen';
import ViewDocument from 'src/pages/ChatBot/Documents/ViewDocuments';
import ChatScreen from 'src/pages/ChatBot/Documents/ChatScreen';
// import AddManagementUser from 'src/pages/ChatBot/Documents/AddDocuments';

// import NewChat from 'src/pages/ChatBot/NewChat/ChatBot';
import Skillset from 'src/pages/Assessor/Skillset';
import WorkflowUserList from 'src/pages/Workflow/WorkflowUserList';
import AssessorWorkload from 'src/pages/Assessor/WorkLoad';
import LeaveManagement from 'src/pages/Assessor/LeaveManagement';
import ViewAssessors from 'src/pages/Assessor/View Assessor/ViewAssessor';
import ViewWorkflow from 'src/pages/Workflow/ViewWorkflowUser';
import AddWorkflowUser from 'src/pages/Workflow/AddWorkflowUser';
import ManagementUserList from 'src/pages/Management/ManagementUserList';
import ViewManagement from 'src/pages/Management/ViewManagementUser';
import AddManagementUser from 'src/pages/Management/AddManagementUser';
import LeaveManagementWorkload from 'src/pages/Workflow/LeaveManagementWorkflow';
import ViewRequestor from 'src/pages/Requestor/ViewRequestors';
import AddRequestor from 'src/pages/Requestor/AddRequestor';
import AssessorTemplates from 'src/pages/Assessor/Template';
import AssessorWorkschedule from 'src/pages/Assessor/Workschedule';
import JobReport from 'src/pages/Reports/JobReport';
import AssessorReport from 'src/pages/Reports/AssessorReport';
import HolidayReport from 'src/pages/Reports/HolidayReport';
import RequestorReport from 'src/pages/Reports/RequestorReport';
import LeaveWorkflow from 'src/pages/Workflow/LeavesWorkflow';

import ExceptionReport from 'src/pages/Reports/ExceptionReport';
import ViewJobReport from 'src/pages/Jobs/viewJobReport';
// import GuestGuard from '../auth/GuestGuard';

// config
// import { PATH_AFTER_LOGIN } from '../config-global';
//
import Manual from 'src/pages/Manual';
import RefundJobs from 'src/pages/Jobs/RefundJob';
import { from } from 'stylis';
// import ChatBot from 'src/pages/ChatBot/NewChat/ChatBot';
import NewChat from 'src/pages/ChatBot/NewChat/ChatBot';
import AddDocuments from 'src/pages/ChatBot/Documents/AddDocuments';
import Checklist from 'src/pages/Assessor/Checklist';
import JobStageChecklist from 'src/pages/Reports/JobStageChecklist';
import JobAuditReport from 'src/pages/Reports/JobAuditReport';
import Signature from 'src/pages/Profile/Signature';
import {
  // PageTwo,
  // PageThree,
  // MainPage
  LoginPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),

      children: [
        { path: 'dashboard', element: <DashBoard />, index: true },
        { path: 'requestors', element: <ListRequestor />, index: true },
        { path: 'documents', element: <DocumentScreen /> },
        { path: 'manual', element: <Manual /> },
        { path: 'chatBot', element: <NewChat /> },
        { path: 'chatscreen', element: <ChatScreen /> },
        { path: 'jobs', element: <Jobs /> },
        { path: 'viewJobs', element: <ViewJobs /> },
        { path: 'viewJobReport', element: <ViewJobReport /> },
        { path: 'addjob', element: <AddJob /> },
        { path: 'reports', element: <Reports /> },
        { path: 'profile', element: <Profile /> },
        { path: 'assessorTemplates', element: <AssessorTemplates /> },
        { path: 'checklist', element: <Checklist /> },
        { path: 'assessorlist', element: <AccessorList /> },
        { path: 'viewassessor', element: <ViewAssessors /> },
        { path: 'workflowUserList', element: <WorkflowUserList /> },
        { path: 'viewworkflow', element: <ViewWorkflow /> },
        { path: 'managementUserList', element: <ManagementUserList /> },
        { path: 'viewmanagement', element: <ViewManagement /> },
        { path: 'addManagementUser', element: <AddManagementUser /> },
        { path: 'requestors', element: <ListRequestor /> },
        { path: 'viewrequestor', element: <ViewRequestor /> },
        { path: 'addrequestor', element: <AddRequestor /> },
        { path: 'three', element: <DashboardLayout /> },
        { path: 'viewdocument', element: <ViewDocument /> },
        // { path: 'adddocuments', element: <AddDocuments  /> },
        { path: 'assessorDashboard', element: <AssessorDashBoard /> },
        { path: 'addAssessorUser', element: <AddAssessorUser /> },
        { path: 'addWorkflowUser', element: <AddWorkflowUser /> },
        { path: 'skillset', element: <Skillset /> },

        { path: 'workload', element: <AssessorWorkload /> },
        { path: 'leavemanagement', element: <LeaveManagement /> },
        { path: 'leavemanagementworkload', element: <LeaveManagementWorkload /> },
        { path: 'workscheduleassessor', element: <AssessorWorkschedule /> },
        { path: 'jobreport', element: <JobReport /> },
        { path: 'jobstagechecklist', element: <JobStageChecklist /> },
        { path: 'jobauditreport', element: <JobAuditReport /> },
        { path: 'assessorreport', element: <AssessorReport /> },
        { path: 'holidayreport', element: <HolidayReport /> },
        { path: 'requestorreport', element: <RequestorReport /> },
        { path: 'exceptionreport', element: <ExceptionReport /> },
        { path: 'leavesworkflow', element: <LeaveWorkflow /> },
        { path: 'signature', element: <Signature/> },
        { path: 'refund jobs', element: <RefundJobs /> },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
    },
    {
      path: '/forgotpassword',
      element: <ResetPasswordPage />,
    },
    {
      path: '/newpassword',
      element: <NewPasswordPage />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
