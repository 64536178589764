import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  // FormGroup,
  Grid,
  // InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TextField,
  Typography,
} from '@mui/material';
import axios from 'src/utils/axios';
import { 
  // DataGrid,GridToolbar,GridRowSelectionModel,
   GridColDef } from '@mui/x-data-grid';
// import { id } from 'date-fns/locale';
import { useEffect, useState } from 'react';

import {
  //  useLocation, 
   useNavigate } from 'react-router-dom';
import { useAuthContext } from 'src/auth/useAuthContext';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';

export default function Skillset() {
  // const location = useLocation();
  const { user } = useAuthContext();
  // const { data } = location.state;
  // const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  // const [allSkillsetData, setAllSkillsetData] = useState<any>(null);
   const [skillsetData, setSkillsetData] = useState<any>(false);
  //  let  skillset:  any;
  // const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const navigate = useNavigate();
  // const [skillsetUpdate, setIsSkillsetUpdate] = useState(false);
  const userrole = `${user?.UserRole ?? ''}`;

  // const handleInputChange = (index:any, field:any, value:any) => {
  //   setSkillsetData((prevData:any) => {
  //     const newData = [...prevData];
  //     newData[index][field] = value;
  //     return newData;
  //   });
  // };


  const [resultObject, setResultObject] = useState([]);



  useEffect(() => {
    fetchassessors()
    setTimeout(() => {
      console.log(skillsetData)
    },5000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , []) 
  async function  fetchassessors(){
    try{
      const response = await axios.get('/core/getAllAssessorSkillset');
      
      const groupedData:any = [];

      response.data.forEach((item:any) => {
        if (!groupedData[item.UserID]) {
          groupedData[item.UserID] = {}
          groupedData[item.UserID].UserID = item.UserID;
          groupedData[item.UserID].UserFname = item.UserFname;
          groupedData[item.UserID].UserLname = item.UserLname;
          groupedData[item.UserID].Skillset = []
          groupedData[item.UserID].Skillset.push(item.SkillsetName); 
        }else{
            groupedData[item.UserID].Skillset.push(item.SkillsetName);
        }
      });
  
      console.log(groupedData)
      setResultObject(groupedData);
       const newArray = response.data.map(({user_id,...obj}:any) => ({
          id: user_id,
          ...obj
        }));
       setSkillsetData(newArray)
    }catch(error){
      console.error(error)
    }
  }

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   setSearchValue(value);

  //   const filteredRows = data.filter((row: any) =>
  //     Object.values(row).some((cellValue) =>
  //       String(cellValue).toLowerCase().includes(value.toLowerCase())
  //     )
  //   );
  //   setFilteredData(filteredRows);
  // };

  const handleUpdateClick = () => {
    const updatedRows = selectedRows.map((row) => {
      const updatedRow = {
        id: row.id,
        basix: row[`checkbox-basix-${row.id}`] ? 'Y' : 'N',
        natHers: row[`checkbox-basix-${row.id}`] ? 'Y' : 'N',
        varification: row[`checkbox-varification-${row.id}`] ? 'Y' : 'N',
        urgentJobs: row[`checkbox-urgentJobs-${row.id}`] ? 'Y' : 'N',
        bess: row[`checkbox-reassessment-${row.id}`] ? 'Y' : 'N',
        dts: row[`checkbox-reassessment-${row.id}`] ? 'Y' : 'N',
      };
      return updatedRow;
    });

    // Print the updated rows (replace with your desired logic)
    // setIsSkillsetUpdate(true);
    console.log(updatedRows);
    // setAllSkillsetData(updatedRows);
    navigate('/assessorlist', { state: { skillsetData: updatedRows } });
    setSelectedRows([]);
  };

  //   const handleRowSelection = (params: any) => {
  //     const selectedRowIds = params.selection.map((row: any) => row.id);
  //     const updatedSelectedRows = filteredData.filter((row) => selectedRowIds.includes(row.id));
  //     setSelectedRows(updatedSelectedRows);
  //   };

  const goToAssessoerList = () => {
    navigate('/assessorlist');
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'fname',
      headerName: 'Name',
      // align: 'center',
      // headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {`${params.row.user_fname } ${ params.row.user_lname}`}
        </Typography>
      ),
      disableColumnMenu: true,
    },
    {
      field: 'is_basix',
      headerName: 'Basix (GSSBX/GDSBX)',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <FormControlLabel
          control={
            <Checkbox
              //   name={`checkbox-A-${params.row.id}`}
              checked={params.row.is_basix === 'y'}
              onChange={() =>{ 
                filteredData.map((row) =>{ 
                  if(row.id === params.row.id){
                    console.log(row)
                  } 
                  return row
                })
                // setFilteredData(...filteredData)
                handleCheckboxChange(params.row.is_basix, 'basix')}}
            />
          }
          label=""
          style={{ marginLeft: '8px' }}
        />
      ),
    },
    {
      field: 'natHers',
      headerName: 'NatHers (ASSR/ADSR)',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      type: 'singleSelect',
      renderCell: (params: any) => (
        <FormControlLabel
          control={
            <Checkbox
                name={`checkbox-B-${params.row.id}`}
              checked={params.row.is_nathers === 'y'}
              onChange={() => handleCheckboxChange(params.row.is_nathers, 'natHers')}
            />
          }
          label=""
          style={{ marginLeft: '8px' }}
        />
      ),
    },

    {
      field: 'verification',
      headerName: 'Verification (AVSR/AVDR)',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      type: 'singleSelect',
      renderCell: (params: any) => (
        <FormControlLabel
          control={
            <Checkbox
              // name={`checkbox-C-${params.row.id}`}
               checked={params.row.is_verification === 'y'}
              onChange={() => handleCheckboxChange(params.row.id, 'verification')}
            />
          }
          label=""
          style={{ marginLeft: '8px' }}
        />
      ),
    },

    {
      field: 'urgentJobs',
      headerName: 'Urgent Jobs',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      type: 'singleSelect',
      renderCell: (params: any) => (
        <FormControlLabel
          control={
            <Checkbox
              //   name={`checkbox-B-${params.row.id}`}
              checked={params.row.is_urgent_jobs === 'y'}
              onChange={() => handleCheckboxChange(params.row.id, 'urgentJobs')}
            />
          }
          label=""
          style={{ marginLeft: '8px' }}
        />
      ),
    },

    {
      field: 'dts',
      headerName: 'Deemed To Satisfy',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      type: 'singleSelect',
      renderCell: (params: any) => (
        <FormControlLabel
          control={
            <Checkbox
              // name={`checkbox-C-${params.row.id}`}
              checked={params.row.is_deemed_to_satisfy === 'y'}
              onChange={() => handleCheckboxChange(params.row.id, 'dts')}
            />
          }
          label=""
          style={{ marginLeft: '8px' }}
        />
      ),
    },
    {
      field: 'bess',
      headerName: 'BESS',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      type: 'singleSelect',
      renderCell: (params: any) => (
        <FormControlLabel
          control={
            <Checkbox
              // name={`checkbox-C-${params.row.id}`}
                  checked={params.row.is_bess === 'y'}
              onChange={() => handleCheckboxChange(params.row.id, 'bess')}
            />
          }
          label=""
          style={{ marginLeft: '8px' }}
        />
      ),
    },
  ];

  const handleCheckboxChange = (rowId: number, columnName: string) => {
    const updatedSelectedRows = [...selectedRows];
    const rowIndex = updatedSelectedRows.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      updatedSelectedRows[rowIndex][`checkbox-${columnName}-${rowId}`] =
        !updatedSelectedRows[rowIndex][`checkbox-${columnName}-${rowId}`];
    } else {
      const newRow = {
        id: rowId,
        [`checkbox-${columnName}-${rowId}`]: true,
      };
      updatedSelectedRows.push(newRow);
    }
    setSelectedRows(updatedSelectedRows);
  };

  // const handleResetClick = () => {
  //   const updatedSelectedRows = selectedRows.map((row) => {
  //     const updatedRow = { ...row };
  //     Object.keys(updatedRow).forEach((key) => {
  //       if (key.includes('checkbox')) {
  //         updatedRow[key] = false;
  //       }
  //     });
  //     return updatedRow;
  //   });
  //   setSelectedRows(updatedSelectedRows);
  // };
  const { themeStretch } = useSettingsContext();

  return (
    <Container sx={{ maxWidth: '100% !important' ,paddingTop:'50px',paddingBottom:'50px'}}>
    <Helmet>
        <title> Assessor | Energy Advance</title>
    </Helmet>


    <Container maxWidth={themeStretch ? false : 'lg'}>
        
        <CustomBreadcrumbs
        heading="Assessor Skillsets"
        links={[
           
            {
            name: 'Assessor',
            href: "/assessorlist",
            },
            { name: 'Assessor Skillset' },
        ]}
        /> 
      <Grid container sx={{ marginTop: 2, marginBottom: 2 }} justifyContent="space-between">
        
        {/* <Grid item>
          <TextField
            variant="outlined"
            size="small"
            value={searchValue}
            placeholder="Search Assessors"
            type="search"
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="bi:search" width={24} />
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}
      </Grid>

     <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell  sx={{width:"60%"}}>Skillset</TableCell>
            <TableCell  >Action</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {resultObject.map((item:any, index:any) => (
            item && (
              <TableRow key={index}>
                <TableCell>{item.UserID}</TableCell>
                <TableCell>{item.UserFname}</TableCell>
                <TableCell>{item.UserLname}</TableCell>
                <TableCell sx={{width:"60%"}}>
                  {item.Skillset && item.Skillset.join(', ')}
                </TableCell>
                { (userrole === 'Management' || userrole === 'Workflow') && (
                <TableCell><Link  onClick={() => {navigate(`/viewassessor?id=${item.UserID}&edit=true&section=skillset`)}}> <Iconify icon="fluent:edit-16-filled"  /> Edit</Link></TableCell>
                )}
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      <Button
        variant="soft"
        color="secondary"
        onClick={goToAssessoerList}
        sx={{ float: 'right', marginTop: '20px' ,mt:1}}
      >
        Back
      </Button>
    </Container></Container>
  );
}
