import React, {  useState, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  Modal,
  Card,
  Container,
  Link,
  Stack,
  Typography,
  styled,
  Divider ,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import axios from 'src/utils/axios';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { writeText } from 'clipboard-polyfill';


import { useSnackbar } from 'notistack';
// import Iconify from 'src/components/iconify';
import DocViewer, { DocViewerRef, DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import Scrollbar from 'src/components/scrollbar';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
// import { useLocation, useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
// import { Message } from 'yup/lib/types';
// import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';

// import { any } from 'prop-types';
import GreetingMessage from './GreetingMessage';

// import Iconify from 'src/components/iconify';

interface DocumentData {
  DocLocation: string;
  DocName: string;
  // Add other properties here
}

interface ActionsMenuProps {
  onView: (item: boolean, edit: boolean) => void;
  onDownload: () => void;
  data: DocumentData;
  fetchDocument: () => void;
}

// function ActionsMenu({ onView, onDownload, data, fetchDocument }: ActionsMenuProps) {

//   const { user } = useAuthContext();
//   const userrole = `${user?.UserRole ?? ''}`;
//   const userid = user?.ID ?? '';
//   // const [documentData, setDocumentData] = useState([]);

//   const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
//   const [open, setOpen] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();

//   const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const [documents, setDocuments] = useState<IDocument[]>([]);
//   const [openFile, setFileOpen] = useState(false);
//   const handleFileClose = () => setFileOpen(false);
//   const docViewerRef = useRef<DocViewerRef>(null);

//   const handleView = async (loc: string) => {
//     console.log('In view');
//     axios

//       .post(
//         `/core/getChatBotFile?loc=${loc}`,
//         {},
//         {
//           responseType: 'blob',
//         }
//       )
//       .then((response) => {
//         console.log('Report response data:', response.data);
//         const blob = response.data;
//         const objectUrl = URL.createObjectURL(blob);
//         setDocuments([{ uri: objectUrl, fileType: 'pdf' }]);
//         setFileOpen(true);
//       })
//       .catch((error) => {
//         console.error('Error downloading report:', error);
//         enqueueSnackbar(error, { variant: 'error' });
//       });
//   };

//   return (
//     <div>
//       <Modal
//         open={openFile}
//         onClose={handleFileClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Scrollbar>
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <Box
//               sx={{
//                 height: '100%',
//                 width: '900px',
//               }}
//             >
//               {' '}
//               <IconButton
//                 onClick={handleFileClose}
//                 sx={{
//                   position: 'absolute',
//                   top: '10px',
//                   right: '10px',
//                   color: 'rgba(0, 0, 0, 0.5)',
//                 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//               <DocViewer
//                 documents={documents}
//                 ref={docViewerRef}
//                 config={{
//                   header: {
//                     disableHeader: true,
//                     disableFileName: false,
//                     retainURLParams: false,
//                   },
//                   csvDelimiter: ',',
//                   pdfZoom: {
//                     defaultZoom: 1.1,
//                     zoomJump: 0.2,
//                   },

//                   pdfVerticalScrollByDefault: false,
//                 }}
//                 pluginRenderers={DocViewerRenderers}
//               />
//             </Box>
//           </Box>
//         </Scrollbar>
//       </Modal>
//       <IconButton onClick={handleClick}>
//         <Iconify icon="eva:more-vertical-fill" />
//       </IconButton>
//       <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
//         <MenuItem onClick={handleView}>
//           <Iconify icon="carbon:view-filled" sx={{ marginRight: 1 }} /> View
//         </MenuItem>
//       </Menu>
//     </div>
//   );
// }

export default function ChatScreenPopUp() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [generatedAnswer, setGeneratedAnswer] = useState<string>('');
  const [errorState, setErrorState] = useState<string | null>(null);
  const [greetingShown, setGreetingShown] = useState(true);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadquestion, setLoadingQuestion] = useState(false);
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  const userid_str = `${user?.ID ?? '0'}`;
  const userid = parseInt(userid_str, 10);
  const [apiResponse, setApiResponse] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [openFile, setFileOpen] = useState(false);
  const handleFileClose = () => setFileOpen(false);
  const docViewerRef = useRef<DocViewerRef>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  // const dummyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messages.length) {
      chatContainerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      
    }
  }, [messages.length]);

  const handleFocus = () => {
    setGreetingShown(false)
  };

  const handleCopyClick = async (content : string) => {
    try {
      await navigator.clipboard.writeText(content);
      writeText(content)
      .then(() => {
        console.log('Text copied to clipboard');
        setCopied(true);
      })
      .catch((error:any) => {
        console.error('Failed to copy text: ', error);
      });      
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
    }
  };


  const handleSourceDocument = async (loc : string) => {

    const checkFileExtension = (fileName: string) => {
      // Regular expression to match file extensions
      const extensionRegex = /\.(pdf|txt)$/i;
      return extensionRegex.test(fileName);
    };

    setLoading(true);

    if (checkFileExtension(loc)){
      console.log('File does not have one of the allowed extensions: docx, xlsx, csv');
      // await handleViewSourceDocument(loc);
      await handleViewSourceDocument(loc);
    }else{
      console.log('File has one of the allowed extensions: docx, xlsx, csv');
      await handleDownload(loc);
    }
    setTimeout(() => {
      // After some delay, set loading state back to false
      setLoading(false);

      // Perform additional operations if necessary (e.g., navigation)
      // history.push(sourceloc);
    }, 7000); // Simulating a 2-second delay

  };

  const handleDownload = async (loc: string) => {

    function extractFilename(path: string) {
      // Split the path based on both forward slash (/) and backward slash (\)
      const parts = path.split(/[/\\]/);

      // Return the last part which is the filename
      return parts[parts.length - 1];
    }

    try {
      console.log("Inside download")
      await axios.post(
        `/core/getChatBotFile?loc=${loc}`,
        {},
        {
          responseType: 'arraybuffer',
        }
      ).then((response) => {

      // const filenameMatch = extractFilename(loc);
      // console.log("Filename Match :", filenameMatch)
      // const filename = filenameMatch || 'document.pdf'; // Default filename if not found
      // console.log("Filename :", filename)
      
      const fileName = response.headers['content-disposition']?.split('filename=')[1];
      const contentType = response.headers['content-type'];
      console.log("Response :", response)
      console.log("Filename : ", fileName)
  
      if (fileName) {
        const blob = new Blob([response.data], {
          type: contentType,
        });
  
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = decodeURIComponent(fileName);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        // If the Content-Disposition header is not present or does not contain a filename, set a default file name
        const blob = new Blob([response.data], {
          type: contentType,
        });
        const filenameMatch = extractFilename(loc);
        console.log("Filename Match :", filenameMatch) 
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filenameMatch;
        a.click();
        window.URL.revokeObjectURL(url);
      }
      setLoading(false)
    })
    .catch((error) => {
      console.log("Inside error while trying to download")
      // console.log("Error : ", string(error))
      // console.error('Error downloading report:', error);
      enqueueSnackbar("File is Missing or not able to Access.", { variant: 'error' });
      setLoading(false)
    }); 
      // handleClose();
    } catch (error) {
      // console.error('Error downloading report:', error);
      // enqueueSnackbar(error, { variant: 'error' });
      console.log("Error downloading report")
      enqueueSnackbar("File is Missing or not able to Access.", { variant: 'error' });
      setLoading(false)
    }
  };


  const handleViewSourceDocument = async (loc: string) => {
    console.log('In view');

    function extractFilename(path: string) {
      // Split the path based on both forward slash (/) and backward slash (\)
      const parts = path.split(/[/\\]/);

      // Return the last part which is the filename
      return parts[parts.length - 1];
    }

    axios

      .post(
        `/core/getChatBotFile?loc=${loc}`,
        {},
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        console.log('Report response data:', response);
        const blob = response.data;
        const objectUrl = URL.createObjectURL(blob);
        const filenameMatch = extractFilename(loc);
        console.log("Filename Match :", filenameMatch)
        const filename = filenameMatch || 'document.pdf'; // Default filename if not found
        console.log("Filename :", filename)
        setDocuments([{ uri: objectUrl, fileName: filename}]);
        setFileOpen(true);
        setLoading(false)
      })
      .catch((error) => {
        // console.error('Error downloading report:', error);
        // enqueueSnackbar(error, { variant: 'error' });
        console.log("Error in View report")
        enqueueSnackbar("File is Missing or not able to Access.", { variant: 'error' });
        setLoading(false)
      });
  };

  // const handleClick = async (loc :string) => {
  //   try {
  //     setApiResponse(loc)

  //   } catch (error) {
  //     console.error('Error fetching API:', error);
  //   }
  //   alert(apiResponse);
  // };

  // console.log("User info :", user)

  useEffect(() => {
    const greetingTimeout = setTimeout(() => {
      setGreetingShown(false);
    }, 10000);

    return () => clearTimeout(greetingTimeout);
  }, []);

  interface Message {
    role: string;
    content: string;
    source?: string;
    sourceloc?: string;
    timestamp?: string;
    date?: string;

  }

  interface RefDocument {
    [key: string]: string;
  }


  const handleKeyDownTextArea = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default Enter key behavior (e.g., new line in textarea)
      handleSearchSubmit();
    }
  };

  const getCurrentTimestamp = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };


  const handleSearchSubmit = async () => {
    if (!searchQuery.trim()) {

      return

    }
    const lowerCaseQuery = searchQuery.toLowerCase();

    // const userrole = 'Management';
    // const userid = 3;
    const question = searchQuery;

    const askquestion_payload = {
      userrole,
      userid,
      question,
    };

    // const getAskQuestion = async() => {
    //   axios
    // .post(`/core/askquestion`,askquestion_payload)
    // .then((response) => {
    //   console.log('Response = ', response.data);
    // })
    // .catch((error) => {
    //   console.error('Error fetching ask question:', error);
    // })
    // };

    // User's message

    // Get current date and time
    const date_info = async () => {
      const currentDate = new Date();


      const options = { timeZone: 'Australia/Perth' };
      const formattedDateTimeString = currentDate.toLocaleString('en-AU', options);
      const currentDateAWST = moment().tz('Australia/Perth');
      const formattedDateTime = currentDateAWST.format('DD-MM-YYYY HH:mm:ss');
      // Set the formatted date and time to state
      // setCurrentTime(formattedDateTime);
      console.log('current date awst = ', formattedDateTime);
      
      return formattedDateTime;
    };



    const userMessage: Message = { role: 'user', content: searchQuery, source: "", date: await date_info() };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setTimeout(async () => {
      try {

        // Show loading indicator
        // setLoading(true);
        setErrorState(null);
        setLoadingQuestion(true);
        // debugger; // eslint-disable-line no-debugger
        // Make an Axios API call to get the response
        console.log("Payload Ask Question : ", askquestion_payload)
        setTimeout(() => {
          setLoadingQuestion(false);
        }, 10000); 
        // await getAskQuestion()


        try {

          const response = await axios.post(`/core/askquestion`, askquestion_payload);

          // Handle successful response
          console.log(response);
          console.log('Answer of asked question = ', response.data?.data[0]?.answer);
          console.log('API Response status code : ', response.status);

          const chatbotMessage: Message = {
            role: 'chatbot',
            content: "",
            source: "",
            date: ""
          };

          if (response.status !== 200) {
            // Handle error response
            chatbotMessage.content = response.data?.data[0]?.answer;
            chatbotMessage.date = response.data?.data[0]?.atime;
            chatbotMessage.source = "";
          } else {
            // Handle successful response
            const api_response = response.data;

            let output_msg = "";
            if (api_response.status === "success") {
              output_msg = api_response.data[0]?.answer;
            } else {
              output_msg = api_response.status_msg;
            }

            chatbotMessage.content = output_msg;
            chatbotMessage.date = response.data?.data[0]?.atime;

            // Check if reference_docs is available
            if (response.data?.data?.[0]?.reference_docs) {
              const ref_doc_temp: RefDocument = response.data?.data?.[0]?.reference_docs[0];
              const entries = Object.entries(ref_doc_temp);
              if (entries.length > 0) {
                const [key, value] = entries[0];
                if (value !== "") {
                  console.log("Key:", key);
                  chatbotMessage.source = key;
                  console.log("Value:", value);
                  chatbotMessage.sourceloc = value;
                }
              }
            }
          }

          // Update messages state with the chatbot message
          // userMessage.date = response.data?.data[0]?.qtime !== "" ?  response.data?.data[0]?.qtime : await date_info()
          // setMessages((prevMessages) => [...prevMessages, userMessage]);
          setMessages((prevMessages) => [...prevMessages, chatbotMessage]);
          setLoadingQuestion(false);
        } catch (error) {
          // Handle error
          console.error('Error:', error);
          const errbotMessage: Message = {
            role: 'chatbot',
            content: "Something went wrong!!! Please try again or contact Support Team.",
            source: "",
            date: await date_info()
          };
          // userMessage.date = await date_info()
          // setMessages((prevMessages) => [...prevMessages, userMessage]);
          setMessages((prevMessages) => [...prevMessages, errbotMessage]);
          setLoadingQuestion(false);
        }

      } catch (error) {
        // Handle error appropriately
        // setLoading(false);

        if (error.response) {
          console.error(
            'Server responded with error status:',
            error.response.status,
            error.response.data
          );
          setErrorState(`Server error: ${error.response.status} - ${error.response.data.message}`);
        } else if (error.request) {
          console.error('No response received from the server:', error.request);
          setErrorState('No response received from the server');
        } else {
          console.error('Error setting up the request:', error.message);
          setErrorState(`Error setting up the request: ${error.message}`);
        }
        setLoadingQuestion(false);
      }
    }, 10);

    // Clear the search field
    setSearchQuery('');
  };

  //   const [alignment, setAlignment] = useState('today');

  return (
    <>
      <Container sx={{ maxWidth: '50% ', marginLeft: 'auto', marginRight: 'auto'}}
        // sx={{
        //   maxWidth: '100% !important',
        //   marginLeft: '430px',
        // }}
      >
        <Modal
          open={openFile}
          onClose={handleFileClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Scrollbar>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',color: 'black'}}>
              <Box
                sx={{
                  height: '100%',
                  width: '900px',
                }}
              >
                {' '}
                <IconButton
                  onClick={handleFileClose}
                  sx={{
                    position: 'fixed',
                    top: '10px',
                    right: '10px',
                    color: 'aliceblue'
                    // color: 'rgba(0, 0, 0, 0.5)',
                    // backgroundColor: 'grey'
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DocViewer
                  documents={documents}
                  ref={docViewerRef}
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: false,
                      retainURLParams: false,
                    },
                    csvDelimiter: ',',
                    pdfZoom: {
                      defaultZoom: 1.1,
                      zoomJump: 0.2,
                    },

                    pdfVerticalScrollByDefault: false,
                  }}
                  pluginRenderers={DocViewerRenderers}
                />
              </Box>
            </Box>
          </Scrollbar>
        </Modal>
        <Helmet>
          <title> New Chat | Info_Bot</title>
        </Helmet>

        {/* <CustomBreadcrumbs
                heading="Add New Document"
                links={[
                    {
                        href: '/managementUserList',
                    },
                ]} /> */}
      </Container>

      <Card

        sx={{
          marginLeft: '8px',
          width: '1070px',
          height: '590px',
          display: 'flex',
          
          flexDirection: 'column',
        }}
      >
        {greetingShown && <GreetingMessage onClose={() => setGreetingShown(false)} />}

        <Scrollbar
          sx={{
            maxHeight: '480px',
            height: '100%',
            overflowY: 'auto',
            marginTop: '5px',
          }}
        >
          <Stack spacing={2} sx={{ p: 3, flexGrow: 1 }} ref={chatContainerRef}>
            {messages.map((message, index) => (
              <Box key={index}>
                {/* Display the chatbot icon and "You:" */}
                <Typography
                  variant="body1"
                  sx={{
                    p: 1,
                    textAlign: 'left',
                    marginLeft: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '19px',
                  }}
                >
                  {message.role === 'user' && (
                    <Avatar sx={{ width: 24, height: 24, marginRight: 1 }}>
                      <PersonIcon sx={{ fontSize: 20 }} />
                    </Avatar>
                  )}
                  {message.role === 'chatbot' && (
                    <Avatar sx={{ width: 24, height: 24, marginRight: 1 }}>
                      <ChatBubbleOutlineIcon sx={{ fontSize: 20 }} />
                    </Avatar>
                  )}
                  <strong>{message.role === 'user' ? 'You' : 'ChatBot'}</strong>
                  <span style={{ marginLeft: '10px', fontSize: '12px' }}>
                    {/* {message.role === 'user' && `${message.timestamp}, ${message.date}`} */}
                    {/* {message.role === 'chatbot' && `${message.date}, ${message.timestamp}`} */}
                    {/* {message.timestamp && `${message.timestamp}, ${message.date}`} */}

                    {message.date !== "" && `${message.date}`}

                  </span>
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    p: 1,
                    textAlign: 'left',
                    marginLeft: '34px',
                    // marginTop:'0px',
                    // display: 'flex',
                    alignItems: 'center',
                    mb: 0.1,
                  }}
                >
                  {message.content}
                </Typography>
                {message.role === 'chatbot'  && message.source !== "" && message.sourceloc !== "" && (
                  <div>
                 <Tooltip title='Copy Response'>
                  {/* <Typography
                    variant="button"
                    component="button"
                    onClick={handleCopyClick}
                    sx={{ cursor: 'pointer', display: 'flex', marginLeft: '40px',alignItems: 'center' }}
                  > */}
                    <IconButton
                      aria-label="Copy to Clipboard"
                      sx={{ display: 'flex', marginLeft: '40px',alignItems: 'center' }}
                      onClick={() => handleCopyClick(message.content ? message.content : '')}
                      size="small"
                    >
                    <FileCopyIcon sx={{ marginLeft: 1 }} />
                    </IconButton>
                  </Tooltip>
                  </div>)}
                <Typography
                  variant="body1"
                  sx={{
                    p: 1,
                    textAlign: 'left',
                    marginLeft: '34px',
                    // marginTop:'0px',
                    // display: 'flex',
                    //                    alignItems: 'center',
                    fontSize: '14px',
                    mb: 0.1,
                  }}
                > {message.role === 'chatbot'  && message.source !== "" && message.sourceloc !== "" && (
                  <div>
                  <strong>Source Document :   </strong>
                  </div>)}
                  {message.role === 'chatbot' && message.source !== ""
                   && message.sourceloc !== "" && (
                    loading ? (
                      <div>Loading Document...</div>
                    ) : (
                    // <a href="#" className=".clean-link" onClick={() => handleClick(message.sourceloc ? message.sourceloc : '')}>
                    <Link
                      variant="body2"
                      onClick={() => handleSourceDocument(message.sourceloc ? message.sourceloc : '')}
                      sx={{ cursor: "pointer" }}
                      color="inherit"
                      underline="always"
                    >
                      {message.source}
                    </Link>))}
                  {/* {message.source}</a> )} */}
                </Typography>
                {/* {index !== messages.length - 1 && <Divider />} */}
                {index !== messages.length - 1 && message.role === 'chatbot' && <Divider />}

              </Box>
            ))}
          </Stack>
        </Scrollbar>

        {/* Display the generated answer in a scrollable box at the top */}
        {generatedAnswer && (
          <Box
            sx={{
              display: 'contents',
              maxHeight: '490px',
              height: '390px',
              padding: '4px',
              boxSizing: 'border-box',
              position: 'relative',
              overflowY: 'auto',
              marginTop: '50px',
            }}
          >
            {/* <Typography variant="body1">
<strong>ChatBot: </strong>
{generatedAnswer}
</Typography> */}
          </Box>
        )}
        {loading ? (
                      <div style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}> Processing Document...</div>
                    ) : (
        <div>
        {loadquestion ? (
            <div style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center',marginBottom: '25px' }}> Processing Question...</div>
        ) : (
       
        <Stack
          direction="row"
          alignItems="center"
          spacing={20}
          sx={{ p: 3, position: 'absolute', bottom: 2, width: '100%' }}
        >
          <div style={{ position: 'relative', width: '1098px' }}>
            <textarea
              onFocus={handleFocus}
              placeholder="Please Enter Your Question Here"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDownTextArea}
              rows={Math.max(Math.ceil(searchQuery.length / 100), 1)}
              style={{
                width: '94%',
                marginLeft: '30px',
                height: 'auto',
                borderRadius: '20px',
                minHeight: '70px',
                maxHeight: '200px',
                resize: 'none',
               // overflowY: 'auto',
                fontSize: '17px',
                padding: '20px 10px',
                lineHeight: 1.5,
              }}
            />

            <ArrowUpwardIcon
              style={{
                height: '70px',
                width: '30px',
                position: 'absolute',
                bottom: '6px',
                // right: '1px',
                left:'945px',
                color: 'green',
                display: 'flex',
              }}
              onClick={handleSearchSubmit}
            />
          </div>
        </Stack> )}</div>
        )}
      </Card>
    </>
  );
}

const CHART_HEIGHT = 459;
const LEGEND_HEIGHT = 70;

const StyledChartWrapper = styled('div')(({ theme }: any) => ({
  height: CHART_HEIGHT,

  // marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-graphical': {
    transform: 'translate(0, 0) !important',
  },

  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center !important',
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));


// function setErrorState(arg0: string) {
//   throw new Error('Function not implemented.');
// }