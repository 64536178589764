/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { useAuthContext } from 'src/auth/useAuthContext';

type GreetingMessageProps = {
  onClose: () => void;
};
const GreetingMessage: FC<GreetingMessageProps> = ({ onClose }) => {
  const { user } = useAuthContext();
  const fullName = `${user?.UserFname ?? ''} ${user?.UserLname ?? ''}`;
  return (
    <div
      style={{
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
      }}
    >
      <h2 style={{ marginBottom: '16px' }}>Hello {fullName}, how may I assist you today?</h2>
      {/* <button onClick={onClose}>Close</button> */}
    </div>
  );
};
export default GreetingMessage;