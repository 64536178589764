import Iconify from 'src/components/iconify';
import {
  // CSSProperties,
  ReactNode,
  useEffect,
  useState,
} from 'react';
// import DateRangePicker, { useDateRangePicker } from 'src/components/date-range-picker';
import { useNavigate } from 'react-router';
import axios from 'src/utils/axios';
import _mock, { randomInArray } from 'src/_mock';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fNumber } from 'src/utils/formatNumber';
import Scrollbar from 'src/components/scrollbar';
// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useAuthContext } from 'src/auth/useAuthContext';
// import { fDate } from 'src/utils/formatTime';
import {
  List,
  Paper,
  Avatar,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Grid,
  Container,
  // Stack,
  Card,
  // CardContent,
  Typography,
  // CircularProgress,
  // FormControlLabel,
  // Checkbox,
  Button,
  TextField,
  // IconButton,
  // Backdrop,
  // RadioGroup,
  // Radio,
  // Dialog,
  // DialogTitle,
  InputAdornment,
  Box,
  // FormLabel,
  // Select,
  // MenuItem,
  // Switch,
  // Breadcrumbs,
  // Link,
  ButtonProps,
} from '@mui/material';
import {
  // alpha,
  styled,
  // useTheme
} from '@mui/material/styles';
import { useSnackbar } from 'src/components/snackbar';
import DataGridCustom from 'src/pages/Jobs/DataGridCustom';

interface JobInfo {
  id: string;
  JobRecordID: number;
  JobNumber: string;
  JobAddJobcode: string;
  JobSlaTime: string;
  AWOH_VALID: string;
  Ncc_Type: string;
  JobSlaMsg: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobStage: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: number;
  JobPostAssessmentFlag: boolean;
  ReqName: string;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  LinkedJobs: boolean;
}

export default function Jobs({ id }: any) {
  const [assessorId, setId] = useState(Number(id));
  // const [start, setStart] = useState<Date | null>(new Date());
  // const [end, setEnd] = useState<Date | null>(new Date());
  // const [open, setOpen] = useState(false);
  const [jobs, setJobs] = useState<JobInfo[]>([]);
  // const [assessorJobs, setAssessorJobs] = useState<JobInfo[]>([]);
  // const [jobStatusCounts, setJobStatusCounts] = useState<any>(null);
  const [assignedCounts, setAssignedCount] = useState<any>(null);
  const [assignedUrgentCounts, setAssignedUrgentCount] = useState<any>(null);
  const [assessmentStartedCount, setAssessmentStartedCount] = useState<any>(null);
  const [assessmentStartedUrgentCount, setAssessmentStartedUrgentCount] = useState<any>(null);
  const [completedCount, setCompletedCount] = useState<any>(null);
  const [completedUrgentCount, setCompletedUrgentCount] = useState<any>(null);
  const [onHoldCount, setOnHoldCount] = useState<any>(null);
  const [onHoldCountUrgentCount, setOnHoldUrgentCount] = useState<any>(null);
  // const [jobStatusCountByName, setJobStatusCountByname] = useState<number | null>(null);
  const [filterStatus, setFilterStatus] = useState('assessment started');
  const [filteredData, setFilteredData] = useState<JobInfo[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const [searchQuery, setSearchQuery] = useState('');

  // const them = useTheme();
  // const isDarkMode = them.palette.mode === 'dark';

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const filteredJob = searchQuery
    ? filteredData.filter((job: any) => {
        const searchLower = searchQuery.toLowerCase();
        // const searchUpper = searchQuery.toUpperCase();

        let idMatch = false;
        let ReqNameMatch = false;
        let JobNumberMatch = false;
        let AssessornameMatch = false;
        let UserLnameMatch = false;
        let JobRatingMatch = false;
        let SiteAddress = false;

        if (filterStatus === 'urgent') {
          idMatch = job.id.toString().includes(searchLower);
          ReqNameMatch =
            job.JobIsUrgent.trim() !== '' && job.ReqName.toLowerCase().includes(searchLower);
          JobNumberMatch =
            job.JobIsUrgent.trim() !== '' && job.JobNumber.toLowerCase().includes(searchLower);
          AssessornameMatch =
            job.JobIsUrgent.trim() !== '' && job.UserFname.toLowerCase().includes(searchLower);
          UserLnameMatch =
            job.JobIsUrgent.trim() !== '' && job.UserLname.toLowerCase().includes(searchLower);
          JobRatingMatch =
            job.JobIsUrgent.trim() !== '' &&
            job.JobRating.toString().toLowerCase().includes(searchLower);
          SiteAddress =
            job.JobIsUrgent.trim() !== '' && job.JobSiteAddr.toLowerCase().includes(searchLower);
        } else {
          idMatch = job.id.toString().toLowerCase().includes(searchLower);
          ReqNameMatch = job.ReqName.toLowerCase().includes(searchLower);
          JobNumberMatch = job.JobNumber.toLowerCase().includes(searchLower);
          AssessornameMatch = job.UserFname.toLowerCase().includes(searchLower);
          UserLnameMatch = job.UserLname.toLowerCase().includes(searchLower);
          JobRatingMatch = job.JobRating.toString().toLowerCase().includes(searchLower);
          SiteAddress = job.JobSiteAddr.toLowerCase().includes(searchLower);
        }

        const searchdata =
          idMatch ||
          ReqNameMatch ||
          AssessornameMatch ||
          UserLnameMatch ||
          SiteAddress ||
          JobRatingMatch ||
          JobNumberMatch;

        return searchdata;
      })
    : filteredData;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const milliseconds = new Date().getMilliseconds();
        // console.log(milliseconds);
        // let response = await axios.get('/core/getAllJobs');
        // console.log('management api calling');

        // console.log('1 ', milliseconds - new Date().getMilliseconds());

        // console.log('assessor:', response.data);

        // console.log('2 ', milliseconds - new Date().getMilliseconds());

        // if (user?.UserRole === 'Assessor') {
        //   setFilteredData(
        //     modifiedData.filter((item: any) => item.JobAssessorID === user?.ID) as JobInfo[]
        //   );

        // } else
        if (user?.UserRole === 'Management' || user?.UserRole === 'Workflow') {
          // console.log('In else part  = ');
          // setFilteredData(
          //   modifiedData.filter((item: any) => item.JobAssessorID === id) as JobInfo[]
          // );
          const response = await axios.get(`/core/getJobsByID?assessorId=${id}`);
          console.log('Assessor api calling');
          const modifiedData = response.data.map(({ JobRecordID, ...job }: any) => ({
            id: JobRecordID,
            // JobCreatedAt: new Date(JobCreatedAt),
            ...job,
          }));
          setJobs(modifiedData as JobInfo[]);
          setFilteredData(
            modifiedData.filter((item: any) => item.JobStatus === 'assessment started') as JobInfo[]
          );
        }

        // console.log('4 ', milliseconds - new Date().getMilliseconds());
      } catch (error) {
        console.error(error);
      }
    };

    // const fetchStatusCount = async () => {
    //   try {
    //     const response = await axios.get('/core/getJobStatusCount');
    //     setJobStatusCounts(response.data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };

    fetchData();
    // fetchStatusCount();
  }, [id, user?.ID, user?.UserRole]);

  // This below use effect is for fetching the counts
  // Count for the perticular assessor if manager logins
  // We are getting counts for 'assessment started' , 'assigned' , 'complete' , 'on hold' statuses
  useEffect(() => {
    setAssignedCount(
      jobs.filter((item) => item.JobStatus === 'assigned' && item.JobAssessorID === assessorId)
        .length
    );
    setAssignedUrgentCount(
      jobs.filter(
        (item) =>
          item.JobStatus === 'assigned' &&
          item.JobIsUrgent === 'urgent' &&
          item.JobAssessorID === assessorId
      ).length
    );

    setAssessmentStartedCount(
      jobs.filter(
        (item) => item.JobStatus === 'assessment started' && item.JobAssessorID === assessorId
      ).length
    );
    setAssessmentStartedUrgentCount(
      jobs.filter(
        (item) =>
          item.JobStatus === 'assessment started' &&
          item.JobIsUrgent === 'urgent' &&
          item.JobAssessorID === assessorId
      ).length
    );

    setCompletedCount(
      jobs.filter(
        (item) => item.JobStatus === 'marked as complete' && item.JobAssessorID === assessorId
      ).length
    );
    setCompletedUrgentCount(
      jobs.filter(
        (item) =>
          item.JobStatus === 'marked as complete' &&
          item.JobIsUrgent === 'urgent' &&
          item.JobAssessorID === assessorId
      ).length
    );

    setOnHoldCount(
      jobs.filter((item) => item.JobStatus === 'on hold' && item.JobAssessorID === assessorId)
        .length
    );
    setOnHoldUrgentCount(
      jobs.filter(
        (item) =>
          item.JobStatus === 'on hold' &&
          item.JobIsUrgent === 'urgent' &&
          item.JobAssessorID === assessorId
      ).length
    );
  }, [assessorId, jobs, user?.ID, user?.UserRole]);

  const StyledListContainer = styled(Paper)(({ theme }) => ({
    width: '100%',
    border: `solid 1px ${theme.palette.divider}`,
  }));

  // const card: CSSProperties = {
  //   // backgroundColor:isDarkMode ? theme.palette.grey[600] : theme.palette.grey[400],
  //   display: 'flex',
  //   borderRadius: 0,
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // };
  // const count: CSSProperties = {
  //   marginTop: 1,
  //   fontSize: '28px',
  //   fontWeight: 'bold',
  // };
  // const label: CSSProperties = {
  //   fontSize: '16px',
  //   marginTop: '8px',
  // };
  // const [rowid, setrowid] = useState('');

  const handleView = (value: string, edit: boolean) => {
    navigate(`/viewJobs?id=${value}&edit=${edit}`);
  };

  // const goToAddJobPage = () => {
  //   navigate('/addjob');
  // };

  // const handleClose = () => {};
  // const options = [
  //   { value: 'option 1', label: 'New' },
  //   { value: 'option 2', label: 'Existing' },
  // ];

  // This below method is for get the jobs as per filter changes
  // Filters are listed to left hand side of the screen
  const handleFilterChange = (status: string) => {
    setFilterStatus(status);

    if (status === 'all') {
      console.log('All status = ');
      // const filteredJobs = jobs.filter((item: any) => item.JobAssessorID === assessorId);
      setFilteredData(jobs);
      // console.log('Filtered jobs = ', filteredJobs);
    } else if (status === 'urgent') {
      const filtered = jobs.filter(
        (item) => item.JobIsUrgent === status && item.JobAssessorID === assessorId
      );
      setFilteredData(filtered);
      console.log('Urgent jobs = ', filteredData);
    } else {
      const filtered = jobs.filter(
        (item) => item.JobStatus === status && item.JobAssessorID === assessorId
      );
      setFilteredData(filtered);
      console.log('Other jobs = ', filteredData);
    }
    //   // eslint-disable-next-line no-lonely-if
    //   if (status !== 'all') {
    //     if (user?.UserRole === 'Assessor') {
    //       const filteredJobs = jobs.filter(
    //         (item: any) => item.JobStatus === status && item.JobAssessorID === user?.ID
    //       );
    //       setFilteredData(filteredJobs);
    //     } else {
    //       const filtered = jobs.filter((item) => item.JobStatus === status);
    //       setFilteredData(filtered);
    //       console.log('Other jobs = ', filteredData);
    //     }
    //   }
  };

  const getStatusCounts = (status: string) => {
    let statusCounts = 0;
    if (status === 'all') {
      const filteredJobs = jobs.filter((item: any) => item.JobAssessorID === assessorId);
      statusCounts = filteredJobs.length;
    } else if (status === 'urgent') {
      jobs.forEach((item) => {
        if (item.JobIsUrgent === status && item.JobAssessorID === assessorId) {
          statusCounts += 1;
        }
      });
    } else
      jobs.forEach((item) => {
        if (item.JobStatus === status && item.JobAssessorID === assessorId) {
          statusCounts += 1;
        }
      });

    return statusCounts;
  };
  // const {
  //   startDate,
  //   endDate,
  //   onChangeStartDate,
  //   onChangeEndDate,
  //   open: openPicker,
  //   onOpen: onOpenPicker,
  //   onClose: onClosePicker,
  //   onReset: onResetPicker,
  //   isSelected: isSelectedValuePicker,
  //   isError,
  //   shortLabel,
  // } = useDateRangePicker(new Date(), new Date());

  // const handleChangeStartDate = (newValue: Date | null) => {
  //   onChangeStartDate(newValue);
  // };

  // const handleChangeEndDate = (newValue: Date | null) => {
  //   onChangeEndDate(newValue);
  // };

  // const requestJob = async () => {
  //   try {
  //     await axios
  //       .get(`/core/requestByAssessor?ID=${user?.ID}`)
  //       .then((response) => {
  //         console.log('Request by assessor in view assessor = ', response.data);
  //         enqueueSnackbar(response.data);
  //         // if (response.data === 'Success') {
  //         //   enqueueSnackbar('This request has been successful and scheduled.');
  //         // } else {
  //         //   enqueueSnackbar(response.data, { variant: 'error' });
  //         // }
  //       })
  //       .catch((error) => {
  //         console.log('Request by assessor catch= ');
  //         enqueueSnackbar(error, { variant: 'error' });
  //       });
  //   } catch (error) {
  //     console.error("Error = ",error);
  //   }
  // };

  // const requestJob = async () => {
  //   try {
  //     const response = await axios.get(`/core/requestByAssessor?ID=${user?.ID}`);
  //     const responseData = response.data;
  //     console.log('Request by assessor =', responseData);

  //     if (responseData.error) {
  //       enqueueSnackbar(responseData.error, { variant: 'error' });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const [view, setview] = useState('');
  // const [screen, setscreen] = useState(null);
  return (
    <Container sx={{ maxWidth: '1710px !important', marginTop: 2 }}>
      {/* <Dialog
                    fullWidth
                    maxWidth='lg'
                    open={open}
                    onClose={handleClose}
                >
                       <ViewJobs handleClose={handleClose} data={_dataGrid.filter((row)=> row.id === rowid)[0]}/>

            </Dialog> */}
      <Grid container spacing={3}>
        <Grid container direction="row" spacing={1} sx={{ margin: 1, marginLeft: 10 }}>
          <Grid item xs={12} sm={6} md={2.9}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 7,
                height: '80%',
                minHeight: '200px',
                width: '100%',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">
                  Assessment Started /
                  <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                </Typography>
                {/* <Typography variant="h3">
                  {fNumber(jobStatusCounts[0].TotalCount)} /{' '}
                  <span style={{ fontSize: '20px', color: '#ff5630' }}>
                    {jobStatusCounts[0].UrgentCount}
                  </span>
                </Typography> */}

                {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[0].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[0].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}

                {assessmentStartedCount !== null ? (
                  <Typography variant="h3">
                    {fNumber(assessmentStartedCount)} /{' '}
                    <span style={{ fontSize: '30px', color: '#ff5630' }}>
                      {assessmentStartedUrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )}
              </Box>
              {/* <Iconify icon="carbon:in-progress" width={24} /> */}
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2.9}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 7,
                height: '80%',
                minHeight: '200px',
                width: '100%',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">
                  Assigned (Assessment Not Started)
                  <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                </Typography>
                {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[1].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[1].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}
                {assignedCounts !== null ? (
                  <Typography variant="h3">
                    {fNumber(assignedCounts)} /{' '}
                    <span style={{ fontSize: '30px', color: '#ff5630' }}>
                      {assignedUrgentCounts}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )}
              </Box>
              {/* <Iconify icon="ic:baseline-assessment" width={24} /> */}
            </Card>{' '}
          </Grid>
          <Grid item xs={12} sm={6} md={2.9}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 7,
                height: '80%',
                minHeight: '200px',
                width: '100%',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">
                  Marked as Complete /
                  <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                </Typography>
                {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[2].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[2].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}

                {completedCount !== null ? (
                  <Typography variant="h3">
                    {fNumber(completedCount)} /{' '}
                    <span style={{ fontSize: '30px', color: '#ff5630' }}>
                      {completedUrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )}
              </Box>
              {/* <Iconify icon="carbon:task-complete" width={24} /> */}
            </Card>{' '}
          </Grid>
          <Grid item xs={12} sm={6} md={2.9}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 7,
                height: '80%',
                minHeight: '200px',
                width: '100%',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">
                  On Hold /<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                </Typography>
                {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[3].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[3].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}
                {onHoldCount !== null ? (
                  <Typography variant="h3">
                    {fNumber(onHoldCount)} /{' '}
                    <span style={{ fontSize: '30px', color: '#ff5630' }}>
                      {onHoldCountUrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )}
              </Box>
              {/* <Iconify icon="pajamas:status-paused" width={24} /> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ marginBottom: '10px' }}>
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={2.5}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}
          >
            <StyledListContainer
              sx={{
                height: '660px',
                minWidth: '250px',
                width: '250px',
                marginLeft: '7px',
                borderRadius: 0,
              }}
            >
              <Scrollbar>
                <List>
                  {/* ---- */}
                  <ListItemButton
                    selected={filterStatus === 'all'}
                    onClick={() => {
                      handleFilterChange('all');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="All" secondary={`count:${getStatusCounts('all')}`} />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'new'}
                    onClick={() => {
                      handleFilterChange('new');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="clarity:new-solid" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="New" secondary={`count:${getStatusCounts('new')}`} />
                  </ListItemButton>
                  {/* <ListItemButton
                    selected={filterStatus === 'in progress'}
                    onClick={() => {
                      handleFilterChange('in progress');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="carbon:in-progress" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="In Progress"
                      secondary={`count:${getStatusCounts('in progress')}`}
                    />
                  </ListItemButton> */}
                  <ListItemButton
                    selected={filterStatus === 'urgent'}
                    onClick={() => {
                      handleFilterChange('urgent');
                    }}
                    sx={{
                      color: '#EDA565',
                      '&.Mui-selected': {
                        backgroundColor: '#F9B572',
                        color: 'white',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="fluent:alert-urgent-24-filled" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Urgent"
                      secondary={`count:${getStatusCounts('urgent')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'assigned'}
                    onClick={() => {
                      handleFilterChange('assigned');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="mdi:assignment" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Assigned"
                      secondary={`count:${getStatusCounts('assigned')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'assessment started'}
                    onClick={() => {
                      handleFilterChange('assessment started');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Assessment Started"
                      secondary={`count:${getStatusCounts('assessment started')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'workflow to action'}
                    onClick={() => {
                      handleFilterChange('workflow to action');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="ic:baseline-assessment" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Workflow to Action"
                      secondary={`count:${getStatusCounts('workflow to action')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'pending validation'}
                    onClick={() => {
                      handleFilterChange('pending validation');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="carbon:task-complete" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Pending Validation"
                      secondary={`count:${getStatusCounts('pending validation')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'marked as complete'}
                    onClick={() => {
                      handleFilterChange('marked as complete');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="carbon:task-complete" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Marked as Complete"
                      secondary={`count:${getStatusCounts('marked as complete')}`}
                    />
                  </ListItemButton>

                  {/* <ListItemButton
                    selected={filterStatus === 'reassess initiated'}
                    onClick={() => {
                      handleFilterChange('reassess initiated');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Reassess Initiated"
                      secondary={`count:${getStatusCounts('reassess initiated')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'reassess started'}
                    onClick={() => {
                      handleFilterChange('reassess started');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Reassess Started"
                      secondary={`count:${getStatusCounts('reassess started')}`}
                    />
                  </ListItemButton>

                  <ListItemButton
                    selected={filterStatus === 'reassess completed'}
                    onClick={() => {
                      handleFilterChange('reassess completed');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="material-symbols:all-match-sharp" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Reassess Completed"
                      secondary={`count:${getStatusCounts('reassess completed')}`}
                    />
                  </ListItemButton> */}

                  <ListItemButton
                    selected={filterStatus === 'on hold'}
                    onClick={() => {
                      handleFilterChange('on hold');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="pajamas:status-paused" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="On Hold"
                      secondary={`count:${getStatusCounts('on hold')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={filterStatus === 'cancelled'}
                    onClick={() => {
                      handleFilterChange('cancelled');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="pajamas:status-cancelled" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Cancelled"
                      secondary={`count:${getStatusCounts('cancelled')}`}
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      color: '#D53343',
                      '&.Mui-selected': {
                        backgroundColor: '#D53343',
                        color: 'white',
                      },
                    }}
                    selected={filterStatus === 'exception'}
                    onClick={() => {
                      handleFilterChange('exception');
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Iconify icon="ant-design:exception-outlined" width={24} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Exception"
                      secondary={`count:${getStatusCounts('exception')}`}
                    />
                  </ListItemButton>
                </List>
              </Scrollbar>
            </StyledListContainer>
          </Grid>
          <Grid
            direction="column"
            item
            xs={12}
            sm={12}
            md={9.5}
            sx={{
              display: 'flex',
              marginTop: 2,
              justifyContent: 'center',
              height: '680px',
              width: '680px',
              alignItems: 'start',
            }}
          >
            <Grid item xs={2} sm={2} md={1} spacing={3} sx={{ marginTop: 'auto' }}>
              {/* <Grid container direction="row"  > */}
              <TextField
                variant="outlined"
                size="small"
                sx={{ width: '400px', marginTop: 1, height: '50px' }}
                placeholder="Search Job"
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="bi:search" width={24} />
                    </InputAdornment>
                  ),
                }}
                value={searchQuery}
                onChange={handleSearchChange}
                title="Search will be applicable for JobId, jobNumber, SiteAddress, Rating, Requestor FirstName and AssessorName"
              />
              {/* <Button
                variant="soft"
                size="medium"
                sx={{ marginLeft: 1, marginTop: 1, height: '40px' }}
              >
                search
              </Button> */}
              {/* <Button
                variant="soft"
                size="medium"
                color="secondary"
                sx={{ marginLeft: 1, marginTop: 1, height: '40px' }}
              >
                Reset{' '}
              </Button> */}
              {/* <FormControlLabel
                control={<Switch defaultChecked sx={{ marginLeft: 1 }} />}
                label="Case Sensitive"
              /> */}
              {/* <FileFilterButton
                isSelected={!!isSelectedValuePicker}
                startIcon={<Iconify icon="eva:calendar-fill" />}
                onClick={onOpenPicker}
              >
                {isSelectedValuePicker ? shortLabel : 'Select Date'}
              </FileFilterButton>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  variant="calendar"
                  startDate={startDate}
                  endDate={endDate}
                  onChangeStartDate={handleChangeStartDate}
                  onChangeEndDate={handleChangeEndDate}
                  open={openPicker}
                  onClose={onClosePicker}
                  isSelected={isSelectedValuePicker}
                  isError={isError}
                />
              </LocalizationProvider> */}
              {/* {user?.UserRole === 'Assessor' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={requestJob}
                  sx={{ marginLeft: 1, height: '35px' }}
                >
                  Request Job
                </Button>
              )} */}
            </Grid>
            <Grid container direction="row" sx={{ height: '600px' }}>
              {/* {filteredData && (
                <DataGridCustom
                  data={filteredData}
                  sendData={(value, edit) => {
                    handleView(value, edit);
                  }}
                />
              )} */}
              {filteredData && (
                <DataGridCustom
                  data={filteredJob}
                  sendData={(value, edit) => {
                    handleView(value, edit);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </>
      </Grid>
    </Container>
  );
}

const _dataGrid = [...Array(40)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  lastLogin: _mock.time(index),
  occupency: _mock.number.percent(index),
  rating: _mock.number.rating(index),
  status: randomInArray([
    'Assigned',
    'In Progress',
    'Pending Validation',
    // 'Reassess Initiated',
    //  'Reassess Started',
    //  'Reasses Completed',
    'Marked as Complete',
    'Workflow to Action',
    'On Hold',
    'Urgent Jobs',
    'Exception',
    'New',
    'Cancelled',
  ]),
  jobType: randomInArray(['New', 'Existing']),
  firstName: `${_mock.name.lastName(index)}  ${_mock.name.firstName(index)}`,
  lastName: _mock.name.firstName(index),
  createdDate: _mock.time(index),
}));

interface Props extends ButtonProps {
  children?: ReactNode;
  isSelected: boolean;
}

function FileFilterButton({ children, isSelected, ...other }: Props) {
  return (
    <Button
      variant="soft"
      color="inherit"
      sx={{
        textTransform: 'unset',
        color: 'text.secondary',
        width: { xs: 1, md: 'auto' },
        justifyContent: 'flex-start',
        fontWeight: 'fontWeightMedium',
        ...(isSelected && {
          color: 'text.primary',
        }),
      }}
      {...other}
    >
      {children}

      <Box sx={{ flexGrow: 1 }} />
    </Button>
  );
}
