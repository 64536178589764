import {
  Button,
  Card,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  Checkbox,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
// import _mock from 'src/_mock/_mock';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import { TreeItem, TreeItemProps, TreeView, LoadingButton } from "@mui/lab";
// import { DatePicker } from '@mui/lab';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { DateRangePicker } from '@mui/x-date-pickers/DateRangePicker';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface Schedul {
  day: string;
  fromTime: string;
  toTime: string;
}

export default function AddAssessorScheduleTab({ onNext, onback, assessor, setAssessor }: any) {
  const [selectedDays, setSelectedDays] = useState<Schedul[]>([]);
  const [selectedDaysFlag, setSelectedDaysFlag] = useState(false);

  const handleClick = (event: any) => {
    const { value, checked } = event.target;
    console.log(value, checked);
    if (!checked) {
      setSelectedDays(selectedDays.filter((val: Schedul) => val.day !== value));
    } else {
      setSelectedDaysFlag(false);
      const existingSchedul: Schedul = {
        day: value,
        // fromTime: '2023-07-27T10:30:00-16:00',
        // toTime: '2023-07-27T17:00:00-16:30',
        fromTime: '2023-07-27T08:00:00',
        toTime: '2023-07-27T15:00:00',
      };
      setSelectedDays([...selectedDays, existingSchedul]);
    }
  };

  function handleFromChange(value: any, day: any) {
    setSelectedDays((prevScheduls) =>
      prevScheduls.map((Schedul, i) =>
        Schedul.day === day ? { ...Schedul, fromTime: value } : Schedul
      )
    );
    console.log(selectedDays);
  }

  function handleToChange(value: any, day: any) {
    console.log(value);
    setSelectedDays((prevScheduls) =>
      prevScheduls.map((Schedul, i) =>
        Schedul.day === day ? { ...Schedul, toTime: value } : Schedul
      )
    );
  }
  useEffect(() => {
    if (assessor && assessor.selectedDays) {
      setSelectedDays(assessor.selectedDays.map((day:Schedul) => ({day:day.day , fromTime:`1970-01-01T${day.fromTime}`,toTime:`1970-01-01T${day.toTime}`})) as Schedul[]);
    }
  }, [assessor]);

  const validationSchema = Yup.object().shape({
    // You can add additional validation if needed
  });

  const {
    // control,
    handleSubmit,
    // setValue,
    // formState: { errors },
  } = useForm<Schedul[]>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: Schedul[]) => {
    if (selectedDays.length !== 0) {
      setSelectedDaysFlag(false);
      setAssessor((prevAssessor: any) => ({
        ...prevAssessor,
        selectedDays:selectedDays.map((day)  => ({ day:day.day ,fromTime: new Date(day.fromTime).toTimeString().split(' ')[0].toString()   ,toTime:new Date(day.toTime).toTimeString().split(' ')[0].toString() }))
      }));
      console.log(assessor);
      onNext();
    } else {
      setSelectedDaysFlag(true);
    }
  };

  return (
    <>
      {/* <Container> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
          <Grid container direction="row" md={12} sx={{ margin: 1 }}>
            <Grid item xs={12} md={12}>
              {/* <Card sx={{ py: 3, px: 3, textAlign: 'center', height: '100%', width: '100%' }}> */}
              <Grid item lg={3}>
                <Typography variant="h6">Assessor Work Schedule</Typography>
              </Grid>
              <Grid container direction="row" spacing={3} lg={12} sx={{ margin: 1 }}>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
                  (day) => (
                    <Grid item lg={3} key={day}>
                      <FormControlLabel
                        value={day}
                        control={<Checkbox />}
                        label={day}
                        labelPlacement="end"
                        checked={selectedDays.some((Schedul) => Schedul.day === day)}
                        onChange={handleClick}
                      />
                    </Grid>
                  )
                )}
              </Grid>
              {selectedDaysFlag && (
                <Grid item lg={3}>
                  <Typography variant="body1" sx={{color:'red'}}>Please select days first.</Typography>
                </Grid>
              )}
              <Grid
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                spacing={3}
                sx={{ marginLeft: 'auto', marginRight: 'auto', margin: 1, maxWidth: 'fit-content' }}
              >
                {selectedDays.map((day: Schedul) => (
                  <Card
                    key={day.day}
                    sx={{
                      paddingLeft: '10px',
                      paddingTop: '10px',
                      pb: '10px',
                      paddingRight: '10px',
                      marginLeft: '10px',
                      marginTop: '10px',
                      maxWidth: '160px',
                    }}
                  >
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      {day.day}
                    </Typography>
                    <Stack spacing={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm
                          label="From -"
                          value={day.fromTime}
                          onChange={(newValue) => {
                            handleFromChange(newValue, day.day);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} margin="normal" />
                          )}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm
                          label="To -"
                          value={day.toTime}
                          onChange={(newValue: any) => {
                            console.log(newValue);
                            handleToChange(newValue, day.day);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} margin="normal" />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Card>
                ))}
              </Grid>
              {/* </Card> */}
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  onback();
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {/* </Container> */}
    </>
  );
}
