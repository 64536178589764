import {
  Box,
  Button,
  CardHeader,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { RHFTextField } from 'src/components/hook-form';

interface Props {
  data: JobRecord;
  reportData: any;
}

interface JobRecord {
  JobRecordID: number;
  JobNumber: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: number;
  JobPostAssessmentFlag: boolean;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
}

export default function Cover({
  data,
  reportData,
  saveReportData,
  setSaveReportData,
  invalidFields,
}: any) {
  const handleRatingChange = (event: any) => {
    const newRating = parseFloat(event.target.value);

    // Check if the new rating is a number and within the valid range
    if (!Number.isNaN(newRating) && newRating >= 0 && newRating <= 10) {
      setSaveReportData((prevData: any) => ({
        ...prevData,
        Rating: newRating,
      }));
    } else {
      // If the new rating is out of range, set it to  10.
      const boundedRating = Math.max(0, Math.min(newRating, 10));
      setSaveReportData((prevData: any) => ({
        ...prevData,
        Rating: boundedRating,
      }));
    }
  };
  // let zone = saveReportData?.NatHersClimateZone;
  // if (zone && /^\d\s$/.test(zone)) {
  //   zone = zone.trim();
  // }
  // console.log('Zone value cover=',zone);
  console.log('Qdc = ', saveReportData?.QDCOneStarCreditApplied);
  const handleDepositedPlanNumberChange = (event: any) => {
    const newDepositedPlanNumber = parseFloat(event.target.value);
    console.log('DP Number : ', newDepositedPlanNumber);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      DPNumber: newDepositedPlanNumber,
    }));
  };

  function showTopBackgroundCOlor() {
    let color: string = '#7030a0';
    if (reportData?.projectInfoData.NccType === '2019') {
      color = '#31869b';
    }

    return color;
  }

  function showBackgroundColor() {
    let color: string = '#17a986';
    let failValue: number = 7;
    if (reportData?.projectInfoData.NccType === '2019') {
      if (
        saveReportData?.State === 'Queensland' &&
        saveReportData?.QDCOneStarCreditApplied === 'Yes'
      ) {
        failValue = 5;
      } else {
        failValue = 6;
      }
    } else if (reportData?.projectInfoData.NccType === '2022') {
      if (
        saveReportData?.State === 'Queensland' &&
        saveReportData?.QDCOneStarCreditApplied === 'Yes'
      ) {
        failValue = 6;
      } else {
        failValue = 7;
      }
    }

    if (
      saveReportData?.State === 'New South Wales' &&
      (saveReportData?.NatHersClimateZone === '9 ' ||
        saveReportData?.NatHersClimateZone === '10' ||
        saveReportData?.NatHersClimateZone === '11')
    ) {
      if (
        (saveReportData?.EnergyHeatingTarget !== undefined &&
          reportData?.projectInfoData?.EnergyHeating > saveReportData.EnergyHeatingTarget) ||
        (saveReportData?.EnergyCoolingTarget !== undefined &&
          reportData?.projectInfoData.EnergyCooling > saveReportData.EnergyCoolingTarget)
      ) {
        color = '#ac0000';
      }
    } else if (
      (saveReportData?.Rating !== undefined && saveReportData?.Rating < failValue) ||
      (saveReportData?.EnergyHeatingTarget !== undefined &&
        reportData?.projectInfoData?.EnergyHeating > saveReportData.EnergyHeatingTarget) ||
      (saveReportData?.EnergyCoolingTarget !== undefined &&
        reportData?.projectInfoData.EnergyCooling > saveReportData.EnergyCoolingTarget)
    ) {
      color = '#ac0000';
    }

    return color;
  }

  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ backgroundColor: showTopBackgroundCOlor() }}>
        <CardHeader title="" subheader="" sx={{ padding: '5px' }} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ backgroundColor: showBackgroundColor() }}
      >
        <Typography typography="h3" color="#ffffff" sx={{ padding: '2rem 0 0 1rem' }}>
          NCC {reportData?.projectInfoData?.NccType}
        </Typography>
        <Typography typography="h3" sx={{ padding: '1rem 1rem 0 0' }}>
          <img
            src="./ea-report-logo.png"
            alt="Logo"
            style={{ width: '50%', height: '70%', float: 'right' }}
          />
        </Typography>
      </Box>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack direction="row">
            <Box sx={{ typography: 'h4', fontWeight: '100' }}>
              {reportData?.projectInfoData?.NccType === '2019'
                ? 'ABCB NCC 2019 VOLUME TWO AMENDMENT 1'
                : 'ABCB NCC 2022 VOLUME TWO'}
            </Box>
          </Stack>
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            {/* Conditional based on state as per excel */}
            <Box sx={{ typography: 'h3' }}>BASIX Thermal Comfort</Box>
          </Stack>
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ typography: 'h3' }}>Energy Efficiency Assessment</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack direction="column">
            {saveReportData?.State === 'New South Wales' &&
            (saveReportData?.NatHersClimateZone === '9 ' ||
              saveReportData?.NatHersClimateZone === '10' ||
              saveReportData?.NatHersClimateZone === '11') ? (
              <>
                <Typography typography="h6" color="#788490">
                  Deposited Plan (DP) Number
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </Typography>
                {/* <Typography typography="h4" color="#17a986">
                  <TextField
                    name="DepositedPlanNumber"
                    value={saveReportData?.DPNumber || ''}
                    onChange={handleDepositedPlanNumberChange}
                    sx={{
                      border:
                        invalidFields.indexOf('DPNumber') >= 0
                          ? '3px solid red'
                          : '1px solid black',
                      background: 'cadetblue',
                      borderRadius: '6px',
                    }}
                  />
                </Typography> */}
                <Stack direction="row" alignItems="center" sx={{ minWidth: 72, flex: '1 1' }}>
                  <Typography typography="h6" variant="body2" color="#17a986">
                    {saveReportData?.QDCOneStarCreditApplied === 'No' ||
                    saveReportData?.QDCOneStarCreditApplied === ''
                      ? saveReportData?.DPNumber
                      : `${saveReportData?.DPNumber} stars + ${saveReportData?.QDCOneStarCreditApplied}`}
                  </Typography>
                </Stack>
              </>
            ) : (
              <>
                <Typography typography="h6" color="#788490">
                  Accredited Star Rating
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </Typography>
                <Stack direction="row" alignItems="end" sx={{ minWidth: 72, flex: '1 1' }}>
                  <Typography typography="h6" variant="body2" color="#17a986">
                    {saveReportData?.QDCOneStarCreditApplied === 'No' ||
                    saveReportData?.QDCOneStarCreditApplied === ''
                      ? saveReportData?.Rating
                      : `${saveReportData?.Rating} stars + ${saveReportData?.QDCOneStarCreditApplied}`}
                  </Typography>
                </Stack>

                {/* <Typography typography="h4" color="#17a986">
                  <TextField
                    type="text"
                    name="Rating"
                    // value={saveReportData?.Rating}
                    value={
                      saveReportData?.QDCOneStarCreditApplied === 'No'
                        ? saveReportData?.Rating
                        : `${saveReportData?.Rating} stars + ${saveReportData?.QDCOneStarCreditApplied}`
                    }
                    onChange={handleRatingChange}
                    sx={{
                      border:
                        invalidFields.indexOf('Rating') >= 0 ? '3px solid red' : '1px solid black',
                      background: 'cadetblue',
                      borderRadius: '6px',
                      width: '300px',
                    }}
                  />
                </Typography>
                <Typography variant="body2" sx={{ color: 'red' }}>
                  (NOTE:Range min 0 max 10)
                </Typography> */}
              </>
            )}
          </Stack>

          <Stack direction="column">
            <Typography typography="h6" color="#788490">
              REFERENCE
            </Typography>
            <Typography typography="h4" color="#17a986">
              {reportData?.projectInfoData?.ReferenceNumber}
            </Typography>
          </Stack>

          <Stack direction="column">
            <Typography typography="h6" color="#788490">
              SITE ADDRESS
            </Typography>
            <Typography typography="h4" color="#17a986">
              {reportData?.projectInfoData?.Address}
            </Typography>
          </Stack>

          <Stack direction="column">
            <Typography typography="h6" color="#788490">
              DWELLING TYPE
            </Typography>
            <Typography typography="h4" color="#17a986">
              {reportData?.projectInfoData?.DwellingType
                ? reportData?.projectInfoData?.DwellingType
                : ''}
            </Typography>
          </Stack>

          <Stack direction="column">
            <Typography typography="h6" color="#788490">
              COMMISSIONED BY
            </Typography>
            <Typography typography="h4" color="#17a986">
              {reportData?.projectInfoData?.ClientName}
            </Typography>
          </Stack>

          <Stack direction="column">
            <Typography typography="h6" color="#788490">
              ASSESSMENT DATE
            </Typography>
            <Typography typography="h4" color="#17a986">
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd-MM-yyyy')
                : ''}
            </Typography>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Energy Advance Australia Pty. Ltd.
            </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              NatHERS Accreditation Number: DMN/14/1662
            </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              34 Dellamarta Road WANGARA 6065
            </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              PO Box 1436 WANGARA DC 6947
            </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              ACN: 60 9332014 | 1300 850 228
            </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              energy@energyadvance.com.au
            </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>1300 850 228</Box>
          </Stack>
          <Stack direction="column">
            <img src="./ea-report-cover.png" alt="Logo" style={{ width: '40vh', height: '20vh' }} />
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack textAlign="center">
          <Box sx={{ color: 'text.disabled', typography: 'caption', fontSize: '0.85rem' }}>
            Energy Advance Australia Pty. Ltd. has taken care to ensure the report&apos;s accuracy
            at the time of publication. However, circumstances may change, and no warranty,
            assurance, or representation is made regarding its continued accuracy. The company and
            its affiliates accept no liability for any loss or damage arising from the use of this
            report, whether due to error, omission, or misrepresentation. The report must not be
            distributed, copied, or modified for disclosure to parties not involved in the
            project&apos;s specific approval process.
            {reportData?.projectInfoData.NccType === '2019' && (
              <Box component="span" sx={{ fontSize: '0.5rem' }}>
                {' '}
                v1701X
              </Box>
            )}
            {reportData?.projectInfoData.NccType === '2022' && (
              <Box component="span" sx={{ fontSize: '0.5rem' }}>
                {' '}
                NCC1701X8
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}
