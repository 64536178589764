import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import useOffSetTop from 'src/hooks/useOffSetTop';
import { HEADER } from 'src/config-global';

// @mui
import { Container, Link, Stack, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../routes/paths';
// components
import Iconify from '../components/iconify';
// sections
import AuthNewPasswordForm from '../sections/auth/AuthNewPasswordForm';
// assets
import { SentIcon } from '../assets/icons';
import Header from './Header';

// ----------------------------------------------------------------------

export default function NewPasswordPage() {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Header isOffset={isOffset} />

    <Container component="main">
      <Stack
    sx={{
      py: 12,
      m: 'auto',
      maxWidth: 400,
      minHeight: '100vh',
      textAlign: 'center',
      justifyContent: 'center',
    }}
  >
      <Helmet>
        <title> New Password | Energy Australia</title>
      </Helmet>

      <SentIcon sx={{ mb: 5, height: 96 }} />

      {/* <Typography variant="h3" paragraph>
        Request sent successfully!
      </Typography> */}

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        
        Please enter the Password below to change it.
      </Typography>

      <AuthNewPasswordForm />

      {/* <Typography variant="body2" sx={{ my: 3 }}>
        Don’t have a code? &nbsp;
        <Link variant="subtitle2">Resend code</Link>
      </Typography> */}

      <Link
        component={RouterLink}
        to={PATH_AUTH.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Return to sign in
      </Link>
      </Stack>
      </Container>
    </>
  );
}
