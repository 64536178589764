import {
  // useCallback,
  useEffect,
  useState,
} from 'react';
import axios from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// @mui
import {
  Stack,
  Dialog,
  Button,
  // TextField,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  Typography,
  styled,
  Checkbox,
} from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
// import label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, {
  // RHFRadioGroup,
  RHFTextField,
} from 'src/components/hook-form';
// components
import Editor from 'src/components/editor';
// import Iconify from '../../components/iconify';
// import { Upload } from '../../components/upload';
import SelectTemplate from './SelectTemplate';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  job: any;
  attachments: any;
}

type FormValuesProps = {
  EmailSubject: string;
  AddressTo: string;
  CCAddress: string;
};

interface EmailInfo {
  EmailRecordID: number;
  EmailMsgID: number;
  EmailFolderID: number;
  EmailSubject: string;
  EmailSummary: string;
  EmailHasAttach: number;
  EmailFlagID: string;
  EmailSender: string;
  EmailToAddress: string;
  EmailCCAddress: string;
  EmailLabelID: number;
  EmailReceivedTime: number;
  EmailStatus: number;
  EmailStatus2: number;
  ThreadCount?: number | null;
  ThreadID?: number | null;
  EmailIsJob: string;
  EmailCreatedAt: string;
  EmailIsProcessed: string;
  EmailProcessedAt: string;
  EmailJSON: any;
}

export default function SendEmailDialog({ open, onClose, job, attachments }: Props) {
  const { user } = useAuthContext();

  const [data, setData] = useState<EmailInfo | null>(null);

  const [files, setFiles] = useState<(File | string)[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  console.log(attachments);
  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  // const CommentSchema = Yup.object().shape({
  //   EmailSubject: Yup.string().required('Email Subject is required'),
  //   AddressTo: Yup.string().required('Address To  is required'),
  //   CCAddress: Yup.string(),
  // });
  // const defaultValues = {
  //   EmailSubject: '',
  //   AddressTo: '',
  //   CCAddress: '',
  // };
  const methods = useForm<FormValuesProps>({
    // resolver: yupResolver(CommentSchema),
    // defaultValues,
  });
  const {
    // reset,
    handleSubmit,
    setValue,
    // watch,
    // formState: { isSubmitting },
  } = methods;
  // const values = watch()
  useEffect(() => {
    setValue('EmailSubject', job?.JobEmailSubject);
  }, [job, setValue]);

  useEffect(() => {
    if (job?.EmailRecordID) {
      axios
        .get(`/core/getEmailInfoById?Id=${job?.EmailRecordID}`)
        .then((response) => {
          //        console.log('Job status date 1 = ', response.data);
          setData(response.data as EmailInfo);
          setValue('AddressTo', `${response.data.EmailToAddress} , ${response.data.EmailSender}`);
          if (response.data.EmailToAddress === '' && response.data.EmailSender === '') {
            console.log('Both To address and Email Sender is empty');
            setValue('AddressTo', ``);
          } else if (response.data.EmailToAddress === '') {
            setValue('AddressTo', `${response.data.EmailSender}`);
          } else {
            setValue('AddressTo', `${response.data.EmailToAddress} , ${response.data.EmailSender}`);
          }
          setValue('CCAddress', response.data.EmailCCAddress);
        })
        .catch((error) => {
          console.error('Error fetching getEmailInfoById:', error);
        });
    }
  }, [job?.EmailRecordID, setValue]);

  function formatFileSize(bytes: any) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
  }
  const StyledRoot = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(0, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover': {
      zIndex: 9,
      position: 'relative',
      // boxShadow: theme.customShadows.z24,
      '& #mailActions': { opacity: 1 },
    },
  }));
  const [isloading, setisLoading] = useState(false);
  const onSubmit = async (dat: FormValuesProps) => {
    try {
      setisLoading(true);
      console.log('Data to send mail = ', editor);
      // if (user?.UserRole !== 'Assessor') {
      //   await CommentSchema.validate(dat, { abortEarly: false });
      // }
      const formData = new FormData();
      formData.append('AttachmentId', JSON.stringify(selectedAttachments));
      formData.append(`EmailSubject`, dat.EmailSubject);
      const styledEditor = editor.replace(
        /<p>/g,
        '<p style="font-family: Calibri; font-size: 10.5pt; padding: 0; margin: 0;">'
      );
      const styledEditor1 = `<p style="font-family: Calibri; font-size: 10.5pt; padding: 0; margin: 0;">${styledEditor}</p>`;
      formData.append(`EmailContent`, styledEditor1);
      formData.append(`AddressTo`, dat.AddressTo);
      formData.append(`CCAddress`, dat.CCAddress);
      formData.append(`JobRecordID`, job.JobRecordID);
      formData.append(`JobEmailID`, job.JobEmailMsgID);
      formData.append(`EntryBy`, user?.ID);
      console.log('Data to send mail = ', dat.AddressTo);

      if (editor === '') {
        enqueueSnackbar('Email Content is Required', { variant: 'error' });
        setisLoading(false);
        // return;
      }
      if (user?.UserRole !== 'Assessor') {
        if (dat.AddressTo === '') {
          enqueueSnackbar('To Address Is Required', { variant: 'error' });
          // } else if (dat.CCAddress === '') {
          //   enqueueSnackbar('CC Address Is Required', { variant: 'error' });
        } else if (dat.EmailSubject === '') {
          enqueueSnackbar('Email Subject Is Required', { variant: 'error' });
        } else if (editor !== '') {
          setisLoading(true);
          axios
            .post(`/core/sendMail`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              enqueueSnackbar(response.data);
              setEditor('');
              setValue('AddressTo', '');
              setValue('CCAddress', '');
              onClose();
              setisLoading(false);
            })
            .catch((error) => {
              enqueueSnackbar(error.error, { variant: 'error' });
              setisLoading(false);
            });
        }
      } else if (editor !== '') {
        setisLoading(true);
        axios
          .post(`/core/sendMail`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            enqueueSnackbar(response.data);
            setEditor('');
            setValue('AddressTo', '');
            setValue('CCAddress', '');
            onClose();
            setisLoading(false);
          })
          .catch((error) => {
            enqueueSnackbar(error.error, { variant: 'error' });
            setisLoading(false);
          });
      }
    } catch (error) {
      console.error(error);
      setisLoading(false);
    }
  };

  const [selectedAttachments, setSelectedAttachments] = useState<any>([]);

  const handleCheckboxChange = (jobAttachID: any) => {
    const isSelected = selectedAttachments.includes(jobAttachID);
    console.log(selectedAttachments);
    if (isSelected) {
      setSelectedAttachments((prevSelected: any) =>
        prevSelected.filter((id: any) => id !== jobAttachID)
      );
    } else {
      setSelectedAttachments((prevSelected: any) => [...prevSelected, jobAttachID]);
    }
  };

  const [editor, setEditor] = useState('');
  const onValueChanges = (value: any) => {
    setEditor(value);
  };
  const [template, setTemplate] = useState(false);

  const openTemplateSelector = () => {
    setTemplate(true);
  };
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> Send Email </DialogTitle>

      <SelectTemplate
        open={template}
        onClose={() => {
          setTemplate(false);
        }}
        setEditor={onValueChanges}
        job_data={job}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel> */}
            {user?.UserRole !== 'Assessor' && (
              <>
                <RHFTextField name="AddressTo" label="Address To" sx={{ width: '100%' }} />
                <RHFTextField name="CCAddress" label="CC Address" sx={{ width: '100%' }} />
                <RHFTextField name="EmailSubject" label="Email Subject" sx={{ width: '100%' }} />
              </>
            )}

            <Button
              variant="text"
              onClick={() => {
                openTemplateSelector();
              }}
            >
              {' '}
              Select Template
            </Button>
            <Editor value={editor} onChange={onValueChanges} onchan={setEditor} />

            <Typography variant="subtitle2" noWrap>
              Select attachments to Send Email to Requestor/Builder
              {/* Note : Minimum 1  attachment to be selected */}
            </Typography>
            {attachments &&
              attachments.map((list: any, key: any) => (
                <StyledRoot sx={{ bgcolor: 'background.paper' }}>
                  <Checkbox
                    checked={selectedAttachments.includes(list.JobAttachLoc)}
                    onChange={() => handleCheckboxChange(list.JobAttachLoc)}
                  />
                  <Stack
                    key={key}
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    sx={{
                      my: 1,
                      px: 1,
                      py: 0.75,
                    }}
                  >
                    <FileThumbnail file={list.JobAttachExtension} />

                    <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                      <Typography variant="subtitle2" noWrap>
                        {list.JobAttachName}
                      </Typography>
                      <Typography variant="subtitle2" noWrap>
                        {formatFileSize(list.JobAttachSize)}
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledRoot>
              ))}
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="contained" loading={isloading} type="submit">
            Send Mail
          </LoadingButton>

          <Button variant="outlined" color="secondary" onClick={onClose} disabled={isloading}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
