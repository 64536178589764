import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  StackProps,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import { fShortenNumber } from 'src/utils/formatNumber';
import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import { useAuthContext } from 'src/auth/useAuthContext';
import axios from 'src/utils/axios';
// import _mock from 'src/_mock';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Iconify from 'src/components/iconify';
import {
  useEffect,
  //  useRef,
  useState,
} from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router';
// import { useSnackbar } from 'src/components/snackbar';
import Chart, { useChart } from '../../components/chart';

// interface JobInfo {
//   id: string;
//   JobRecordID: number;
//   JobNumber: string;
//   EmailRecordID: number;
//   JobEmailMsgID: number;
//   JobEmailSubject: string;
//   JobFloors: number;
//   JobRequestorID: number;
//   JobSiteAddr: string;
//   JobAddrState: string;
//   JobIsUrgent: string;
//   JobCode: string;
//   JobType: string;
//   JobPrelimFinal: string;
//   JobAssessorID: number;
//   JobReprocessFlag: boolean;
//   JobDwelling: string;
//   JobStatus: string;
//   JobProcessedFlag: string;
//   JobProcessedAt: string | null;
//   JobCreatedAt: string;
//   JobRating: number;
//   JobPostAssessmentFlag: boolean;
//   ReqName: string;
//   UserFname: string;
//   UserLname: string;
//   UserEmail: string;
// }

type UserProfile = {
  UserProfilePic: any;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | 'deleted' | any;
  UserStreetAddr: string;
  UserCountry: 'Australia' | 'United States' | any;
  UserLastLogin: any;
  UserCreatedAt: string;
};

export default function DashBoard() {
  const [value, setValue] = useState<Date | null>(new Date());
  const theme = useTheme();
  const { user } = useAuthContext();
  const fullName = `${user?.UserFname ?? ''} ${user?.UserLname ?? ''}`;
  const userrole = `${user?.UserRole ?? ''}`;
  let userlastlogin = `${user?.UserLastLogin ?? ''}`;
  // console.log(user?.UserLastLogin);
  const d = new Date(userlastlogin);
  // console.log(d);

  if (userlastlogin !== '') {
    userlastlogin = d.toLocaleString('en-US', { timeZone: 'Australia/Perth' });
    // console.log(userlastlogin);
  }

  const [assessorName, setAssessorName] = useState(fullName);
  const [requestors, setRequestors] = useState<any>(null);
  const [jobAgingCounts, setJobAgingCounts] = useState<any>(null);
  const [jobStatusCounts, setJobStatusCounts] = useState<any>(null);
  const [jobStatusProgressChartCounts, setJobStatusProgressChartCounts] = useState<any>(null);
  const [assessor, setAssessor] = useState<any>(null);
  const [assessorTemp, setAssessorTemp] = useState<any>(null);
  const [assignedCounts, setAssignedCount] = useState<any>(null);
  const [assessmentStartedCount, setAssessmentStartedCount] = useState<any>(null);
  const [assessmentCompleteCount, setAssessmentCompleteCount] = useState<any>(null);
  const [newCount, setNewCount] = useState<any>(null);
  const [onHoldCount, setOnHoldCount] = useState<any>(null);
  const [profilePicUrl, setProfilePicUrl] = useState('');

  useEffect(() => {
    axios
      .get(`/core/getAssessor?Id=${user?.ID}`)
      .then((response) => {
        setAssessorTemp(response.data as UserProfile);
      })
      .catch((error) => {
        console.error('Error fetching assessor:', error);
      });
  }, [user?.ID]);

  // useEffect(() => {
  //   if (user?.UserProfilePic) {
  //     console.log('User pic location:', user?.UserProfilePic);
  //     console.log('Profile pic location:', assessorTemp?.UserProfilePic);
  //     axios
  //       .get(`/core/getAssessorProfilePic?loc=${assessorTemp?.UserProfilePic}`, {
  //         responseType: 'blob',
  //       })
  //       .then((response) => {
  //         const blob = response.data;
  //         console.log('Profile pic location response = ', assessorTemp?.UserProfilePic);
  //         const objectUrl = URL.createObjectURL(blob);
  //         // Now set the objectUrl as the src for the Avatar
  //         setProfilePicUrl(objectUrl); // Update profilePicUrl with the fetched URL
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching assessor profile:', error);
  //       });
  //   }
  // }, [assessorTemp?.UserProfilePic, user?.UserProfilePic]);

  useEffect(() => {
    if (assessorTemp?.UserProfilePic) {
      console.log('User pic location:', user?.UserProfilePic);
      console.log('Profile pic location:', assessorTemp?.UserProfilePic);

      axios
        .get(`/core/getAssessorProfilePic?loc=${assessorTemp?.UserProfilePic}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const blob = response.data;

          // Check if the blob is actually an image
          if (blob.type.startsWith('image/')) {
            const objectUrl = URL.createObjectURL(blob);
            setProfilePicUrl(objectUrl); // Update profilePicUrl with the fetched URL
          } else {
            console.error('Received non-image blob:', blob);
          }
        })
        .catch((error) => {
          console.error('Error fetching assessor profile:', error);
        });
    }
  }, [assessorTemp?.UserProfilePic, user?.UserProfilePic]);

  useEffect(() => {
    fetchAssessors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAssessors = async () => {
    try {
      const response = await axios.get('/core/getAllAssessor');
      const newArray = response.data.map(({ ID, ...rest }: any) => ({
        id: ID,
        ...rest,
      }));
      const neArray = newArray.map((userv: any) => ({
        ...userv,
        UserLastLogin: convertStringToDate(userv.UserLastLogin), // Convert LastLogin to Date
        UserCreatedAt: convertStringToDate(userv.UserCreatedAt), // Convert CreatedAt to Date
      }));
      setAssessor(neArray);
    } catch (error) {
      console.error('Error fetching assessors:', error);
    }
  };

  function convertStringToDate(dateString: string): Date {
    return new Date(dateString);
  }

  useEffect(() => {
    fetchRequestors();
  }, []);
  const fetchRequestors = async () => {
    try {
      const response = await axios.get('/core/getAllRequestors');
      const requestorsArray = response.data.map(({ ID, ...rest }: any) => ({
        id: ID,
        ...rest,
      }));
      setRequestors(requestorsArray);
    } catch (error) {
      console.error('Error fetching Requestors:', error);
    }
  };

  useEffect(() => {
    fetchJobAgingCount();
    fetchJobStatusCount();
    fetchJobStatusCountProgressChart();
  }, []);

  const fetchJobStatusCountProgressChart = async () => {
    try {
      const response = await axios.get('/core/getJobStatusCountsProgressChart');
      const counts = response.data;
      setJobStatusProgressChartCounts(counts);
      setNewCount(counts?.new);
      setAssignedCount(counts?.assigned);
      setAssessmentStartedCount(counts?.['assessment started']);
      setAssessmentCompleteCount(counts?.['workflow to action']);
      setOnHoldCount(counts?.['on hold']);
    } catch (error) {
      console.error('Error fetching getJobStatusCountsProgressChart:', error);
    }
  };

  const fetchJobAgingCount = async () => {
    try {
      const response = await axios.get('/core/getJobAgingCount');
      const jobAging = response.data;
      setJobAgingCounts(jobAging);
    } catch (error) {
      console.error('Error fetching getJobAgingCount:', error);
    }
  };

  const fetchJobStatusCount = async () => {
    try {
      const response = await axios.get('/core/getJobStatusCount');
      const countsJobStatus = response.data;
      setJobStatusCounts(countsJobStatus);
    } catch (error) {
      console.error('Error fetching getJobStatusCount:', error);
    }
  };

  const series = [
    newCount || 0,
    assignedCounts || 0,
    assessmentStartedCount || 0,
    assessmentCompleteCount || 0,
    onHoldCount || 0,
  ];

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const milliseconds = new Date().getMilliseconds();

  //       const response = await axios.get('/core/getAllJobs');
  //       // console.log('1 ', milliseconds - new Date().getMilliseconds());

  //       const modifiedData = response.data.map(({ JobCreatedAt, JobRecordID, ...job }: any) => ({
  //         id: JobRecordID,
  //         JobCreatedAt: new Date(JobCreatedAt),
  //         ...job,
  //       }));
  //       // console.log('2 ', milliseconds - new Date().getMilliseconds());

  //       setJobs(modifiedData as JobInfo[]);
  //       // console.log('4 ', milliseconds - new Date().getMilliseconds());
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  //   // fetchStatusCount();
  // }, [user?.ID, user?.UserRole]);

  // useEffect(() => {
  //   setAssignedCount(jobs.filter((item) => item.JobStatus === 'assigned').length);
  //   setAssignedUrgentCount(
  //     jobs.filter((item) => item.JobStatus === 'assigned' && item.JobIsUrgent === 'urgent').length
  //   );

  //   setAssessmentStartedCount(
  //     jobs.filter((item) => item.JobStatus === 'assessment started').length
  //   );
  //   setAssessmentStartedUrgentCount(
  //     jobs.filter(
  //       (item) => item.JobStatus === 'assessment started' && item.JobIsUrgent === 'urgent'
  //     ).length
  //   );

  //   setCompletedCount(jobs.filter((item) => item.JobStatus === 'complete').length);
  //   setCompletedUrgentCount(
  //     jobs.filter((item) => item.JobStatus === 'complete' && item.JobIsUrgent === 'urgent').length
  //   );

  //   setOnHoldCount(jobs.filter((item) => item.JobStatus === 'on hold').length);
  //   setOnHoldUrgentCount(
  //     jobs.filter((item) => item.JobStatus === 'on hold' && item.JobIsUrgent === 'urgent').length
  //   );
  // }, [jobs, user?.ID, user?.UserRole]);

  const chartOptions = useChart({
    labels: ['New', 'Assigned', 'Assessment Started', 'Marked as Complete', 'Others'],
    legend: {
      position: 'top',
      itemMargin: {
        vertical: 2,
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
    },
    fill: {
      colors: ['#808080', '#7b68ee', '#4682b4', '#2e8b57', '#ff6347'],
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    colors: ['#808080', '#7b68ee', '#4682b4', '#2e8b57', '#ff6347'],
  });

  const chart = {
    categories: ['0-3', '3-6', '6-9', '9-12', '>12'],
    colors: [theme.palette.primary.main, theme.palette.warning.main],

    data: [
      { name: 'New', data: jobAgingCounts?.[0].new || [] },
      { name: 'Assigned', data: jobAgingCounts?.[1].assigned || [] },
      { name: 'Assessment started', data: jobAgingCounts?.[2]?.['assessment started'] || [] },
      { name: 'All', data: jobAgingCounts?.[3].All || [] },
      // {
      //   name: 'All',
      //   data: Array.isArray(jobAgingCounts) && jobAgingCounts[3] ? jobAgingCounts[3].All || [] : [],
      // },
    ],
  };
  const { categories, colors, data } = chart;

  const chartOptionBar = useChart({
    colors,
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
      title: {
        text: 'Aging Days',
        style: {
          fontSize: '16px',
        },
      },

      labels: {
        show: true,
        style: {
          fontSize: '13px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'No. of Jobs',
        style: {
          fontSize: '16px',
        },
      },
      labels: {
        show: true,
        style: {
          fontSize: '13px',
        },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
      y: {
        formatter: (v: number) => `${v}`,
      },
      style: {
        fontSize: '13px',
      },
    },
  });

  // const [selectedValue, setSelectedValue] = useState<any>(statusCountsArray[0]);
  // const handleChange = (hello: any, sel: any) => {
  //   statusCountsArray.forEach((valu) => {
  //     if (valu.timeFrame === sel) {
  //       console.log('Selected value = ', valu);
  //       setSelectedValue(valu);
  //     }
  //   });
  // };

  const [alignment, setAlignment] = useState('today');

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  const navigate = useNavigate();
  const goToProfile = () => {
    navigate('/profile');
  };

  return (
    <Grid container spacing={2} sx={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Grid item xs={12} sm={12} md={10}>
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container flexDirection="row" justifyContent="end" sx={{ marginTop: 3 }}>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="today">Today</ToggleButton>
                <ToggleButton value="yesterday">Yesterday</ToggleButton>
                <ToggleButton value="week">Weekly</ToggleButton>
                <ToggleButton value="month">Monthly</ToggleButton>
                <ToggleButton value="all">All</ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {alignment === 'today' && (
              <Grid container direction="row" spacing={1} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assessment Started /
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Today['assessment started']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Today['Urgent_assessment started'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:in-progress" width={24} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assigned (Assessment Not Started){' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Today.assigned}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Today.Urgent_assigned || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="ic:baseline-assessment" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Marked as Complete /{' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {/* {fNumber(132)} /{' '} */}
                        {jobStatusCounts?.Today['marked as complete']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Today['Urgent_marked as complete'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:task-complete" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        On Hold /<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Today['on hold']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Today['Urgent_on hold'] || 0}
                        </span>{' '}
                      </Typography>
                    </Box>
                    <Iconify icon="pajamas:status-paused" width={24} />
                  </Card>
                </Grid>
              </Grid>
            )}
            {alignment === 'yesterday' && (
              <Grid container direction="row" spacing={1} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assessment Started /
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Yesterday['assessment started']}/
                        <span style={{ fontSize: '30px', color: '#ff5630  ' }}>
                          {jobStatusCounts?.Yesterday['Urgent_assessment started'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:in-progress" width={24} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assigned (Assessment Not Started){' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Yesterday.assigned}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Yesterday.Urgent_assigned || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="ic:baseline-assessment" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Marked as Complete /{' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {/* {fNumber(132)} /{' '} */}
                        {jobStatusCounts?.Yesterday['marked as complete']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Yesterday['Urgent_marked as complete'] || 0}
                        </span>{' '}
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:task-complete" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        On Hold /<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Yesterday['on hold']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Yesterday['Urgent_on hold'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="pajamas:status-paused" width={24} />
                  </Card>
                </Grid>
              </Grid>
            )}
            {alignment === 'week' && (
              <Grid container direction="row" spacing={1} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assessment Started /
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Week['assessment started']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Week['Urgent_assessment started'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:in-progress" width={24} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assigned (Assessment Not Started){' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Week.assigned}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Week.Urgent_assigned || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="ic:baseline-assessment" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Marked as Complete /{' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {/* {fNumber(132)} /{' '} */}
                        {jobStatusCounts?.Week['marked as complete']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Week['Urgent_marked as complete'] || 0}
                        </span>{' '}
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:task-complete" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        On Hold /<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Week['on hold']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Week['Urgent_on hold'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="pajamas:status-paused" width={24} />
                  </Card>
                </Grid>
              </Grid>
            )}
            {alignment === 'month' && (
              <Grid container direction="row" spacing={1} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assessment Started /
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Month['assessment started']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Month['Urgent_assessment started'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:in-progress" width={24} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assigned (Assessment Not Started){' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Month.assigned}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Month.Urgent_assigned || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="ic:baseline-assessment" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Marked as Complete /{' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {/* {fNumber(132)} /{' '} */}
                        {jobStatusCounts?.Month['marked as complete']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Month['Urgent_marked as complete'] || 0}
                        </span>{' '}
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:task-complete" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        On Hold /<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.Month['on hold']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.Month['Urgent_on hold'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="pajamas:status-paused" width={24} />
                  </Card>
                </Grid>
              </Grid>
            )}
            {alignment === 'all' && (
              <Grid container direction="row" spacing={1} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assessment Started /
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.All['assessment started']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.All['Urgent_assessment started'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:in-progress" width={24} />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assigned (Assessment Not Started){' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.All.assigned}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.All.Urgent_assigned || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="ic:baseline-assessment" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Marked as Complete /{' '}
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {/* {fNumber(132)} /{' '} */}
                        {jobStatusCounts?.All['marked as complete']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.All['Urgent_marked as complete'] || 0}
                        </span>{' '}
                      </Typography>
                    </Box>
                    <Iconify icon="carbon:task-complete" width={24} />
                  </Card>{' '}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      height: '80%',
                      minHeight: '180px',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        On Hold /<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      <Typography variant="h3">
                        {jobStatusCounts?.All['on hold']}/
                        <span style={{ fontSize: '30px', color: '#ff5630' }}>
                          {jobStatusCounts?.All['Urgent_on hold'] || 0}
                        </span>
                      </Typography>
                    </Box>
                    <Iconify icon="pajamas:status-paused" width={24} />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={5}>
                <Card sx={{  width: '100%', borderRadius: 2 }}>
                  <CardHeader title="Overall Progress Chart" />
                  <StyledChartWrapper dir="ltr">
                    <Chart type="pie" series={series} options={chartOptions} height={492} />
                  </StyledChartWrapper>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <Card sx={{ width: 'auto', height: '510px' }}>
                  <CardHeader title="Requestor List" subheader="" />

                  <Scrollbar>
                    <Stack spacing={3} sx={{ p: 3 }}>
                      {requestors?.map((country: any) => (
                        <CountryItem key={country.id} country={country} />
                      ))}
                    </Stack>
                  </Scrollbar>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader title="Job Aging Chart" subheader="" />

                  <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
                    <Chart type="bar" series={data} options={chartOptionBar} height={364} />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={2} marginTop={2.5}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Card sx={{ alignItems: 'center', p: 3, height: '400px' }}>
              <Stack
                spacing={2}
                sx={{
                  width: 'fit-content',

                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={profilePicUrl}
                  sx={{ boxShadow: 'z23', width: 150, height: 150, borderRadius: 1 }}
                  variant="square"
                />
                <TextMaxLine line={1}>
                  <Label sx={{ marginTop: 0.1, width: '230px', fontSize: 14 }}>
                    {assessorName}
                  </Label>
                </TextMaxLine>

                <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                  {/* Role : Management */}
                  Role : {userrole}
                </Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                    label="Last Login"
                    disabled
                    // value={Date.now().toString()}
                    value={userlastlogin}
                    onChange={setValue}
                  />
                </LocalizationProvider>
                <Button
                  onClick={goToProfile}
                  variant="outlined"
                  sx={{ width: '230px', fontSize: 14 }}
                >
                  {' '}
                  View Profile
                </Button>
              </Stack>
            </Card>
          </Grid>
          <Grid item sx={{ height: '850px' }}>
            <Card sx={{ width: '100%' }}>
              <CardHeader title="Assessor List" subheader="" />

              <Stack spacing={3} sx={{ p: 3, height: '745px' }}>
                <Scrollbar>
                  {assessor?.map((author: any) => (
                    <AuthorItem key={author.id} author={author} />
                  ))}
                </Scrollbar>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const CHART_HEIGHT = 459;
const LEGEND_HEIGHT = 70;

const StyledChartWrapper = styled('div')(({ theme }: any) => ({
  height: CHART_HEIGHT,

  // marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-graphical': {
    transform: 'translate(0, 0) !important',
  },

  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    width: 'auto',

    flexDirection: 'row',
    justifyContent: 'center !important',
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

const StyledBlock = styled((props: StackProps) => (
  <Stack direction="row" alignItems="center" {...props} />
))({
  minWidth: 72,
  flex: '1 1',
});

const StyledItemIcon = styled(Iconify)(({ theme }) => ({
  width: 16,
  height: 16,
  marginRight: theme.spacing(0.5),
}));

// ----------------------------------------------------------------------

type ItemProps = {
  id: string;
  ReqName: string;
  ReqSubloc: string;
  TotalJobs: string;
  TotalCompleted: string;
  Exception: string;
  // flag: string;
};

type CountryItemProps = {
  country: ItemProps;
};

function CountryItem({ country }: CountryItemProps) {
  // console.log('Country in = ', country);
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <StyledBlock sx={{ minWidth: 150 }}>
        {/* <Image disabledEffect alt={country.name} src={country.flag} sx={{ width: 28, mr: 1 }} /> */}
        {/* <Avatar src={country.flag} sx={{ marginRight: 1 }} /> */}
        <Typography variant="subtitle2">
          {country.ReqName} : {country.ReqSubloc}
        </Typography>
      </StyledBlock>

      <StyledBlock>
        <StyledItemIcon icon="carbon:in-progress" sx={{ marginLeft: '100px' }} />
        <Typography variant="body2">{fShortenNumber(country.TotalJobs)}</Typography>
      </StyledBlock>

      <StyledBlock>
        <StyledItemIcon icon="ic:baseline-assessment" sx={{ marginLeft: '100px' }} />
        <Typography variant="body2">{fShortenNumber(country.TotalCompleted)}</Typography>
      </StyledBlock>

      <StyledBlock>
        <StyledItemIcon icon="carbon:task-complete" color="primary" sx={{ marginLeft: '100px' }} />
        {/* <Iconify icon="carbon:task-complete" color='green' width={10} /> */}
        <Typography variant="body2">{fShortenNumber(country.Exception)}</Typography>
      </StyledBlock>
    </Stack>
  );
}

type AuthorItemProps = {
  author: AuthoItemProps;
};

type AuthoItemProps = {
  id: string;
  UserFname: string;
  UserLname: string;
  avatar: string;
  TotalJobs: number;
  TotalCompleted: number;
  UserOccupency: number;
};

function AuthorItem({ author }: AuthorItemProps) {
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar alt={author.UserFname} src={author.avatar} />

      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            marginTop: '10px',
          }}
        >
          {author.UserFname} {author.UserLname}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            mt: 0.5,

            alignItems: 'center',
            color: 'text.secondary',
          }}
        >
          Current Jobs :{author.TotalJobs} | Marked as Complete :{author.TotalCompleted}{' '}
        </Typography>

        {userrole === 'Management' && (
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,

              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            Occupancy:
            {author.UserOccupency || 0}
            <Iconify icon="fa-solid:percentage" width={16} sx={{ mr: 0.5 }} />
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
