import {
  CardContent,
  Typography,
  Card,
  Button,
  Grid,
  // Box,
  TextField,
  FormControl,
  // FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  // Link,
  // IconButton,
  // Stack,
  // MenuItem,
  // Select,
  // SelectChangeEvent,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  // Avatar,
  // InputLabel,
  NativeSelect,
  TextFieldProps,
} from '@mui/material';
import React, { useState } from 'react';
// import _mock from 'src/_mock';
// import TextMaxLine from 'src/components/text-max-line';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { 
  DatePicker, 
  // DesktopDatePicker, 
  LocalizationProvider 
} from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/lab';
// import { DateRange } from '@mui/lab';
import { useDateRangePicker } from 'src/components/date-range-picker';
import { fDate } from 'src/utils/formatTime';
// import { useNavigate } from 'react-router';

interface ReportProps {
  reportTypeTemp: String;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function GenerateReports({ reportTypeTemp, setIsVisible }: ReportProps) {
  const [start, setStart] = useState<Date | null>(new Date());
  const [end, setEnd] = useState<Date | null>(new Date());
  const pickerCalendar = useDateRangePicker(new Date(), new Date());
  const [showReport, setShowReport] = useState(false);
  // const [reportPage, setReportPage] = useState(false);

  const showReportFun = () => {
    setShowReport(true);
  };

  const handleBack = () => {
    window.history.back(); // Go back to the previous screen
  };

  // const navigate = useNavigate();
  const backToReport = () => {
    setIsVisible(false);
  };

  return (
    <>
      {reportTypeTemp === 'job' && (
        <>
          <Typography
            variant="h3"
            sx={{
              textAlign: 'center',
              marginTop: '40px',
              marginBottom: '40px',
              marginLeft: '40px',
              marginRight: '40px',
            }}
          >
            Generate Reports - Job Information
          </Typography>
          <Card
            style={{
              display: 'flex',
              borderRadius: 25,
              flexDirection: 'column',
              marginTop: '40px',
              marginLeft: '100px',
              marginRight: '100px',
            }}
          >
            <CardContent>
              <Grid container spacing={2} style={{ padding: '0px', marginTop: '25px' }}>
                <Grid xs={2} />
                <Grid xs={4}>
                  <Typography variant="h5" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                    Select Job Status
                  </Typography>

                  <FormControl fullWidth sx={{ marginTop: '40px', marginBottom: '40px' }}>
                    <NativeSelect
                      defaultValue={10}
                      inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option value={10}>Assigned</option>
                      <option value={30}>In Progress</option>
                      <option value={20}>Hold</option>
                    </NativeSelect>
                  </FormControl>

                  <div style={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                      --- OR ---
                    </Typography>
                  </div>

                  <FormControl fullWidth sx={{ marginTop: '40px', marginBottom: '40px' }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="outstanding-jobs"
                        control={<Radio />}
                        label="Outstanding Jobs"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid xs={4} style={{ marginLeft: '140px' }}>
                  <Typography variant="h5" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                    Select Period or Date Range
                  </Typography>

                  <FormControl fullWidth sx={{ marginTop: '40px', marginBottom: '40px' }}>
                    <NativeSelect
                      defaultValue={10}
                      inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <option value={10}>Daily</option>
                      <option value={30}>Weekly</option>
                      <option value={20}>Monthly</option>
                    </NativeSelect>
                  </FormControl>
                  <div style={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                      --- OR ---
                    </Typography>
                  </div>

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    sx={{ marginTop: '40px', marginBottom: '40px' }}
                  >
                    <DatePicker
                      label="Start"
                      value={fDate(pickerCalendar.startDate)}
                      minDate={new Date('2017-01-01')}
                      onChange={(newValue: React.SetStateAction<Date | null>) => {
                        setStart(newValue);
                      }}
                      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                        <TextField {...params} margin="dense" sx={{ marginRight: 1 }} />
                      )}
                    />
                    <DatePicker
                      label="End"
                      value={fDate(pickerCalendar.endDate)}
                      minDate={new Date('2017-01-01')}
                      onChange={(newValue: React.SetStateAction<Date | null>) => {
                        setEnd(newValue);
                      }}
                      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                        <TextField {...params} margin="dense" />
                      )}
                    />
                    <DateRangePicker
                      variant="calendar"
                      open={pickerCalendar.open}
                      startDate={pickerCalendar.startDate}
                      endDate={pickerCalendar.endDate}
                      onChangeStartDate={pickerCalendar.onChangeStartDate}
                      onChangeEndDate={pickerCalendar.onChangeEndDate}
                      onClose={pickerCalendar.onClose}
                      isError={pickerCalendar.isError}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ padding: '0px', marginTop: '25px' }}>
                <Grid xs={10} />
                <Grid xs={2}>
                  <Button
                    onClick={showReportFun}
                    variant="outlined"
                    size="large"
                    sx={{ marginRight: '10px' }}
                  >
                    Submit
                  </Button>
                  <Button onClick={backToReport} variant="outlined" size="large">
                    Back
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
      {reportTypeTemp === 'assessors' && (
        <Card
          style={{
            display: 'flex',
            borderRadius: 25,
            flexDirection: 'column',
            marginTop: '40px',
            marginLeft: '100px',
            marginRight: '100px',
          }}
        >
          <CardContent>
            <Typography
              variant="h3"
              sx={{ textAlign: 'center', marginTop: '40px', marginBottom: '40px' }}
            >
              Generate Reports - Assessor Information
            </Typography>
            <Grid container spacing={2} style={{ padding: '0px', marginTop: '25px' }}>
              <Grid xs={1} />
              <Grid xs={3}>
                <Typography variant="h5" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                  Select Assessor
                </Typography>

                <FormControl fullWidth sx={{ marginTop: '40px', marginBottom: '40px' }}>
                  <NativeSelect
                    defaultValue={10}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option value={10}>Assessor 1</option>
                    <option value={30}>Assessor 2</option>
                    <option value={20}>Assessor 3</option>
                  </NativeSelect>
                </FormControl>
                <div style={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                    --- OR ---
                  </Typography>
                </div>

                <FormControl fullWidth sx={{ marginTop: '40px', marginBottom: '40px' }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"

                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="All Assessors"
                      control={<Radio />}
                      label="All Assessors"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid xs={4} style={{ marginLeft: '140px' }}>
                <Typography variant="h5" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                  Select Period or Date Range
                </Typography>

                <FormControl fullWidth sx={{ marginTop: '40px', marginBottom: '40px' }}>
                  <NativeSelect
                    defaultValue={10}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option value={10}>Daily</option>
                    <option value={30}>Weekly</option>
                    <option value={20}>Monthly</option>
                  </NativeSelect>
                </FormControl>
                <div style={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                    --- OR ---
                  </Typography>
                </div>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{ marginTop: '40px', marginBottom: '40px' }}
                >
                  <DatePicker
                    label="Start"
                    value={fDate(pickerCalendar.startDate)}
                    minDate={new Date('2017-01-01')}
                    onChange={(newValue: React.SetStateAction<Date | null>) => {
                      setStart(newValue);
                    }}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                      <TextField {...params} margin="dense" sx={{ marginRight: 1 }} />
                    )}
                  />
                  <DatePicker
                    label="End"
                    value={fDate(pickerCalendar.endDate)}
                    minDate={new Date('2017-01-01')}
                    onChange={(newValue: React.SetStateAction<Date | null>) => {
                      setEnd(newValue);
                    }}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                      <TextField {...params} margin="dense" />
                    )}
                  />
                  <DateRangePicker
                    variant="calendar"
                    open={pickerCalendar.open}
                    startDate={pickerCalendar.startDate}
                    endDate={pickerCalendar.endDate}
                    onChangeStartDate={pickerCalendar.onChangeStartDate}
                    onChangeEndDate={pickerCalendar.onChangeEndDate}
                    onClose={pickerCalendar.onClose}
                    isError={pickerCalendar.isError}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid xs={2} style={{ marginLeft: '140px' }}>
                <Typography variant="h5" sx={{ marginTop: '40px', marginBottom: '40px' }}>
                  Assessors Performance
                </Typography>
                <FormControl fullWidth sx={{ marginTop: '40px', marginBottom: '40px' }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"

                    // value={value}
                    // onChange={handleChange}
                  >
                    <FormControlLabel value="Include" control={<Radio />} label="Include" />
                    <FormControlLabel value="Exclude" control={<Radio />} label="Exclude" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ padding: '0px', marginTop: '25px' }}>
              <Grid xs={10} />
              <Grid xs={2}>
                <Button
                  onClick={showReportFun}
                  variant="outlined"
                  size="large"
                  sx={{ marginRight: '10px' }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid xs={2}>
                <Button onClick={handleBack} variant="outlined" size="large">
                  Back
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {showReport && (
        <>
          <Grid container spacing={2} style={{ padding: '0px', marginTop: '25px' }}>
            <Grid xs={1} />
            <Grid xs={10}>
              <Typography variant="h6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ padding: '0px', marginTop: '25px' }}>
            <Grid xs={10} />
            <Grid xs={2}>
              <Button variant="outlined" size="large">
                Download
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
