import React, { useState, useEffect } from 'react';
import { Container, Typography, CircularProgress } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridRowParams,
} from '@mui/x-data-grid';
import axios from 'src/utils/axios';
import Label from 'src/components/label';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

const RefundJobs = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { themeStretch } = useSettingsContext();
  const [showLoadingModal, setShowLoadingModal] = useState(false); // State to control loading popup

 
  useEffect(() => {
    let isMounted = true;
    const fetchRefundJobs = async () => {
      try {
        setShowLoadingModal(true); // Show loading popup while fetching data
        const response = await axios.get('/core/refundinfo');
        const refundInfoData = response.data.map(({ ID, ...rest }: any) => ({
          id: ID,
          ...rest,
        }));
        if (isMounted) {
          setRows(refundInfoData);
          console.log('Rows = ', refundInfoData);
        }
      } catch (error) {
        console.error('Error fetching refund jobs:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
          setShowLoadingModal(false); // Hide loading popup when data is loaded
        }
      }
    };

    fetchRefundJobs();

    return () => {
      isMounted = false; // Clean up the flag when the component unmounts
    };
  }, []); // Empty dependency array to run the effect only once

  const getRowClassName = (params: GridRowParams) => {
    const status = params.row.Status;
    if (params.row.Status === 'Refund Requested') {
      return 'status-row';
    }
    return '';
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function RenderStatus(getStatus: string) {
    let backgroundColor = '';
    switch (getStatus) {
      case 'Refund Requested':
        backgroundColor = '#85D5CA';
        break;
      case 'Refund Initiated':
        backgroundColor = '#D58985';
        break;
      case 'Refund Received':
        backgroundColor = '#D585C8';
        break;
      default:
        backgroundColor = '';
    }

    const labelStyle = {
      backgroundColor,
      color: 'white',
    };

    return (
      <div>
        <Label variant="filled" style={labelStyle}>
          {getStatus}
        </Label>
      </div>
    );
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    // { field: 'ToAddress', headerName: 'To Address', width: 200 },
    { field: 'FromAddress', headerName: 'Initiated By', width: 300 },
    { field: 'InitiatedAt', headerName: 'Initiated at', width: 200 },
    { field: 'Subject', headerName: 'Subject', width: 400 },
    // { field: 'Summary', headerName: 'Summary', width: 200 },
    {
      field: 'Status',
      headerName: 'Status',
      width: 200,
      renderCell: (params: any) => RenderStatus(params.value),
    },
    // { field: 'CCAddress', headerName: 'CC Address', width: 200 },
    // { field: 'NotifiedToWorkflow', headerName: 'Notified to Workflow', width: 180 },
    // { field: 'NotificationTime', headerName: 'Notification Time', width: 200 },
    { field: 'ReplyFromFR', headerName: 'Reply from FR', width: 200 },
    { field: 'ReplyFromFRDateTime', headerName: 'Reply from FR DateTime', width: 200 },
    // { field: 'ThreadCount', headerName: 'ThreadCount', width: 200 },
    // { field: 'ThreadId', headerName: 'ThreadId', width: 200 },
    // { field: 'EmailMsgId', headerName: 'EmailMsgId', width: 200 },
    { field: 'NewCertificateNo', headerName: 'NewCertificateNo', width: 200 },
    { field: 'OldCertificateNo', headerName: 'OldCertificateNo', width: 200 },
  ];

  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `Basix_Refund_Certificate_Data_${formattedDateTime}`;

  return (
    <Container sx={{ maxWidth: '1710px !important', marginTop: 2 }}>
      {/* <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          links={[
            
            { name: 'Basix Refund Certificate' },
          ]}
        />
      </Container> */}
      <Typography
        variant="body2"
        sx={{ float: 'right', marginLeft: '200px', color: 'red', marginTop: '10px' }}
      >
        *Red indicates Basix Refund Certificate is not processed.
      </Typography>
      <Typography variant="h4" gutterBottom sx={{ marginTop: '30px', marginBottom: '30px' }}>
        Basix Refund Certificate{' '}
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowClassName={getRowClassName}
        loading={loading}
        pagination
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarExportContainer>
                <GridCsvExportMenuItem
                  options={{
                    fileName: file_Name,
                    utf8WithBom: true,
                  }}
                />
              </GridToolbarExportContainer>
            </GridToolbarContainer>
          ),
        }}
      />
      {showLoadingModal && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}
  >
    <div style={{ textAlign: 'center' }}>
      <CircularProgress />
      <Typography variant="body1" style={{ marginTop: '5px' }}>
        Please wait while the data is being loaded...
      </Typography>
    </div>
  </div>
)}

    </Container>
  );
};

export default RefundJobs;
