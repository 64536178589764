import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Chart from 'src/components/chart';
import { position } from 'stylis';
import Image from '../../components/image';
import './AnalysisCharts.css';

interface Props {
  reportData: any;
}

export default function Analysis({ reportData, saveReportData, setSaveReportData }: any) {
  const heatingTarget = saveReportData?.EnergyHeatingTarget || 0;
  const coolingTarget = saveReportData?.EnergyCoolingTarget || 0;
  const total_target = heatingTarget + coolingTarget;
  const total_proposed = reportData?.projectInfoData?.EnergyTotal || 0;

  const total_percentage = ((total_target - total_proposed) / total_target) * 100;
  const blue_times =
    (((((reportData?.projectInfoData?.EnergyCooling || 0) / 3.6) *
      (reportData?.areasData[0]?.size || 0)) /
      0.04) *
      5) /
    40075;

  const blue_years =
    (((reportData?.projectInfoData?.EnergyCooling || 0) / 3.6) *
      (reportData?.areasData[0]?.size || 0)) /
    (0.1 * 8760);

  const pink_years =
    (((reportData?.projectInfoData?.EnergyHeating || 0) / 3.6) *
      (reportData?.areasData[0]?.size || 0)) /
    (0.1 * 8760);

  const pink_times =
    (((((reportData?.projectInfoData?.EnergyHeating || 0) / 3.6) *
      (reportData?.areasData[0]?.size || 0)) /
      0.04) *
      5) /
    40075;

  // if(total_target <= )
  const [mixedChartSeries, SetMixedChartSeries] = useState<any>([]);
  const [mixedChartOptions, SetMixedChartOptions] = useState<any>();
  const [jobRecordId, SetJobRecordId] = useState<number>(0);

  const [averageGlazingRatio, SetAverageGlazingRatio] = useState<number>(0);

  useEffect(() => {
    if (reportData) {
      if (reportData.zonesData) {
        const zoneData: any[] = reportData.zonesData;
        // Plot Mixed Chart
        plotMixedChart(zoneData, reportData.projectInfoData?.NccType);
        plotWindowFloorAreaRationChart(zoneData);
      }

      if (reportData.windowsDirectionData) {
        const windowsDirectionData: any[] = reportData.windowsDirectionData;
        // Plot Window & Door Orientation Horizontal Bar Chart
        plotWindowDoorBarChart(windowsDirectionData, reportData?.areasData[0].size);
      }

      SetJobRecordId(reportData?.projectInfoData?.job_record_id);
    }
  }, [reportData]);

  function plotMixedChart(zoneData: any[], nccType: string) {
    const totalHeatingArray: number[] = [];
    const totalCoolingArray: number[] = [];
    const roomAreas: number[] = [];
    const labels: string[] = [];

    let maxValue: number = 0;
    let maxAreaValue: number = 0;

    zoneData.map((zone: any) => {
      if (zone.totalHeating > 0 && zone.totalCooling > 0) {
        totalHeatingArray.push(zone.totalHeating);
        totalCoolingArray.push(zone.totalCooling);
        roomAreas.push(zone.area);
        labels.push(zone.zone);

        const value = Math.max(zone.totalHeating, zone.totalCooling);
        if (value > maxValue) {
          maxValue = Math.round(value);
        }

        if (zone.area > maxAreaValue) {
          maxAreaValue = Math.round(zone.area);
        }
      }
      return zone;
    });

    const remainder: number = maxValue % 200;
    if (remainder !== 0) {
      maxValue = maxValue + 200 - remainder;
    }
    maxValue = Math.ceil(maxValue);

    const areaRemainder: number = maxAreaValue % 2;
    if (areaRemainder !== 0) {
      maxAreaValue = maxAreaValue + 2 - areaRemainder;
    }
    maxAreaValue = Math.ceil(maxAreaValue);

    let curveType = 'straight';
    if (nccType === '2019') {
      curveType = 'smooth';
    }

    const series = [
      {
        name: 'Total Heating (MJ)',
        type: 'column',
        data: totalHeatingArray,
      },
      {
        name: 'Total Cooling (MJ)',
        type: 'column',
        data: totalCoolingArray,
      },
      {
        name: 'Room Areas',
        type: 'line',
        data: roomAreas,
      },
    ];
    SetMixedChartSeries(series);

    const options = {
      chart: {
        id: 'mixedChart',
        height: '100%',
        type: 'line',
        stacked: false,
        background: '#ffffff',
        toolbar: {
          show: false,
        },
      },
      colors: ['#ffa5a4', '#ccb9e9', '#f5a96b'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 4],
        curve: curveType,
        colors: ['#c05a57', '#7d629e', '#f5a96b'],
      },
      xaxis: {
        categories: labels,
        labels: {
          rotate: -26,
          rotateAlways: true,
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#808080',
          },
          labels: {
            style: {
              colors: '#808080',
            },
          },
          title: {
            text: 'ENERGY LOADS (MJ)',
            style: {
              color: '#808080',
            },
          },
          tooltip: {
            enabled: true,
          },
          min: 0,
          max: maxValue,
          stepSize: 200,
          decimalsInFloat: 0,
          // tickAmount: maxValue / 200
        },
        {
          show: false,
          seriesName: 'Total Heating (MJ)',
          opposite: false,
        },
        {
          seriesName: 'RoomAreas',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#808080',
          },
          labels: {
            style: {
              colors: '#808080',
            },
          },
          title: {
            text: '',
            style: {
              color: '#808080',
            },
          },
          min: 0,
          max: maxAreaValue,
          stepSize: 2,
          decimalsInFloat: 0,
          // tickAmount: maxAreaValue / 2
        },
      ],
      tooltip: {
        fillSeriesColor: true,
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
        theme: 'dark',
      },
      legend: {
        horizontalAlign: 'center',
        position: 'bottom',
        labels: {
          colors: '#000000',
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: '60%',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          inverseColors: true,
        },
      },
    };
    SetMixedChartOptions(options);
  }

  const [windowDoorSeries, SetWindowDoorSeries] = useState<any>([]);
  const [windowDoorOptions, SetWindowDoorOptions] = useState<any>();

  function plotWindowDoorBarChart(windowsDirectionData: any[], area: number) {
    const windowData: number[] = [];
    const labels: string[] = [];

    let glazing = 0;
    let maxRange = 0;

    windowsDirectionData.map((windowDirection: any) => {
      windowData.push(windowDirection.area);
      if (windowDirection.area > maxRange) {
        maxRange = windowDirection.area;
      }
      labels.push(windowDirection.direction);

      glazing += windowDirection.area;

      return windowDirection;
    });

    const remainder: number = maxRange % 2;
    if (remainder !== 0) {
      maxRange = maxRange + 2 - remainder;
    }
    maxRange = Math.ceil(maxRange);

    windowData.reverse();
    labels.reverse();
    SetWindowDoorSeries([
      {
        name: 'Area',
        data: windowData,
      },
    ]);

    const options = {
      chart: {
        id: 'windowDoorChart',
        type: 'bar',
        toolbar: {
          show: false,
        },
        background: '#ffffff',
      },
      colors: ['#b3a2c7', '#95b3d7', '#31859c', '#d99694'],
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          barHeight: '20%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: labels,
        title: {
          text: 'AREAS (M²)',
          style: {
            fontWeight: '100',
          },
        },
        max: maxRange,
        min: 0,
        stepSize: 2,
        tickAmount: maxRange / 2,
        decimalsInFloat: 0,
      },
      legend: {
        show: false,
      },
      tooltip: {
        fillSeriesColor: true,
        theme: 'dark',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };
    SetWindowDoorOptions(options);

    // Average Glazing Ratio
    if (area > 0) {
      const ratio = (glazing / area) * 100;
      SetAverageGlazingRatio(ratio);
    }
  }

  const [windowFloorAreaRatioSeries, SetWindowFloorAreaRatioSeries] = useState<number[]>([]);
  const [windowFloorAreaRatioOptions, SetWindowFloorAreaRatioOptions] = useState<any>();

  function plotWindowFloorAreaRationChart(zoneData: any[]) {
    const zoneDataDesc = [...zoneData].sort((a, b) => b.area - a.area);

    const areaRatios: number[] = [];
    const label: string[] = [];
    let index: number = 0;
    zoneDataDesc.map((zone: any) => {
      if (zone.areaRatio > 0 && index < 8) {
        areaRatios.push(zone.areaRatio);
        label.push(zone.zone);
        index += 1;
      }
      return zone;
    });

    SetWindowFloorAreaRatioSeries(areaRatios);

    // const value = (opts.w.config.labels[opts.seriesIndex]+" "+opts.w.config.series[opts.seriesIndex] as string);
    const windowFloorOptions = {
      chart: {
        id: 'windowFloorChart',
        toolbar: {
          show: false,
        },
        height: '100%',
      },
      labels: label,
      responsive: [
        {
          breakpoint: 480,
        },
      ],
      legend: {
        show: false,
      },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
        },
        formatter: (val: any, opts: any) => [
          `${opts.w.config.labels[opts.seriesIndex]}`,
          `${opts.w.config.series[opts.seriesIndex]}`,
        ],
        style: {
          fontWeight: 'bold',
          colors: ['#808080'],
        },
      },
      fill: {
        colors: [
          '#4F81BD',
          '#C0504D',
          '#9BBB59',
          '#8064A2',
          '#4BACC6',
          '#F79646',
          '#2C4D75',
          '#772C2A',
        ],
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: 'Top 8 Window to Floor\nArea Ratios',
                color: '#ffffff',
                fontSize: '16px',
                formatter: (w: any) =>
                  w.globals.seriesTotals.reduce((a: any, b: any) => parseFloat((a + b).toFixed(2))),
              },
            },
          },
          dataLabels: {
            offset: 50,
          },
        },
      },
      colors: [
        '#4F81BD',
        '#C0504D',
        '#9BBB59',
        '#8064A2',
        '#4BACC6',
        '#F79646',
        '#2C4D75',
        '#772C2A',
      ],
    };
    SetWindowFloorAreaRatioOptions(windowFloorOptions);
  }

  function exportAsImage(chartId: string) {
    const chart = ApexCharts.getChartByID(chartId);
    chart?.dataURI().then((base64: any) => {
      console.log('Base 64 :: ', base64.imgURI);
    });
  }

  if (jobRecordId <= 0) return <></>;

  return (
    <Grid item xs={12} sm={12} md={9}>
      {/* <Button
                        variant="contained"
                        size="medium"
                        sx={{ borderRadius: 0 }}
                        onClick={() => exportAsImage('windowFloorChart')}
                    >
                        Download PDF
                </Button> */}
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE ANALYSIS</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />
        {total_proposed <= total_target ? (
          <Stack textAlign="center" sx={{ fontSize: '3rem' }}>
            <Box sx={{ color: '#17a986', typography: 'caption' }}>
              <Typography typography="h2" sx={{ fontStyle: 'italic' }}>
                This dwelling exceeds minimal thermal
              </Typography>
              <Typography typography="h2" sx={{ fontStyle: 'italic' }}>
                comfort compliance targets by {total_percentage.toFixed(1)} %
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Stack textAlign="center">
            <Box sx={{ color: '#17a986', typography: 'caption' }}>
              <Typography typography="h3">
                This dwelling falls short of the minimum thermal
              </Typography>
              <Typography typography="h3">
                comfort compliance targets by {total_percentage.toFixed(1)} %
              </Typography>
            </Box>
          </Stack>
        )}

        {/* <Stack textAlign="center">
          <Box sx={{ color: '#17a986', typography: 'caption' }}>
            <Typography typography="h3">This dwelling exceeds minimal thermal </Typography>
            <Typography typography="h3">comfort complience targets by 3.3%</Typography>
          </Box>
        </Stack> */}

        <Stack spacing={6} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={20} sx={{ marginTop: '20px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              textAlign="center"
              sx={{ minWidth: 500, flex: '0 1', color: '#7030A0' }}
            >
              <Typography typography="h5" variant="subtitle2">
                Cooling the main areas in this home each year uses as much energy as walking around
                the Earth {blue_times.toFixed(1)} times, or watching Netflix continuously for
                approximately {blue_years.toFixed(1)} years.
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ minWidth: 300, flex: '1 1' }}>
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src="/assets/images/about/Image1.png"
                sx={{ maxWidth: '100%', height: '150px' }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={6} sx={{ px: 3 }} alignItems="start">
          <Stack direction="row" alignItems="center" spacing={20} sx={{ marginTop: '20px' }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ minWidth: 300, flex: '1 1', marginLeft: '10px' }}
            >
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src="/assets/images/about/Image2.png"
                sx={{ maxWidth: '100%', height: '145px', marginLeft: '60px' }}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              textAlign="center"
              sx={{ minWidth: 500, flex: '0 1', color: '#D17B79' }}
            >
              <Typography typography="h5" variant="subtitle2">
                Heating the main areas in this home uses the same amount of energy every year as
                watching Netflix continuously for approximately {pink_years.toFixed(1)} years, or
                walking around the Earth {pink_times.toFixed(1)} times.
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack textAlign="center">
          <Box sx={{ color: '#17a986', typography: 'caption', marginTop: '40px' }}>
            <Typography typography="h4">Room-by-Room Energy Use Analysis</Typography>
          </Box>
        </Stack>

        <Stack textAlign="center">
          <Box sx={{ typography: 'h6', fontWeight: '100' }}>
            This graph shows the annual energy needed to heat and cool each room to a comfortable
            temperature. The taller the bar means the room requires either more warmth to stay cozy
            during cold weather, or energy to remain cool when its hot outside. The line represents
            what would be considered good energy use for the size of each room. When bars rise above
            this line, it means the room is using more energy than expected, highlighting
            opportunities for energy-efficient improvements. The goal is shorter bars, indicating
            your home is comfortably heated and cooled without excessive energy use. Rooms with the
            tallest bars are key areas to focus on for energy-saving upgrades.
          </Box>
        </Stack>

        <Grid item xs={12} sm={12} md={3} border="1px solid" sx={{ marginTop: '20px' }}>
          <Chart type="line" series={mixedChartSeries} options={mixedChartOptions} height={500} />
        </Grid>

        <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '22px' }}>
              <img
                src="./ea-report-footer.png"
                alt="Logo"
                style={{ width: '20vh', height: '10vh' }}
              />
            </Box>
            <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
            <Stack direction="column" sx={{ marginTop: '45px' }}>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                energy@energyadvance.com.au
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                ACN: 60 9332014 | 1300 850 228
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: '#919eab3d',
              p: '0.5rem',
              padding: '3rem',
            }}
          >
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>COMPLIANCE STAMP NOT </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}
