import {
  Avatar,
  Box,
  Button,
  // ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  // CardMedia,
  // CircularProgress,
  // Container,
  // Divider,
  Grid,
  // InputAdornment,
  // LinearProgress,
  // MenuItem,
  // Select,
  Stack,
  // Tab,
  // Tabs,
  TextField,
  Typography,
  // makeStyles,
  styled,
  // useTheme,
  // Paper,
  // TextFieldProps,
  // ToggleButtonGroup,
  // ToggleButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import {
  fNumber,
  // fShortenNumber
} from 'src/utils/formatNumber';
// import {
//   // orderBy,
//   // round
// } from 'lodash';
// import { alpha } from '@mui/material/styles';
import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';
import { useAuthContext } from 'src/auth/useAuthContext';
import _mock from // randomNumber,
// randomNumberRange
'src/_mock';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Iconify from 'src/components/iconify';
// import Editor from 'src/components/editor/Editor';
import {
  // CSSProperties,
  // Fragment,
  useEffect,
  // useRef,
  useState,
} from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { useNavigate } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import { textAlign } from '@mui/system';
import axios from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import Chart, { useChart } from '../../components/chart';
import OccupencyRate from './OccupencyRate';

interface JobInfo {
  id: string;
  JobRecordID: number;
  JobNumber: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: number;
  JobPostAssessmentFlag: boolean;
  ReqName: string;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
}

export default function AssessorDashBoard() {
  // const navigate = useNavigate();
  const [value, setValue] = useState<Date | null>(new Date());
  // const theme = useTheme();
  // const isDarkMode = theme.palette.mode === 'dark';
  // const cardRef = useRef({});
  const [jobs, setJobs] = useState<JobInfo[]>([]);
  const [assignedCounts, setAssignedCount] = useState<any>(null);
  const [assignedUrgentCounts, setAssignedUrgentCount] = useState<any>(null);
  const [assessmentStartedCount, setAssessmentStartedCount] = useState<any>(null);
  const [assessmentCompleteCount, setAssessmentCompleteCount] = useState<any>(null);
  const [newCount, setNewCount] = useState<any>(null);
  const [assessmentStartedUrgentCount, setAssessmentStartedUrgentCount] = useState<any>(null);
  const [completedCount, setCompletedCount] = useState<any>(null);
  const [completedUrgentCount, setCompletedUrgentCount] = useState<any>(null);
  const [onHoldCount, setOnHoldCount] = useState<any>(null);
  const [onHoldCountUrgentCount, setOnHoldUrgentCount] = useState<any>(null);
  const { user } = useAuthContext();
  const [assessorId, setAssessorId] = useState<any>(user?.ID);
  const fullName = `${user?.UserFname ?? ''} ${user?.UserLname ?? ''}`;
  const [assessorName, setAssessorName] = useState(fullName);
  const [addNotes, setAddNotes] = useState(false);
  // const [addReminder, setAddReminder] = useState(false);
  const [info, setInfo] = useState('');
  const [note, setNote] = useState<any[]>([]);
  // const [reminder, setReminder] = useState<string[]>([]);
  // const [selectedCardIndex, setSelectedCardIndex] = useState<number>(-1);
  const { enqueueSnackbar } = useSnackbar();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editedNote, setEditedNote] = useState<any>(null);
  const [editedNoteValue, setEditedNoteValue] = useState('');

  const series = [
    newCount,
    assignedCounts,
    assessmentStartedCount,
    assessmentCompleteCount,
    onHoldCount,
  ];

  const chartOptions = useChart({
    labels: ['New', 'Assigned', 'Assessment Started', 'Workflow to Action', 'On Hold'],
    legend: {
      position: 'top',
      itemMargin: {
        vertical: 8,
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
  });

  // const codeSnippetStyle: CSSProperties = {
  //   fontFamily: 'Courier New, monospace',
  //   backgroundColor: isDarkMode ? theme.palette.grey[800] : theme.palette.grey[200],
  //   // padding: '10px',
  //   paddingLeft: '10px',
  //   margin: '0',
  //   whiteSpace: 'pre-wrap',
  //   lineHeight: '1.5',
  //   overflowX: 'auto',
  //   boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
  //   width: '100%',
  //   height: '200px',
  // };
  // const card: CSSProperties = {
  //   // backgroundColor:isDarkMode ? theme.palette.grey[600] : theme.palette.grey[400],
  //   display: 'flex',
  //   borderRadius: 0,
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // };
  // const count: CSSProperties = {
  //   marginTop: 1,
  //   fontSize: '28px',
  //   fontWeight: 'bold',
  // };
  // const label: CSSProperties = {
  //   fontSize: '16px',
  //   marginTop: '8px',
  // };
  // const [currentTab, setCurrentTab] = useState('one');
  // const interpolateColor = (
  //   color1: { r: any; g: any; b: any },
  //   color2: { r: any; g: any; b: any },
  //   ratio: number
  // ) => {
  //   const r = Math.round(color1.r + (color2.r - color1.r) * ratio);
  //   const g = Math.round(color1.g + (color2.g - color1.g) * ratio);
  //   const b = Math.round(color1.b + (color2.b - color1.b) * ratio);
  //   return `rgb(${r},${g},${b})`;
  // };

  // const getGradientColorBySatisfactionRate = (rate: number) => {
  //   const startColor = { r: 100, g: 0, b: 0 };
  //   const endColor = { r: 0, g: 100, b: 0 };

  //   const ratio = rate / 100;
  //   const color = interpolateColor(startColor, endColor, ratio);
  //   return color;
  // };
  // const list = [...Array(20)].map((country, index) => ({
  //   id: _mock.id(index),
  //   name: _mock.name.fullName(index),
  //   android: _mock.number.age(index) - 20,
  //   windows: round(_mock.number.rating(index)),
  //   apple: _mock.number.age(index + 10),
  //   flag: _mock.image.random(index + 5),
  // }));
  // const chart = {
  //   categories: ['0-3', '3-6', '6-9', '9-12', '<12'],
  //   colors: [theme.palette.primary.main, theme.palette.warning.main],
  //   data: [
  //     { name: 'New', data: [31, 20, 3, 10, 4] },
  //     { name: 'Assigned', data: [56, 31, 7, 12, 6] },
  //     { name: 'In Progress', data: [77, 56, 13, 34, 10] },
  //     { name: 'All', data: [100, 80, 25, 60, 25] },
  //   ],
  // };
  // const {
  //   // categories,
  //   // colors,
  //   // data
  // } = chart;

  // const chartOptionBar = useChart({
  //   colors,
  //   stroke: {
  //     show: true,
  //     width: 4,
  //     colors: ['transparent'],
  //   },
  //   xaxis: {
  //     categories,
  //     title: {
  //       text: 'Aging Days',
  //       style: {
  //         fontSize: '16px',
  //       },
  //     },

  //     labels: {
  //       show: true,
  //       style: {
  //         fontSize: '13px',
  //       },
  //     },
  //   },
  //   yaxis: {
  //     title: {
  //       text: 'No. of Jobs',
  //       style: {
  //         fontSize: '16px',
  //       },
  //     },
  //     labels: {
  //       show: true,
  //       style: {
  //         fontSize: '13px',
  //       },
  //     },
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: (v: number) => `$${v}`,
  //     },
  //     style: {
  //       fontSize: '13px',
  //     },
  //   },
  // });
  // const GB = 1000000000 * 24;
  // const [selectedValue, setSelectedValue] = useState<any>(statusCountsArray[0]);
  // const handleChange = (hello: any, sel: any) => {
  //   statusCountsArray.forEach((valu) => {
  //     if (valu.timeFrame === sel) {
  //       setSelectedValue(valu);
  //     }
  //   });
  // };
  useEffect(() => {
    axios
      .get(`/core/getJobsByAssessor?Id=${assessorId}`)
      .then((response) => {
        console.log('Response from backend:', response.data);
        const modifiedData = response.data.map(({ JobCreatedAt, JobRecordID, ...job }: any) => ({
          id: JobRecordID,
          JobCreatedAt: new Date(JobCreatedAt),
          ...job,
        }));
        setJobs(modifiedData as JobInfo[]);
        console.log('Jobs of a particular assessor =', modifiedData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [assessorId]);

  // const handleClick = () => {
  //   navigate('/profile');
  // };

  const getNoteByAssessor = () => {
    axios
      .get(`/core/getNotesByAssessorId?Id=${assessorId}`)
      .then((response) => {
        console.log('Response from backend for notes:', response.data);
        setNote(response.data);
        console.log('Notes of a particular assessor =', response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    getNoteByAssessor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessorId]);

  useEffect(() => {
    setNewCount(jobs.filter((item) => item.JobStatus === 'new').length);
    setAssessmentCompleteCount(
      jobs.filter((item) => item.JobStatus === 'workflow to action').length
    );
    setAssignedCount(jobs.filter((item) => item.JobStatus === 'assigned').length);

    setAssignedUrgentCount(
      jobs.filter((item) => item.JobStatus === 'assigned' && item.JobIsUrgent === 'urgent').length
    );
    setAssessmentStartedCount(
      jobs.filter((item) => item.JobStatus === 'assessment started').length
    );
    setAssessmentStartedUrgentCount(
      jobs.filter(
        (item) => item.JobStatus === 'assessment started' && item.JobIsUrgent === 'urgent'
      ).length
    );
    setCompletedCount(jobs.filter((item) => item.JobStatus === 'marked as complete').length);
    setCompletedUrgentCount(
      jobs.filter(
        (item) => item.JobStatus === 'marked as complete' && item.JobIsUrgent === 'urgent'
      ).length
    );
    setOnHoldCount(jobs.filter((item) => item.JobStatus === 'on hold').length);
    setOnHoldUrgentCount(
      jobs.filter((item) => item.JobStatus === 'on hold' && item.JobIsUrgent === 'urgent').length
    );
  }, [jobs]);

  const handleAddNote = () => {
    setAddNotes(true);
  };

  // const handleAddReminder = () => {
  //   setAddReminder(true);
  //   setAddReminder(true);
  // };

  const handleClose = () => {
    setAddNotes(false);
    // setAddReminder(false);
  };

  // const handleSaveNotes = () => {
  //     console.log("Info = " , info)
  //     if (selectedCardIndex !== -1) {
  //         const updatedCards = [...note];
  //         updatedCards[selectedCardIndex] = info.trim();
  //         setNote(updatedCards);
  //         setSelectedCardIndex(-1);
  //     } else {
  //         setNote([...note, info.trim()]);
  //         console.log("Notes = " , note)
  //     }
  //     setInfo('');
  //     handleClose();
  // };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleOpenEditDialog = (note: any) => {
    setEditedNoteValue(note.notes);
    setEditedNote(note);
    setIsEditDialogOpen(true);
  };

  const handleDeleteNote = (noteToDelete: any) => {
    console.log('Note to delete = ', noteToDelete);
    axios
      .delete(
        `/core/deleteNoteForAssessor?Id=${noteToDelete.user_id}&noteId=${noteToDelete.note_id}`
      )
      .then((response) => {
        console.log('Response from backend:', response.data);
        enqueueSnackbar(response.data);
        getNoteByAssessor();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleSaveEditedNote = () => {
    const payload = {
      notes: editedNoteValue,
    };
    axios
      .post(
        `/core/updateNoteForAssessor?Id=${editedNote.user_id}&noteId=${editedNote.note_id}`,
        payload
      )
      .then((response) => {
        console.log('Response from backend:', response.data);
        enqueueSnackbar(response.data);
        getNoteByAssessor();
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setIsEditDialogOpen(false);
  };

  const handleCancelEdit = () => {
    // Discard changes made to the edited note
    setEditedNote('');

    // Close the edit dialog
    setIsEditDialogOpen(false);
  };

  const handleSaveNotes = () => {
    console.log('info in save notes = ', info);
    const payload = {
      notes: info,
    };
    axios
      .post(`/core/addNoteForAssessor?Id=${assessorId}`, payload)
      .then((response) => {
        console.log('Response from backend:', response.data);
        enqueueSnackbar(response.data);
        getNoteByAssessor();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    handleClose();
  };

  // const handleSaveReminder = () => {
  //   if (selectedCardIndex !== -1) {
  //     const updatedCards = [...reminder];
  //     updatedCards[selectedCardIndex] = info.trim();
  //     setReminder(updatedCards);
  //     setSelectedCardIndex(-1);
  //   } else {
  //     setReminder([...reminder, info.trim()]);
  //   }
  //   setInfo('');
  //   handleClose();
  // };

  // const handleEditReminder = (index: any) => {
  //   setSelectedCardIndex(index);
  //   setInfo(reminder[index]);
  //   handleAddReminder();
  // };

  // const handleDeleteReminder = (index: any) => {
  //   const updatedCards = [...reminder];
  //   updatedCards.splice(index, 1);
  //   setReminder(updatedCards);
  // };

  const handleInputChange = (event: any) => {
    setInfo(event.target.value);
  };

  return (
    <>
      <Grid container sx={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
        <Grid item xs={12} sm={12} md={10}>
          <Grid container direction="column">
            <Grid item xs={12} sm={12} md={12}>
              <Grid container direction="row" spacing={2} sx={{ margin: 1, marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={2.9} sx={{ alignItems: 'center' }}>
                  <Card
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      p: 7,
                      marginLeft: '-20px',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        Assessment Startedss/
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      {/* <Typography variant="h3">
                  {fNumber(jobStatusCounts[0].TotalCount)} /{' '}
                  <span style={{ fontSize: '20px', color: '#ff5630' }}>
                    {jobStatusCounts[0].UrgentCount}
                  </span>
                </Typography> */}

                      {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[0].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[0].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}

                      {assessmentStartedCount !== null ? (
                        <Typography variant="h3">
                          {fNumber(assessmentStartedCount)} /{' '}
                          <span style={{ fontSize: '30px', color: '#ff5630' }}>
                            {assessmentStartedUrgentCount}
                          </span>
                        </Typography>
                      ) : (
                        <Typography variant="h6">Loading...</Typography>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2.9} sx={{ alignItems: 'center' }}>
                  <Card sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', p: 7 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1">
                      Assigned (Assessment Not Started)<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[1].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[1].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}
                      {assignedCounts !== null ? (
                        <Typography variant="h3">
                          {fNumber(assignedCounts)} /{' '}
                          <span style={{ fontSize: '20px', color: '#ff5630' }}>
                            {assignedUrgentCounts}
                          </span>
                        </Typography>
                      ) : (
                        <Typography variant="h6">Loading...</Typography>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2.9} sx={{ alignItems: 'center' }}>
                  <Card sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', p: 7 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1">
                        Marked as Complete /
                        <span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[2].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[2].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}

                      {completedCount !== null ? (
                        <Typography variant="h3">
                          {fNumber(completedCount)} /{' '}
                          <span style={{ fontSize: '20px', color: '#ff5630' }}>
                            {completedUrgentCount}
                          </span>
                        </Typography>
                      ) : (
                        <Typography variant="h6">Loading...</Typography>
                      )}
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={2.9} sx={{ alignItems: 'center' }}>
                  <Card sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', p: 7 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1">
                        On Hold /<span style={{ fontSize: '15px', color: '#ff5630' }}>Urgent</span>
                      </Typography>
                      {/* {jobStatusCounts !== null && jobStatusCounts.length > 0 ? (
                  <Typography variant="h3">
                    {fNumber(jobStatusCounts[3].TotalCount)} /{' '}
                    <span style={{ fontSize: '20px', color: '#ff5630' }}>
                      {jobStatusCounts[3].UrgentCount}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h6">Loading...</Typography>
                )} */}
                      {onHoldCount !== null ? (
                        <Typography variant="h3">
                          {fNumber(onHoldCount)} /{' '}
                          <span style={{ fontSize: '20px', color: '#ff5630' }}>
                            {onHoldCountUrgentCount}
                          </span>
                        </Typography>
                      ) : (
                        <Typography variant="h6">Loading...</Typography>
                      )}
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={10}>
              <Grid direction="row" container spacing={1}>
                <Grid item xs={12} sm={12} md={3}>
                  <Card sx={{ maxWidth: 352, width: 'auto', borderRadius: 2 }}>
                    <CardHeader title="Progress Chart" />
                    <StyledChartWrapper dir="ltr">
                      <Chart type="pie" series={series} options={chartOptions} height={492} />
                    </StyledChartWrapper>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={4} direction="row">
                  <Card sx={{ display: 'flex', p: 7, height: '100%' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <Typography variant="h4">Statistics</Typography>
                      </div>
                      <div style={{ maxHeight: '300px', overflow: 'auto', width: '100%' }}>
                        <Typography variant="subtitle2">
                          Overall Cancelled
                          <span style={{ float: 'right', marginRight: '20px' }}>22</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Turn Around time
                          <span style={{ float: 'right', marginRight: '20px' }}>1:10:25</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Urgent Ticket Completed
                          <span style={{ float: 'right', marginRight: '20px' }}>18</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Overall Completed
                          <span style={{ float: 'right', marginRight: '20px' }}>10</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Overall Cancelled
                          <span style={{ float: 'right', marginRight: '20px' }}>22</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Turn Around time
                          <span style={{ float: 'right', marginRight: '20px' }}>1:10:25</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Urgent Ticket Completed
                          <span style={{ float: 'right', marginRight: '20px' }}>18</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Overall Cancelled
                          <span style={{ float: 'right', marginRight: '20px' }}>22</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Turn Around time
                          <span style={{ float: 'right', marginRight: '20px' }}>1:10:25</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Urgent Ticket Completed
                          <span style={{ float: 'right', marginRight: '20px' }}>18</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Overall Completed
                          <span style={{ float: 'right', marginRight: '20px' }}>10</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Overall Cancelled
                          <span style={{ float: 'right', marginRight: '20px' }}>22</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Turn Around time
                          <span style={{ float: 'right', marginRight: '20px' }}>1:10:25</span>
                        </Typography>
                        <Typography variant="subtitle2">
                          Urgent Ticket Completed
                          <span style={{ float: 'right', marginRight: '20px' }}>18</span>
                        </Typography>
                      </div>
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={4.8}>
                  <Card sx={{ display: 'flex', p: 7, height: '100%' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <div style={{ textAlign: 'center' }}>
                        <Typography variant="h4">Notes</Typography>
                      </div>
                      <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                        {note.map((cardInfo, index) => (
                          <Card
                            key={index}
                            style={{ width: '90%', marginTop: '10px', backgroundColor: '#FCE5CD' }}
                          >
                            <CardContent sx={{ color: 'black' }}>{cardInfo.notes}</CardContent>
                            <div style={{ position: 'absolute', top: '15px', right: '5px' }}>
                              <IconButton onClick={() => handleOpenEditDialog(cardInfo)}>
                                <EditIcon style={{ color: 'black' }} />
                              </IconButton>
                              <IconButton onClick={() => handleDeleteNote(cardInfo)}>
                                <DeleteIcon style={{ color: 'black' }} />
                              </IconButton>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={1.8}>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleAddNote}
                    sx={{ marginLeft: '20px', height: '50px', marginTop: '20px', width: '100%' }}
                  >
                    Add Notes
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={1.8}>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{ marginLeft: '20px', height: '50px', marginTop: '20px', width: '100%' }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2} marginTop={2.5}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Card sx={{ display: 'flex', alignItems: 'center', p: 3, height: '400px' }}>
                <Stack
                  spacing={2}
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={_mock.image.random(7)}
                    sx={{ boxShadow: 'z23', width: 150, height: 150, borderRadius: 1 }}
                    variant="square"
                  />
                  <TextMaxLine line={1}>
                    <Label sx={{ marginTop: 0.1, width: '230px', fontSize: 14 }}>
                      {assessorName}
                    </Label>
                  </TextMaxLine>

                  <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                    Role : Assessor
                  </Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      inputFormat="DD-MM-YYYY HH:mm:ss"
                      renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                      label="Last Login"
                      disabled
                      value={Date.now()}
                      onChange={setValue}
                    />
                  </LocalizationProvider>
                </Stack>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ width: '100%' }}>
                <CardHeader title="Occupency Rate" subheader="" sx={{ textAlign: 'center' }} />
                <Stack spacing={1} sx={{ p: 3, padding: '17px' }}>
                  <Scrollbar>
                    <OccupencyRate
                      // total={GB}
                      chart={{
                        series: 88,
                      }}
                      total={0}
                      data={[]}
                    />
                  </Scrollbar>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isEditDialogOpen && (
        <Dialog open={isEditDialogOpen} fullWidth>
          <DialogTitle>Edit Note</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="info-input"
              label="Note"
              type="text"
              fullWidth
              value={editedNoteValue}
              onChange={(e) => setEditedNoteValue(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelEdit}>Cancel</Button>
            <Button onClick={() => handleSaveEditedNote()}>Save</Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog open={addNotes} onClose={handleClose} fullWidth>
        <DialogTitle>Add Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="info-input"
            label="Note"
            type="text"
            fullWidth
            value={info}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveNotes} color="primary" disabled={!info.trim()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
// const CustomSmallSelect = styled((props: TextFieldProps) => (
//   <TextField select SelectProps={{ native: true }} {...props} />
// ))(({ theme }) => ({
//   '& fieldset': {
//     display: 'none',
//   },
//   '& select': {
//     ...theme.typography.subtitle2,
//     padding: theme.spacing(0.5, 0, 0.5, 1),
//     paddingRight: '28px !important',
//   },
//   '& .MuiOutlinedInput-root': {
//     borderRadius: Number(theme.shape.borderRadius) * 0.75,
//     backgroundColor: alpha(theme.palette.grey[500], 0.08),
//   },
// }));
// const TABS = [
//   {
//     value: 'one',
//     icon: <Iconify icon="eva:phone-call-fill" width={24} />,
//     label: 'Item One',
//   },
//   {
//     value: 'two',
//     icon: <Iconify icon="eva:heart-fill" width={24} />,
//     label: 'Item Two',
//   },
//   {
//     value: 'three',
//     label: 'Item Three',
//     disabled: true,
//   },
//   {
//     value: 'four',
//     icon: <Iconify icon="eva:headphones-fill" width={24} />,
//     label: 'Item Four',
//   },
// ];

// const assessors = [
//   {
//     name: 'John Alice Smith',
//     open: 10,
//     solved: 5,
//     satisfactionRate: 10,
//   },
//   {
//     name: 'Michael Emma Johnson',
//     open: 20,
//     solved: 15,
//     satisfactionRate: 50,
//   },
//   {
//     name: 'David Sophia Brown',
//     open: 5,
//     solved: 2,
//     satisfactionRate: 100,
//   },
//   {
//     name: 'Daniel Olivia Davis',
//     open: 8,
//     solved: 4,
//     satisfactionRate: 85,
//   },
//   {
//     name: 'James Emily Wilson',
//     open: 12,
//     solved: 10,
//     satisfactionRate: 70,
//   },
//   {
//     name: 'Benjamin Mia Anderson',
//     open: 3,
//     solved: 1,
//     satisfactionRate: 95,
//   },
//   {
//     name: 'Alexander Ava Taylor',
//     open: 7,
//     solved: 6,
//     satisfactionRate: 82,
//   },
//   {
//     name: 'William Alice Clark',
//     open: 15,
//     solved: 12,
//     satisfactionRate: 88,
//   },
//   {
//     name: 'Sophia John Martin',
//     open: 25,
//     solved: 20,
//     satisfactionRate: 78,
//   },
//   {
//     name: 'Daniel Emma Smith',
//     open: 18,
//     solved: 15,
//     satisfactionRate: 92,
//   },
//   {
//     name: 'James Olivia Johnson',
//     open: 9,
//     solved: 7,
//     satisfactionRate: 68,
//   },
//   {
//     name: 'Michael Emily Brown',
//     open: 22,
//     solved: 18,
//     satisfactionRate: 84,
//   },
//   {
//     name: 'David Sophia Davis',
//     open: 13,
//     solved: 10,
//     satisfactionRate: 76,
//   },
//   {
//     name: 'Benjamin Mia Wilson',
//     open: 6,
//     solved: 4,
//     satisfactionRate: 90,
//   },
//   {
//     name: 'Alexander Ava Anderson',
//     open: 30,
//     solved: 25,
//     satisfactionRate: 72,
//   },
// ];
const CHART_HEIGHT = 459;
const LEGEND_HEIGHT = 70;

const StyledChartWrapper = styled('div')(({ theme }: any) => ({
  height: CHART_HEIGHT,

  // marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-graphical': {
    transform: 'translate(0, 0) !important',
  },

  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center !important',
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// const StyledBlock = styled((props: StackProps) => (
//   <Stack direction="row" alignItems="center" {...props} />
// ))({
//   minWidth: 72,
//   flex: '1 1',
// });

// const StyledItemIcon = styled(Iconify)(({ theme }) => ({
//   width: 16,
//   height: 16,
//   marginRight: theme.spacing(0.5),
// }));

// ----------------------------------------------------------------------

// type ItemProps = {
//   id: string;
//   name: string;
//   android: number;
//   windows: number;
//   apple: number;
//   flag: string;
// };

// type CountryItemProps = {
//   country: ItemProps;
// };

// function CountryItem({ country }: CountryItemProps) {
//   return (
//     <Stack direction="row" alignItems="center" spacing={2}>
//       <StyledBlock sx={{ minWidth: 120 }}>
//         {/* <Image disabledEffect alt={country.name} src={country.flag} sx={{ width: 28, mr: 1 }} /> */}
//         <Avatar src={country.flag} sx={{ marginRight: 1 }} />
//         <Typography variant="subtitle2">{country.name}</Typography>
//       </StyledBlock>

//       <StyledBlock>
//         <StyledItemIcon icon="carbon:in-progress" />
//         <Typography variant="body2">{fShortenNumber(country.android)}</Typography>
//       </StyledBlock>

//       <StyledBlock>
//         <StyledItemIcon icon="ic:baseline-assessment" />
//         <Typography variant="body2">{fShortenNumber(country.windows)}</Typography>
//       </StyledBlock>

//       <StyledBlock sx={{ minWidth: 88 }}>
//         <StyledItemIcon icon="carbon:task-complete" color="primary" />
//         {/* <Iconify icon="carbon:task-complete" color='green' width={10} /> */}
//         <Typography variant="body2">{fShortenNumber(country.apple)}</Typography>
//       </StyledBlock>
//     </Stack>
//   );
// }

// const _appAuthors = [...Array(30)].map((_, index) => ({
//   id: _mock.id(index),
//   name: _mock.name.fullName(index),
//   avatar: _mock.image.random(index),
//   favourite: _mock.number.percent(index),
//   android: _mock.number.age(index) - 20,
//   windows: round(_mock.number.rating(index)),
//   apple: _mock.number.age(index + 10),
// }));

// type AuthorItemProps = {
//   author: AuthoItemProps;
//   index: number;
// };

// type AuthoItemProps = {
//   id: string;
//   name: string;
//   avatar: string;
//   favourite: number;
//   android: number;
//   windows: number;
//   apple: number;
// };

// function AuthorItem({ author, index }: AuthorItemProps) {
//   return (
//     <Stack direction="row" alignItems="center" spacing={2}>
//       <Avatar alt={author.name} src={author.avatar} />

//       <Box sx={{ flexGrow: 1 }}>
//         <Typography variant="subtitle2">{author.name}</Typography>
//         <Typography
//           variant="caption"
//           sx={{
//             mt: 0.5,
//             display: 'flex',
//             alignItems: 'center',
//             color: 'text.secondary',
//           }}
//         >
//           In Progress :{author.android} | Completed :{author.windows}{' '}
//         </Typography>

//         <Typography
//           variant="caption"
//           sx={{
//             mt: 0.5,
//             display: 'flex',
//             alignItems: 'center',
//             color: 'text.secondary',
//           }}
//         >
//           Occupancy:
//           {fShortenNumber(author.favourite)}
//           <Iconify icon="fa-solid:percentage" width={16} sx={{ mr: 0.5 }} />
//         </Typography>
//       </Box>

//       {/* <Iconify
//         icon="ant-design:trophy-filled"
//         sx={{
//           p: 1,
//           width: 40,
//           height: 40,
//           borderRadius: '50%',
//           color: 'primary.main',
//           bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
//           ...(index === 1 && {
//             color: 'info.main',
//             bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
//           }),
//           ...(index === 2 && {
//             color: 'error.main',
//             bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
//           }),
//         }}
//       /> */}
//     </Stack>
//   );
// }
// const statusCountsArray = [
//   {
//     timeFrame: 'Today',
//     statusCounts: {
//       inProgress: 3,
//       onHold: 1,
//       complete: 2,
//       assigned: 0,
//     },
//   },
//   {
//     timeFrame: 'Yesterday',
//     statusCounts: {
//       inProgress: 2,
//       onHold: 2,
//       complete: 1,
//       assigned: 1,
//     },
//   },
//   {
//     timeFrame: 'Week',
//     statusCounts: {
//       inProgress: 5,
//       onHold: 3,
//       complete: 7,
//       assigned: 2,
//     },
//   },
//   {
//     timeFrame: 'Month',
//     statusCounts: {
//       inProgress: 10,
//       onHold: 5,
//       complete: 12,
//       assigned: 8,
//     },
//   },
//   {
//     timeFrame: 'All',
//     statusCounts: {
//       inProgress: 50,
//       onHold: 20,
//       complete: 60,
//       assigned: 30,
//     },
//   },
// ];
