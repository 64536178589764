// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Tooltip, Box } from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
import Iconify from 'src/components/iconify';
import { bgBlur } from '../../../utils/cssStyles';
//
import { IconButtonAnimate } from '../../animate';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onToggle: VoidFunction;
};

export default function ToggleButton({ open, onToggle }: Props) {
  const theme = useTheme();
  const { user } = useAuthContext();

  // Check if user is logged in
  if (!user) return null;

  return (
    <Box
      sx={{
        p: 0.5,
        right: 24,
        bottom: 90,
        zIndex: 999,
        position: 'fixed',
        borderRadius: '50%',
        boxShadow: `-12px 12px 32px -4px ${alpha(
          theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
          0.36
        )}`,
        ...bgBlur({ color: theme.palette.background.default }),
      }}
    >
      <Tooltip title="InfoBot" placement="left">
        <IconButtonAnimate color="primary" onClick={onToggle} sx={{ p: 1.25 }}>
          <Iconify icon="fluent-mdl2:chat-bot" style={{ fontSize: '2rem' }} />
          {/* <SvgColor src="/assets/icons/setting/ic_setting.svg" /> */}
        </IconButtonAnimate>
      </Tooltip>
    </Box>
  );
}
