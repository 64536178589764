import {
  // Avatar,
  // Box,
  Button,
  // TextField,
  // ButtonGroup,
  Card,
  CardContent,
  // Divider,
  // Fab,
  // FormControl,
  // FormControlLabel,
  // FormLabel,
  Grid,
  // IconButton,
  // InputLabel,
  // NativeSelect,
  // Radio,
  // RadioGroup,
  Typography,
  // Select,
  // MenuItem,
  // ListItemText,
  // Checkbox,
  Stack,
  Tooltip,
} from '@mui/material';
// import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import DateRangePicker, { useDateRangePicker } from 'src/components/date-range-picker';
// import { fDate } from 'src/utils/formatTime';
// import { TextField } from 'material-ui';
// import { DateRange, DateRangePicker } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
// import { useDateRangePicker } from 'src/components/date-range-picker';
import React, { useState } from 'react';
import { useAuthContext } from 'src/auth/useAuthContext';
import GenerateReports from './GenerateReports';

// ----------------------------------------------------------------------

export default function Reports() {
  // const pickerCalendar = useDateRangePicker(new Date(), new Date());
  // const [start, setStart] = useState<Date | null>(new Date());
  // const [end, setEnd] = useState<Date | null>(new Date());
  // const [selectedDate, handleDateChange] = React.useState([null, null]);
  // const [value, setValue] = useState<DateRange<Date>>([null, null])
  // console.log({ value })
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const { user } = useAuthContext();
  const [isVisible, setIsVisible] = useState(false);
  const [reportType, setReportType] = useState('');
  const navigate = useNavigate();

  // const options = [
  //   'Option 1',
  //   'Option 2',
  //   'Option 3',
  //   // Add more options as needed
  // ];

  // const showReports = (reportTypeOne: String) => {
  //   setIsVisible(true);
  //   setReportType(reportTypeOne.toString());
  // };

  // const handleChange = (event: { target: { value: any } }) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectedOptions(value);
  // };
  // const [displayJobsReport, setdisplayJobsReport] = useState(false);
  // const [showReport, setShowReport] = useState(false);

  // const displayJobsReportFun = () => {
  //   setdisplayJobsReport(true);
  // };

  // const showReportFun = () => {
  //   setShowReport(true);
  // };

  // const handleBack = () => {
  //   window.history.back(); // Go back to the previous screen
  // };

  const gotoJobReport = () => {
    navigate('/jobreport');
  };

  const gotoJobStageChecklistReport = () => {
    navigate('/jobstagechecklist');
  };
  const gotoJobAuditReport = () => {
    navigate('/jobauditreport');
  };

  const gotoAssessorReport = () => {
    navigate('/assessorreport');
  };
  const gotoExceptionReport = () => {
    navigate('/exceptionreport');
  };
  const gotoHolidayReport = () => {
    navigate('/holidayreport');
  };
  const gotoRequestorReport = () => {
    navigate('/requestorreport');
  };

  return (
    <>
      {!isVisible && (
        <Card
          style={{
            display: 'flex',
            borderRadius: 0,
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <CardContent>
            <Typography variant="h3">Generate Reports</Typography>
          </CardContent>
        </Card>
      )}

      {/* <Grid container spacing={2} style={{ padding: '50px' }}>
                <Grid xs={3} />
                <Grid xs={6}>
                    <Typography variant="h5">
                        Jobs Reports
                    </Typography>
                    <Button variant='contained' size="medium" sx={{ borderRadius: 0, marginLeft: '20px', height: '50px', }}>
                        Generate Report
                    </Button>
                </Grid >
            </Grid> */}
      {!isVisible && (

        <div>

        {user?.UserRole === 'Assessor' ? (
          
              // Only show the Assessor Report for Assessor role
              <Grid
                container
                direction="row"
                spacing={2}
                lg={12}
                sx={{ marginTop: '20px', justifyContent: 'center', alignItems: 'center' }}
              >
                <Grid xs={5} container>
                  <Card
                    style={{
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  >
                  <CardContent>
                    <Grid container sx={{ marginBottom: '-6px', marginTop: '-30px' }}>
                      <Grid xs={9} style={{ marginTop: '30px' }}>
                        <Stack
                          spacing={2}
                          sx={{
                            margin: 1,
                            width: 'fit-content',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="h5">Assessor Reports</Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={3} style={{ marginTop: '30px' }}>
                        <Tooltip
                          title="This button provides feature to generate a report for Assessors for single or all assessors depending on period and date range"
                          placement="top-end"
                        >
                          <Button
                            onClick={() => gotoAssessorReport()}
                            variant="outlined"
                            size="medium"
                            sx={{ width: '100%' }}
                          >
                            Generate Report
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <><Grid
                container
                direction="row"
                spacing={2}
                lg={12}
                sx={{ marginTop: '20px', justifyContent: 'center', alignItems: 'center' }}
              >
                <Grid xs={5} container>
                  <Card
                    style={{
                      // display: 'flex',
                      borderRadius: '10px',
                      width: '100%',
                    }}
                  >
                    <CardContent>
                      <Grid container sx={{ marginBottom: '-6px', marginTop: '-30px' }}>
                        <Grid xs={9} style={{ marginTop: '30px' }}>
                          <Stack
                            spacing={2}
                            sx={{
                              margin: 1,
                              width: 'fit-content',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="h5">Job Report</Typography>
                          </Stack>
                        </Grid>

                        <Grid xs={3} style={{ marginTop: '30px' }}>
                          <Tooltip
                            title="This button provides feature to generate a report for specific data (Job Status | Active Jobs | Period or on date range) of job creation."
                            placement="top-end"
                          >
                            <Button
                              //   onClick={() => showReports('job')}
                              onClick={() => gotoJobReport()}
                              variant="outlined"
                              size="medium"
                              sx={{ width: '100%' }}
                            >
                              Generate Report
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid xs={5} container>
                  <Card
                    style={{
                      // display: 'flex',
                      borderRadius: '10px',
                      width: '100%',
                      marginLeft: '15px',
                    }}
                  >
                    <CardContent>
                      <Grid container sx={{ marginBottom: '-6px', marginTop: '-30px' }}>
                        <Grid xs={9} style={{ marginTop: '30px' }}>
                          <Stack
                            spacing={2}
                            sx={{
                              margin: 1,
                              width: 'fit-content',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="h5">Job Stage Checklist</Typography>
                          </Stack>
                        </Grid>

                        <Grid xs={3} style={{ marginTop: '30px' }}>
                          <Tooltip
                            title="This button provides feature to generate a report for specific data (Job Id | Stage Name | Failed Audit on mentioned date range) of job with over all job stage movements captured."
                            placement="top-end"
                          >
                            <Button
                              //   onClick={() => showReports('job')}
                              onClick={() => gotoJobStageChecklistReport()}
                              variant="outlined"
                              size="medium"
                              sx={{ width: '100%' }}
                            >
                              Generate Report
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid><Grid
                container
                direction="row"
                spacing={2}
                lg={12}
                sx={{ marginTop: '20px', justifyContent: 'center', alignItems: 'center' }}
              >
                  <Grid xs={5} container>
                    <Card
                      style={{
                        borderRadius: '10px',
                        width: '100%',
                      }}
                    >
                      <CardContent>
                        <Grid container sx={{ marginBottom: '-6px', marginTop: '-30px' }}>
                          <Grid xs={9} style={{ marginTop: '30px' }}>
                            <Stack
                              spacing={2}
                              sx={{
                                margin: 1,
                                width: 'fit-content',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="h5">Exception Report</Typography>
                            </Stack>
                          </Grid>

                          <Grid xs={3} style={{ marginTop: '30px' }}>
                            <Tooltip
                              title="This button provides feature to generate a report for jobs who went in exception"
                              placement="top-end"
                            >
                              <Button
                                onClick={() => gotoExceptionReport()}
                                variant="outlined"
                                size="medium"
                                sx={{ width: '100%' }}
                              >
                                Generate Report
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={5} container>
                    <Card
                      style={{
                        borderRadius: '10px',
                        width: '100%',
                        marginLeft: '15px',
                      }}
                    >
                      <CardContent>
                        <Grid container sx={{ marginBottom: '-6px', marginTop: '-30px' }}>
                          <Grid xs={9} style={{ marginTop: '30px' }}>
                            <Stack
                              spacing={2}
                              sx={{
                                margin: 1,
                                width: 'fit-content',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="h5">Assessor Reports</Typography>
                            </Stack>
                          </Grid>

                          <Grid xs={3} style={{ marginTop: '30px' }}>
                            <Tooltip
                              title="This button provides feature to generate a report for Assessors for single or all assessors depending on period and date range"
                              placement="top-end"
                            >
                              <Button
                                onClick={() => gotoAssessorReport()}
                                variant="outlined"
                                size="medium"
                                sx={{ width: '100%' }}
                              >
                                Generate Report
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid><Grid
                  container
                  direction="row"
                  spacing={2}
                  lg={12}
                  sx={{ marginTop: '20px', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Grid xs={5} container>
                    <Card
                      style={{
                        borderRadius: '10px',
                        width: '100%',
                      }}
                    >
                      <CardContent>
                        <Grid container sx={{ marginBottom: '-6px', marginTop: '-30px' }}>
                          <Grid xs={9} style={{ marginTop: '30px' }}>
                            <Stack
                              spacing={2}
                              sx={{
                                margin: 1,
                                width: 'fit-content',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="h5">Requestor Report</Typography>
                            </Stack>
                          </Grid>

                          <Grid xs={3} style={{ marginTop: '30px' }}>
                            <Tooltip
                              title="This button provides feature to generate a report for Requestors for single or all requestors depending on period and date range"
                              placement="top-end"
                            >
                              <Button
                                onClick={() => gotoRequestorReport()}
                                variant="outlined"
                                size="medium"
                                sx={{ width: '100%' }}
                              >
                                Generate Report
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={5} container>
                    <Card
                      style={{
                        borderRadius: '10px',
                        width: '100%',
                        marginLeft: '15px',
                      }}
                    >
                      <CardContent>
                        <Grid container sx={{ marginBottom: '-6px', marginTop: '-30px' }}>
                          <Grid xs={9} style={{ marginTop: '30px' }}>
                            <Stack
                              spacing={2}
                              sx={{
                                margin: 1,
                                width: 'fit-content',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="h5">Holiday Report</Typography>
                            </Stack>
                          </Grid>

                          <Grid xs={3} style={{ marginTop: '30px' }}>
                            <Tooltip
                              title="This button provides feature to generate a report for Leave/Holiday for Teams(Assessor Team , Workflow Team)."
                              placement="top-end"
                            >
                              <Button
                                variant="outlined"
                                size="medium"
                                onClick={() => gotoHolidayReport()}
                                sx={{ width: '100%' }}
                              >
                                Generate Report
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid><Grid
                  container
                  direction="row"
                  spacing={2}
                  lg={12}
                  sx={{ marginTop: '20px', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Grid xs={5} container>
                    <Card
                      style={{
                        // display: 'flex',
                        borderRadius: '10px',
                        width: '100%',
                      }}
                    >
                      <CardContent>
                        <Grid container sx={{ marginBottom: '-6px', marginTop: '-30px' }}>
                          <Grid xs={9} style={{ marginTop: '30px' }}>
                            <Stack
                              spacing={2}
                              sx={{
                                margin: 1,
                                width: 'fit-content',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="h5">Job Audit Report</Typography>
                            </Stack>
                          </Grid>

                          <Grid xs={3} style={{ marginTop: '30px' }}>
                            <Tooltip
                              title="This button provides feature to generate a report for specific date of audit | no audit | all jobs with over all job stage movements captured."
                              placement="top-end"
                            >
                              <Button
                                //   onClick={() => showReports('job')}
                                onClick={() => gotoJobAuditReport()}
                                variant="outlined"
                                size="medium"
                                sx={{ width: '100%' }}
                              >
                                Generate Report
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={5} />
                </Grid></>
        )}  
        </div>
      )}

      {/* <Divider sx={{ borderBottom: '2px solid', marginTop: '50px', marginLeft: '100px', marginRight: '100px' }} /> */}

      {isVisible && <GenerateReports reportTypeTemp={reportType} setIsVisible={setIsVisible} />}
    </>
  );
}
