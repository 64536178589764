import {
  Button,
  Grid,
  Stack,
  FormControl,
  RadioGroup,
  FormLabel,
  // Radio,
  IconButton,
  FormControlLabel,
  Typography,
  Checkbox,
} from '@mui/material';
import { Container } from '@mui/system';
import { useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import Upload from 'src/components/upload/Upload';
import FormProvider from 'src/components/hook-form';
import { Upload } from 'src/components/upload';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSnackbar } from 'notistack';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
// import { useSettingsContext } from 'src/components/settings';
import axios from 'src/utils/axios';
// import { useNavigate } from 'react-router';
// import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
// import { any } from 'prop-types';

type FormValuesProps = {
  DocumentFile: any;

  id: number;
  DocName: string;
  DocRole: string;
  DocLocation: string;
  DocExtension: string;
  DocType: string;
  DocSize: number;
  CreatedByName: string;
  DocVectorFlag: boolean;
  DocVectorDate: string;
  CreatedBy: string;
  CreatedAt: string;
  DocStatus: string;
  DocAction: string;
  // AccessRole: 'Management' | 'Workflow' | 'Assessor' | '';
  AccessRole: string[];
};

interface Props {
  setAddDocument: any;
}

export default function AddDocuments({ setAddDocument }: Props) {
  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const [documentFiles, setDocumentFiles] = useState<(File | string)[]>([]);
  // const [documentFiles, setDocumentFiles] = useState<File | string >();
  const [showExplanation, setShowExplanation] = useState(false);
  const [loading, setLoading] = useState(false);
  const CommentSchema = Yup.object().shape({
    UserFName: Yup.string().required('Name is required'),
    AccessRole: Yup.array()
      .min(1, 'Select at least one visibility option')
      .required('Select at least one visibility option'),
  });

  const defaultValues = {
    DocName: '',
    DocRole: '',
    DocLocation: '',
    DocExtension: '',
    DocType: '',
    DocSize: undefined,
    DocVectorFlag: undefined,
    DocVectorDate: '',
    DocStatus: 'active',
    // AccessRole: '' as '' | 'Management' | 'Workflow' | 'Assessor' | undefined, // Fix the type
    AccessRole: ['Management'],
    // DocumentFile: null, // Default value for the document file
    DocumentFiles: null as FileList | null,
  };

  const handleDocumentDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      // setDocumentFiles([...documentFiles, ...newFiles]);
      setDocumentFiles(newFiles);
    },
    []
  );


  const handleRemoveDocumentFile = (inputFile: File | string) => {
    const filtered = documentFiles.filter((file) => file !== inputFile);
    setDocumentFiles(filtered);
  };

  // const handleRemoveDocumentFile = (inputFile: File | string) => {
  //    setDocumentFiles();
  // };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const goToChatBot = () => {
    setAddDocument(false);
  };

  const {
    // watch,
    setValue,
    // handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  const handleVisibilityChange = (role: string, checked: boolean) => {
    console.log('Role:', role, 'Checked:', checked); // Log the role and checked status
    const currentRoles = methods.getValues('AccessRole') || [];
    let updatedRoles: string[];

    if (checked) {
      // Add the role to the selected roles if checked
      updatedRoles = [...currentRoles, role];
    } else {
      // Remove the role from the selected roles if unchecked
      updatedRoles = currentRoles.filter((selectedRole) => selectedRole !== role);
    }

    console.log('Updated Roles:', updatedRoles); // Log the updated roles

    setValue('AccessRole', updatedRoles, {
      shouldValidate: true,
    });
  };

  const handleUpload = async (data: any) => {
    try {
      console.log('Document ID:', user?.ID);
      console.log('User Role:', user?.UserRole);
      setLoading(true);
      if (documentFiles.length === 0) {
        enqueueSnackbar('Please select a file first', { variant: 'error' });
        setLoading(false);
        return;
      }

      const formDataDocument = new FormData();
      documentFiles.forEach((file) => {
        formDataDocument.append('documentFiles', file);
      });
      formDataDocument.append('userid', user?.ID.toString() ?? '');
      const docRoles = methods.getValues('AccessRole') || [];
      if (docRoles.length === 0) {
        enqueueSnackbar('Error: Missing visibility selection', { variant: 'error' });
        setLoading(false);
        return;
      }

      formDataDocument.append('docroles', docRoles.join(','));
      console.log("upload doc payload :", formDataDocument)

      const response = await axios.post(`/core/uploaddoc`, formDataDocument);
      console.log('Resopnse data = ', response.data);

      if (response.status === 200) {
        setLoading(false);
        if (response.data.status === 'success') {
          enqueueSnackbar('The document will be labeled as "Pending" and will be added after business hours', { variant: 'success' });
          setAddDocument(false);
        } else {
          // If response data indicates failure, log and throw an error
          const errorMessage = response.data.message || 'Failed to upload files';
          console.error('Error in server response:', errorMessage);
          throw new Error(errorMessage);
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 200) {
        // This indicates a successful response status, but there might be an error reported by the server
        console.error('Server reported an error:', error.response.data);
        enqueueSnackbar(error.response.data.message || 'An error occurred while uploading files', {
          variant: 'error',
        });
      } else {
        // This is a general error, unrelated to the file upload process
        console.error('Error uploading files:', error);
        enqueueSnackbar(error.error, {
          variant: 'error',
        });
      }
    }
  };

  return (
    <>
      <Container
        sx={{
          maxWidth: '100% !important',
          marginLeft: '430px',
          marginTop: '20px',
        }}
      >
        <Helmet>
          <title> Add Document | Chat_Info</title>
        </Helmet>

        <CustomBreadcrumbs
          heading="Add New Document"
          links={[
            {
              href: '/DocumentList',
            },
          ]}
        />

        {!methods.watch('AccessRole').includes('Management') &&
          !methods.watch('AccessRole').includes('Assessor') &&
          !methods.watch('AccessRole').includes('Workflow') && (
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                top: '60%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '8px',
                backgroundColor: '#f44336',
                color: 'white',
                borderRadius: '1px',
                zIndex: '1',
              }}
            >
              At least one visibility option must be selected
            </Typography>
          )}
      </Container>
      <Scrollbar>
        <Grid
          container
          spacing={2}
          sx={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <FormProvider methods={methods}>
            <Grid container spacing={2} md={12} xl={12}>
              <Grid item xs={12} md={4}>
                <Grid item xs={12} md={4}>
                {documentFiles.length === 0 && (
                  <Upload
                    onDrop={handleDocumentDrop}
                    files={documentFiles}
                    onRemove={handleRemoveDocumentFile}
                    sx={{
                      width: '1210px',
                      marginLeft: '50px',
                    }}
                  />)}
                  {documentFiles.length > 0 && (
                    <Grid container spacing={2} sx={{ marginTop: '10px', marginLeft: '50px',width: '800px' }}>
                      <Grid item xs={12}>
                        <Typography variant="h6">Selected Files:</Typography>
                      </Grid>
                      {documentFiles.map((file: File | string, index: number) => (
                        <Grid item xs={12} key={file instanceof File ? file.name : file}>
                          <Stack direction="row" alignItems="center">
                            <Typography>{file instanceof File ? file.name : file}</Typography>
                            <IconButton size="small" onClick={() => handleRemoveDocumentFile(file)}>
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
                {/* <Grid container justifyContent="space-between">

                </Grid> */}
                <Grid container spacing={2} sx={{ marginTop: '20px', marginLeft: '50px' }}>
                <Grid item sx={{color: 'red', marginLeft: '700px' }}>
                    <Typography variant="body2" sx={{width:'400px' }}>
                      *Only .pdf, .txt, .docx , .doc , .xlsx, .xls , .csv extension files can be uploaded.
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginTop: '10px' }}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        onMouseEnter={() => setShowExplanation(true)}
                        onMouseLeave={() => setShowExplanation(false)}
                      >
                        Visibility
                      </FormLabel>
                      {showExplanation && (
                        <Typography variant="body2" sx={{width:'850px' }}>
                          NOTE: Visibility settings facilitate the ability of a particular role to inquire
                          about a document. However, the management role retains the authority to
                          view or delete documents, regardless of whether the visibility setting is
                          turned off for a specific document.
                        </Typography>
                      )}
                      <RadioGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={methods.watch('AccessRole').includes('Management')}
                              onChange={(e) =>
                                handleVisibilityChange('Management', e.target.checked)
                              }
                            />
                          }
                          label="Management"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={methods.watch('AccessRole').includes('Workflow')}
                              onChange={(e) => handleVisibilityChange('Workflow', e.target.checked)}
                            />
                          }
                          label="Workflow"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={methods.watch('AccessRole').includes('Assessor')}
                              onChange={(e) => handleVisibilityChange('Assessor', e.target.checked)}
                            />
                          }
                          label="Assessor"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* Grid for Save and Back buttons */}
              <Grid container spacing={2} justifyContent="flex-end" sx={{ marginTop: '20px' }}>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        sx={{ marginTop: '20px', marginLeft: '80px' }}
                      >
                        Save
                      </Button> */}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        sx={{ marginTop: '20px', marginLeft: '80px' }}
                        disabled={documentFiles.length < 1 || loading || methods.watch('AccessRole').length === 0}                        
                      >
                        {loading ? 'Uploading...' : 'Upload'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={goToChatBot}
                        color="secondary"
                        sx={{ marginTop: '20px', marginBottom: '20px' }}
                        disabled={loading}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Scrollbar>
    </>
  );
}
