import { Box, Button, Divider, Grid, Stack, TextField } from '@mui/material';
import { textAlign } from '@mui/system';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import axios from 'src/utils/axios';

interface Props {
  data: JobRecord;
}

interface JobRecord {
  JobRecordID: number;
  JobNumber: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: number;
  JobPostAssessmentFlag: boolean;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
}

interface JobOptions {
  job_option: string;
  job_record_id: number;
  option_id: number;
}

export default function Options({
  reportData,
  saveReportData,
  setSaveReportData,
  invalidFields,
}: any) {
  const [options, setOptions] = useState<JobOptions[]>([]);

  useEffect(() => {
    // Check if reportData and optionsData are defined before setting the state
    if (saveReportData && saveReportData.Options) {
      setOptions(saveReportData.Options);
    }
  }, [saveReportData]);

  // const addOptions = (e: any, index: any) => {
  //   const newOptions = [...options];
  //   newOptions[index] = e.target.value;
  //   setOptions(newOptions);
  // };

  const addOptions = (e: any, index: number) => {
    const newOptions: JobOptions[] = [...options];
    newOptions[index].job_option = e.target.value;
    setOptions(newOptions);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Options: newOptions,
    }));
  };

  const handleSave = async () => {
    console.log('Values of options:', options);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Options: options,
    }));
  };

  // const handleRemove = (index: number) => {
  //   const updatedOptions = options.filter((v, i) => {
  //     if (i !== index) {
  //       return true;
  //     }
  //     return false;
  //   });
  //   setOptions(updatedOptions);
  // };
  // const handleAdd = () => {
  //   setOptions([...options, '']); // Add a new empty option when "Add Option" is clicked
  // };

  const handleRemove = (option: any, index: number) => {
    console.log(`Deleted option: ${JSON.stringify(option)}`);
    if (option.option_id) {
      axios
        .delete(`/core/removeOption`, { data: option })
        .then((response) => {
          if (response.status === 200) {
            const updatedOptions = options.filter((v, i) => {
              if (v.option_id === undefined || v.option_id !== option.option_id) {
                return true;
              }
              return false;
            });
            setOptions(updatedOptions);
            setSaveReportData((prevData: any) => ({
              ...prevData,
              Options: updatedOptions,
            }));
          }
        })
        .catch((error) => {
          console.error('Error fetching getJob:', error);
        });
    } else {
      const updatedOptions = options.filter((v, i) => {
        if (i !== index) {
          return true;
        }
        return false;
      });
      setOptions(updatedOptions);
      setSaveReportData((prevData: any) => ({
        ...prevData,
        Options: updatedOptions,
      }));
    }
  };

  const handleAdd = () => {
    setOptions([...options, { job_option: '' } as JobOptions]);
  };
  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE OPTIONS</Box>
          </Stack>
          {invalidFields.indexOf('Options') >= 0 && (
            <Box sx={{ color: 'red', typography: 'h6' }}>Please Fill Options.</Box>
          )}
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
          {options.map((option: any, index: any) => (
            <Stack key={index}>
              <Stack textAlign="center">
                <Box sx={{ color: '#17a986', typography: 'body2' }}>
                  Option {index + 1} : Compliance Achieved
                </Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption', fontStyle: 'italic' }}>
                  The following upgrades are required, these are inclusive of all the specifications
                  as noted on The Specifications:
                </Box>
              </Stack>
              <Stack alignItems="flex-end" spacing={1.5}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                  <TextField
                    size="small"
                    multiline
                    rows={2}
                    name={index.toString()}
                    value={option?.job_option || ''}
                    onChange={(e) => addOptions(e, index)}
                    sx={{
                      width: '100%',
                      background: 'cadetblue',
                      borderRadius: '6px',
                    }}
                  />
                </Stack>
                {/* Save and Remove buttons removed from here */}
              </Stack>
              {/* <Grid item> */}
              <Button
                size="small"
                color="error"
                startIcon={<Iconify icon="mdi:remove" />}
                onClick={() => handleRemove(option, index)}
                sx={{ marginTop: '10px' }}
              >
                Remove
              </Button>
              {/* </Grid> */}
            </Stack>
          ))}
          {/* Single "Save" button outside the loop */}
          <Stack spacing={1.5}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={handleAdd}
                  sx={{ flexShrink: 0 }}
                >
                  Add Option
                </Button>
              </Grid>
              {/* <Grid item>
                <Button
                  size="small"
                  color="success"
                  startIcon={<Iconify icon="material-symbols-light:save-sharp" />}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid> */}
            </Grid>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              <img
                src="./ea-report-footer.png"
                alt="Logo"
                style={{ width: '20vh', height: '10vh' }}
              />
            </Box>
            <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
            <Stack direction="column" sx={{ marginTop: '25px' }}>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                energy@energyadvance.com.au
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                ACN: 60 9332014 | 1300 850 228
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}
function enqueueSnackbar(data: any) {
  throw new Error('Function not implemented.');
}
