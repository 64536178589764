import { Button, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'src/components/snackbar';
//   import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
//   import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//   import Label from 'src/components/label/Label';
// import _mock from 'src/_mock/_mock';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
//   import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
//   import { fData } from 'src/utils/formatNumber';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router';

type DocumentProfile = {
  id: number;
 DocName: string;
 DocRole: string;
 DocLocation: string;
 DocExtension: string;
 DocType: string;
 DocSize: number;
 DocVectorFlag: boolean;
 DocVectorDate: string;
 DocStatus: string;
  // Documentname: string;
  // UserFname: string;
  // UserLname: string;
  // UserEmail: string;
  // UserPhone: string;
  // UserGender: string;
  // UserState: string;
  // UserZipcode: string;
  // UserStatus: 'active' | 'inactive' | any;
  // UserStreetAddr: string;
  // UserCountry: 'Australia' | any;
  // UserCreatedAt: string;
};
interface ViewJobDialogProp {
  document: DocumentProfile;
  id: string;
  edit: boolean;
}

export default function ProfileDocument({ document, id, edit }: ViewJobDialogProp) {
  console.log('Data = ', document);

  const [values, setValues] = useState<Date | null>(new Date());
  const [image, setImage] = useState<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountryCode, setSelectedCountryCode] = useState('+61');

  const CommentSchema = Yup.object().shape({
    UserFname: Yup.string().required('First Name is required'),
    UserLname: Yup.string().required('Last Name is required'),

    UserEmail: Yup.string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      'Email must be a valid email address in the format name@example.com'
    ),
    UserPhone: Yup.string()
      .required('Phone Number is required. Minimum 8 Maximum 10')
      .min(8)
      .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
        if (!value) return false;
        return /^(\+61-){0,1}\d+$/.test(value);
      }),

    UserStatus: Yup.string().required('Status is required'),
    UserCountry: Yup.string().required('Country is required'),
    UserProfilePic: Yup.string(),
  });

  const defaultValues = {
    // id: 0,
    DocName: '',
    DocRole: '',
    DocLocation: '',
    DocExtension: '',
    DocType: '',
    // DocSize: 0,
    DocVectorFlag: false,
    DocVectorDate: '',
    DocStatus: 'active',
    // UserFname: '',
    // UserLname: '',
    // UserEmail: '',
    // UserPhone: '',
    // UserGender: '',
    // UserState: '',
    // UserZipcode: '',
    // UserStatus: '',
    // UserStreetAddr: '',
    // UserCountry: 'Australia',
    // UserProfilePic: '',
  };

  const methods = useForm<DocumentProfile>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    setValue,
    // reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    console.log('Document data:', document);
    if (document) {
      // setValue('UserPhone', document.UserPhone);
      // if (document.UserPhone.startsWith('+61')) {
      //   setSelectedCountryCode('+61');
      //   // } else if (document.UserPhone.startsWith('+1')) {
      //   //   setSelectedCountryCode('+1');
      // } else {
      //   setSelectedCountryCode(' ');
      // }
      // document.UserPhone = document.UserPhone.replace('+61-', '');
      // document.UserPhone = document.UserPhone.replace('+1-', '');
      // const indexofHyphen = document.UserPhone.indexOf('-');
      // if (indexofHyphen !== -1) {
      //   setValue('UserPhone', document.UserPhone.substring(indexofHyphen + 1));
      // } else {
      //   setValue('UserPhone', document.UserPhone);
      // }
      setValue('DocName', document.DocName);
      setValue('DocRole', document.DocRole);
      setValue('DocLocation', document.DocLocation);
      setValue('DocExtension', document.DocExtension);

      // 

      setValue('DocType', document.DocType);
      setValue('DocSize', document.DocSize);
      setValue('DocVectorFlag', document.DocVectorFlag);
      setValue('DocVectorDate', document.DocVectorDate);
      setValue('DocStatus', document.DocStatus);


    }
    // if (document.UserProfilePic) {
    //   axios
    //     .get(`/core/getAssessorProfilePic?loc=${document.UserProfilePic}`, {
    //       responseType: 'blob',
    //     })
    //     .then((response) => {
    //       const blob = response.data;
    //       const objectUrl = URL.createObjectURL(blob);
    //       setValue('UserProfilePic', objectUrl, { shouldValidate: true });
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching document:', error);
    //     });
    // }
  }, [document, setValue]);

  // console.log('Post set Phone number : ', document.UserPhone);
  // console.log('Post Set Country Code : ', selectedCountryCode);

  const goToDocumentList = () => {
    navigate('/documentUserList');
  };

  const onSubmit = async (dat: DocumentProfile) => {
    try {
      // if (dat.UserPhone.trim() !== '') {
      //   dat.UserPhone = dat.UserPhone.replace('+61-', '');
      //   // dat.UserPhone = dat.UserPhone.replace('+1-', '');
      //   if (selectedCountryCode === '+61') {
      //     if (!dat.UserPhone.startsWith('+61')) {
      //       dat.UserPhone = `+61-${dat.UserPhone}`;
      //     }
      //     // } else if (selectedCountryCode === '+1') {
      //     //   if (!dat.UserPhone.startsWith('+1')) {
      //     //     dat.UserPhone = `+1-${dat.UserPhone}`;
      //     //   }
      //   }
      // }
      // setValue('UserPhone', dat.UserPhone);

      console.log('Document data = ', dat);
      await CommentSchema.validate(dat, { abortEarly: false });
      const formDataObj = new FormData();
      formDataObj.append('file', image);
      formDataObj.append('document', JSON.stringify(dat));
      axios
        .post(`/core/updateManagement?id=${id}`, formDataObj)
        .then((response) => {
          enqueueSnackbar(response.data, { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    } catch (error) {
      console.error(error);
    }
  };

  // const handleDrop = useCallback(
  //   // (acceptedFiles: File[]) => {
  //   //   const file = acceptedFiles[0];

  //   //   const newFile = Object.assign(file, {
  //   //     preview: URL.createObjectURL(file),
  //   //   });

  //   //   // if (file) {
  //   //   //   setImage(file);
  //   //   //   setValue('UserProfilePic', newFile, { shouldValidate: true });
  //   //   // }
  //   // },
  //   [setValue]
  // );

  return (
    //     <Card
    //     sx={{
    //       marginLeft: '10px',
    //       width: '1170px',
    //       height: '640px',
    //       display: 'flex',
    //       flexDirection: 'column',
    //     }}
    //   >
    <>
      {/* <Container> */}

      <Grid container display="flow">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} md={12} xl={12}>
            <Grid item xs={12} md={4}>
              {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
              {/* <Stack
                  spacing={2}
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                > */}
              {/* <RHFUploadAvatar
                    name="UserProfilePic"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    disabled={!edit}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 4,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  /> */}

              {/* <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                    Role : Document
                  </Label> */}
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                      label="Created Date"
                      disabled
                      value={document?.UserCreatedAt}
                      onChange={setValues}
                    />
                  </LocalizationProvider> */}
              {/* </Stack> */}
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} md={8} sx={{}}>
              {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
              <Box
                rowGap={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="Documentname" label="Document Name*" disabled />
                <RHFTextField name="UserFname" label="UploadedBy*" disabled={!edit} />
                {/* <RHFTextField name="UserLname" label="Last Name*" disabled={!edit} /> */}
                {/* <RHFTextField name="UserEmail" label="Email Address*" disabled={!edit} /> */}
                {/* <RHFTextField name="UserPhone" label="Phone Number" disabled={edit} /> */}
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  {/* <RHFTextField
                      name="CountryCode"
                      label="Country Code"
                      // disabled={!edit}
                      sx={{ flex: 1 }}
                      InputProps={{
                        inputProps: { maxLength: 4 },
                        value: '+61',
                        readOnly: true, // non-editable
                      }}
                    /> */}
                  {/* <FormControl>
                      <InputLabel>Country Code</InputLabel>
                      <Select
                        name="CountryCode"
                        label="Country Code"
                        value={selectedCountryCode}
                        
                        onChange={(event: SelectChangeEvent) => {
                          const selectcountrycode = event.target.value as string;
  
                          if (selectcountrycode === '+61') {
                            methods.setValue('UserCountry', 'Australia');
                          
                          }
  
                          setSelectedCountryCode(event.target.value);
                        }}
                        disabled={!edit}
                      >
                        <MenuItem value="+61">+61</MenuItem>
                        
                      </Select>
                    </FormControl> */}

                  {/* <RHFTextField
                      name="UserPhone"
                      label="Phone Number**"
                      // disabled={!edit}
                      sx={{ flex: 5 }}
                      InputProps={{ inputProps: { maxLength: 10 } }}
                    /> */}
                  {/* <RHFTextField
                      name="UserPhone"
                      label="Phone Number*"
                      disabled={!edit}
                      sx={{ flex: 5 }}
                      InputProps={{ inputProps: { maxLength: 10 } }}
                    /> */}
                </Stack>

                {/* <RHFSelect name="UserGender" label="Gender" disabled={!edit}>
                    
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </RHFSelect> */}

                <Stack spacing={2} alignItems="flex-start">
                  {/* <RHFTextField
                      name="UserStreetAddr"
                      disabled={!edit}
                      multiline
                      rows={3}
                      label="Address"
                    /> */}
                </Stack>
                {/* <RHFTextField name="UserState" disabled={edit} label="State/Region" /> */}

                {/* <RHFSelect
                    native
                    name="UserCountry"
                    label="Country*"
                    disabled={edit}
                    placeholder="Country"
                  >
                    <option key="Australia" value="Australia">
                      Australia
                    </option>
                    <option key="United States" value="United States">
                      United States
                    </option>
                  </RHFSelect> */}
                {/* <FormControl>
                    <InputLabel>Country*</InputLabel>
                    <Select
                      name="UserCountry"
                      label="Country*"
                      disabled={!edit}
                      placeholder="Country"
                      value={methods.watch('UserCountry')}
                      onChange={(event: SelectChangeEvent) => {
                        const selectedCountry = event.target.value as string;
  
                        if (selectedCountry === 'Australia') {
                          setSelectedCountryCode('+61');
                       
                        }
  
                        methods.setValue('UserCountry', selectedCountry);
                      }}
                    >
                      <MenuItem value="Australia">Australia</MenuItem>
                    </Select>
                  </FormControl> */}
                {/* <RHFTextField name="UserCountry" label="Country" value="Austriliya" /> */}
                {/* <RHFTextField name="UserZipcode" disabled={!edit} label="Postal Code" />
                  <RHFSelect
                    native
                    name="UserStatus"
                    label="Status*"
                    disabled={!edit}
                    placeholder="Status"
                  >
                    <option key="active" value="active">
                      Active
                    </option>
                    <option key="inactive" value="inactive">
                      Inactive
                    </option>
                   
                  </RHFSelect> */}
              </Box>
              {/* </Card> */}
              <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                <Grid item>&nbsp;</Grid>
                {/* <Button variant="contained" onClick={goToDocumentList} color="primary">
                    Back
                  </Button> */}

                {edit && (
                  <Grid item>
                    <Button variant="contained" type="submit" color="primary">
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      onClick={goToDocumentList}
                      color="secondary"
                      sx={{ marginLeft: '10px' }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </>
    //   {/* </Card> */}
  );
}
