// import { Link as RouterLink } from 'react-router-dom';
// @mui
import DocViewer, {DocViewerRef ,DocViewerRenderers, IDocument} from '@cyntler/react-doc-viewer';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import axios from 'src/utils/axios';




export default function Manual() {
  const theme = useTheme();
  const docViewerRef = useRef<DocViewerRef>(null);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [blob, setBlob] = useState<any>();


    useEffect(() => {
      axios
      .get(`/core/getManuleLoc`)
      .then((response) => {
        handleFileOpen(response.data)
      })
      .catch((error) => {
        console.error('Error fetching getJobFile:', error);
      }).then(() => { 
      })
    },[])
 
  const handleFileOpen = (loc :string) => {
    axios
      .get(`/core/getJobFile?loc=${loc}`, {
        responseType: 'blob',
      })
      .then((response) => {
        setBlob(response.data)
        const objectUrl = URL.createObjectURL(response.data);
        const fileType = "application/pdf";
        const fileName = "MasterManualPDF.pdf";
        // setDocuments([{ uri: objectUrl, fileType: "application/pdf" }]);
        setDocuments([{ uri: objectUrl, fileType, fileName}]);
      })
      .catch((error) => {
        console.error('Error fetching getJobFile:', error);
      });
  };

  const download = () => {
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = "MasterManualPDF.pdf";
    link.click();

    window.URL.revokeObjectURL(objectUrl);
  }

  return (
    <>
        <Button onClick={() => download()} variant="contained">Download</Button>

        <DocViewer
                documents={documents}
                ref={docViewerRef}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                    retainURLParams: false,
                  },
                  csvDelimiter: ',',
                  pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2,
                  },

                  pdfVerticalScrollByDefault: false,
                }}
                pluginRenderers={DocViewerRenderers}
              />
    </>
  );
}

// ----------------------------------------------------------------------
