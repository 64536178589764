import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Step, Paper, Button, Stepper, StepLabel, Typography } from '@mui/material';
// eslint-disable-next-line import/no-named-as-default
import AddAssessorProfileTab from './Profile Tab';
// eslint-disable-next-line import/no-named-as-default
import AddAssessorScheduleTab from './Schedule Tab';
import AddAssessorSkillsetTab from './Skillset Tab';

// ----------------------------------------------------------------------

const steps = ['Profile', 'Schedule', 'Skillset'];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [value, setValue] = useState<any | null>(null); 
  const [image, setImage] = useState<Blob | null>(null); 



  
  const handleNext = () => {
   
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit= () => {

    handleNext()
  };


  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
        
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Paper
            sx={{
              p: 3,
              my: 3,
              minHeight: 120,
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
            }}
          >
            <Typography sx={{ my: 1 }}>All steps completed - you&apos;re finished</Typography>
          </Paper>

          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          <Paper
            sx={{
              p: 3,
              my: 3,
              minHeight: 120,
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
            }}
          >
           {activeStep === 0 && <AddAssessorProfileTab  next={handleNext} assessor={value}  setAssessor={setValue} setImage={setImage} image={image}/>}
           {activeStep === 1 && <AddAssessorScheduleTab onNext={handleSubmit} onback={handleBack} assessor={value}  setAssessor={setValue}/>}
           {activeStep === 2 && <AddAssessorSkillsetTab onBack={handleBack} assessor={value}  setAssessor={setValue} image={image}/>}

          </Paper>
          {/* <Box sx={{ display: 'flex' }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            
            <Button variant="contained"  onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box> */}
        </>
      )}
    </>
  );
}
