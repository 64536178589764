import { useState } from 'react';
// form

// @mui
import { Grid, Stack, Button, TextField, InputLabel, IconButton } from '@mui/material';
// import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
// import _mock from 'src/_mock';
import axios from 'src/utils/axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'src/components/snackbar';
// ----------------------------------------------------------------------
interface ChangePasswordProps {
  onClose: () => void;
}
export default function ChangePassword({ onClose }: ChangePasswordProps) {
  const { user } = useAuthContext();
  // const [value, setLastLogin] = useState<Date | null>(new Date());
  // const [open, setOpen] = useState(false);
  // const [values, setValues] = useState<Date | null>(new Date());
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const navigate = useNavigate();

  const handleTogglePasswordVisibility = (field: any) => {
    switch (field) {
      case 'currentPassword':
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  function validatePassword(password: any) {
    // Minimum 8 characters in length
    if (newPassword.length < 8) {
      return 'Password must be at least 8 characters long.';
    }

    // Password must start with a character
    if (!/^[a-zA-Z]/.test(password)) {
      return 'Password must start with a character.';
    }

    // At least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    }

    // At least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter.';
    }

    // At least one numeric digit
    if (!/\d/.test(password)) {
      return 'Password must contain at least one numeric digit.';
    }

    // At least one special character (@ | * | $)
    if (!/[@*$]/.test(password)) {
      return 'Password must contain at least one of the following special characters: @, *, $.';
    }

    // Password meets all criteria
    return null;
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log('Current Password:', currentPassword);
    console.log('New Password:', newPassword);
    console.log('Confirm Password:', confirmPassword);
    const validationError = validatePassword(newPassword);
    if (currentPassword === '' || newPassword === '' || confirmPassword === '') {
      enqueueSnackbar('All fields required', { variant: 'error' });
    } else if (newPassword !== confirmPassword) {
      enqueueSnackbar('New password and confirm password not matched.', { variant: 'error' });
    } else if (validationError) {
      enqueueSnackbar('Please follow the password policy.', { variant: 'error' });
    } else {
      try {
        axios
          .post(`/core/changePassword?id=${user?.ID}`, {
            currentPassword,
            newPassword,
            confirmPassword,
          })
          .then((response) => {
            console.log('API response:', response.data);
            if (response.data === 'Current password does not match.') {
              enqueueSnackbar('Current password does not matched.', { variant: 'error' });
            } else {
              enqueueSnackbar(response.data);
              handleCancel();
            }
          })
          .catch((error) => {
            enqueueSnackbar('Current password does not matched.', { variant: 'error' });
          });
      } catch (error) {
        console.error('Error sending data to the API:', error);
      }
    }
  };
  const handleCancel = () => {
    onClose(); // Close the dialog when "Cancel" button is clicked
  };
  return (
    <>
      <Grid container display="flow">
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ margin: 1, width: '550px', display: 'flex' }}>
            <InputLabel>Current Password</InputLabel>
            <TextField
              id="current-password"
              type={showCurrentPassword ? 'text' : 'password'}
              autoComplete="current-password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility('currentPassword')}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
            <InputLabel htmlFor="new-password">New Password</InputLabel>
            <TextField
              id="new-password"
              type={showNewPassword ? 'text' : 'password'}
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility('newPassword')}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
            <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
            <TextField
              id="confirm-password"
              type={showConfirmPassword ? 'text' : 'password'}
              autoComplete="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => handleTogglePasswordVisibility('confirmPassword')}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button onClick={handleCancel} variant="contained" color="primary">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
        <span
          style={{
            fontSize: '15px',
            color: '#ff0000',
            fontFamily: 'cursive',
          }}
        >
          Note: On successful change of password you will be logged out and you need to login again.
        </span>
        <div style={{ marginTop: '10px' }}>
          <span style={{ fontSize: '18px', fontFamily: 'monospace', marginBottom: '10px' }}>
            The password must adhere to the following policy:
            <br />
          </span>
        </div>
        <div style={{ fontSize: '15px' }}>
          - Minimum 8 characters in length.
          <br />
          - Password must start with character.
          <br />
          - At least one uppercase letter.
          <br />
          - At least one lowercase letter.
          <br />
          - At least one numeric digit.
          <br />
          - At least one special character(@ | * | $)
          <br />
        </div>
      </Grid>

      {/* <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
        sx={{ border: '1px solid #ccc', borderRadius: '4px' }}
      >
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '30px' }}
        >
          Change Password
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Stack
                spacing={2}
                sx={{
                  margin: 1,
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={_mock.image.random(2)}
                  sx={{ boxShadow: 'z23', width: 150, height: 150, borderRadius: 1 }}
                  variant="square"
                />
                <TextMaxLine line={1}>
                  <Label sx={{ marginTop: 0.1, width: '230px', fontSize: 14 }}>
                    Mandar Ghongade
                  </Label>
                </TextMaxLine>
                <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                  Role : Management
                </Label>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Stack spacing={2} sx={{ margin: 1, width: '550px', display: 'flex' }}>
                <InputLabel>Current Password</InputLabel>
                <TextField
                  id="outlined-password-input"
                  type="password"
                  autoComplete="current-password"
                />
                <InputLabel htmlFor="my-textfield">New Password</InputLabel>
                <TextField
                  id="outlined-password-input"
                  type="password"
                  autoComplete="current-password"
                />
                <InputLabel htmlFor="my-textfield">Confirm Password</InputLabel>
                <TextField
                  id="outlined-password-input"
                  type="password"
                  autoComplete="current-password"
                />
              </Stack>
            </Grid>
          </Grid>
          <span
            style={{
              fontSize: '18px',
              color: '#ff0000',
              marginLeft: '200px',
              fontFamily: 'cursive',
            }}
          >
            Note: On successful change of password you will be logout and need to login again.
          </span>
          <div style={{ marginTop: '10px' }}>
            <span style={{ fontSize: '18px', marginLeft: '200px', fontFamily: 'monospace' }}>
              The password must adhere to the following policy:
              <br />
            </span>
          </div>
          <div style={{ fontSize: '15px', marginLeft: '200px' }}>
            - Minimum 8 characters in length.
            <br />
            - Password must start with character.
            <br />
            - At least one uppercase letter.
            <br />
            - At least one lowercase letter.
            <br />
            - At least one numeric digit.
            <br />
            - At least one special character(@ | * | $)
            <br />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid xs={9} />
            <Grid
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '30px',
              }}
            >
              <Button
                size="large"
                variant="outlined"
                sx={{ marginTop: '10px', marginRight: '30px' }}
              >
                Update
              </Button>
              <Button
                size="large"
                variant="outlined"
                sx={{ marginTop: '10px' }}
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
