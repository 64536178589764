import {
  MouseEvent,
  useRef,
  //  SetStateAction,
  useState,
} from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import {
  // Stack,
  // Typography,
  Box,
  // Rating,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  // Card,
  Modal,
  // Backdrop,
  // CircularProgress,
  // Grid,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  // GridToolbar,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridCsvExportMenuItem,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
// utils
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/useAuthContext';
// import { CustomAvatar } from 'src/components/custom-avatar';
// import Label from 'src/components/label';
import axios from 'src/utils/axios';
import DocViewer, { DocViewerRef, DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import Scrollbar from 'src/components/scrollbar';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import Label from 'src/components/label';
import { InlineIcon } from '@iconify/react';

// import { fPercent } from 'src/utils/formatNumber';
// import { useNavigate } from 'react-router';
// components

// ----------------------------------------------------------------------

interface DocumentData {
  userid: number;
  id: number;
  DocLocation: string;
  DocName: string;
  DocExtension: string;
  DocStatus: string;
  // Add other properties here
}

interface ActionsMenuProps {
  onView: (item: boolean, edit: boolean) => void;
  onDelete: (data?: DocumentData) => void;
  onDownload: () => void;
  data: DocumentData;
  fetchDocument: () => void;
}

function ActionsMenu({ onView, onDelete, onDownload, data, fetchDocument }: ActionsMenuProps) {
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  const [isLoading, setIsLoading] = useState(false);
  const userid = user?.ID ?? '';
  // const [documentData, setDocumentData] = useState([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpen(true);
    handleClose();
  };

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [openFile, setFileOpen] = useState(false);
  const handleFileClose = () => setFileOpen(false);
  const docViewerRef = useRef<DocViewerRef>(null);

  const handleView = async () => {
    setIsLoading(true);

    const checkFileExtension = (fileName: string) => {
      const extensionRegex = /\.(abc)$/i;
      return extensionRegex.test(fileName);
    };

    console.log('In view');

    if (checkFileExtension(data?.DocLocation)) {
      console.log('File has one of the allowed extensions: abc');
      console.log('File name = ', data?.DocLocation);
      const loc = data?.DocLocation;
      const type = 'location';
      const doc_payload = { loc, type };

      await axios
        .post('/core/getChatBotFilePresign', doc_payload)
        .then((response) => {
          console.log('Report response data:', response.data);

          const objectUrl = response.data?.data || '';
          console.log('Object URL:', objectUrl);
          if (objectUrl !== '') {
            setDocuments([{ uri: objectUrl, fileType: 'docx' }]);
            setFileOpen(true);
          } else {
            console.log('Issue with Object URL');
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error downloading report:', error);
          enqueueSnackbar(error, { variant: 'error' });
          setIsLoading(false);
        });
    } else {
      console.log('File does not have one of the allowed extensions: abc');
      console.log('File name = ', data?.DocLocation);
      await axios
        .post(`/core/getChatBotFile?loc=${data?.DocLocation}`, {}, { responseType: 'arraybuffer' })
        .then((response) => {
          const fileName = response.headers['content-disposition']?.split('filename=')[1];
          const contentType = response.headers['content-type'];

          const blob = new Blob([response.data], { type: contentType });
          const objectUrl = URL.createObjectURL(blob);
          console.log('Object URL:', objectUrl);

          // Set the document state with the correct fileType and fileName
          setDocuments([
            {
              uri: objectUrl,
              fileType: contentType,
              fileName: fileName ? decodeURIComponent(fileName) : data.DocName,
            },
          ]);
          setFileOpen(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error downloading report:', error);
          enqueueSnackbar(error, { variant: 'error' });
          setFileOpen(false);
          setIsLoading(false);
        });
    }
  };
  // const handleView = async () => {

  //   setIsLoading(true);

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 10000); // Simulate loading time

  //   const checkFileExtension = (fileName: string) => {
  //       // Regular expression to match file extensions
  //       // const extensionRegex = /\.(docx|xlsx|csv)$/i;
  //       const extensionRegex = /\.(abc)$/i;
  //       return extensionRegex.test(fileName);
  //   };

  //   console.log('In view');

  //     if (checkFileExtension(data?.DocLocation)) {

  //       console.log('File has one of the allowed extensions: docx, xlsx, csv');
  //       const loc = data?.DocLocation
  //       const type = "location"
  //       const doc_payload = {
  //           loc,
  //           type,
  //         };
  //       await axios
  //       .post('/core/getChatBotFilePresign', doc_payload)
  //       .then((response) => {
  //         console.log('Report response data:', response.data);

  //         const objectUrl = response.data?.data || ""
  //         console.log(" Object URL :", objectUrl)
  //         if (objectUrl !== ""){
  //           setDocuments([{ uri: objectUrl,fileType: 'docx'}]);
  //           setFileOpen(true);
  //         }else{
  //           console.log('Issue with Object URL');
  //         }
  //         setIsLoading(false)
  //       })
  //       .catch((error) => {
  //         console.error('Error downloading report:', error);
  //         enqueueSnackbar(error, { variant: 'error' });
  //         setIsLoading(false)
  //       });
  //     } else {
  //       console.log('File does not have one of the allowed extensions: docx, xlsx, csv')
  //       await axios

  //         .post(
  //           `/core/getChatBotFile?loc=${data?.DocLocation}`,
  //           {},
  //           {
  //             responseType: 'blob',
  //           }
  //         )
  //         .then((response) => {
  //           console.log('Report response data:', response.data);
  //           console.log('Report response data type:', response.data.type);
  //           const blob = response.data;
  //           const objectUrl = URL.createObjectURL(blob);
  //           console.log(" Object URL :", objectUrl)
  //           setDocuments([{ uri: objectUrl }]);
  //           setFileOpen(true);
  //           setIsLoading(false)

  //         })
  //         .catch((error) => {
  //           console.error('Error downloading report:', error);
  //           enqueueSnackbar(error, { variant: 'error' });
  //           setFileOpen(false);
  //           setIsLoading(false)
  //         });
  //     }
  //   };

  const handleConfirmDelete = (row: any) => {
    console.log('documenet id is showing:', data?.id);
    console.log('user id is showing:', user?.ID);

    axios
      .post(`/core/deletedoc`, { userid: user?.ID, documentid: data?.id })

      .then((response) => {
        console.log('Delete response:', response.data);
        enqueueSnackbar(
          'The document will be labeled as "Pending" and will be deleted after business hours.',
          { variant: 'success' }
        );
        onDelete(data);
        setOpen(false);
        fetchDocument();
      })
      .catch((error) => {
        console.error('Error deleting document:', error);
        enqueueSnackbar('Document is not in Active State', { variant: 'error' });
      });
  };
  const handleDownload = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 10000); // Simulate loading time
    try {
      await axios
        .post(
          `/core/getChatBotFile?loc=${data?.DocLocation}`,
          {},
          {
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          const fileName = response.headers['content-disposition']?.split('filename=')[1];
          const contentType = response.headers['content-type'];

          if (fileName) {
            const blob = new Blob([response.data], {
              type: contentType,
            });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = decodeURIComponent(fileName || data.DocName);
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            // If the Content-Disposition header is not present or does not contain a filename, set a default file name
            const blob = new Blob([response.data], {
              type: contentType,
            });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${data.DocName}`;
            a.click();
            window.URL.revokeObjectURL(url);
          }

          handleClose();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log('Inside error while trying to download');
          setIsLoading(false);
          // console.log("Error : ", string(error))
          // console.error('Error downloading report:', error);
          enqueueSnackbar('File is Missing or not able to Access.', { variant: 'error' });
        });
    } catch (error) {
      console.error('Error downloading report:', error);
      setIsLoading(false);
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        open={openFile}
        onClose={handleFileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Scrollbar>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
          >
            <Box
              sx={{
                height: '100%',
                width: '900px',
              }}
            >
              {' '}
              <IconButton
                onClick={handleFileClose}
                sx={{
                  position: 'fixed',
                  top: '10px',
                  right: '10px',
                  color: 'aliceblue',
                  // color: 'rgba(0, 0, 0, 0.5)',
                  // backgroundColor: 'grey'
                }}
              >
                <CloseIcon />
              </IconButton>
              <DocViewer
                documents={documents}
                ref={docViewerRef}
                config={{
                  // requestHeaders: {'Access-Control-Allow-Origin': '*',
                  // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                  // }
                  // ,
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                    retainURLParams: true,
                    // corsEnabled: true,
                  },
                  csvDelimiter: ',',
                  pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2,
                  },

                  pdfVerticalScrollByDefault: false,
                }}
                pluginRenderers={DocViewerRenderers}
              />
            </Box>
          </Box>
        </Scrollbar>
      </Modal>
      <IconButton onClick={handleClick}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      <div>
        {isLoading && <div className="overlay" />}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {/* <MenuItem onClick={handleView}>
          <Iconify icon="carbon:view-filled" sx={{ marginRight: 1 }} /> View
        </MenuItem> */}
          {['pdf', 'txt'].includes(data?.DocExtension.toLowerCase()) && (
            <MenuItem onClick={handleView} disabled={isLoading}>
              <Iconify icon="carbon:view-filled" sx={{ marginRight: 1 }} />{' '}
              {isLoading ? 'Loading...' : 'View'}
            </MenuItem>
          )}
          <MenuItem onClick={handleDownload} disabled={isLoading}>
            <Iconify icon="bx:download" sx={{ marginRight: 1 }} />{' '}
            {isLoading ? 'Loading...' : 'Download'}
          </MenuItem>
          {userrole === 'Management' && data?.DocStatus.toLowerCase() === 'active' && (
            <MenuItem onClick={() => handleDelete()} disabled={isLoading}>
              <Iconify icon="feather:trash-2" sx={{ marginRight: 1, color: 'red' }} />
              {isLoading ? 'Loading...' : 'Delete'}
            </MenuItem>
          )}
        </Menu>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this document?</DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} sx={{ color: 'red' }} autoFocus>
            <InlineIcon icon="feather:trash-2" style={{ marginRight: '0.5rem' }} />
            Confirm
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// ----------------------------------------------------------------------

type Documents = {
  id: number;
  DocName: string;
  DocRole: string;
  DocLocation: string;
  DocExtension: string;
  DocType: string;
  DocSize: number;
  CreatedByName: string;
  DocVectorFlag: boolean;
  DocVectorDate: string;
  CreatedBy: string;
  CreatedAt: string;
  DocStatus: string;
  DocAction: string;
}[];
interface ChildProps {
  data: Documents;
  sendData: (data: string, edit: boolean) => void;
  fetchDocuments: () => void;
}

export default function DataGridDocument({ data, sendData, fetchDocuments }: ChildProps) {
  console.log(data);

  // const specificDate = new Date('Sat Jan 01 2000 00:00:00 GMT+0530');
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'DocName',
      headerName: 'Document Name',
      align: 'left',
      headerAlign: 'left',
      // flex: 1,
      width: 310,
    },
    {
      field: 'DocRole',
      headerName: ' Visibility',
      align: 'left',
      headerAlign: 'left',
      width: 235,
      valueFormatter: (params) =>
        params.value
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
    },
    {
      field: 'DocExtension',
      headerName: ' Doc Extension',
      align: 'center',
      headerAlign: 'center',
      // flex: 1,
      width: 120,
    },
    // {
    //   field: 'DocType',
    //   headerName: ' Doc Type',
    //   align: 'left',
    //   headerAlign: 'left',
    //   // flex: 1,
    //   width: 215,
    // },
    {
      field: 'DocAction',
      headerName: ' Doc Action ',
      align: 'left',
      headerAlign: 'left',
      // flex: 1,
      width: 110,
      valueFormatter: (params) =>
        params.value
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
    },

    {
      field: 'DocStatus',
      headerName: ' Status ',
      align: 'left',
      headerAlign: 'left',
      width: 130,
      valueFormatter: (params) =>
        params.value
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
      renderCell: (params) => RenderStatus(params.row.DocStatus),
    },
    {
      field: 'CreatedByName',
      headerName: ' Created By ',
      align: 'left',
      headerAlign: 'left',
      // flex: 1,
      width: 175,
    },

    {
      field: 'CreatedAt',
      headerName: ' Created At',
      align: 'left',
      headerAlign: 'left',
      // flex: 1,
      width: 160,
    },
    {
      field: 'DocSize',
      headerName: ' Doc Size ',
      align: 'left',
      headerAlign: 'left',
      // flex: 1,
      width: 120,
    },

    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      width: 130,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <ActionsMenu
          onView={(val, edit) => {
            handleView(params.row, edit);
          }}
          data={params.row}
          onDelete={() => {}}
          onDownload={() => {}}
          fetchDocument={() => {
            fetchDocuments();
          }}
        />
      ),
    },
  ];
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleView = (row: any, edit: boolean) => {
    sendData(row.id, edit);
  };

  return (
    <DataGrid
      // checkboxSelection
      disableRowSelectionOnClick
      rows={data}
      columns={columns}
      initialState={{
        columns: {
          columnVisibilityModel: {
            DocSize: false,
            CreatedByName: true,
            // DocType: false,
            DocAction: false,
            UploadedDate: false,
            DocLocation: false,
            DocExtension: false,
            CreatedBy: true,
            UserCreatedBy: false,
            UserCreatedAt: true,
            CreatedAt: false,
          },
        },
      }}
      // pagination
      sx={{ height: '700px' }}
      onRowSelectionModelChange={(newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
      }}
      slots={{
        toolbar: MyExportButton,
      }}
    />
  );
}

// ----------------------------------------------------------------------

function MyExportButton() {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
  const day = currentDate.getDate();

  // Add leading zeros if month or day is single-digit
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  const formattedDateTime = `${formattedDay}${formattedMonth}${currentDate.getFullYear()}`;
  const file_Name = `EA_InfoBot_Document_Data_${formattedDateTime}`;

  // const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  // const file_Name = `EA_Document_Data_${formattedDateTime}`;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fileName: file_Name,
            fields: [
              'id',
              'DocName',
              'DocRole',
              'DocLocation',
              'DocExtension',
              // 'DocType',
              'DocSize',
              'UserCreatedAt',
              'DocVectorFlag',
              'ReqType',
              'DocVectorDate',
              'CreatedBy',
              'CreatedAt',
              'DocStatus',
              'DocAction',
            ],
            // delimiter: ';',
            utf8WithBom: true,
            allColumns: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

function RenderStatus(getStatus: string) {
  let backgroundColor = '';
  switch (getStatus) {
    case 'Delete':
    case 'delete':
      backgroundColor = 'tomato';
      break;
    case 'Active':
    case 'active':
      backgroundColor = 'seagreen';
      break;
    case 'Pending':
    case 'Notprocessed':
    case 'pending':
    case 'notprocessed':
      backgroundColor = 'orange';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStatus}
      </Label>
    </div>
  );
}
