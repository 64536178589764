import '../../utils/highlight';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/useAuthContext';
import axios from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';

//
import { EditorProps } from './types';
import { StyledEditor } from './styles';
import EditorToolbar, { formats } from './EditorToolbar';

// ----------------------------------------------------------------------
interface Signature {
  UserID: number;
  Signature: string;
  EntryBy?: number;
}
export default function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  onchan,
  simple = false,
  helperText,
  sx,
  ...other
}: EditorProps) {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };
  const { enqueueSnackbar } = useSnackbar();
  const [signature, setSignature] = useState<Signature | null>(null);
  const { user } = useAuthContext();

  useEffect(() => {
    axios
      .get(`/core/getusersignbyid?Id=${user?.ID}`)
      .then((response) => {
        setSignature(response.data as Signature);
      })
      .catch((err) => {
//        enqueueSnackbar('No signature found', { variant: 'error' });
        console.error('Error fetching status signature:', err);
      });
  }, [onchan, setSignature, simple, user]);

  const addSignature = () => {
//    console.log("In add sign function");
    if (signature?.Signature !== undefined){
    // eslint-disable-next-line no-unsafe-optional-chaining
      onchan((valu: any) => valu + signature?.Signature);
    }
  };
  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} addSignature={addSignature} />

        <ReactQuill
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write something..."
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
