import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';

interface Props {
  reportData: any;
}

export default function Regulations12019({ reportData }: Props) {
  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE REGULATIONS</Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack>
          <Box sx={{ typography: 'caption' }}>
            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5 Application
            </Typography>
            <Typography variant="body2">This Part applies to-</Typography>
            <Typography variant="body2">
              (a) a Class 1 building; and (b) a Class 10a building; and (c) a Class 10b swimming
              pool associated with a Class 1 or 10a building.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.0 Application
            </Typography>
            <Typography variant="body2">
              A heated water supply system must be designed and installed in accordance with Part B2
              of NCC Volume Three - Plumbing Code of Australia.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.1 Insulation of services
            </Typography>
            <Typography variant="body2">
              Thermal insulation for central heating water piping and heating and cooling ductwork
              must-
            </Typography>
            <Typography variant="body2">
              (a) be protected against the effects of weather and sunlight, and
            </Typography>
            <Typography variant="body2">
              (b) be able to withstand the temperatures within the piping or ductwork and
            </Typography>
            <Typography variant="body2">
              (c) use thermal insulation material by AS/NZS 4859.1.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.2 Central heating water piping
            </Typography>
            <Typography variant="body2">
              Central heating water piping that is not within a conditioned space must be thermally
              insulated to achieve the minimum material R-Value as follows:
            </Typography>
            <Typography variant="body2">
              (a) Internal piping including- (i) flow and return piping that is-{' '}
            </Typography>
            <Typography variant="body2">(A) within an unventilated wall space or</Typography>
            <Typography variant="body2">
              (B) within an internal floor between storeys; or (C) between ceiling insulation and a
              ceiling; and
            </Typography>
            <Typography variant="body2">
              (ii) heated water piping encased within a concrete floor slab (except that which is
              part of a floor heating system), must, in all climate zones, have a minimum material
              R-Value of 0.4.
            </Typography>
            <Typography variant="body2">
              (b) Piping located within a ventilated wall space, an enclosed building subfloor or a
              roof space, including-
            </Typography>
            <Typography variant="body2">(i) flow and return piping; and</Typography>
            <Typography variant="body2">
              (ii) cold water supply piping within 500 mm of the connection to the central water
              heating system; and
            </Typography>
            <Typography variant="body2">
              (iii) relief valve piping within 500 mm of the connection to the central water heating
              system, must have a minimum material R-value of-
            </Typography>
            <Typography variant="body2">(iv) in climate zones 1, 2, 3 and 5 - 0.6; and</Typography>
            <Typography variant="body2">(v) in climate zones 4, 6 and 7 - 0.9; and</Typography>
            <Typography variant="body2">(vi) in climate zone 8 - 1.3.</Typography>
            <Typography variant="body2">
              (c) Piping located outside the building or in an unenclosed building subfloor or roof
              space, including-
            </Typography>
            <Typography variant="body2">(i) flow and return piping; and</Typography>
            <Typography variant="body2">
              (ii) cold water supply piping within 500 mm of the connection to the central water
              heating system; and
            </Typography>
            <Typography variant="body2">
              (iii) relief valve piping within 500 mm of the connection to the central water heating
              system, must have a minimum material R-value of-
            </Typography>
            <Typography variant="body2">
              (iv) in climate zones 1, 2, 3 and 5 - 0.6; and (v) in climate zones 4, 6 and 7 - 1.3;
              and (vi) in climate zone 8 - 1.3.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.3 Heating and cooling ductwork
            </Typography>
            <Typography variant="body2">
              (a) Heating and cooling ductwork and fittings must- (i) achieve the material R-Value
              in 3.12.5.3(d); and
            </Typography>
            <Typography variant="body2">(ii) be sealed against air loss-</Typography>
            <Typography variant="body2">
              (A) by closing all openings in the surface, joints, and seams of ductwork with
              adhesives, mastics, sealants or gaskets following AS 4254.1 and AS 4254.2 for a Class
              C seal or
            </Typography>
            <Typography variant="body2">
              (B) for flexible ductwork, with a draw band and a sealant or adhesive tape.
            </Typography>
            <Typography variant="body2">(b) Duct insulation must-</Typography>
            <Typography variant="body2">
              (i) abut adjoining duct insulation to form a continuous barrier; and
            </Typography>
            <Typography variant="body2">
              (ii) be installed so that it maintains its position and thickness, other than at
              flanges and supports; and
            </Typography>
            <Typography variant="body2">
              (iii) where located outside the building, under a suspended floor, in an attached
              Class 10a building or in a roof space-
            </Typography>
            <Typography variant="body2">
              (A) be protected by an outer sleeve of protective sheeting to prevent the insulation
              from becoming damp and
            </Typography>
            <Typography variant="body2">
              (B) have the outer protective sleeve sealed with adhesive tape not less than 48 mm
              wide, creating an airtight and waterproof seal.
            </Typography>
            <Typography variant="body2">
              (c) The requirements of 3.12.5.3(a) and 3.12.5.3(b) do not apply to a duct-
            </Typography>
            <Typography variant="body2">
              (i) that is not insulated, and (ii) that is constructed of- (A) rigid duct board; or
              (B) flexible ductwork.
            </Typography>
            <Typography variant="body2">
              (d) Material R-Values for heating and cooling ductwork insulation must be not less
              than the minimum values as follows:
            </Typography>
            <Typography variant="body2">
              (i) For heating and cooling ductwork located within a conditioned space-
            </Typography>
            <Typography variant="body2">
              (A) internal ductwork - R-Value of 1.0; and (B) external ductwork - R-Value of 1.5.
            </Typography>
            <Typography variant="body2">
              (ii) For heating and cooling ductwork located outside the building or in an unenclosed
              building subfloor or roof space-
            </Typography>
            <Typography variant="body2">
              (A) internal ductwork - R-Value of 1.0; and (B) external ductwork - R-Value of 2.0.
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '0.5rem' }} />

            <Typography variant="body2" sx={{ color: '#17a986' }}>
              3.12.5.4 Electric Resistance Space Heating
            </Typography>
            <Typography variant="body2">(a) Separate isolating switches for each room</Typography>
            <Typography variant="body2">
              (b) A separate temperature controller and time switch for each group of rooms with
              common heating needs
            </Typography>
            <Typography variant="body2">
              (c) Power loads of not more than 110 W/m² for living areas, and 150 W/m² for bathrooms
            </Typography>
            <Divider sx={{ borderStyle: 'none', py: '5rem' }} />

            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '10px' }}>
                  <img
                    src="./ea-report-footer.png"
                    alt="Logo"
                    style={{ width: '20vh', height: '10vh' }}
                  />
                </Box>
                <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
                <Stack direction="column" sx={{ marginTop: '45px' }}>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    energy@energyadvance.com.au
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
                  </Box>
                  <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                    ACN: 60 9332014 | 1300 850 228
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                  borderColor: '#919eab3d',
                  p: '0.5rem',
                  padding: '3rem',
                }}
              >
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                  COMPLIANCE STAMP NOT{' '}
                </Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}
