import {
  Button,
  Card,
  Container,
  // DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  // Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'src/utils/axios';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router';
// import { CSVLink } from 'react-csv';
import { Controller, useForm } from 'react-hook-form';
import { useSettingsContext } from 'src/components/settings';
import Label from 'src/components/label';
import { Helmet } from 'react-helmet-async';
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSnackbar } from '../../components/snackbar';
// import DownloadPDFButton from './GeneratePdf';

export default function JobReport() {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Job ID',
      width: 100,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobNumber',
      headerName: 'Job No.',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },

    {
      field: 'JobPrelimFinal',
      type: 'text',
      headerName: 'Prelim/Final',
      align: 'left',
      headerAlign: 'left',
      width: 80,
    },
    {
      field: 'JobType',
      type: 'singleSelect',
      headerName: 'Job Type',
      // valueOptions: ['New', 'Existing'],
      align: 'left',
      headerAlign: 'left',
      width: 80,
      renderCell: (params) => (
        <Typography variant="body2" noWrap>
          {params.row.JobType}
        </Typography>
      ),
    },
    {
      field: 'JobRating',
      headerName: 'Job Rating',
      width: 80,
      align: 'left',

      headerAlign: 'left',
    },
    {
      field: 'JobCode',
      headerName: 'Job Code',
      width: 100,

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobDwelling',
      headerName: 'Job Dwelling',
      align: 'left',
      headerAlign: 'left',
      width: 130,
    },
    {
      field: 'JobAddJobcode',
      headerName: 'Additional Job code',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'JobSlaTime',
      headerName: 'JobSla Time',
      align: 'left',
      headerAlign: 'left',
      width: 160,
    },
    {
      field: 'AWOH_VALID',
      headerName: 'WOH Status',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'Ncc_Type',
      headerName: 'Ncc Type',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'JobStatus',
      type: 'singleSelect',
      headerName: 'Status',
      valueOptions: [
        '',
        'new',
        'assigned',
        'assessment started',
        'workflow to action',
        'pending validation',
        // 'reassess initiated',
        // 'reassess started',
        // 'reassess completed',
        'marked as complete',
        'cancelled',
        'on hold',
        'exception',
      ],
      align: 'center',
      headerAlign: 'center',
      width: 130,
      renderCell: (params) => RenderStatus(params.row.JobStatus),
    },
    {
      field: 'JobCreatedAt',
      // type: 'dateTime',
      headerName: 'Created Date',
      align: 'left',
      headerAlign: 'left',
      width: 200,
    },

    // -------------
    {
      field: 'EmailRecordID',
      headerName: 'Email Record ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobAddrState',
      headerName: 'Job State',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobAssessorID',
      headerName: 'Assessor ID',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobRequestorID',
      headerName: 'Requestor ID',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'ReqName',
      headerName: 'Requestor Name',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'ReqSubLocation',
      headerName: 'Requestor Sublocation',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobEmailMsgID',
      headerName: 'Job Msg ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobFloors',
      headerName: 'Job Floors',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobIsUrgent',
      headerName: 'Urgent',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobPostAssessmentFlag',
      headerName: 'Post Assessment Flag',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobProcessedAt',
      headerName: 'Processed At',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobProcessedFlag',
      headerName: 'Processed Flag',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobRecordID',
      headerName: 'Job Record ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    // {
    //   field: 'JobRequestorID',
    //   headerName: 'Requestor ID',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
    {
      field: 'JobSiteAddr',
      headerName: 'Job Site Address',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
  ];
  // const getFormattedToday = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
  //   const day = String(today.getDate()).padStart(2, '0');
  //   return `${day}-${month}-${year}`;
  // };
  //   const { data } = location.state;
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  // const [selectedDate, setSelectedDate] = useState('');
  // const [assessorName, setAssessorName] = useState('Jayvion Saimon');
  // const [assessorId, setSelectedAssessorIdTemp] = useState<any>(null);
  // const [singleAssessorId, setSingleAssessorId] = useState(null);
  // const [leaveType, setLeaveType] = useState('leave');
  // const [startDate, setStartDate] = useState(getFormattedToday);
  // const [endDate, setEndDate] = useState(getFormattedToday);
  // const [remark, setRemark] = useState('');
  const [jobStatusDropdown, setJobStatusDropdown] = useState<any>();
  const [jobPeriodDropdown, setJobPeriodDropdown] = useState<any>();
  // const [allAssessorSelected, setAllAssessorSelected] = useState(false);
  // const [leaveData, setLeaveData] = useState<any[]>([]);
  // const [assessorLeaveData, setAssessorLeaveData] = useState<any[]>([]);
  // const [assessorHolidayData, setAssessorHolidayData] = useState<any[]>([]);
  // const [activeAssessors, setActiveAssessors] = useState([]);
  // const [user_id, setUserId] = useState<any>(null);
  // const [start_date, setStartDateTemp] = useState<any>(null);
  // const [open, setOpen] = useState(false);
  // const [rowData, setRowData] = useState(null);
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const [, setStartDateGreater] = useState(false);
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const [jobReportData, setJobReportData] = useState<any[]>([]);
  const [outstandingJobs, setOutstandingJobs] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const goToReports = () => {
    navigate('/reports');
  };

  const handleDisableJobStatus = (event: any) => {
    console.log('Radio value = ', event.target.value);
    setJobStatusDropdown(false);
    setOutstandingJobs('true');
  };

  const handleDisablePeriod = (event: any) => {
    setJobPeriodDropdown(false);
  };

  const onSubmit = (data: any) => {
    if (data?.period === 'custom'){
      data.period = ''
    }
    if (jobStatusDropdown === false) {
      data.userId = user?.ID;
      data.jobStatus = '';
      data.outstandingJobs = true;
    }
    if (data?.period !== ''){
      data.startDate = '';
      data.endDate = '';
    }

    if (data.jobStatus !== '') {
      data.outstandingJobs = false;
    }
    if (jobPeriodDropdown === false) {
      data.userId = user?.ID;
      data.period = '';
      if (jobStatusDropdown !== false) {
        data.outstandingJobs = false;
      }
    }
    data.userId = user?.ID;
    console.log('Job report data = ', data);
    axios
      .post('/core/createJobReport', data)
      .then((response) => {
        console.log('Response from backend:', response.data);
        if (response.data === null) {
          //  enqueueSnackbar('No Jobs Data Available', { variant: 'error' });
          setJobReportData([]);
        }
        const modifiedData = response.data.map(({ JobRecordID, ...job }: any) => ({
          id: JobRecordID,
          // JobCreatedAt: new Date(JobCreatedAt),
          ...job,
        }));

        setJobReportData(modifiedData as any[]);
      })
      .catch((error) => {
        console.error('Error sending data to backend:', error);
        // if (error?.response?.data !== null) {
        enqueueSnackbar(error?.error, { variant: 'error' });
       // }
        // setShowErrorMessage(true);
      });
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleReset = () => {
    setJobStatusDropdown(null);
    setJobPeriodDropdown(null);
    setOutstandingJobs('');
    setJobReportData([]);
    reset();
  };

  // const csvData = jobReportData.map((item) => ({
  //   JobRecordID: item.JobRecordID,
  //   JobNumber: item.JobNumber,
  //   EmailRecordID: item.EmailRecordID,
  //   JobEmailMsgID: item.JobEmailMsgID,
  //   JobEmailSubject: item.JobEmailSubject,
  //   JobFloors: item.JobFloors,
  //   JobRequestorID: item.JobRequestorID,
  //   JobSiteAddr: item.JobSiteAddr,
  //   JobAddrState: item.JobAddrState,
  //   JobIsUrgent: item.JobIsUrgent,
  //   JobCode: item.JobCode,
  //   JobType: item.JobType,
  //   JobPrelimFinal: item.JobPrelimFinal,
  //   JobAssessorID: item.JobAssessorID,
  //   JobReprocessFlag: item.JobReprocessFlag,
  //   JobDwelling: item.JobDwelling,
  //   JobStatus: item.JobStatus,
  //   JobProcessedFlag: item.JobProcessedFlag,
  //   JobProcessedAt: item.JobProcessedAt,
  //   JobCreatedAt: item.JobCreatedAt,
  //   JobRating: item.JobRating,
  //   JobPostAssessmentFlag: item.JobPostAssessmentFlag,
  // }));

  // Define headers for the CSV file (optional)
  // const headers = [
  //   { label: 'Job Record ID', key: 'JobRecordID' },
  //   { label: 'Job Number', key: 'JobNumber' },
  //   { label: 'Email Record ID', key: 'EmailRecordID' },
  //   { label: 'Job Email Msg ID', key: 'JobEmailMsgID' },
  //   { label: 'Job Email Subject', key: 'JobEmailSubject' },
  //   { label: 'Job Floors', key: 'JobFloors' },
  //   { label: 'Requestor ID', key: 'JobRequestorID' },
  //   { label: 'Job Site Address', key: 'JobSiteAddr' },
  //   { label: 'State', key: 'JobAddrState' },
  //   { label: 'Job Is Urgent', key: 'JobIsUrgent' },
  //   { label: 'Job Code', key: 'JobCode' },
  //   { label: 'Job Type', key: 'JobType' },
  //   { label: 'Job Prelim Final', key: 'JobPrelimFinal' },
  //   { label: 'Assessor ID', key: 'JobAssessorID' },
  //   { label: 'Reprocess Flag', key: 'JobReprocessFlag' },
  //   { label: 'Job Dwelling', key: 'JobDwelling' },
  //   { label: 'Job Status', key: 'JobStatus' },
  //   { label: 'Processed Flag', key: 'JobProcessedFlag' },
  //   { label: 'Job Processed At', key: 'JobProcessedAt' },
  //   { label: 'Job Created At', key: 'JobCreatedAt' },
  //   { label: 'Job Rating', key: 'JobRating' },
  //   { label: 'Post Assessmnet Flag', key: 'JobPostAssessmentFlag' },
  // ];

  return (
    <Container sx={{ maxWidth: '80% !important', paddingTop: '20px', paddingBottom: '10px' }}>
      <Helmet>
        <title> Job Report | Energy Advance</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ float: 'left' }}>
        <CustomBreadcrumbs
          heading="Job Report"
          sx={{ marginTop: '50px' }}
          links={[
            {
              name: 'Reports',
              href: '/reports',
            },
            { name: 'Job Report' },
          ]}
        />
      </Container>
      <Card sx={{ py: 4, px: 5, textAlign: 'center', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
            <Grid item xs={2} md={6} sx={{ alignItems: 'center' }}>
              <div>
                <Typography variant="body1">Select Job Status</Typography>
                <FormControl sx={{ width: '400px' }}>
                  <Controller
                    name="jobStatus"
                    control={control}
                    defaultValue="assigned"
                    rules={{ required: 'Please select Job Status.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        disabled={jobStatusDropdown === false}
                        onChange={(event) => {
                          field.onChange(event);
                          setJobStatusDropdown(true);
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="new">New</MenuItem>
                        <MenuItem value="assigned">Assigned</MenuItem>
                        <MenuItem value="assessment started">Assessment Started</MenuItem>
                        <MenuItem value="workflow to action">Workflow to Action</MenuItem>
                        <MenuItem value="pending validation">Pending Validation</MenuItem>
                        <MenuItem value="marked as complete">Marked as Complete</MenuItem>
                        {/* <MenuItem value="reassess initiated">Reassess Initiated</MenuItem>
                        <MenuItem value="reassess started">Reassess Started</MenuItem>
                        <MenuItem value="reassess completed">Reassess Completed</MenuItem> */}
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                        <MenuItem value="on hold">On Hold</MenuItem>
                        <MenuItem value="exception">Exception</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
              <Typography variant="h5" sx={{ marginTop: '30px' }}>
                --- OR ---
              </Typography>
              <FormControl sx={{ marginTop: '60px' }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // value={leaveType}
                  value={outstandingJobs}
                  onChange={handleDisableJobStatus}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    // value="outstanding jobs"
                    control={<Radio />}
                    label="Active Jobs"
                    disabled={jobStatusDropdown === true}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={2} md={6}>
              <div>
                <Typography variant="body1">Select Period or Date range</Typography>
                <FormControl sx={{ width: '400px' }} error={!!errors.selectedAssessorId}>
                  <Controller
                    name="period"
                    control={control}
                    defaultValue="daily"
                    rules={{ required: 'Please select Period.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        // disabled={jobPeriodDropdown === false}
                        onChange={(event) => {
                          field.onChange(event);
                          setJobPeriodDropdown(event.target.value !== 'custom'); // Disable dropdown if not 'custom'
                        }}
                      >
                       <MenuItem key="custom" value="custom">
                          Custom
                        </MenuItem>
                        <MenuItem key="daily" value="daily">
                          Daily
                        </MenuItem>
                        <MenuItem key="weekly" value="weekly">
                          Weekly
                        </MenuItem>
                        <MenuItem key="monthly" value="monthly">
                          Monthly
                        </MenuItem>
                      </Select>
                    )}
                  />
                  {/* <FormHelperText>{errors.selectedAssessorId?.message}</FormHelperText> */}
                </FormControl>
              </div>
              {jobPeriodDropdown === false && (
    <>
              <Typography variant="h5" sx={{ marginTop: '30px' }}>
                {/* --- OR --- */}
              </Typography>
              {/* <Grid item xs={2} md={12}> */}
              <div style={{ marginTop: '30px' }}>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue=""
                  // rules={{ required: 'Please select a Start Date.' }}
                  render={({ field }) => (
                    <TextField
                      // disabled={jobPeriodDropdown === true}
                      // sx={{ marginTop: '20px', marginRight: '20px' }}
                      {...field}
                      id="date"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: '20px', marginRight: '20px' }}
                      error={!!errors.startDate}
                     /* onChange={(event) => {
                        field.onChange(event); // This ensures the form state is updated
                        handleDisablePeriod(event);
                      }} */
                    />
                  )}
                />

                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  // rules={{ required: 'Please select an End Date.' }}
                  render={({ field }) => (
                    <TextField
                    //  disabled={jobPeriodDropdown === true}
                      {...field}
                      id="date"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: '20px' }}
                      error={!!errors.endDate}
                    />
                  )}
                />
              </div>
              </>
  )}

              {/* </Grid> */}
            </Grid>

            <Grid
              container
              xs={12}
              md={12}
              textAlign="center"
              justifyContent="space-between"
              sx={{ marginTop: '20px' }}
            >
              <Grid item md={9} />
              <Grid item md={1} textAlign="center">
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
                {/* <CSVLink
                  data={csvData}
                  headers={headers}
                  filename="job_report.csv"
                  className="btn btn-primary"
                >
                  Download as CSV
                </CSVLink> */}
              </Grid>
              <Grid item md={0.7} textAlign="center">
                <Button variant="contained" color="primary" onClick={handleReset}>
                  Reset
                </Button>
              </Grid>
              <Grid item md={0.7} textAlign="center">
                <Button variant="contained" color="secondary" onClick={goToReports}>
                  Cancel
                </Button>
              </Grid>
              {/* <DownloadPDFButton data={jobReportData} /> */}
            </Grid>
          </Grid>
        </form>
      </Card>

      <DataGrid
        // checkboxSelection
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: 'id', sort: 'desc' }],
        //   },
        // }}
        sx={{ marginTop: '20px', height: '500px' }}
        disableRowSelectionOnClick
        rows={jobReportData}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              EmailRecordID: false,
              JobAddrState: false,
              JobEmailMsgID: false,
              JobEmailSubject: false,
              JobFloors: false,
              JobIsUrgent: false,
              JobPostAssessmentFlag: false,
              JobProcessedAt: false,
              JobProcessedFlag: false,
              JobRecordID: false,
              JobRequestorID: true,
              JobSiteAddr: false,
            },
          },
        }}
        pagination
        onRowSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        slots={{
          toolbar: MyExportButton,
        }}
      />
    </Container>
  );
}

function RenderStatus(getStatus: string) {
  let backgroundColor = '';
  switch (getStatus) {
    case 'exception':
      backgroundColor = 'tomato';
      break;
    case 'pending validation':
    case 'marked as complete':
      backgroundColor = 'seagreen';
      break;
    // case 'reassess initiated':
    //     backgroundColor = '#B7A57A';
    //     break;
    // case 'reassess started':
    //       backgroundColor = '#1997D1';
    //     break;
    // case 'reassess completed':
    //       backgroundColor = '#AD66BB';
    //     break;
    case 'assessment started':
      backgroundColor = 'steelblue';
      break;
    case 'workflow to action':
      backgroundColor = 'limegreen';
      break;
    case 'new':
      backgroundColor = 'grey';
      break;
    case 'assigned':
      backgroundColor = 'mediumslateblue';
      break;
    case 'on hold':
    case 'cancelled':
      backgroundColor = 'orange';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStatus}
      </Label>
    </div>
  );
}
function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Job_Report_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fields: [
              'id',
              'JobNumber',
              'ReqName',
              'UserFname',
              'JobPrelimFinal',
              'JobType',
              'JobAddJobcode',
              'JobSlaTime',
              'AWOH_VALID',
              'Ncc_Type',
              'JobRating',
              'JobCode',
              'JobDwelling',
              'JobStatus',
              'JobCreatedAt',
              'EmailRecordID',
              'JobAddrState',
              'JobAssessorID',
              'JobEmailMsgID',
              'JobFloors',
              'JobIsUrgent',
              'JobPostAssessmentFlag',
              'JobProcessedAt',
              'JobProcessedFlag',
              'JobRecordID',
              'JobRequestorID',
              'JobSiteAddr',
            ],
            fileName: file_Name,
            allColumns: true,
            // delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
