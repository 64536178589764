import {
  Button,
  Card,
  Container,
  // DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  // Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'src/utils/axios';
// import { useAuthContext } from 'src/auth/useAuthContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// import { CSVLink } from 'react-csv';
import { Controller, useForm } from 'react-hook-form';
import { useSettingsContext } from 'src/components/settings';
import { Helmet } from 'react-helmet-async';
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Label from 'src/components/label';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSnackbar } from '../../components/snackbar';
// import DownloadPDFButton from './GeneratePdf';

// interface Assessor {
//   ID: number;
//   UserUsername: string;
//   UserFname: string;
//   UserLname: string;
//   UserGender: string;
//   UserEmail: string;
//   UserPhone: string;
//   UserStreetAddr: string;
//   UserState: string;
//   UserZipcode: string;
//   UserCountry: string;
//   UserStatus: string;
//   UserProfilePic: string | null;
//   UserRole: string;
//   UserPwdID: number;
//   UserTimezone: string | null;
//   UserLastLogin: string | null;
//   UserCreatedAt: string;
//   UserCreatedBy: number;
//   UserToken: string | null;
//   UserRefreshToken: string | null;
//   UserOccupency: number;
// }

type Requestors = {
  ID: number;
  ReqName: string;
  JobRequestorID: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: string;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  ReqJobTypes: string;
  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
};

export default function RequestorReport() {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Job ID',
      width: 100,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobNumber',
      headerName: 'Job No.',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },

    {
      field: 'JobRequestorName',
      headerName: 'Requestor Name',
      align: 'left',
      headerAlign: 'left',
      width: 200,
    },

    {
      field: 'JobPrelimFinal',
      type: 'text',
      headerName: 'Prelim/Final',
      align: 'left',
      headerAlign: 'left',
      width: 150,
    },
    {
      field: 'JobType',
      type: 'singleSelect',
      headerName: 'Job Type',
      // valueOptions: ['New', 'Existing'],
      align: 'left',
      headerAlign: 'left',
      width: 150,
      renderCell: (params: any) => (
        <Typography variant="body2" noWrap>
          {params.row.JobType}
        </Typography>
      ),
    },
    {
      field: 'JobRating',
      headerName: 'Job Rating',
      width: 100,
      align: 'left',

      headerAlign: 'left',
    },
    {
      field: 'JobCode',
      headerName: 'Job Code',
      width: 100,

      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobDwelling',
      headerName: 'Job Dwelling',
      align: 'left',
      headerAlign: 'left',
      width: 120,
    },
    {
      field: 'JobSlaTime',
      type: 'text',
      headerName: 'JobSla Time',
      align: 'left',
      headerAlign: 'left',
      width: 200,
    },
    {
      field: 'JobAddJobcode',
      type: 'text',
      headerName: 'Additional Job COde',
      align: 'left',
      headerAlign: 'left',
      width: 200,
    },
    {
      field: 'AWOH_VALID',
      headerName: 'WOH Status',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'Ncc_Type',
      headerName: 'Ncc Type',
      align: 'left',
      headerAlign: 'left',
      width: 100,
    },
    {
      field: 'JobStatus',
      type: 'singleSelect',
      headerName: 'Status',
      valueOptions: [
        '',
        'new',
        'assigned',
        'assessment started',
        'workflow to action',
        'pending validation',
        // 'reassess initiated',
        // 'reassess started',
        // 'reassess completed',
        'marked as complete',
        'cancelled',
        'on hold',
        'exception',
      ],
      align: 'center',
      headerAlign: 'center',
      width: 200,
      renderCell: (params: any) => RenderStatus(params.row.JobStatus),
    },
    {
      field: 'JobCreatedAt',
      // type: 'dateTime',
      headerName: 'Created Date',
      align: 'left',
      headerAlign: 'left',
      width: 200,
    },

    // -------------
    {
      field: 'EmailRecordID',
      headerName: 'Email Record ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobAddrState',
      headerName: 'Job State',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobAssessorID',
      headerName: 'Assessor ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobEmailMsgID',
      headerName: 'Job Msg ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobFloors',
      headerName: 'Job Floors',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobIsUrgent',
      headerName: 'Urgent',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobPostAssessmentFlag',
      headerName: 'Post Assessment Flag',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobProcessedAt',
      headerName: 'Processed At',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobProcessedFlag',
      headerName: 'Processed Flag',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobRecordID',
      headerName: 'Job Record ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobRequestorID',
      headerName: 'Requestor ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'JobSiteAddr',
      headerName: 'Job Site Address',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const goToReports = () => {
    navigate('/reports');
  };
  // const getFormattedToday = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
  //   const day = String(today.getDate()).padStart(2, '0');
  //   return `${day}-${month}-${year}`;
  // };
  //   const { data } = location.state;
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [allRequestor, setAllRequestor] = useState('');
  // const [selectedDate, setSelectedDate] = useState('');
  // const [assessorName, setAssessorName] = useState('Jayvion Saimon');
  // const [assessorId, setSelectedAssessorIdTemp] = useState<any>(null);
  // const [singleAssessorId, setSingleAssessorId] = useState(null);
  // const [leaveType, setLeaveType] = useState('leave');
  // const [startDate, setStartDate] = useState(getFormattedToday);
  // const [endDate, setEndDate] = useState(getFormattedToday);
  // const [remark, setRemark] = useState('');
  const [assessorDropdown, setAssessorDropdown] = useState<any>();
  const [jobPeriodDropdown, setJobPeriodDropdown] = useState<any>();
  // const [allAssessorSelected, setAllAssessorSelected] = useState(false);
  // const [leaveData, setLeaveData] = useState<any[]>([]);
  // const [assessorLeaveData, setAssessorLeaveData] = useState<any[]>([]);
  // const [assessorHolidayData, setAssessorHolidayData] = useState<any[]>([]);
  // const [activeAssessors, setActiveAssessors] = useState([]);
  // const [user_id, setUserId] = useState<any>(null);
  // const [start_date, setStartDateTemp] = useState<any>(null);
  // const [open, setOpen] = useState(false);
  // const [rowData, setRowData] = useState(null);
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const [, setStartDateGreater] = useState(false);
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  // const { user } = useAuthContext();
  const [jobReportData, setJobReportData] = useState<any[]>([]);
  // const [assessor, setAssessor] = useState<Assessor[] | null>(null);
  const [performance, setPerformance] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [requestors, setRequestors] = useState<Requestors[] | null>(null);

  useEffect(() => {
    axios
      .get(`/core/getActiveRequestors`)
      .then((response) => {
        setRequestors(response.data as Requestors[]);
      })
      .catch((error) => {
        console.error('Error fetching active Requestors:', error);
      });
  }, []);

  const handleDisableAssessorDropdown = (event: any) => {
    console.log('Radio value = ', event.target.value);
    setAllRequestor('true');
    setAssessorDropdown(false);
  };

  const handlePerformance = (event: any) => {
    if (event.target.value === 'include') {
      setPerformance('include');
    } else if (event.target.value === 'exclude') {
      setPerformance('exclude');
    }
    console.log('Preformance = ', performance);
  };

  const handleDisablePeriod = (event: any) => {
    setJobPeriodDropdown(false);
  };

  const {
    // watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const value = watch();
  const onSubmit = (data: any) => {
    if (assessorDropdown === false) {
      data.ID = '';
      data.all = 'true';
    } else {
      data.ID = String(data.ID);
      data.all = 'false';
    }
    if (jobPeriodDropdown === false) {
      data.period = '';
    }

    data.filter = performance;
    console.log('Requestor report data = ', data);
    axios
      .post('/core/getRequestorReport', data)
      .then((response) => {
        if (response.data?.jobs === null) {
         // enqueueSnackbar('No Requestor Data Available', { variant: 'error' });
          setJobReportData([]);
        }
        const modifiedData = response.data.jobs.map(({ JobRecordID, ...job }: any) => ({
          id: JobRecordID,
          // JobCreatedAt: new Date(JobCreatedAt),
          ...job,
        }));
        setJobReportData(modifiedData as any[]);
        console.log('Response from backend:', response.data);
        console.log('Response from backend in job report data:', jobReportData);
      })
      .catch((error) => {
        console.error('Error sending data to backend:', error);
        // setShowErrorMessage(true);
        enqueueSnackbar(error?.error, { variant: 'error' });
      });
  };

  const handleReset = () => {
    setAssessorDropdown(null);
    setJobPeriodDropdown(null);
    setAllRequestor('');
    setJobReportData([]);
    reset();
  };

  return (
    <Container sx={{ maxWidth: '80% !important', paddingTop: '20px', paddingBottom: '10px' }}>
      <Helmet>
        <title> Requestor Report | Energy Advance</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ float: 'left' }}>
        <CustomBreadcrumbs
          heading="Requestor Report"
          sx={{ marginTop: '50px' }}
          links={[
            {
              name: 'Reports',
              href: '/reports',
            },
            { name: 'Requestor Report' },
          ]}
        />
      </Container>
      <Card sx={{ py: 4, px: 5, textAlign: 'center', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
            <Grid item xs={2} md={6} sx={{ alignItems: 'center' }}>
              <div>
                <Typography variant="body1">Select Requestor</Typography>
                <FormControl sx={{ width: '400px' }}>
                  <Controller
                    name="ID"
                    control={control}
                    rules={{ required: 'Please select Requestor.' }}
                    defaultValue={2}
                    render={({ field }) => (
                      <Select
                        {...field}
                        disabled={assessorDropdown === false}
                        onChange={(event) => {
                          field.onChange(event);
                          setAssessorDropdown(true);
                        }}
                      >
                        {requestors &&
                          requestors.map((requestor) => (
                            <MenuItem key={requestor.ID} value={requestor.ID}>
                              {requestor.ReqName}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                  {/* Add error handling, e.g., FormHelperText */}
                </FormControl>
              </div>
              <Typography variant="h5" sx={{ marginTop: '30px' }}>
                --- OR ---
              </Typography>
              <FormControl sx={{ marginTop: '60px' }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={allRequestor}
                  onChange={handleDisableAssessorDropdown}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="All Requestors"
                    disabled={assessorDropdown === true}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={2} md={6}>
              <div>
                <Typography variant="body1">Select Period or Date range</Typography>
                <FormControl sx={{ width: '400px' }} error={!!errors.selectedAssessorId}>
                  <Controller
                    name="period"
                    control={control}
                    defaultValue="daily"
                    rules={{ required: 'Please select Period.' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                       // disabled={jobPeriodDropdown === false}
                        onChange={(event) => {
                          field.onChange(event);
                          setJobPeriodDropdown(event.target.value !== 'custom');
                        }}
                      >
                        <MenuItem key="custom" value="custom">
                          Custom
                        </MenuItem>
                        <MenuItem key="daily" value="daily">
                          Daily
                        </MenuItem>
                        <MenuItem key="weekly" value="weekly">
                          Weekly
                        </MenuItem>
                        <MenuItem key="monthly" value="monthly">
                          Monthly
                        </MenuItem>
                      </Select>
                    )}
                  />
                  {/* <FormHelperText>{errors.selectedAssessorId?.message}</FormHelperText> */}
                </FormControl>
              </div>
              {jobPeriodDropdown === false && (
    <>
              <Typography variant="h5" sx={{ marginTop: '30px' }}>
                {/* --- OR --- */}
              </Typography>
              {/* <Grid item xs={2} md={12}> */}
              <div style={{ marginTop: '30px' }}>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue=""
                  // rules={{ required: 'Please select a Start Date.' }}
                  render={({ field }) => (
                    <TextField
                     // sx={{ marginTop: '20px', marginRight: '20px' }}
                      {...field}
                      id="date"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: '20px', marginRight: '20px' }}
                      error={!!errors.start_date}
                     /*  onChange={(event) => {
                        field.onChange(event);
                        handleDisablePeriod(event);
                      }} */
                      disabled={jobPeriodDropdown === true}
                    />
                  )}
                />

                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  // rules={{ required: 'Please select an End Date.' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="date"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: '20px' }}
                      error={!!errors.end_date}
                      disabled={jobPeriodDropdown === true}
                    />
                  )}
                />
              </div>
              </>
            )}
              {/* </Grid> */}
            </Grid>

            <Grid container xs={12} md={12} textAlign="center" sx={{ marginTop: '20px' }}>
              <Grid item md={9} />
              <Grid item md={1} textAlign="center">
                <Button variant="contained" color="primary" type="submit" sx={{ float: 'right' }}>
                  Submit
                </Button>
                {/* <CSVLink
                  data={csvData}
                  headers={headers}
                  filename="job_report.csv"
                  className="btn btn-primary"
                >
                  Download as CSV
                </CSVLink> */}
              </Grid>
              <Grid item md={0.7} textAlign="center" sx={{ marginLeft: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                  sx={{ float: 'right' }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item md={0.7} textAlign="center" sx={{ marginLeft: '20px' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={goToReports}
                  sx={{ float: 'right', marginLeft: '20px' }}
                >
                  Cancel
                </Button>
              </Grid>
              {/* <DownloadPDFButton data={jobReportData} /> */}
            </Grid>
          </Grid>
        </form>
      </Card>

      <DataGrid
        // checkboxSelection
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: 'id', sort: 'desc' }],
        //   },
        // }}
        sx={{ marginTop: '20px', height: '500px' }}
        disableRowSelectionOnClick
        rows={jobReportData}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              EmailRecordID: false,
              JobAddrState: false,
              JobAssessorID: false,
              JobEmailMsgID: false,
              JobEmailSubject: false,
              JobFloors: false,
              JobIsUrgent: false,
              JobPostAssessmentFlag: false,
              JobProcessedAt: false,
              JobProcessedFlag: false,
              JobRecordID: false,
              JobRequestorID: false,
              JobSiteAddr: false,
            },
          },
        }}
        pagination
        onRowSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        slots={{
          toolbar: MyExportButton,
        }}
      />
    </Container>
  );
}

function RenderStatus(getStatus: string) {
  let backgroundColor = '';
  switch (getStatus) {
    case 'exception':
      backgroundColor = 'tomato';
      break;
    case 'marked as complete':
      break;
    // case 'reassess initiated':
    //   backgroundColor = '#B7A57A';
    //   break;
    // case 'reassess started':
    //   backgroundColor = '#1997D1';
    //   break;
    // case 'reassess completed':
    //   backgroundColor = '#AD66BB';
    //   break;
    case 'pending validation':
      backgroundColor = 'seagreen';
      break;
    case 'assessment started':
      backgroundColor = 'steelblue';
      break;
    case 'workflow to action':
      backgroundColor = 'limegreen';
      break;
    case 'new':
      backgroundColor = 'grey';
      break;
    case 'assigned':
      backgroundColor = 'mediumslateblue';
      break;
    case 'on hold':
    case 'cancelled':
      backgroundColor = 'orange';
      break;
    default:
      backgroundColor = '';
  }

  const labelStyle = {
    backgroundColor,
    color: 'white',
  };

  return (
    <div>
      <Label variant="filled" sx={{ mx: 'auto' }} style={labelStyle}>
        {getStatus}
      </Label>
    </div>
  );
}

function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Requestor_Report_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fields: [
              'id',
              'JobNumber',
              'ReqName',
              'UserFname',
              'JobPrelimFinal',
              'JobType',
              'JobRating',
              'JobCode',
              'JobDwelling',
              'JobSlaTime',
              'JobAddJobcode',
              'AWOH_VALID',
              'Ncc_Type',
              'JobStatus',
              'JobCreatedAt',
              'EmailRecordID',
              'JobAddrState',
              'JobAssessorID',
              'JobEmailMsgID',
              'JobFloors',
              'JobIsUrgent',
              'JobPostAssessmentFlag',
              'JobProcessedAt',
              'JobProcessedFlag',
              'JobRecordID',
              'JobRequestorID',
              'JobSiteAddr',
            ],
            fileName: file_Name,
            allColumns: true,
            // delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
