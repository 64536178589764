import {
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'src/utils/axios';
// import { useAuthContext } from 'src/auth/useAuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { useSettingsContext } from 'src/components/settings';
// import Label from 'src/components/label';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from 'src/components/snackbar';
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

export default function HolidayReport() {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [lastThirtyDays, setLastThirtyDays] = useState('');
  const [assessorLeaveData, setAssessorLeaveData] = useState<any[]>([]);
  // const [showErrorMessage, setShowErrorMessage] = useState(false);
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleDisableDateRange = (event: any) => {
    console.log('Radio value = ', event.target.value);
    setLastThirtyDays('true');
  };

  const handleDisablePeriod = (event: any) => {
    setLastThirtyDays('false');
  };

  const onSubmit = (data: any) => {
    if (lastThirtyDays === 'true') {
      data.last_30_days = true;
      data.end_date = '';
      data.start_date = '';
    } else {
      data.last_30_days = false;
    }
    console.log('Holiday report data = ', data);
    axios
      .post('/core/getHolidayData', data)
      .then((response) => {
        console.log('Response from backend:', response.data);
        if (response.data === null) {
          // enqueueSnackbar('No Holidays/Leaves Available', { variant: 'error' });
          setAssessorLeaveData([]);
        }
        const newArray = response.data.map((obj: any, index: number) => ({
          id: index,
          ...obj,
          assessorFName: 'hello',
          assessorLName: 'World',
        }));
        setAssessorLeaveData(newArray);
      })
      .catch((error) => {
        console.error('Error sending data to backend:', error);
        enqueueSnackbar(error?.error, { variant: 'error' });
        // setShowErrorMessage(true);
      });
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleReset = () => {
    // setLastThirtyDays(null);
    setLastThirtyDays('');
    setAssessorLeaveData([]);
    reset();
  };

  const leave_columns: GridColDef[] = [
    {
      field: 'UserID',
      headerName: 'Id',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      hideable: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'FirstName',
      headerName: 'First Name',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'LastName',
      headerName: 'Last Name',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'LeaveStartDate',
      headerName: 'Start Date',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params: any) => (
      //   <div>
      //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
      //   </div>
      // ),
    },
    {
      field: 'LeaveEndDate',
      headerName: 'End Date',
      align: 'center',
      headerAlign: 'center',
      width: 300,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params: any) => (
      //   <div>
      //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
      //   </div>
      // ),
    },
    {
      field: 'LeaveReason',
      headerName: 'Remark',
      // align: 'center',
      // headerAlign: 'center',
      width: 650,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params: any) => (
      //   <div>
      //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
      //   </div>
      // ),
    },
  ];
  const goToReports = () => {
    navigate('/reports');
  };
  const currentDate = new Date().toISOString().split('T')[0];
  return (
    <Container sx={{ maxWidth: '80% !important', paddingTop: '20px', paddingBottom: '10px' }}>
      <Helmet>
        <title> Holiday Report | Energy Advance</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ float: 'left' }}>
        <CustomBreadcrumbs
          heading="Holiday Report"
          sx={{ marginTop: '50px' }}
          links={[
            {
              name: 'Reports',
              href: '/reports',
            },
            { name: 'Holiday Report' },
          ]}
        />
      </Container>
      <Card sx={{ py: 4, px: 5, textAlign: 'center', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
            <Grid item xs={2} md={6} sx={{ alignItems: 'center' }}>
              <div>
                <Typography variant="body1">Select Team/s</Typography>
                <FormControl sx={{ width: '400px' }}>
                  <Controller
                    name="filter"
                    control={control}
                    defaultValue="Assessor"
                    rules={{ required: 'Please select Job Status.' }}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem key="Workflow" value="Workflow">
                          Workflow
                        </MenuItem>
                        <MenuItem key="Assessor" value="Assessor">
                          Assessor
                        </MenuItem>
                        <MenuItem key="all" value="all">
                          All
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={2} md={6}>
              <div>
                <FormControl sx={{ marginTop: '20px' }}>
                  <RadioGroup
                    value={lastThirtyDays}
                    aria-labelledby="demo-radio-buttons-group-label"
                    onChange={handleDisableDateRange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Last 30 Days"
                      disabled={lastThirtyDays === 'false'}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <Typography variant="h5" sx={{ marginTop: '30px' }}>
                --- OR ---
              </Typography>
              {/* <Grid item xs={2} md={12}> */}
              <div style={{ marginTop: '30px' }}>
                <Controller
                  name="start_date"
                  control={control}
                  defaultValue={currentDate} // Set the default value to today's date
                  render={({ field }) => (
                    <TextField
                      disabled={lastThirtyDays === 'true'}
                      sx={{ marginTop: '20px', marginRight: '20px' }}
                      {...field}
                      id="date"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.start_date}
                      onChange={(event) => {
                        field.onChange(event);
                        handleDisablePeriod(event);
                      }}
                    />
                  )}
                />

                <Controller
                  name="end_date"
                  control={control}
                  defaultValue={currentDate} // Set the default value to today's date
                  render={({ field }) => (
                    <TextField
                      disabled={lastThirtyDays === 'true'}
                      {...field}
                      id="date"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: '20px' }}
                      error={!!errors.end_date}
                    />
                  )}
                />
              </div>

              {/* </Grid> */}
            </Grid>

            <Grid
              container
              xs={12}
              md={12}
              textAlign="center"
              justifyContent="space-between"
              sx={{ marginTop: '20px' }}
            >
              <Grid item md={9} />
              <Grid item md={1} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
                >
                  Submit
                </Button>
                {/* <CSVLink
                    data={csvData}
                    headers={headers}
                    filename="job_report.csv"
                    className="btn btn-primary"
                  >
                    Download as CSV
                  </CSVLink> */}
              </Grid>
              <Grid item md={0.7} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                  sx={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item md={0.7} textAlign="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={goToReports}
                  sx={{ float: 'right', marginTop: '20px', marginRight: '10px' }}
                >
                  Cancel
                </Button>
              </Grid>
              {/* <DownloadPDFButton data={jobReportData} /> */}
            </Grid>
          </Grid>
        </form>
      </Card>

      {/* <DataGrid
        // checkboxSelection
        // initialState={{
        //   sorting: {
        //     sortModel: [{ field: 'id', sort: 'desc' }],
        //   },
        // }}
        sx={{ marginTop: '20px', height: '500px' }}
        disableRowSelectionOnClick
        rows={assessorLeaveData}
        columns={leave_columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        pagination
        onRowSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        slots={{
          toolbar: MyExportButton,
        }}
      /> */}

      <DataGrid
        sx={{ marginTop: '20px', height: '500px' }}
        disableRowSelectionOnClick
        rows={assessorLeaveData}
        columns={leave_columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        pagination
        onRowSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        slots={{
          toolbar: MyExportButton,
        }}
      />
    </Container>
  );
}

function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Holiday_Report_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem
          options={{
            fields: [
              'UserID',
              'FirstName',
              'LastName',
              'LeaveStartDate',
              'LeaveEndDate',
              'LeaveReason',
            ],
            fileName: file_Name,
            allColumns: true,
            utf8WithBom: true,
          }}
        />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
