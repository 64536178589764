import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';

interface Props {
  reportData: any;
}

export default function Specifications({
  reportData,
  saveReportData,
  setSaveReportData,
  invalidFields,
}: any) {
  const [sparking, setSparking] = useState('');
  const [thermalBreakRoof, setthermalBreakRoof] = useState('');
  const [charactristic, setCharactristic] = useState('');
  const [windowType, setWindowsType] = useState('');

  useEffect(() => {
    if (reportData?.projectInfoData?.NccType === '2022') {
      modifyWallObject2022(saveReportData?.Walls?.wallsData);
    } else {
      const wallsDataArray = saveReportData?.Walls?.wallsData || [];
      const modifiedWallsData: any[] = wallsDataArray.map((wall: any) =>
        modifyWallObject2019(wall, wall?.additional_note)
      );
      // console.log('Modified wall data:', modifiedWallsData);
      setSaveReportData((prevData: any) => ({
        ...prevData,
        Walls: {
          ...prevData?.Walls,
          wallsData: modifiedWallsData,
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData?.wallsData]);

  useEffect(() => {
    if (reportData?.roofsceilingsData) {
      const roofsDataArray = saveReportData?.Roofs?.roofData || [];
      const modifiedRoofsData: any[] = roofsDataArray.map((roof: any) => {
        const constructionType = determineRoofType(roof.roof_type);
        const ceiling = calculateCeilingInsulation(roof.bulk_ceiling_insulation);
        const blanket = calculateBlanketInsulation(roof.bulk_roof_insulation);

        roof.construction_type = constructionType;
        roof.ceiling_insulation = ceiling;
        roof.blanket = blanket;

        return roof;
      });

      setSaveReportData((prevData: any) => ({
        ...prevData,
        Roofs: {
          ...prevData?.Roofs,
          roofData: modifiedRoofsData,
        },
      }));
    }

    // Setting floors data
    if (reportData?.floorsData) {
      const floorsDataArray = saveReportData?.Floor?.floorData || [];
      const modifiedFloorsData: any[] = floorsDataArray.map((floor: any) => {
        const ventilationType = calculateFloorType(floor.ventilation);
        const floorInsulation = calculateIntegratedCellValue(
          floor.floor_type,
          floor.bulk_insulation
        );
        const slabEdgeType = calculateSlabEdge(floor.slab_edge_insulation);

        floor.ventilation_type = ventilationType;
        floor.floor_insulation = floorInsulation;
        floor.slab_edge_type = slabEdgeType;

        return floor;
      });
      setSaveReportData((prevData: any) => ({
        ...prevData,
        Floor: {
          ...prevData?.Floor,
          floorData: modifiedFloorsData,
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (reportData?.roofsceilingsData) {
  //     setSaveReportData((prevData: any) => ({
  //       ...prevData,
  //       Roofs: {
  //         ...prevData?.Roofs,
  //         roofData: reportData.roofsceilingsData
  //       },
  //     }));
  //   }
  //   // Setting floors data
  //   if (reportData?.floorsData) {
  //     setSaveReportData((prevData: any) => ({
  //       ...prevData,
  //       Floor: {
  //         ...prevData?.Floor,
  //         floorData: reportData.floorsData
  //       },
  //     }));
  //   }

  //   // Setting gazling data (windows data)
  //   if (reportData?.windowsData) {
  //     setSaveReportData((prevData: any) => ({
  //       ...prevData,
  //       Windows: reportData.windowsData,
  //     }));
  //   }
  // }, [
  //   reportData.floorsData,
  //   reportData.roofsceilingsData,
  //   reportData.windowsData,
  //   setSaveReportData
  // ]);

  const handleSarkingChangeRoof = (e: any, roof: any) => {
    const selectedSarking = e.target.value;
    setSaveReportData((prevData: any) => {
      const updatedRoofs = prevData?.Roofs?.roofData.map((r: any) => {
        if (r.roof_id === roof.roof_id) {
          // If roof_id matches, update the sarking value
          return { ...r, sparking: selectedSarking };
        }
        return r;
      });

      return {
        ...prevData,
        Roofs: {
          ...prevData?.Roofs,
          roofData: updatedRoofs,
        },
      };
    });
  };

  const handleThermalBreakChangeRoof = (e: any, roof: any) => {
    const selectedThermalBreak = e.target.value;
    setSaveReportData((prevData: any) => {
      const updatedRoofs = prevData?.Roofs?.roofData.map((r: any) => {
        if (r.roof_id === roof.roof_id) {
          // If roof_id matches, update the thermal break value
          return { ...r, thermal_break: selectedThermalBreak };
        }
        return r;
      });

      return {
        ...prevData,
        Roofs: {
          ...prevData?.Roofs,
          roofData: updatedRoofs,
        },
      };
    });
  };

  const handleCharactristicsGazling = (e: any, windows: any) => {
    const characteristics = e.target.value;
    setSaveReportData((prevData: any) => {
      const updatedRoofs = prevData?.Windows?.map((w: any) => {
        if (w.windows_id === windows.windows_id) {
          // If roof_id matches, update the sarking value
          return { ...w, characteristics };
        }
        return w;
      });

      return {
        ...prevData,
        Windows: updatedRoofs,
      };
    });
  };

  const handleTypeGazling = (e: any, windows: any) => {
    const glazingType = e.target.value;
    setSaveReportData((prevData: any) => {
      const updatedRoofs = prevData?.Windows?.map((w: any) => {
        if (w.windows_id === windows.windows_id) {
          // If roof_id matches, update the thermal break value
          return { ...w, glazing_type: glazingType };
        }
        return w;
      });

      return {
        ...prevData,
        Windows: updatedRoofs,
      };
    });
  };

  const handleSelectChangeForRoofCeilling = (event: any) => {
    const selectedValue = event.target.value;
    // setAdditionalNote(selectedValue);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Roofs: {
        ...prevData?.Roofs,
        additional_note: selectedValue || '',
      },
    }));
  };

  const handleSelectChangeForCeilingInsulation = (event: any) => {
    const selectedValue = event.target.value;
    // setAdditionalNote(selectedValue);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Roofs: {
        ...prevData?.Roofs,
        CeilingInsulation: selectedValue || '',
      },
    }));
  };

  const handleSelectChangeForCeilingFan = (event: any) => {
    const selectedValue = event.target.value;
    // setAdditionalNote(selectedValue);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Roofs: {
        ...prevData?.Roofs,
        CeilingFan: selectedValue || '',
      },
    }));
  };

  const handleSelectChangeForFloor = (event: any) => {
    const selectedValue = event.target.value;
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Floor: {
        ...prevData?.Floor,
        additional_note: selectedValue || '',
      },
    }));
  };

  const determineRoofType = (inputValue: any) => {
    if (inputValue === '') return '';
    if (inputValue === 'Disc:Attic-Discontinuous') return 'Tiled Roof';
    if (inputValue === 'Framed:Flat - Flat Framed (Metal Deck)')
      return 'Colorbond Flat or Low-Pitched Roof';
    if (inputValue === 'Slab:Slab - Suspended Slab') return 'Suspended Concrete Slab';
    if (inputValue === 'SlabExt:Slab - Suspended Slab - External Insul')
      return 'Suspended Concrete Slab';
    if (inputValue === 'Ceil: Ceiling') return 'Ceiling with Floor Above';
    if (inputValue === 'Cont:Attic-Continuous') return 'Metal Roof OR Tiled Roof w/Sarking';
    if (inputValue === 'HiVent:Attic-Highly Vented')
      return 'Highly Ventilated Roof Space with ACH >84.7';
    if (inputValue === 'Vent:Attic-Vented') return 'Ventilated Roof Space with ACH >31.6';
    return '';
  };

  const calculateFrameType = (wallType: string) => {
    let frameType = '';

    if (wallType.length === 0) {
      frameType = '';
    } else if (wallType.includes('OTH')) {
      frameType = 'None';
    } else if (
      wallType.includes('STF TBK INT') ||
      wallType.includes('STF NTB INT') ||
      wallType.includes('STF TBK') ||
      wallType.includes('STF NTB') ||
      wallType.includes('STF INT')
    ) {
      frameType = 'Steel';
    } else if (wallType.includes('WDF') || wallType.includes('WDF INT')) {
      frameType = 'Timber';
    } else {
      frameType = 'Unknown';
    }

    return frameType;
  };

  const handleSelectChangeForWalls = (event: any) => {
    const selectedValue = event.target.value;
    // setAdditionalNote(selectedValue);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Walls: {
        ...prevData?.Walls,
        additional_note: selectedValue,
      },
    }));
  };
  const achievedComplianceOptions = [
    { value: '', label: 'None' },
    {
      value: 'SPECIFICATION NOTED BELOW: BASE SPEC',
      label: 'SPECIFICATION NOTED BELOW: BASE SPEC',
    },
    { value: 'SPECIFICATION NOTED BELOW: SO SPEC', label: 'SPECIFICATION NOTED BELOW: SO SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S1 SPEC', label: 'SPECIFICATION NOTED BELOW: S1 SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S2 SPEC', label: 'SPECIFICATION NOTED BELOW: S2 SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S3 SPEC', label: 'SPECIFICATION NOTED BELOW: S3 SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S4 SPEC', label: 'SPECIFICATION NOTED BELOW: S4 SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S5 SPEC', label: 'SPECIFICATION NOTED BELOW: S5 SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S6 SPEC', label: 'SPECIFICATION NOTED BELOW: S6 SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S7 SPEC', label: 'SPECIFICATION NOTED BELOW: S7 SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S8 SPEC', label: 'SPECIFICATION NOTED BELOW: S8 SPEC' },
    { value: 'SPECIFICATION NOTED BELOW: S9 SPEC', label: 'SPECIFICATION NOTED BELOW: S9 SPEC' },
    {
      value: 'SPECIFICATION NOTED BELOW: S10 SPEC',
      label: 'SPECIFICATION NOTED BELOW: S10 SPEC',
    },
    {
      value: 'SPECIFICATION NOTED BELOW: S11 SPEC',
      label: 'SPECIFICATION NOTED BELOW: S11 SPEC',
    },
    {
      value: 'SPECIFICATION NOTED BELOW: S12 SPEC',
      label: 'SPECIFICATION NOTED BELOW: S12 SPEC',
    },
  ];

  const achivedComplienceChange = (event: any) => {
    const selectedValue = event.target.value;
    console.log('Specification value = ', selectedValue);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      AchievedComplance: selectedValue,
    }));
  };

  const doubleStoreyModelled = (event: any) => {
    const selectedValue = event.target.value;
    setSaveReportData((prevData: any) => ({
      ...prevData,
      DoubleStoreyModelled: selectedValue,
    }));
  };

  const handleGlazingFrameColor = (event: any) => {
    const selectedValue = event.target.value;
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Walls: {
        ...prevData?.Walls,
        glazing_frame_color: selectedValue,
      },
    }));
  };

  const handleWallColor = (event: any) => {
    const selectedValue = event.target.value;
    setSaveReportData((prevData: any) => ({
      ...prevData,
      Walls: {
        ...prevData?.Walls,
        wall_color: selectedValue,
      },
    }));
  };

  const calculateLocation = (wallType: string) => {
    let location = '';
    if (wallType.includes('INT')) {
      location = 'Internal';
    } else {
      location = 'External';
    }
    return location;
  };

  const calculateThermalBreak = (wallType: string) => {
    let thermalBreak = '';

    if (wallType.length === 0) {
      thermalBreak = '';
    } else if (wallType.includes('OTH')) {
      thermalBreak = 'None';
    } else if (wallType.includes('STF TBK INT') || wallType.includes('STF TBK')) {
      thermalBreak = 'Yes';
    } else if (
      wallType.includes('WDF') ||
      wallType.includes('STF INT') ||
      wallType.includes('WDF INT')
    ) {
      thermalBreak = 'N/A';
    } else {
      thermalBreak = 'No';
    }
    return thermalBreak;
  };

  // Function to calculate Ceiling Insulation based on the provided formula
  const calculateCeilingInsulation = (inputValue: any) => {
    if (inputValue === '') return '';
    if (inputValue === 0 || inputValue === 1.3 || inputValue === 1.8) return 'None';

    let lookupTable: any[] = [];

    if (reportData?.projectInfoData?.NccType === '2019') {
      lookupTable = [
        [4.3, 3, 1.3],
        [3.8, 2.5, 1.3],
        [5.1, 4, 1.1],
        [5.3, 4, 1.3],
        [5.8, 4, 1.8],
        [5.2, 4.1, 1.1],
        [5.4, 4.1, 1.3],
        [5.9, 4.1, 1.8],
        [6.1, 5, 1.1],
        [6.3, 5, 1.3],
        [6.8, 5, 1.8],
        [7.1, 6, 1.1],
        [7.3, 6, 1.3],
        [7.8, 6, 1.8],
        [8.1, 7, 1.1],
        [8.3, 7, 1.3],
        [8.8, 7, 1.8],
      ];
    } else {
      lookupTable = [
        [4.6, 3.5, 1.1],
        [4.3, 3, 1.3],
        [3.8, 2.5, 1.3],
        [5.1, 4, 1.1],
        [5.3, 4, 1.3],
        [5.8, 4, 1.8],
        [5.2, 4.1, 1.1],
        [5.4, 4.1, 1.3],
        [5.9, 4.1, 1.8],
        [6.1, 5, 1.1],
        [6.3, 5, 1.3],
        [6.8, 5, 1.8],
        [7.1, 6, 1.1],
        [7.3, 6, 1.3],
        [7.8, 6, 1.8],
        [8.1, 7, 1.1],
        [8.3, 7, 1.3],
        [8.8, 7, 1.8],
      ];
    }

    // Find the corresponding value using VLOOKUP
    const result = lookupTable.find((row) => row[0] === inputValue);
    if (result) {
      return result[1];
    }
    return inputValue; // If not found, return the original value
  };

  const calculateBlanketInsulation = (inputValue: any) => {
    if (inputValue === '') return '';
    if (inputValue === 1.8) return 'None';

    let lookupTable: any[] = [];

    if (reportData?.projectInfoData?.NccType === '2019') {
      lookupTable = [
        [1.3, 1.3],
        [4.3, 1.3],
        [5.1, 1.1],
        [5.3, 1.3],
        [3.8, 1.3],
        [5.8, 1.8],
        [5.2, 1.1],
        [5.4, 1.3],
        [5.9, 1.8],
        [6.1, 1.1],
        [6.3, 1.3],
        [6.8, 1.8],
        [7.1, 1.1],
        [7.3, 1.3],
        [7.8, 1.8],
        [8.1, 1.1],
        [8.3, 1.3],
        [8.8, 1.8],
      ];
    } else {
      lookupTable = [
        [1.8, 1.8],
        [1.1, 1.1],
        [1.3, 1.3],
        [4.8, 1.3],
        [4.3, 1.3],
        [4.6, 1.1],
        [5.1, 1.1],
        [5.3, 1.3],
        [3.8, 1.3],
        [5.8, 1.8],
        [5.2, 1.1],
        [5.4, 1.3],
        [5.9, 1.8],
        [6.1, 1.1],
        [6.3, 1.3],
        [6.8, 1.8],
        [7.1, 1.1],
        [7.3, 1.3],
        [7.8, 1.8],
        [8.1, 1.1],
        [8.3, 1.3],
        [8.8, 1.8],
      ];
    }

    // Find the corresponding value using VLOOKUP
    const result = lookupTable.find((row) => row[0] === inputValue);
    if (result) {
      return result[1] === 0 ? 'None' : result[1];
    }
    return inputValue; // If not found, return the original value
  };

  const calculateFloorType = (inputValue: any) => {
    switch (inputValue) {
      case 'encl':
        return 'Enclosed';
      case 'encldisc':
        return 'Sealed Subfloor';
      case 'open':
        return 'Ventilated Subfloor';
      case 'vopen':
        return 'Unenclosed Subfloor';
      default:
        return inputValue; // Use the original value if not found in the formula
    }
  };

  const calculateSlabEdge = (inputValue: any) => {
    if (inputValue === '') {
      return ''; // Return empty string if the input is empty
    }
    if (inputValue === 0) {
      return 'None';
    }
    return inputValue;
  };

  const calculateIntegratedCellValue = (inputProjectA: any, inputProjectB: any) => {
    if (inputProjectA.includes('Foil')) {
      return 'Underslab Foil';
    }
    if (inputProjectB === '') {
      return '';
    }
    if (inputProjectB === 0) {
      return 'None';
    }
    if (inputProjectB > 0 && inputProjectB < 0.8) {
      return 'Integrated';
    }
    return inputProjectB;
  };

  const handleAdditionalNoteChange2019 = (e: any, wall: any) => {
    console.log('Additional note', e.target.value);
    const additional_note = e.target.value;
    const wallObj: any[] = saveReportData.Walls.wallsData;

    const updatedWallObj = wallObj.map((wall_data) => {
      if (wall_data.wall_id === wall.wall_id) {
        return { ...wall_data, additional_note };
      }
      return wall_data;
    });

    setSaveReportData((prevData: any) => ({
      ...prevData,
      Walls: {
        ...prevData?.Walls,
        wallsData: updatedWallObj,
      },
    }));
  };

  const handleAdditionalNoteChangeGlazing = (e: any, window: any) => {
    console.log('Additional note', e.target.value);
    const additional_note = e.target.value;
    const windowsObj: any[] = saveReportData.Windows;

    const updatedWindowsObj = windowsObj.map((window_data) => {
      if (window_data.windows_id === window.windows_id) {
        return { ...window_data, additional_note };
      }
      return window_data;
    });

    setSaveReportData((prevData: any) => ({
      ...prevData,
      Windows: updatedWindowsObj,
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modifyWallObject2022 = (wallsArray: any) => {
    const updatedWallsData: any[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const wall of wallsArray) {
      const frameType = calculateFrameType(wall.wall_type);
      const location = calculateLocation(wall.wall_type);
      const thermalBreak = calculateThermalBreak(wall.wall_type);

      // Create a new object with the modified properties
      const updatedWall = {
        ...wall,
        frame_type: frameType,
        location,
        thermal_break: thermalBreak,
      };

      updatedWallsData.push(updatedWall);
    }

    setSaveReportData((prevData: any) => ({
      ...prevData,
      Walls: {
        ...prevData?.Walls,
        wallsData: updatedWallsData,
      },
    }));
  };

  const modifyWallObject2019 = (wall: any, additional_note: string) => {
    // console.log('2019 function runs');
    const updatedWallsData: any[] = saveReportData?.Walls?.wallsData || [];
    const location = calculateLocation(wall.wall_type);

    const indexToUpdate = updatedWallsData.findIndex(
      (existingWall) => existingWall.wall_id === wall.wall_id
    );

    if (indexToUpdate !== -1) {
      return {
        ...updatedWallsData[indexToUpdate],
        location,
        additional_note,
      };
    }
    return {
      ...wall,
      location,
      additional_note,
    };
  };

  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Grid container spacing={2} alignItems="center" sx={{ width: '100%' }}>
          {/* {(reportData?.projectInfoData?.NccType === '2019' ||
            reportData?.projectInfoData?.ClientName !== 'Metricon Homes') && <Grid item xs={6} />} */}

          {reportData?.projectInfoData?.NccType === '2022' && (
            <Grid item xs={6}>
              <Select
                variant="outlined"
                name="achievedCompliance"
                onChange={achivedComplienceChange}
                value={saveReportData?.AchievedComplance || ''}
                sx={{
                  width: '100%',
                  marginLeft: '20px',
                  border: invalidFields.includes('AchievedComplance')
                    ? '3px solid red'
                    : '1px solid black',
                  background: 'cadetblue',
                  borderRadius: '6px',
                  color: '#963634',
                  fontWeight: '800',
                }}
              >
                {achievedComplianceOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Box sx={{ color: '#17a986', typography: 'h3' }}>THE SPECIFICATIONS</Box>
          </Grid>
        </Grid>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            <CardHeader title="Walls" sx={{ textAlign: 'center', color: '#17a986' }} />
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        CONSTRUCTION TYPE AND INSULATION
                      </TableCell>
                      {reportData?.projectInfoData?.NccType === '2022' && (
                        <TableCell sx={{ color: '#17a986' }} align="center">
                          FRAME TYPE
                        </TableCell>
                      )}
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        LOCATION
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        {reportData?.projectInfoData?.NccType === '2019'
                          ? 'ADDITIONAL NOTES*'
                          : 'THERMAL BREAK?'}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {saveReportData?.Walls?.wallsData?.map((wall: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell align="center">{wall.wall_type}</TableCell>
                        {reportData?.projectInfoData?.NccType === '2022' && (
                          <TableCell align="center">
                            <TableCell align="center">{wall.frame_type}</TableCell>
                          </TableCell>
                        )}
                        <TableCell align="center"> {wall.location}</TableCell>
                        <TableCell align="center">
                          {reportData?.projectInfoData?.NccType === '2019' ? (
                            <TextField
                              name="wallAdditionalNote"
                              value={wall.additional_note}
                              onChange={(e) => handleAdditionalNoteChange2019(e, wall)}
                              sx={{
                                width: '100%',
                                border:
                                  invalidFields.indexOf(`${index}-WallAdditionalNote`) >= 0
                                    ? '3px solid red'
                                    : '1px solid black',

                                background: 'cadetblue',
                                borderRadius: '6px',
                              }}
                            />
                          ) : (
                            <TableCell align="center"> {wall.thermal_break}</TableCell>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} direction="column" alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2" fontWeight={100}>
                ADDITIONAL NOTES
                <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Stack>

            <Stack
              direction="column"
              alignItems="center"
              sx={{ minWidth: 200, flex: '1 1', mt: 0 }}
            >
              <Typography typography="h6" variant="subtitle2" fontWeight={100}>
                Refer to Plans/Drawings for the location of external walls.
              </Typography>

              {reportData?.projectInfoData?.NccType === '2022' && (
                <Select
                  variant="outlined"
                  name="wallAdditionalNotes"
                  onChange={handleSelectChangeForWalls}
                  // value={additionalNote}
                  value={saveReportData?.Walls?.additional_note}
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('WallsAdditionalNote') >= 0
                        ? '3px solid red'
                        : '1px solid black',

                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                >
                  {/* <MenuItem value="">Select Additional Notes</MenuItem> */}
                  <MenuItem value="Internal wall insulation:  Garage, Laundry and Bathroom walls only">
                    Internal wall insulation: Garage, Laundry and Bathroom walls only
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: Garage internal walls only">
                    Internal wall insulation: Garage internal walls only
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: Garage and unconditioned wet-areas only">
                    Internal wall insulation: Garage and unconditioned wet-areas only
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: Location as per Mark-Up">
                    Internal wall insulation: Location as per Mark-Up
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: Throughout all internal walls">
                    Internal wall insulation: Throughout all internal walls
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: No internal wall insulation">
                    Internal wall insulation: No internal wall insulation
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: Location As Per Drawings">
                    Internal wall insulation: Location as per Drawings
                  </MenuItem>
                </Select>
              )}

              {reportData?.projectInfoData?.NccType === '2019' && (
                <Select
                  variant="outlined"
                  name="wallAdditionalNotes"
                  onChange={handleSelectChangeForWalls}
                  // value={additionalNote}
                  value={saveReportData?.Walls?.additional_note}
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('WallsAdditionalNote') >= 0
                        ? '3px solid red'
                        : '1px solid black',

                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                >
                  {/* <MenuItem value="">Select Additional Notes</MenuItem> */}
                  <MenuItem value="Internal wall insulation: Garage internal walls only">
                    Internal wall insulation: Garage internal walls only
                  </MenuItem>

                  <MenuItem value="Internal wall insulation: Location as per Mark-Up">
                    Internal wall insulation: Location as per Mark-Up
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: Throughout all internal walls">
                    Internal wall insulation: Throughout all internal walls
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: No internal wall insulation">
                    No internal wall insulation
                  </MenuItem>
                  <MenuItem value="Internal wall insulation: Location As Per Drawings">
                    Location as per Drawings
                  </MenuItem>
                </Select>
              )}

              {/* Wall Color Dropdown */}
              <Select
                variant="outlined"
                name="wallAdditionalNotes"
                onChange={handleWallColor}
                value={saveReportData?.Walls?.wall_color}
                sx={{
                  width: '100%',
                  border:
                    invalidFields.indexOf('WallsAdditionalNote') >= 0
                      ? '3px solid red'
                      : '1px solid black',

                  background: 'cadetblue',
                  borderRadius: '6px',
                  marginTop: '5px',
                }}
              >
                {/* <MenuItem value="">Select Additional Notes</MenuItem> */}
                <MenuItem value="light">Wall Colour: Light</MenuItem>
                <MenuItem value="medium">Wall Colour: Medium</MenuItem>
                <MenuItem value="dark">Wall Colour: Dark</MenuItem>
                <MenuItem value="multiple">Multiple Wall Colours: See Schedule/Drawings</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            <CardHeader title="Roof and Ceiling" sx={{ textAlign: 'center', color: '#17a986' }} />
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        CONSTRUCTION TYPE
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        CEILING INSULATION (R)
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        SARKING
                        <span style={{ color: 'red' }}>*</span>
                      </TableCell>
                      {reportData?.projectInfoData?.NccType === '2022' && (
                        <TableCell sx={{ color: '#17a986' }} align="center">
                          THERMAL BREAK?
                          <span style={{ color: 'red' }}>*</span>
                        </TableCell>
                      )}
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        {reportData?.projectInfoData?.NccType === '2019'
                          ? 'ROOF BLANKET (R)'
                          : 'BLANKET (R)'}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {saveReportData?.Roofs?.roofData?.map((roofsceilings: any, index: any) => (
                      <TableRow>
                        <TableCell align="center">{roofsceilings?.construction_type}</TableCell>
                        <TableCell align="center">{roofsceilings.ceiling_insulation}</TableCell>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="sarking"
                            value={roofsceilings?.sparking}
                            onChange={(e) => {
                              handleSarkingChangeRoof(e, roofsceilings);
                              setSparking(e.target.value);
                            }}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-RoofSparking`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',

                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </TableCell>
                        {reportData?.projectInfoData?.NccType === '2022' && (
                          <TableCell align="center">
                            <Select
                              variant="outlined"
                              name="thermalBreak"
                              value={roofsceilings?.thermal_break}
                              onChange={(e) => {
                                handleThermalBreakChangeRoof(e, roofsceilings);
                                setthermalBreakRoof(e.target.value);
                              }}
                              sx={{
                                width: '100%',
                                border:
                                  invalidFields.indexOf(`${index}-RoofThermalBreak`) >= 0
                                    ? '3px solid red'
                                    : '1px solid black',

                                background: 'cadetblue',
                                borderRadius: '6px',
                              }}
                            >
                              <MenuItem value="Yes (min: R0.2)">Yes (min: R0.2)</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </TableCell>
                        )}
                        <TableCell align="center">{roofsceilings.blanket}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3, marginTop: '10px' }} direction="column" alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2" fontWeight={100}>
                ADDITIONAL NOTES
                <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Stack>

            <Stack
              direction="column"
              alignItems="center"
              sx={{ minWidth: 500, maxWidth: 440, flex: '1 1' }}
            >
              <Select
                variant="outlined"
                name="roofCeilingAdditionalNotes"
                value={saveReportData?.Roofs?.additional_note}
                onChange={(event) => {
                  handleSelectChangeForRoofCeilling(event);
                }}
                sx={{
                  width: '100%',
                  border:
                    invalidFields.indexOf('RoofsAdditionalNote') >= 0
                      ? '3px solid red'
                      : '1px solid black',

                  background: 'cadetblue',
                  borderRadius: '6px',
                }}
              >
                {/* <MenuItem value="">Select Additional Notes</MenuItem> */}
                {reportData?.projectInfoData?.NccType === '2019' && (
                  <MenuItem value="A light coloured roof has been modelled as a worst-case scenario">
                    A light coloured roof has been modelled as a worst-case scenario
                  </MenuItem>
                )}
                {reportData?.projectInfoData?.NccType === '2019' && (
                  <MenuItem value="A dark coloured roof has been modelled as a worst-case scenario">
                    A dark coloured roof has been modelled as a worst-case scenario
                  </MenuItem>
                )}

                <MenuItem value="Solar Absorptance: Light roof colour">
                  Solar Absorptance: Light roof colour
                </MenuItem>
                <MenuItem value="Solar Absorptance: Medium roof colour">
                  Solar Absorptance: Medium roof colour
                </MenuItem>
                <MenuItem value="Solar Absorptance: Dark roof colour">
                  Solar Absorptance: Dark roof colour
                </MenuItem>
              </Select>

              {reportData?.projectInfoData?.NccType === '2022' && (
                <Select
                  variant="outlined"
                  name="roofCeilingAdditionalNotes"
                  value={saveReportData?.Roofs?.CeilingInsulation}
                  onChange={(event) => {
                    handleSelectChangeForCeilingInsulation(event);
                  }}
                  sx={{
                    width: '100%',
                    border: invalidFields.includes('CeilingInsulsation')
                      ? '3px solid red'
                      : '1px solid black',

                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                >
                  {/* <MenuItem value="">Select Additional Notes</MenuItem> */}
                  <MenuItem value="Ceiling Insulation to the House Area only">
                    Ceiling Insulation to the House Area only
                  </MenuItem>
                  <MenuItem value="Ceiling Insulation to the House Area and Garage area">
                    Ceiling Insulation to the House and Garage area
                  </MenuItem>
                  <MenuItem value="No Ceiling Insulation">No Ceiling Insulation</MenuItem>
                  <MenuItem value="Location of Ceiling Insulation as per Drawing">
                    Location of Ceiling Insulation as per Drawing
                  </MenuItem>
                </Select>
              )}

              {reportData?.projectInfoData?.NccType === '2022' && (
                <Select
                  variant="outlined"
                  name="roofCeilingAdditionalNotes"
                  value={saveReportData?.Roofs?.CeilingFan}
                  onChange={(event) => {
                    handleSelectChangeForCeilingFan(event);
                  }}
                  sx={{
                    width: '100%',
                    border: invalidFields.includes('CeilingFan')
                      ? '3px solid red'
                      : '1px solid black',

                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                >
                  {/* <MenuItem value="">Select Additional Notes</MenuItem> */}
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Min Ø 1,200mm ceiling fan added to the Kitchen/Dining/Family area only">
                    Min Ø 1,200mm ceiling fan added to the Kitchen/Dining/Family area only
                  </MenuItem>

                  <MenuItem value="Min Ø 1,200mm ceiling fans added to the Kitchen/Dining/Family area, all Living Areas and Bedrooms (1 per area)">
                    Min Ø 1,200mm ceiling fans added to the Kitchen/Dining/Family area, all Living
                    Areas and Bedrooms (1 per area)
                  </MenuItem>

                  <MenuItem value="Min Ø 1,200mm ceiling fans added to the Bedrooms only">
                    Min Ø 1,200mm ceiling fans added to the Bedrooms only{' '}
                  </MenuItem>

                  <MenuItem value="Min Ø 1,200mm ceiling fans added as per drawings">
                    Min Ø 1,200mm ceiling fans added as per drawings{' '}
                  </MenuItem>
                </Select>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            <CardHeader title="Floor" sx={{ textAlign: 'center', color: '#17a986' }} />
            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        CONSTRUCTION TYPE
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        VENTILATION
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        FLOOR INSUL (R)
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        SLAB EDGE (R)
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        FLOOR AREAS (m<sup>2</sup>)
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* <TableBody>
                    <TableRow>
                      <TableCell align="center">85mm Concrete | 225mm Waffle</TableCell>
                      <TableCell align="center">Enclosed</TableCell>
                      <TableCell align="center">Integrated</TableCell>
                      <TableCell align="center">None</TableCell>
                      <TableCell align="center">204.0</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">Framed Suspended Floor | Uninsulated</TableCell>
                      <TableCell align="center">Enclosed</TableCell>
                      <TableCell align="center">None</TableCell>
                      <TableCell align="center">None</TableCell>
                      <TableCell align="center">3.1</TableCell>
                    </TableRow> */}
                  {/* </TableBody> */}

                  <TableBody>
                    {reportData?.floorsData?.map((floor: any, index: any) => (
                      <TableRow>
                        <TableCell align="center">{floor?.floor_type}</TableCell>
                        <TableCell align="center">{floor?.ventilation_type}</TableCell>
                        <TableCell align="center">{floor?.floor_insulation}</TableCell>
                        <TableCell align="center">{floor?.slab_edge_type}</TableCell>
                        <TableCell align="center">{floor?.area}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed', py: '0.5rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} direction="column" alignItems="start">
          <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ minWidth: 250, flex: '0 1', color: '#17a986' }}
            >
              <Typography typography="h6" variant="subtitle2" fontWeight={100}>
                ADDITIONAL NOTES
                <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Stack>

            <Stack direction="column" alignItems="center" sx={{ width: '100%' }}>
              <TextField
                name="floorAdditionalNotes"
                multiline
                rows={2}
                value={saveReportData?.Floor?.additional_note}
                onChange={(event) => {
                  handleSelectChangeForFloor(event);
                }}
                sx={{
                  width: '100%',
                  border:
                    invalidFields.indexOf('FloorAdditionalNote') >= 0
                      ? '3px solid red'
                      : '1px solid black',

                  background: 'cadetblue',
                  borderRadius: '6px',
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack spacing={3} sx={{ px: 3 }} alignItems="start">
          <Card sx={{ width: 1 }}>
            <CardHeader
              title="Glazing"
              sx={{ textAlign: 'center', color: '#17a986', alignItems: 'center' }}
            />
            <Select
              variant="outlined"
              name="glazingFlameColor"
              onChange={handleGlazingFrameColor}
              // value={additionalNote}
              value={saveReportData?.Walls?.glazing_frame_color}
              sx={{
                width: '95%',
                border:
                  invalidFields.indexOf('WallsAdditionalNote') >= 0
                    ? '3px solid red'
                    : '1px solid black',

                background: 'cadetblue',
                borderRadius: '6px',
                marginTop: '5px',
                marginLeft: '20px',
              }}
            >
              {/* <MenuItem value="">Select Additional Notes</MenuItem> */}
              <MenuItem value="light">Glazing Frame Colour: Light</MenuItem>
              <MenuItem value="medium">Glazing Frame Colour: Medium</MenuItem>
              <MenuItem value="dark">Glazing Frame Colour: Dark</MenuItem>
            </Select>

            <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        WERS CODE
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        CHARACTERISTIC
                        <span style={{ color: 'red' }}>*</span>
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        TYPE
                        <span style={{ color: 'red' }}>*</span>
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        U<sub>w</sub>-VALUE
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        SHGC<sub>w</sub>
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        AREA (m<sup>2</sup>)
                      </TableCell>
                      <TableCell sx={{ color: '#17a986' }} align="center">
                        ADDITIONAL NOTES
                        <span style={{ color: 'red' }}>*</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* <TableBody>
                    <TableRow>
                      <TableCell align="center">AWS-008-01 A</TableCell>
                      <TableCell align="center">
                        <Select
                          variant="outlined"
                          name="glazingCharacteristic"
                          value="Std Double-Glazing"
                          sx={{ width: '100%' }}
                        >
                          <MenuItem value="Single">Single</MenuItem>
                          <MenuItem value="Single Low-E">Single Low-E</MenuItem>
                          <MenuItem value="Std Double-Glazing">Std Double-Glazing</MenuItem>
                          <MenuItem value="Double-Glazing Low-E">Double-Glazing Low-E</MenuItem>
                          <MenuItem value="Triple-Glazing">Triple-Glazing</MenuItem>
                          <MenuItem value="TG with Low-E">TG with Low-E</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          variant="outlined"
                          name="glazingType"
                          value="Awning"
                          sx={{ width: '100%' }}
                        >
                          <MenuItem value="Awning">Awning</MenuItem>
                          <MenuItem value="Sliding">Sliding</MenuItem>
                          <MenuItem value="Fixed">Fixed</MenuItem>
                          <MenuItem value="Double-Hung">Double-Hung</MenuItem>
                          <MenuItem value="Door">Door</MenuItem>
                          <MenuItem value="Louvre">Louvre</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell align="center">4.32</TableCell>
                      <TableCell align="center">0.55</TableCell>
                      <TableCell align="center">27.78</TableCell>
                      <TableCell align="center">
                        <TextField name="glazingAdditionalNotes" sx={{ width: '100%' }} />
                      </TableCell>
                    </TableRow>
                  </TableBody> */}

                  <TableBody>
                    {saveReportData?.Windows?.map((windows: any, index: any) => (
                      <TableRow>
                        <TableCell align="center">{windows?.windows_type.slice(0, 12)}</TableCell>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="glazingCharacteristic"
                            value={windows?.characteristics}
                            onChange={(e) => {
                              handleCharactristicsGazling(e, windows);
                            }}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-GlazeCharacteristics`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',

                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="Standard Single Glazing">
                              Standard Single Glazing
                            </MenuItem>
                            <MenuItem value="Low-E Glazing">Low-E Glazing</MenuItem>
                            <MenuItem value="Standard Double Glazing">
                              Standard Double Glazing
                            </MenuItem>
                            <MenuItem value="Double Glazing+SGL LowE">
                              Double Glazing+SGL LowE
                            </MenuItem>
                            <MenuItem value="Double Glazing+DBL LowE">
                              Double Glazing+DBL LowE
                            </MenuItem>
                            <MenuItem value="Other (See Notes)">Other (See Notes)</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="center">
                          <Select
                            variant="outlined"
                            name="glazingType"
                            value={windows?.glazing_type}
                            onChange={(e) => handleTypeGazling(e, windows)}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-GlazeCharacteristics`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',

                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          >
                            <MenuItem value="Awning">Awning</MenuItem>
                            <MenuItem value="Sliding">Sliding</MenuItem>
                            <MenuItem value="Fixed">Fixed</MenuItem>
                            <MenuItem value="Double-Hung">Double-Hung</MenuItem>
                            <MenuItem value="Louvre">Louvre</MenuItem>
                            <MenuItem value="Bi-fold Window">Bi-fold Window</MenuItem>
                            <MenuItem value="Casement Window">Casement Window</MenuItem>
                            <MenuItem value="Tilt and Turn Window">Tilt and Turn Window</MenuItem>
                            <MenuItem value="Sliding Door">Sliding Door</MenuItem>
                            <MenuItem value="Stacker Door">Stacker Door</MenuItem>
                            <MenuItem value="Bi-fold Door">Bi-fold Door</MenuItem>
                            <MenuItem value="Hinged Door">Hinged Door</MenuItem>
                            {reportData?.projectInfoData?.NccType === '2022' && (
                              <MenuItem value="Glazed Door">Glazed Door</MenuItem>
                            )}
                          </Select>
                        </TableCell>
                        <TableCell align="center">{windows?.uvalue}</TableCell>
                        <TableCell align="center">{windows?.shgc}</TableCell>
                        <TableCell align="center">{windows?.area}</TableCell>
                        <TableCell align="center">
                          <TextField
                            name="glazingAdditionalNotes"
                            value={windows?.additional_note}
                            onChange={(e) => handleAdditionalNoteChangeGlazing(e, windows)}
                            sx={{
                              width: '100%',
                              border:
                                invalidFields.indexOf(`${index}-GlazeAdditionalNote`) >= 0
                                  ? '3px solid red'
                                  : '1px solid black',

                              background: 'cadetblue',
                              borderRadius: '6px',
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack>
          <Box sx={{ typography: 'caption' }}>
            *Proxy WERS codes, though not from the original manufacturer, may be used to meet
            U-Value and SHGC value limitations and are compliant. The U-value of the window or
            glazed door selected must be lower than the value shown. The SHGC of the window or
            glazed door selected must be +/-5% of the value shown (if certified under BASIX then
            +/-10% is allowable)
          </Box>
        </Stack>

        {reportData?.projectInfoData?.NccType === '2022' && (
          <Select
            variant="outlined"
            name="doubleStoreyModelled"
            onChange={doubleStoreyModelled}
            // value={additionalNote}
            value={saveReportData?.DoubleStoreyModelled || ''}
            sx={{
              width: '95%',
              border:
                invalidFields.indexOf('DoubleStoreyModelled') >= 0
                  ? '3px solid red'
                  : '1px solid black',

              background: 'cadetblue',
              borderRadius: '6px',
              marginTop: '5px',
              marginLeft: '20px',
            }}
          >
            {/* <MenuItem value="">Select Additional Notes</MenuItem> */}
            <MenuItem value=""> None </MenuItem>
            <MenuItem value="restricted window openings">
              This double storey has been modelled with restricted window openings (%) as per NCC
              Protection of Openable Windows Advisory Note
            </MenuItem>
            <MenuItem value="fall-project screens">
              This double storey has been modelled with fall-project screens to applicable upper
              floor windows as per NCC Protection of Openable Windows Advisory Note
            </MenuItem>
          </Select>
        )}

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box sx={{ color: 'text.disabled', typography: 'caption', marginTop: '10px' }}>
              <img
                src="./ea-report-footer.png"
                alt="Logo"
                style={{ width: '20vh', height: '10vh' }}
              />
            </Box>
            <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
            <Stack direction="column" sx={{ marginTop: '45px' }}>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                energy@energyadvance.com.au
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                ACN: 60 9332014 | 1300 850 228
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: '#919eab3d',
              p: '0.5rem',
              padding: '3rem',
            }}
          >
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>PRELIMINARY REPORT</Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>COMPLIANCE STAMP NOT </Box>
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>REQUIRED</Box>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}
