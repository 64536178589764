import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import axios from 'src/utils/axios';
import { Button, Card, Grid, InputAdornment, TextField, Typography } from '@mui/material';
// import _mock, { randomInArray } from 'src/_mock';
import {
  //  useLocation,
  useNavigate,
} from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import { Helmet } from 'react-helmet-async';
// import DataGridDocument from './DataGridCustom';
import DataGridDocumentPopUp from './DataGridCustomPopUp';
// import AddDocument from './AddDocuments';
import AddDocumentPopUp from './AddDocumentPopUp';

export default function DocumentScreenPopUp() {
  // const location = useLocation();
  // const { skillsetData } = location.state || [];
  // const { skillsetData } = location.state ?? { skillsetData: [] };
  // const skillsetData = filteredSkillsetData.length > 0 ? filteredSkillsetData[0] : undefined;
  const [isVisible, setIsVisible] = useState(false);
  // const [assessor, setAssessor] = useState<any>(null);
  // const [document, setDocument] = useState<any>(null);
  //   const [workflow, setWorkflow] = useState<any>(null);

  // const [oneAssessor, setSingleAssessor] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  const userid = user?.ID ?? '';
  const [addDocument, setAddDocument] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  // const [filteredDocument, setFilteredDocument] = useState([]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // const [rowId, setRowId] = useState<Number | null>(null);
  // const [edits, setEdit] = useState(false);
  const navigate = useNavigate();
  // const userlocal = localStorage.getItem('user');
  // console.log(userlocal);
  // const handleClose = () => {
  //   // setOpen(false)
  //   setIsVisible(false);
  // };

  const goToAddDocumentPage = () => {
    // navigate('/addDocumentUser');
    setAddDocument(true);
    setIsVisible(false);
  };

  const payload = {
    userid,
    userrole,
  };

  const fetchDocument = async () => {
    try {
      const response = await axios.post('/core/getalldocs', payload);

      const documentArray = response.data?.data?.map(({ ID, ...rest }: any) => ({
        id: ID,
        ...rest,
      }));
      setDocumentData(documentArray);
      console.log(documentArray);

    } catch (error) {
      console.error('Error fetching getAllDocument:', error);
    }
  };

  const filteredDocument =  documentData
    ? documentData?.filter((item: any) => {
        const searchLower = searchQuery.toLowerCase();
        const searchUpper = searchQuery.toUpperCase();
        const idSearch =
          item.id.toString().toLowerCase().includes(searchLower) ||
          item.id.toString().toUpperCase().includes(searchUpper);
        const docNameSearch =
          item.DocName.toString().toLowerCase().includes(searchLower) ||
          item.DocName.toString().toUpperCase().includes(searchUpper);
        const visibilitySearch =
          item.DocRole.toString().toLowerCase().includes(searchLower) ||
          item.DocRole.toString().toUpperCase().includes(searchUpper);
        const docExtensionSearch =
          item.DocExtension.toString().toLowerCase().includes(searchLower) ||
          item.DocExtension.toString().toUpperCase().includes(searchUpper);
        const docTypeSearch =
          item.DocType.toString().toLowerCase().includes(searchLower) ||
          item.DocType.toString().toUpperCase().includes(searchUpper);
        const statusSearch =
          item.DocStatus.toString().toLowerCase().includes(searchLower) ||
          item.DocStatus.toString().toUpperCase().includes(searchUpper);
        return (
          idSearch ||
          docExtensionSearch ||
          statusSearch ||
          docNameSearch ||
          visibilitySearch ||
          docTypeSearch
        );
      })
    : documentData;

  useEffect(() => {
    if( !addDocument ){
      fetchDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDocument]);

  // function convertStringToDate(dateString: string): Date {
  //   return new Date(dateString);
  // }

  const handleView = (value: string, edit: boolean) => {
    goToViewDocument(value, edit);
  };

  const goToViewDocument = (id: any, edit: any) => {
    navigate(`/viewdocument?id=${id}&edit=${edit}`);
  };

  return (
    <div>
      <Helmet>
        <title> Documents | Chat_Bot </title>
      </Helmet>
      <Grid item>
        <Card
          sx={{
            marginLeft: '8px',
            width: '1070px',
            height: '590px',
            display: 'flex',
            borderWidth: '10px',
            flexDirection: 'column',
          }}
        >
          {/* <Container sx={{  marginTop: 2 }}> */}

          {!isVisible && !addDocument && (
            <Grid container sx={{ marginTop: 2, marginBottom: 2 }} justifyContent="space-between">
              <Grid item>
                {(userrole === 'Management' || userrole === 'Workflow') && (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={goToAddDocumentPage}
                    sx={{ borderRadius: 0, marginLeft: '15px' }}
                  >
                    Add Document
                  </Button>
                )}
              </Grid>
              <Grid item sx={{color: 'red', marginLeft: '330px',marginTop:'20px'}}>
              <Typography sx={{ fontSize: '14px' }}>
              *Only PDF and TXT files can be viewed. 
              </Typography>
            </Grid>
              {/* {!isVisible && !addDocument && (
          <Grid container justifyContent="space-between">
            <Grid item sx={{color: 'red', marginLeft: '1000px' }}>
              <Typography sx={{ fontSize: '14px' }}>
              *Only PDF and TXT files can be viewed. 
              </Typography>
            </Grid>
          </Grid>
        )} */}
              <Grid item>
                <TextField
                  sx={{ marginRight: '15px' }}
                  variant="outlined"
                  size="small"
                  placeholder="Search Document"
                  type="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="bi:search" width={24} />
                      </InputAdornment>
                    ),
                  }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  title="Search will be applicable for ID,DocumentName,Visibility,UploadedBy"
                />
              </Grid>
            </Grid>
        )}
        {/* {!isVisible && !addDocument && (
          <Grid container justifyContent="space-between">
            <Grid item sx={{color: 'red', marginLeft: '1000px' }}>
              <Typography sx={{ fontSize: '14px' }}>
              *Only PDF and TXT files can be viewed. 
              </Typography>
            </Grid>
          </Grid>
        )} */}
          {/* {addDocument && <AddDocument />}

          {addDocument === false && !isVisible && filteredDocument !== null && (
            <DataGridDocument
              data={filteredDocument}
              sendData={(value, edit) => {
                handleView(value, edit);
              }}
            />
          )} */}
          {addDocument ? (
            <AddDocumentPopUp setAddDocument={setAddDocument} />
          ) : (
            !isVisible &&
            filteredDocument !== null && (
              <DataGridDocumentPopUp
                data={filteredDocument}
                sendData={(value, edit) => {
                  handleView(value, edit);
                }}
                fetchDocuments={() => {
                  fetchDocument();
                }}
              />
            )
          )}

          {/* </Container> */}
        </Card>
      </Grid>
    </div>
  );
}
