import {
  Alert,
  // Avatar,
  Box,
  Button,
  Card,
  // Checkbox,
  // Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  // FormGroup,
  // FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import axios from 'src/utils/axios';
import { useLocation, useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
// import { any } from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

export default function LeaveManagementWorkload() {
  const getFormattedToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
    const day = String(today.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };
  const location = useLocation();
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  const { data } = location.state ?? {};

  // const [selectedDate, setSelectedDate] = useState('');
  const [workflowName, setWorkflowName] = useState('Jayvion Saimon');
  const [workflowId, setSelectedWorkflowIdTemp] = useState<any>(null);
  // const [singleWorkflowId, setSingleWorkflowId] = useState(null);
  const [leaveType, setLeaveType] = useState('leave');
  const [startDate, setStartDate] = useState(getFormattedToday);
  const [endDate, setEndDate] = useState(getFormattedToday);
  const [remark, setRemark] = useState('');
  const [workflowsDropdown, setWorkflowsDropdown] = useState(false);
  const [allWorkflowSelected, setAllWorkflowSelected] = useState(false);
  // const pageSizeOptions = [10, 25, 40];
  // const [leaveData, setLeaveData] = useState<any[]>([]);
  const [workflowLeaveData, setWorkflowLeaveData] = useState<any[]>([]);
  const [workflowHolidayData, setWorkflowHolidayData] = useState<any[]>([]);
  const [activeWorkflows, setActiveWorkflows] = useState([]);
  const [user_id, setUserId] = useState<any>(null);
  const [start_date, setStartDateTemp] = useState<any>(null);
  const [open, setOpen] = useState(false);
  // const [rowData, setRowData] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [startDateGreater, setStartDateGreater] = useState(false);
  const navigate = useNavigate();

  const goToWorkflowList = () => {
    navigate('/workflowUserList');
  };

  const handleDeleteRow = (_user_id: any, _start_date: any) => {
    setOpen(true);
    setUserId(_user_id);
    setStartDateTemp(_start_date);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (start_date) {
      // Make API call to backend here
      axios
        .delete(`/core/deleteWorkflowLeave?id=${user_id}&startDate=${start_date}`)
        .then((response) => {
          console.log('Response from backend:', response.data);
          setWorkflowInfoInTable();
        })

        .catch((error) => {
          console.error('Error:', error);
        });
    }
    setOpen(false);
  };

  // useEffect(() => {
  //   console.log('Single workflow id =', singleWorkflowId);
  // }, [singleWorkflowId]);

  // const getAllActiveWorkflows = () => {
  //   axios
  //     .get(`/core/getActiveWorkflows`)
  //     .then((response) => {
  //       console.log('Response from backend:', response.data);
  //       const newArray = response.data.map(({ UserID, ...obj }: any) => ({
  //         id: UserID,
  //         ...obj,
  //       }));
  //       console.log('New array Active= ', newArray);
  //       setActiveWorkflows(newArray);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  useEffect(() => {
    setWorkflowInfoInTable();
    // getAllActiveWorkflows();
    const filteredActiveWorkflow = data.filter((item: any) => item.UserStatus === 'active');
    setActiveWorkflows(filteredActiveWorkflow);
    setTimeout(() => {
      console.log('Filtered = ', workflowLeaveData);
      console.log('Filtered = ', workflowHolidayData);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('Active workflows outside useEffect = ', activeWorkflows);

  // const getAllActiveWorkflows = () => {
  //   axios
  //     .get(`/core/getActiveWorkflows`)
  //     .then((response) => {
  //       console.log('Response from backend:', response.data);
  //       const newArray = response.data.map(({ UserID, ...obj }: any) => ({
  //         id: UserID,
  //         ...obj,
  //       }));
  //       console.log('New array Active= ', newArray);
  //       // const activeWorkflowFilter = newArray.filter((item: any) => item.UserStatus === 'active');
  //       setActiveWorkflows(newArray);
  //       console.log('Active workflows = ', activeWorkflows);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  // const handleDateChange = (event: any) => {
  //   setSelectedDate(event.target.value);
  // };

  const handleWorkflowChange = (event: any) => {
    const selectedId = event.target.value;
    setSelectedWorkflowIdTemp(selectedId);
    // setWorkflowInfoInTable(selectedId);
    const selectedWorkflow = data.find((workflow: any) => workflow.id === selectedId);
    setWorkflowName(' ');

    // Update the selectedWorkflowName with the name of the selected workflow
    if (selectedWorkflow) {
      setWorkflowName(selectedWorkflow.name);
    } else {
      // If the selectedId is not found in the data array, set the name to an empty string
      setWorkflowName('');
    }
  };

  // const handleLeaveTypeChange = (event: any) => {
  //   setLeaveType(event.target.value);
  // };

  const handleDisableWorkflows = (event: any) => {
    console.log('Radio value = ', event.target.value);
    if (event.target.value === 'all') {
      setWorkflowsDropdown(true);
      setAllWorkflowSelected(true);
    } else {
      setWorkflowsDropdown(false);
      setAllWorkflowSelected(false);
    }
  };

  const setWorkflowInfoInTable = () => {
    // setSingleWorkflowId(selectedId);
    // console.log('Single workflow id = ', selectedId);

    // Make API call to backend here
    axios
      .get(`/core/getAllWorkflowLeaveData`)
      .then((response) => {
        console.log('Response from backend:', response.data);
        const newArray = response.data.map((obj: any, index: number) => ({
          id: index,
          ...obj,
          workflowFName: 'hello',
          workflowLName: 'World',
        }));
        console.log('New array = ', newArray);

        const filteredLeaveData = newArray.filter((item: any) => item.LeaveType === 'leave');
        setWorkflowLeaveData(filteredLeaveData);

        const filteredHolidayData = newArray.filter((item: any) => item.LeaveType === 'holiday');
        setWorkflowHolidayData(filteredHolidayData);
        // setLeaveData(newArray);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Handler function for the start date value change
  // const handleStartDateChange = (event: any) => {
  //   setStartDate(event.target.value);
  // };

  // // Handler function for the end date value change
  // const handleEndDateChange = (event: any) => {
  //   setEndDate(event.target.value);
  // };

  // // Handler function for the remark value change
  // const handleRemarkChange = (event: any) => {
  //   setRemark(event.target.value);
  // };

  // const handleUpdateClick = () => {
  //   if (allWorkflowSelected) {
  //     console.log('ALL SELECTED');
  //     const allWorkflowHolidayPayload = {
  //       leaveType,
  //       startDate,
  //       endDate,
  //       remark,
  //     };
  //     if (allWorkflowHolidayPayload.startDate > allWorkflowHolidayPayload.endDate) {
  //       setStartDateGreater(true);
  //     } else {
  //       console.log(allWorkflowHolidayPayload);
  //       axios
  //         .post('/core/addAllWorkflowHolidayData', allWorkflowHolidayPayload)
  //         .then((response) => {
  //           console.log('Response from backend:', response.data);
  //           if (response.status === 200) {
  //             setShowSuccessMessage(true);
  //             setWorkflowInfoInTable();
  //           }
  //         })
  //         .catch((error) => {
  //           console.error('Error sending data to backend:', error);
  //           setShowErrorMessage(true);
  //         });
  //     }
  //   } else {
  //     const payload = {
  //       workflowId,
  //       workflowName,
  //       leaveType,
  //       startDate,
  //       endDate,
  //       remark,
  //     };
  //     if (payload.startDate > payload.endDate) {
  //       setStartDateGreater(true);
  //     } else {
  //       console.log(payload);
  //       axios
  //         .post(`/core/addWorkflowLeaveData?Id=${user?.ID}`, payload)
  //         .then((response) => {
  //           // Handle the response from the backend if needed
  //           console.log('Response from backend:', response.data);
  //           if (response.status === 200) {
  //             setShowSuccessMessage(true);
  //             setWorkflowInfoInTable();
  //           }
  //         })
  //         .catch((error) => {
  //           // Handle any errors that occurred during the request
  //           console.error('Error sending data to backend:', error);
  //           setShowErrorMessage(true);
  //         });
  //     }
  //   }
  // };
  const handleSnackbarClose = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setStartDateGreater(false);
  };

  let leave_columns: GridColDef[];
  let holiday_columns: GridColDef[];

  if (userrole === 'Management') {
    leave_columns = [
      // {
      //   field: 'id',
      //   headerName: 'ID',
      //   align: 'center',
      //   headerAlign: 'center',
      //   width: 50,
      //   hideable:true,
      //   sortable: false,
      //   filterable: false,
      //   disableColumnMenu: true,
      // },
      {
        field: 'UserID',
        headerName: 'Id',
        align: 'center',
        headerAlign: 'center',
        width: 80,
        hideable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'FirstName',
        headerName: 'First Name',
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'LastName',
        headerName: 'Last Name',
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'LeaveStartDate',
        headerName: 'Start Date',
        align: 'center',
        headerAlign: 'center',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        // renderCell: (params: any) => (
        //   <div>
        //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
        //   </div>
        // ),
      },
      {
        field: 'LeaveEndDate',
        headerName: 'End Date',
        align: 'left',
        headerAlign: 'left',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        // renderCell: (params: any) => (
        //   <div>
        //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
        //   </div>
        // ),
      },
      {
        field: 'LeaveReason',
        headerName: 'Remark',
        align: 'left',
        headerAlign: 'left',
        width: 600,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <div
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              width: '100%',
              textAlign: 'left',
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: 'delete',
        headerName: 'Action',
        align: 'center',
        headerAlign: 'center',
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: any) => (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteRow(params.row.UserID, params.row.LeaveStartDate)}
          >
            Delete
          </Button>
        ),
      },
    ];

    holiday_columns = [
      // {
      //   field: 'id',
      //   headerName: 'ID',
      //   align: 'center',
      //   headerAlign: 'center',
      //   hideable:true,
      //   width: 50,
      //   sortable: false,
      //   filterable: false,
      //   disableColumnMenu: true,
      // },
      {
        field: 'UserID',
        headerName: 'Id',
        align: 'center',
        headerAlign: 'center',
        width: 80,
        hideable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'FirstName',
        headerName: 'First Name',
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'LastName',
        headerName: 'Last Name',
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'LeaveStartDate',
        headerName: 'Start Date',
        align: 'center',
        headerAlign: 'center',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        // renderCell: (params: any) => (
        //   <div>
        //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
        //   </div>
        // ),
      },
      {
        field: 'LeaveEndDate',
        headerName: 'End Date',
        align: 'center',
        headerAlign: 'center',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        // renderCell: (params: any) => (
        //   <div>
        //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
        //   </div>
        // ),
      },
      {
        field: 'LeaveReason',
        headerName: 'Remark',
        align: 'left',
        headerAlign: 'left',
        width: 600,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <div
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              width: '100%',
              textAlign: 'left',
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: 'delete',
        headerName: 'Action',
        align: 'center',
        headerAlign: 'center',
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: any) => (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteRow(params.row.UserID, params.row.LeaveStartDate)}
          >
            Delete
          </Button>
        ),
      },
    ];
  } else {
    leave_columns = [
      // {
      //   field: 'id',
      //   headerName: 'ID',
      //   align: 'center',
      //   headerAlign: 'center',
      //   width: 50,
      //   hideable:true,
      //   sortable: false,
      //   filterable: false,
      //   disableColumnMenu: true,
      // },
      {
        field: 'UserID',
        headerName: 'Id',
        align: 'center',
        headerAlign: 'center',
        width: 80,
        hideable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'FirstName',
        headerName: 'First Name',
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'LastName',
        headerName: 'Last Name',
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'LeaveStartDate',
        headerName: 'Start Date',
        align: 'center',
        headerAlign: 'center',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        // renderCell: (params: any) => (
        //   <div>
        //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
        //   </div>
        // ),
      },
      {
        field: 'LeaveEndDate',
        headerName: 'End Date',
        align: 'center',
        headerAlign: 'center',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        // renderCell: (params: any) => (
        //   <div>
        //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
        //   </div>
        // ),
      },
      {
        field: 'LeaveReason',
        headerName: 'Remark',
        align: 'left',
        headerAlign: 'left',
        width: 600,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <div
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              width: '100%',
              textAlign: 'left',
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: 'delete',
        headerName: 'Action',
        align: 'center',
        headerAlign: 'center',
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: any) => (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteRow(params.row.UserID, params.row.LeaveStartDate)}
          >
            Delete
          </Button>
        ),
      },
    ];

    holiday_columns = [
      // {
      //   field: 'id',
      //   headerName: 'ID',
      //   align: 'center',
      //   headerAlign: 'center',
      //   hideable:true,
      //   width: 50,
      //   sortable: false,
      //   filterable: false,
      //   disableColumnMenu: true,
      // },
      {
        field: 'UserID',
        headerName: 'Id',
        align: 'center',
        headerAlign: 'center',
        width: 80,
        hideable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'FirstName',
        headerName: 'First Name',
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'LastName',
        headerName: 'Last Name',
        width: 180,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'LeaveStartDate',
        headerName: 'Start Date',
        align: 'center',
        headerAlign: 'center',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        // renderCell: (params: any) => (
        //   <div>
        //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
        //   </div>
        // ),
      },
      {
        field: 'LeaveEndDate',
        headerName: 'End Date',
        align: 'center',
        headerAlign: 'center',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        // renderCell: (params: any) => (
        //   <div>
        //     {params.value.fromDate}&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;{params.value.toDate}
        //   </div>
        // ),
      },
      {
        field: 'LeaveReason',
        headerName: 'Remark',
        align: 'left',
        headerAlign: 'left',
        width: 600,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <div
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              width: '100%',
              textAlign: 'left',
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: 'delete',
        headerName: 'Action',
        align: 'center',
        headerAlign: 'center',
        width: 200,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteRow(params.row.UserID, params.row.LeaveStartDate)}
          >
            Delete
          </Button>
        ),
      },
    ];
  }

  // const handleDeleteRow = (row: any) => {
  //   console.log('Deleted Row:', row);

  //   // Add your delete logic here (e.g., making an API call to delete the row from the backend)
  // };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue('selectedWorkflowId', '-1');
  }, [setValue]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSubmit = (data: any) => {
    // Your logic to handle the form submission here
    console.log('Leave data', data);
    console.log('All workflow', allWorkflowSelected);
    if (allWorkflowSelected) {
      console.log('ALL SELECTED');
      const allWorkflowHolidayPayload = {
        leaveType,
        startDate,
        endDate,
        remark,
      };
      if (data.startDate > data.endDate) {
        setStartDateGreater(true);
      } else {
        console.log('All Workflow leave data = ', data);
        axios
          .post(`/core/addAllWorkflowHolidayData?Id=${user?.ID}`, data)
          .then((response) => {
            console.log('Response from backend:', response.data);
            if (response.status === 200) {
              setShowSuccessMessage(true);
              setWorkflowInfoInTable();
            }
          })
          .catch((error) => {
            console.error('Error sending data to backend:', error);
            setShowErrorMessage(true);
          });
      }
    } else {
      const payload = {
        workflowId,
        workflowName,
        leaveType,
        startDate,
        endDate,
        remark,
      };

      if (data.startDate > data.endDate) {
        setStartDateGreater(true);
      } else {
        console.log('Single workflow data', data);
        axios
          .post(`/core/addWorkflowLeaveData?Id=${user?.ID}`, data)
          .then((response) => {
            // Handle the response from the backend if needed
            console.log('Response from backend:', response.data);
            if (response.status === 200) {
              setShowSuccessMessage(true);
              setWorkflowInfoInTable();
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the request
            console.error('Error sending data to backend:', error);
            setShowErrorMessage(true);
          });
      }
    }
  };

  const handleReset = () => {
    reset();
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ width: '100%', marginTop: '10px' }}
      >
        <DialogTitle>Workflow Leave Management</DialogTitle>
      </Grid>
      <Card sx={{ py: 4, px: 5, textAlign: 'center', width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
            <Grid item xs={2} md={3}>
              <Stack
                spacing={2}
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 'auto',
                }}
              >
                <div>
                  <Typography variant="body1">Select Workflow User</Typography>
                  <FormControl sx={{ width: '400px' }} error={!!errors.selectedWorkflowId}>
                    <Controller
                      name="selectedWorkflowId"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Please select an Workflow.' }}
                      render={({ field }) => (
                        <Select {...field} disabled={workflowsDropdown === true}>
                          {activeWorkflows.map((workflow: any) => (
                            <MenuItem key={workflow.id} value={workflow.id}>
                              {`${workflow.UserFname} ${workflow.UserLname}`}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {/* <FormHelperText>{errors.selectedWorkflowId?.message}</FormHelperText> */}
                  </FormControl>
                </div>
              </Stack>
              <Typography variant="h5" sx={{ marginTop: '30px' }}>
                --- OR ---
              </Typography>
              <FormControl sx={{ marginTop: '40px' }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // value={leaveType}
                  onChange={handleDisableWorkflows}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value="all" control={<Radio />} label="All" />
                  <FormControlLabel value="individual" control={<Radio />} label="Individual" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={2} md={2.4}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Leave Type</FormLabel>
                <Controller
                  name="leaveType"
                  control={control}
                  defaultValue="leave"
                  render={({ field }) => (
                    <RadioGroup {...field} aria-labelledby="demo-radio-buttons-group-label">
                      <FormControlLabel value="leave" control={<Radio />} label="Leave" />
                      <FormControlLabel value="holiday" control={<Radio />} label="Holiday" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={2} md={2}>
              <Controller
                name="startDate"
                control={control}
                defaultValue=""
                rules={{ required: 'Please select a Start Date.' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="date"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!errors.startDate}
                    sx={{ width: '100%' }}
                  />
                )}
              />
              {/* <FormHelperText>{errors.startDate?.message}</FormHelperText> */}

              <Controller
                name="endDate"
                control={control}
                defaultValue=""
                rules={{ required: 'Please select an End Date.' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="date"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ marginTop: '20px', width: '100%' }}
                    error={!!errors.endDate}
                  />
                )}
              />
              {/* <FormHelperText>{errors.endDate?.message}</FormHelperText> */}
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <Controller
                    name="remark"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-multiline-static"
                        label="Remark"
                        multiline
                        rows={4}
                        sx={{
                          width: '100%',
                          // overflow: 'auto', // Ensures content is scrollable within the field
                          whiteSpace: 'pre-wrap', // Ensures text wraps correctly
                          wordWrap: 'break-word', // Prevents long words from causing overflow
                        }}
                      />
                    )}
                  />
                </div>
              </Box>
            </Grid>

            <Grid container xs={12} md={12} textAlign="center" sx={{ marginTop: '20px' }}>
              <Grid item md={9} />
              <Grid item md={1} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item md={0.7} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                  sx={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item md={0.7} textAlign="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={goToWorkflowList}
                  sx={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Grid container direction="row" spacing={1} md={12} sx={{ margin: 1 }}>
        <Grid item xs={2} md={12}>
          <Card sx={{ py: 10, px: 5, textAlign: 'center', width: '100%' }}>
            <DialogTitle>Upcoming Leaves For 2 Weeks</DialogTitle>
            <div style={{ height: 300, width: '100%' }}>
              <DataGrid
                rows={workflowLeaveData}
                columns={leave_columns}
                disableRowSelectionOnClick
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            </div>
          </Card>
        </Grid>

        <Grid item xs={2} md={12}>
          <Card sx={{ py: 5, px: 5, textAlign: 'center', width: '100%', marginRight: '30px' }}>
            <DialogTitle>Upcoming Holidays For 2 Weeks</DialogTitle>
            <div style={{ height: 300, width: '100%' }}>
              <DataGrid
                rows={workflowHolidayData}
                columns={holiday_columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            </div>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <Alert severity="warning">Are you sure you want to delete?</Alert>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={3000} // Adjust the duration as per your requirement
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Data added successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorMessage}
        autoHideDuration={3000} // Adjust the duration as per your requirement
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          Please check the dates. Might be duplicate.
        </Alert>
      </Snackbar>

      <Snackbar
        open={startDateGreater}
        autoHideDuration={3000} // Adjust the duration as per your requirement
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          Start date should less or equal to than end date.
        </Alert>
      </Snackbar>
    </>
  );
}
