// @mui
import {
  // Typography,
  Stack,
  Typography,
} from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import {
  StyledRoot,
  // StyledSectionBg,
  // StyledSection,
  StyledContent,
} from './styles';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />
      {/* <Typography
        variant="h3"
        sx={{
          position: 'absolute',
          top: '4%',
          left: '5%',
          color: '#00A885',
          zIndex: 1,
          fontStyle: 'italic',
        }}
      >
        Energy Advance Australia
      </Typography> */}

      {/* <StyledSection>
        <Typography variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          {title || 'Hi, Welcome back'}
        </Typography> */}

      <Image
        disabledEffect
        visibleByDefault
        alt="auth"
        src={illustration || '/assets/images/about/energy-advance-3.jpg'}
        sx={{ maxWidth: '100%' }}
      />

      {/* <StyledSectionBg />
      </StyledSection> */}

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
