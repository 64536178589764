import { Box, Button, Divider, Grid, MenuItem, Select, Stack, TextField } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import axios from 'src/utils/axios';

interface JobOptions {
  job_option: string;
  job_record_id: number;
  option_id: number;
  option_type: string;
}

export default function BasicOptions({
  reportData,
  saveReportData,
  setSaveReportData,
  invalidFields,
}: any) {
  const [answerOne, setAnswerOne] = useState('');
  const [answerTwo, setAnswerTwo] = useState('');
  const [answerThree, setAnswerThree] = useState('');

  const handleChangeAnswerOne = (answer1: string) => {
    console.log('Answer 1: ', answer1);
    setAnswerOne(answer1);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      ThermalAnswer: answer1,
    }));
  };

  const handleChangeAnswerTwo = (answer2: string) => {
    console.log('Answer 2: ', answer2);
    setAnswerTwo(answer2);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      WaterAnswer: answer2,
    }));
  };

  const handleChangeAnswerThree = (answer3: string) => {
    console.log('Answer 3: ', answer3);
    setAnswerThree(answer3);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      EnergyAnswer: answer3,
    }));
  };

  const addWaterSectionOptions = (e: any, index: number) => {
    const newOptionsWater: JobOptions[] = [...saveReportData.WaterSectionOptions];
    newOptionsWater[index].job_option = e.target.value;
    console.log('Water section options:', newOptionsWater);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      WaterSectionOptions: newOptionsWater,
    }));
  };

  const addEnergySectionOptions = (e: any, index: number) => {
    const newOptionsEnergy: JobOptions[] = [...saveReportData.EnergySectionOptions];
    newOptionsEnergy[index].job_option = e.target.value;
    console.log('Energy section options:', newOptionsEnergy);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      EnergySectionOptions: newOptionsEnergy,
    }));
  };

  const handleRemoveWaterOption = (option: any, index: number) => {
    console.log(`Deleted option: ${JSON.stringify(option)}`);
    const waterOptions = saveReportData?.WaterSectionOptions;
    if (option.option_id) {
      axios
        .delete(`/core/removeOption`, { data: option })
        .then((response) => {
          console.log('Report data = ', reportData);
          if (response.status === 200) {
            const updatedOptions = waterOptions.filter((v: any, i: number) => {
              if (v.option_id === undefined || v.option_id !== option.option_id) {
                return true;
              }
              return false;
            });
            setSaveReportData((prevData: any) => ({
              ...prevData,
              WaterSectionOptions: updatedOptions,
            }));
          }
        })
        .catch((error) => {
          console.error('Error fetching getJob:', error);
        });
    } else {
      const updatedOptions = waterOptions.filter((v: any, i: number) => {
        if (i !== index) {
          return true;
        }
        return false;
      });
      setSaveReportData((prevData: any) => ({
        ...prevData,
        WaterSectionOptions: updatedOptions,
      }));
    }
  };

  const handleRemoveEnergyOption = (option: any, index: number) => {
    console.log(`Deleted option: ${JSON.stringify(option)}`);
    const energyOptions = saveReportData?.EnergySectionOptions;
    if (option.option_id) {
      axios
        .delete(`/core/removeOption`, { data: option })
        .then((response) => {
          console.log('Report data = ', reportData);
          if (response.status === 200) {
            const updatedOptions = energyOptions.filter((v: any, i: number) => {
              if (v.option_id === undefined || v.option_id !== option.option_id) {
                return true;
              }
              return false;
            });
            setSaveReportData((prevData: any) => ({
              ...prevData,
              EnergySectionOptions: updatedOptions,
            }));
          }
        })
        .catch((error) => {
          console.error('Error fetching getJob:', error);
        });
    } else {
      const updatedOptions = energyOptions.filter((v: any, i: number) => {
        if (i !== index) {
          return true;
        }
        return false;
      });
      setSaveReportData((prevData: any) => ({
        ...prevData,
        EnergySectionOptions: updatedOptions,
      }));
    }
  };

  const handleAddEnergySectionOption = () => {
    // const options = [...energySectionOptions, { job_option: '' } as JobOptions]
    // setEnergySectionOptions(options);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      EnergySectionOptions: [...saveReportData.EnergySectionOptions, { job_option: '' }],
    }));
  };
  const handleAddWaterSectionOption = () => {
    // const options = [...waterSectionOptions, { job_option: '' } as JobOptions]
    // setWaterSectionOptions(options);
    setSaveReportData((prevData: any) => ({
      ...prevData,
      WaterSectionOptions: [...saveReportData.WaterSectionOptions, { job_options: '' }],
    }));
  };

  return (
    <Grid item xs={12} sm={12} md={9}>
      <Box sx={{ p: '2rem', border: '1px solid #e6e2e2' }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.ClientName}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Assessment Date:{' '}
              {reportData?.projectInfoData?.AccessmentDate
                ? format(new Date(reportData?.projectInfoData?.AccessmentDate), 'dd/MM/yyyy')
                : ''}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              {reportData?.projectInfoData?.Address}
            </Box>
          </Stack>
          <Stack direction="column">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              Reference Number: {reportData?.projectInfoData?.ReferenceNumber}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack
          spacing={2}
          alignItems="flex-end"
          sx={{ textAlign: 'right', typography: 'body2', width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{ margin: '0', marginTop: '0px !important', lineHeight: '1.2' }}
          >
            <Box sx={{ color: '#11c1ed', typography: 'h3' }}>THE BASIX OPTIONS</Box>
          </Stack>
          {(invalidFields.indexOf('EnergySectionOptions') >= 0 ||
            invalidFields.indexOf('WaterSectionOptions') >= 0) && (
            <Box sx={{ color: 'red', typography: 'h6' }}>Please Fill Water/Energy Options.</Box>
          )}
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
          <Stack>
            <Stack textAlign="center">
              <Box sx={{ color: '#17a986', typography: 'h3' }}>
                Thermal Comfort Section Compliance Achieved?
                <span style={{ color: 'red' }}>*</span>
              </Box>
            </Stack>
            <Stack alignItems="flex-end" spacing={1.5}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                <Select
                  variant="outlined"
                  name="thermalAnswer"
                  value={saveReportData?.ThermalAnswer}
                  onChange={(e) => handleChangeAnswerOne(e.target.value)}
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('ThermalAnswer') >= 0
                        ? '3px solid red'
                        : '1px solid black',
                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                >
                  <MenuItem value="yes">Yes: Options Not Required</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </Stack>
            </Stack>

            <Stack textAlign="center" sx={{ marginTop: '10px' }}>
              <Box sx={{ color: '#11c1ed', typography: 'h3' }}>
                Water Section Compliance Achieved?
                <span style={{ color: 'red' }}>*</span>
              </Box>
            </Stack>
            <Stack alignItems="flex-end" spacing={1.5}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                <Select
                  variant="outlined"
                  name="mounting"
                  value={saveReportData?.WaterAnswer}
                  onChange={(e) => handleChangeAnswerTwo(e.target.value)}
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('WaterAnswer') >= 0
                        ? '3px solid red'
                        : '1px solid black',
                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                >
                  <MenuItem value="yes">Yes: Options Not Required</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </Stack>
            </Stack>
            {(answerTwo === 'no' || saveReportData?.WaterAnswer === 'no') && (
              <Stack spacing={1.5}>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item sx={{ marginTop: '10px' }}>
                    <Button
                      size="small"
                      startIcon={<Iconify icon="mingcute:add-line" />}
                      onClick={handleAddWaterSectionOption}
                      sx={{ flexShrink: 0, color: '#11c1ed' }}
                    >
                      Add Water Section Option
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            )}

            <Stack textAlign="center" sx={{ marginTop: '10px' }}>
              <Box sx={{ color: '#ed8e11', typography: 'h3' }}>
                Energy Section Compliance Achieved?
                <span style={{ color: 'red' }}>*</span>
              </Box>
            </Stack>
            <Stack alignItems="flex-end" spacing={1.5}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                <Select
                  variant="outlined"
                  name="mounting"
                  value={saveReportData?.EnergyAnswer}
                  onChange={(e) => handleChangeAnswerThree(e.target.value)}
                  sx={{
                    width: '100%',
                    border:
                      invalidFields.indexOf('EnergyAnswer') >= 0
                        ? '3px solid red'
                        : '1px solid black',
                    background: 'cadetblue',
                    borderRadius: '6px',
                  }}
                >
                  <MenuItem value="yes">Yes: Options Not Required</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Stack>

          {(answerThree === 'no' || saveReportData?.EnergyAnswer === 'no') && (
            <Stack spacing={1.5}>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    size="small"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                    onClick={handleAddEnergySectionOption}
                    sx={{ flexShrink: 0, color: '#ed8e11' }}
                  >
                    Add Energy Section Option
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Stack>

        <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
          {saveReportData?.WaterSectionOptions.map((option: any, index: any) => (
            <Stack key={index} sx={{ marginTop: '40px' }}>
              <Stack textAlign="center">
                <Box sx={{ color: '#11c1ed', typography: 'h6' }}>
                  Water Section Option {index + 1} : Compliance Achieved
                </Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption', fontStyle: 'italic' }}>
                  The following upgrades are required, these are inclusive of all the specifications
                  as noted on The Specifications:
                </Box>
              </Stack>
              <Stack alignItems="flex-end" spacing={1.5}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                  <TextField
                    size="small"
                    multiline
                    rows={2}
                    name={index.toString()}
                    value={option?.job_option || ''}
                    onChange={(e) => addWaterSectionOptions(e, index)}
                    sx={{
                      width: '100%',
                      background: 'cadetblue',
                      borderRadius: '6px',
                    }}
                  />
                </Stack>
                {/* Save and Remove buttons removed from here */}
              </Stack>
              {/* <Grid item> */}
              <Button
                size="small"
                color="error"
                startIcon={<Iconify icon="mdi:remove" />}
                onClick={() => handleRemoveWaterOption(option, index)}
              >
                Remove
              </Button>
              {/* </Grid> */}
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'none', py: '1rem' }} />

        <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
          {saveReportData?.EnergySectionOptions.map((option: any, index: any) => (
            <Stack key={index} sx={{ marginTop: '40px' }}>
              <Stack textAlign="center">
                <Box sx={{ color: '#ed8e11', typography: 'h6' }}>
                  Energy Section Option {index + 1} : Achieved
                </Box>
                <Box sx={{ color: 'text.disabled', typography: 'caption', fontStyle: 'italic' }}>
                  The following upgrades are required, these are inclusive of all the specifications
                  as noted on The Specifications:
                </Box>
              </Stack>
              <Stack alignItems="flex-end" spacing={1.5}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                  <TextField
                    size="small"
                    multiline
                    rows={2}
                    name={index.toString()}
                    value={option?.job_option || ''}
                    onChange={(e) => addEnergySectionOptions(e, index)}
                    sx={{
                      width: '100%',
                      background: 'cadetblue',
                      borderRadius: '6px',
                    }}
                  />
                </Stack>
                {/* Save and Remove buttons removed from here */}
              </Stack>
              {/* <Grid item> */}
              <Button
                size="small"
                color="error"
                startIcon={<Iconify icon="mdi:remove" />}
                onClick={() => handleRemoveEnergyOption(option, index)}
              >
                Remove
              </Button>
              {/* </Grid> */}
            </Stack>
          ))}
        </Stack>

        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
              <img
                src="./ea-report-footer.png"
                alt="Logo"
                style={{ width: '20vh', height: '10vh' }}
              />
            </Box>
            <Divider orientation="vertical" sx={{ mr: '0.8rem' }} />
            <Stack direction="column" sx={{ marginTop: '25px' }}>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                energy@energyadvance.com.au
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                Energy Advance Australia Pty Ltd | 34 Dellamarta Road WANGARA 6065{' '}
              </Box>
              <Box sx={{ color: 'text.disabled', typography: 'caption' }}>
                ACN: 60 9332014 | 1300 850 228
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
}
function enqueueSnackbar(data: any) {
  throw new Error('Function not implemented.');
}
