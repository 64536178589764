import { Button, Container, Typography } from '@mui/material';
import axios from 'src/utils/axios';
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  // GridRowSelectionModel,
  // GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

import { 
  // useLocation,
   useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
// import { useSettingsContext } from 'src/components/settings';

export default function AssessorWorkschedule() {
  // const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  // const [selectedRows, setSelectedRows] = useState<any[]>([]);
  // const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAssessorsWorkload();
    setTimeout(() => {
      console.log('Filtered = ', filteredData);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchAssessorsWorkload() {
    try {
      const response = await axios.get('/core/getAssessorWorkSchedule');
      console.log('assessor workload:', response.data);

      const newArray = response.data.map(({ UserID, ...obj }: any) => ({
        id: UserID,
        ...obj,
      }));
      console.log('New array', newArray);
      setFilteredData(newArray);
    } catch (error) {
      console.error(error);
    }
  }

  const goToAssessoerList = () => {
    navigate('/assessorlist');
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      align: 'center',
      headerAlign: 'center',
      width: 100,
    },
    {
      field: 'UserFName',
      headerName: 'First Name',
      // align: 'center',
      // headerAlign: 'center',
      width: 120,
    },
    {
      field: 'UserLName',
      headerName: 'Last Name',
      // align: 'center',
      // headerAlign: 'center',
      width: 120,
    },
    {
      field: 'MondayStart',
      headerName: 'Monday',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          params.row.MondayStart === 'Non Working Day' &&
          params.row.MondayEnd === 'Non Working Day'
        ) {
          return (
            <Typography variant="body2" noWrap>
              Non Working Day
            </Typography>
          );
        }
        if (params.row.MondayStart !== null && params.row.MondayEnd !== '') {
          return (
            <Typography variant="body2" noWrap>
              {`${params.row.MondayStart} To ${params.row.MondayEnd}`}
            </Typography>
          );
        }
        return null;
      },
    },

    {
      field: 'MondayEnd',
      headerName: 'Monday End',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'TuesdayStart',
      headerName: 'Tuesday',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          params.row.TuesdayStart === 'Non Working Day' &&
          params.row.TuesdayEnd === 'Non Working Day'
        ) {
          return (
            <Typography variant="body2" noWrap>
              Non Working Day
            </Typography>
          );
        }
        if (params.row.TuesdayStart !== null && params.row.TuesdayStart !== '') {
          return (
            <Typography variant="body2" noWrap>
              {`${params.row.TuesdayStart} To ${params.row.TuesdayEnd}`}
            </Typography>
          );
        }
        return null;
      },
    },
    {
      field: 'TuesdayEnd',
      headerName: 'Tuesday End',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    // {
    //   field: 'WednesdayStart',
    //   headerName: 'Wednsday',
    //   align: 'center',
    //   headerAlign: 'center',
    //   width: 200,
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    // renderCell: (params) => (
    //     <Typography variant="body2" noWrap>
    //       {`${params.row.WednesdayStart} To ${params.row.WednesdayEnd}`}
    //     </Typography>
    //   ),
    // },
    {
      field: 'WednesdayStart',
      headerName: 'Wednesday',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          params.row.WednesdayStart === 'Non Working Day' &&
          params.row.WednesdayEnd === 'Non Working Day'
        ) {
          return (
            <Typography variant="body2" noWrap>
              Non Working Day
            </Typography>
          );
        }
        if (params.row.WednesdayStart !== null && params.row.WednesdayStart !== '') {
          return (
            <Typography variant="body2" noWrap>
              {`${params.row.WednesdayStart} To ${params.row.WednesdayEnd}`}
            </Typography>
          );
        }
        return null;
      },
    },
    {
      field: 'WednesdayEnd',
      headerName: 'Wednesday End',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'ThursdayStart',
      headerName: 'Thursday',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          params.row.ThursdayStart === 'Non Working Day' &&
          params.row.ThursdayEnd === 'Non Working Day'
        ) {
          return (
            <Typography variant="body2" noWrap>
              Non Working Day
            </Typography>
          );
        }
        if (params.row.ThursdayStart !== null && params.row.ThursdayStart !== '') {
          return (
            <Typography variant="body2" noWrap>
              {`${params.row.ThursdayStart} To ${params.row.ThursdayEnd}`}
            </Typography>
          );
        }

        return null;
      },
    },
    {
      field: 'ThursdayEnd',
      headerName: 'Thursday End',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'FridayStart',
      headerName: 'Friday',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          params.row.FridayStart === 'Non Working Day' &&
          params.row.FridayEnd === 'Non Working Day'
        ) {
          return (
            <Typography variant="body2" noWrap>
              Non Working Day
            </Typography>
          );
        }
        if (params.row.FridayStart !== null && params.row.FridayStart !== '') {
          return (
            <Typography variant="body2" noWrap>
              {`${params.row.FridayStart} To ${params.row.FridayEnd}`}
            </Typography>
          );
        }
        return null;
      },
    },
    {
      field: 'FridayEnd',
      headerName: 'Friday End',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'SaturdayStart',
      headerName: 'Saturday ',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params) => {
      //   if (
      //     params.row.SaturdayStart === 'Non Working Day' &&
      //     params.row.SaturdayEnd === 'Non Working Day'
      //   ) {
      //     return (
      //       <Typography variant="body2" noWrap>
      //         Non Working Day
      //       </Typography>
      //     );
      //   }
      //   if (params.row.SaturdayStart !== null && params.row.SaturdayStart !== '') {
      //     return (
      //       <Typography variant="body2" noWrap>
      //         {`${params.row.SaturdayStart} To ${params.row.SaturdayEnd}`}
      //       </Typography>
      //     );
      //   }
      //   return null;
      // },
      // renderCell: (params) => {
      //   if (params.row.UserLastLogin === '0') {
      //     return (
      //       <Typography variant="body2" noWrap>
      //         yet to login
      //       </Typography>
      //     );
      //   }
      //     return (
      //       <Typography variant="body2" noWrap>
      //         {params.row.lastLoginDate.toLocaleString()}
      //       </Typography>
      //     );

      // },
      renderCell: (params) => {
        if (
          params.row.SaturdayStart === 'Non Working Day' &&
          params.row.SaturdayEnd === 'Non Working Day'
        ) {
          return (
            <Typography variant="body2" noWrap>
              Non Working Day
            </Typography>
          );
        }
        if (params.row.SaturdayStart !== null && params.row.SaturdayStart !== '') {
          return (
            <Typography variant="body2" noWrap>
              {`${params.row.SaturdayStart} To ${params.row.SaturdayEnd}`}
            </Typography>
          );
        }
        return null;
      },
    },
    {
      field: 'SaturdayEnd',
      headerName: 'Saturday End',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'SundayStart',
      headerName: 'Sunday ',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (
          params.row.SundayStart === 'Non Working Day' &&
          params.row.SundayEnd === 'Non Working Day'
        ) {
          return (
            <Typography variant="body2" noWrap>
              Non Working Day
            </Typography>
          );
        }
        if (params.row.SundayStart !== null && params.row.SundayStart !== '') {
          return (
            <Typography variant="body2" noWrap>
              {`${params.row.SundayStart} To ${params.row.SundayEnd}`}
            </Typography>
          );
        }
        return null;
      },
    },
    {
      field: 'SundayEnd',
      headerName: 'Sunday End',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];
  // const { themeStretch } = useSettingsContext();

  return (
    <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '50px' }}>
      <Helmet>
        <title> EA Assessor Workschedule</title>
      </Helmet>

      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
      <CustomBreadcrumbs
        heading="Assessor Workschedule"
        links={[
          {
            name: 'Assessor',
            href: '/assessorlist',
          },
          { name: 'Assessor Workschedule' },
        ]}
      />
      <DataGrid
        disableRowSelectionOnClick
        rows={filteredData}
        columns={columns}
        {...filteredData}
        initialState={{
          columns: {
            columnVisibilityModel: {
              FridayEnd: false,
              MondayEnd: false,
              SaturdayEnd: false,
              SundayEnd: false,
              ThursdayEnd: false,
              TuesdayEnd: false,
              WednesdayEnd: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        // pagination
        sx={{ height: '500px' }}
        onRowSelectionModelChange={(newSelectionModel: any) => {
          // setSelectionModel(newSelectionModel);
        }}
        slots={{
          toolbar: MyExportButton,
        }}
      />
      <Button
        variant="soft"
        color="secondary"
        onClick={goToAssessoerList}
        sx={{ float: 'right', marginTop: '20px' }}
      >
        Back
      </Button>
      {/* <Button
		  variant="contained"
		  color="primary"
		  onClick={handleUpdateClick}
		  sx={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
		>
		  Update
		</Button> */}
      {/* </Container> */}
    </Container>
  );
}

function MyExportButton() {
  const currentDate = new Date();
  const formattedDateTime = currentDate.toLocaleDateString().replace(/\//g, '');
  const file_Name = `EA_Assessor_Workschedule_${formattedDateTime}`;

  return (
    // <GridToolbarContainer>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExportContainer>
      <GridCsvExportMenuItem
        options={{
        fileName: file_Name,
        // delimiter: ';',
        allColumns: true,
        utf8WithBom: true,
      }}
      />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
