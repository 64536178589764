import {
  Button,
  Grid,
  TextField,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { Box } from '@mui/system';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  useCallback,
  useEffect,
  // useRef,
  useState,
} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Label from 'src/components/label/Label';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { fData } from 'src/utils/formatNumber';
import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
import { useNavigate } from 'react-router-dom';
// import { defaultImage } from 'src/assets/energy-advance.png'
// import image from "../src/assets/energy-advance.png";

type FormValuesProps = {
  UserProfilePic: any;
  UserFName: string;
  UserLName: string;
  UserEmail: string;
  UserPhone: string;
  UserGender: string;
  UserState: string;
  UserZipcode: string;
  UserStatus: 'active' | 'inactive' | 'deleted' | any;
  UserStreetAddr: string;
  UserCountry: 'Australia' | 'United States' | any;
};

export default function AddAssessorProfileTab({
  next,
  assessor,
  setAssessor,
  setImage,
  image,
}: any) {
  const [values, setValues] = useState<Date | null>(new Date());
  const navigate = useNavigate();
  const [selectedCountryCode, setSelectedCountryCode] = useState('+61');

  const CommentSchema = Yup.object().shape({
    UserFName: Yup.string().required('Name is required'),
    UserLName: Yup.string().required('Name is required'),
    UserEmail: Yup.string()
      .required('Email is required')
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        'Email must be a valid email address in the format name@example.com'
      ),

    UserPhone: Yup.string()
      .required('Phone Number is required. Minimum 8 Maximum 10')
      .min(8)
      .test('is-digits-only', 'Invalid phone number, only digits accepted input', (value) => {
        if (!value) return false;
        return /^(\+61-|\+1-){0,1}\d+$/.test(value);
      }),
    // UserGender: Yup.string().required('Gender is required'),
    UserState: Yup.string(),
    // UserZipcode: Yup.string().required('ZipCode is required'),
    UserStatus: Yup.string(),
    UserStreetAddr: Yup.string(),
    UserCountry: Yup.string().required('Country is required'),
    UserProfilePic: Yup.string(),
  });

  const defaultValues = {
    UserFName: '',
    UserLName: '',
    UserEmail: '',
    UserPhone: '',
    UserGender: '',
    UserState: '',
    UserZipcode: '',
    UserStatus: 'active',
    UserStreetAddr: '',
    UserCountry: 'Australia',
    UserProfilePic: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    // watch,
    setValue,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    console.log(assessor);
    if (assessor) {
      setValue('UserFName', assessor.UserFName);
      setValue('UserLName', assessor.UserLName);
      setValue('UserEmail', assessor.UserEmail);

      setValue('UserGender', assessor.UserGender);
      setValue('UserState', assessor.UserState);
      setValue('UserZipcode', assessor.UserZipcode);
      setValue('UserStatus', assessor.UserStatus || 'active');
      setValue('UserStreetAddr', assessor.UserStreetAddr);
      setValue('UserCountry', assessor.UserCountry);
      if (assessor.UserCountry === 'Australia') {
        setSelectedCountryCode('+61');
        setValue('UserPhone', assessor.UserPhone.replace('+61-', ''));
      } else {
        setSelectedCountryCode('+1');
        setValue('UserPhone', assessor.UserPhone.replace('+1-', ''));
      }
    }
  }, [assessor, setValue]);

  useEffect(() => {
    if (image) {
      setValue('UserProfilePic', image, { shouldValidate: true });
    }
  }, [image, setValue]);

  // const value = watch();
  const onSubmit = async (profile_data: FormValuesProps) => {
    try {
      await CommentSchema.validate(profile_data, { abortEarly: false });
      profile_data.UserPhone = `${selectedCountryCode}-${profile_data.UserPhone}`;

      setAssessor((prevAssessor: any) => ({
        ...prevAssessor,
        ...profile_data,
      }));
      next();
    } catch (error) {
      // Handle validation errors if any
      console.error('Validation error:', error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('UserProfilePic', newFile, { shouldValidate: true });
        setImage(newFile);
      }
    },
    [setImage, setValue]
  );

  const goToAssessoerList = () => {
    navigate('/assessorlist');
  };
  const [disableState, setDisableState] = useState(false);
  return (
    <>
      {/* <Container> */}
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: '100%', display: 'flow', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} md={12} xl={12}>
            <Grid item xs={12} md={4} alignSelf="center">
              {/* <Card sx={{ paddingTop:'70px',paddingBottom:'70px', textAlign: 'center' }}> */}
              <Stack
                spacing={2}
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <RHFUploadAvatar
                  name="UserProfilePic"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 4,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />

                <Label color="secondary" variant="outlined" sx={{ width: '230px', fontSize: 14 }}>
                  Role : Assessor
                </Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    renderInput={(props) => <TextField {...props} sx={{ width: '230px' }} />}
                    label="Created Date"
                    disabled
                    value={Date.now()}
                    onChange={setValues}
                  />
                </LocalizationProvider>
              </Stack>
              {/* </Card> */}
            </Grid>
            <Grid item xs={12} md={8} sx={{}}>
              {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
              <Box
                rowGap={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="UserFName" label="First Name*" />
                <RHFTextField name="UserLName" label="Last Name*" />
                <RHFTextField name="UserEmail" label="Email Address*" />
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  {/* <FormControl>
                    <InputLabel>Country Code</InputLabel>
                    <Select
                      name="CountryCode"
                      label="Country Code"
                      value={selectedCountryCode}
                      onChange={(event) => setSelectedCountryCode(event.target.value)}
                    >
                      <MenuItem value="+61">+61</MenuItem>
                      <MenuItem value="+1">+1</MenuItem>
                    </Select>
                  </FormControl>

                  <RHFTextField
                    name="UserPhone"
                    label="Phone Number*"
                    sx={{ flex: 5 }}
                    InputProps={{ inputProps: { minlength: 8 ,maxLength: 10 } }}
                  /> */}
                  <FormControl>
                    <InputLabel>Country Code</InputLabel>
                    <Select
                      name="CountryCode"
                      label="Country Code"
                      value={selectedCountryCode}
                      // onChange={(event) => setSelectedCountryCode(event.target.value)}
                      onChange={(event) => {
                        const selectcountrycode = event.target.value as string;

                        if (selectcountrycode === '+61') {
                          setValue('UserCountry', 'Australia');
                          setDisableState(false);
                        } else if (selectcountrycode === '+1') {
                          setValue('UserCountry', 'United States');
                          setValue('UserState', '');
                          setDisableState(true);
                        }

                        setSelectedCountryCode(event.target.value);
                      }}
                    >
                      <MenuItem value="+61">+61</MenuItem>
                      <MenuItem value="+1">+1</MenuItem>
                    </Select>
                  </FormControl>
                  <RHFTextField
                    name="UserPhone"
                    label="Phone Number*"
                    // disabled={!edit}
                    sx={{ flex: 5 }}
                    InputProps={{ inputProps: { maxLength: 10 } }}
                  />
                </Stack>

                <RHFSelect name="UserGender" label="Gender" placeholder="Gender">
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </RHFSelect>
                <Stack spacing={2} alignItems="flex-start">
                  <RHFTextField name="UserStreetAddr" multiline rows={3} label="Address" />
                </Stack>
                {/* <RHFTextField name="UserState" label="State/Region" /> */}
                <RHFSelect
                  name="UserState"
                  label="State/Region"
                  placeholder="State"
                  disabled={disableState}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="NSW - New South Wales">NSW - New South Wales</MenuItem>
                  <MenuItem value="VIC - Victoria">VIC - Victoria</MenuItem>
                  <MenuItem value="QLD - Queensland">QLD - Queensland</MenuItem>
                  <MenuItem value="WA  - Western Australia">WA - Western Australia</MenuItem>
                  <MenuItem value="SA  - South Australia">SA - South Australia</MenuItem>
                  <MenuItem value="TAS - Tasmania">TAS - Tasmania</MenuItem>
                </RHFSelect>

                <RHFSelect
                  name="UserCountry"
                  label="Country*"
                  defaultValue="Australia"
                  onChange={(event) => {
                    const newSelectedCountry = event.target.value;
                    if (newSelectedCountry === 'Australia') {
                      setSelectedCountryCode('+61');
                      setDisableState(false);
                    } else if (newSelectedCountry === 'United States') {
                      setSelectedCountryCode('+1');
                      setValue('UserState', '');
                      setDisableState(true);
                    }
                    setValue('UserCountry', event.target.value);
                  }}
                >
                  {/* <MenuItem value="">None</MenuItem> */}
                  <MenuItem value="Australia">Australia</MenuItem>
                  {/* <MenuItem value="United States">United States</MenuItem> */}
                </RHFSelect>
                {/* {selectedCountry} */}

                {/* <RHFTextField name="UserCountry" label="Country" value="Austriliya" /> */}
                <RHFTextField name="UserZipcode" label="Postal Code" />
                <RHFSelect name="UserStatus" label="Status*" placeholder="Status">
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="active">active</MenuItem>
                  <MenuItem value="inactive">inactive</MenuItem>
                  {/* <MenuItem value="deleted">deleted</MenuItem> */}
                </RHFSelect>
              </Box>
              {/* </Card> */}
            </Grid>
          </Grid>
          <Grid container justifyContent="end" sx={{ marginTop: '20px' }}>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <Button variant="contained" type="submit" color="primary">
                Next
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={goToAssessoerList}
                variant="contained"
                color="primary"
                sx={{ marginLeft: '20px' }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </>
  );
}
