import {
    Button,
    // Card,
    // CardContent,
    // CardHeader,
    // Accordion,
    // AccordionDetails,
    // AccordionSummary,
    // Container,
    // Dialog,
    // DialogTitle,
    // Avatar,
    // FormControlLabel,
    // FormLabel,
    Grid,
    // InputLabel,
    // LinearProgress,
    // Link,
    // ListItem,
    // MenuItem,
    // Radio,
    // RadioGroup,
    // Select,
    // SelectChangeEvent,
    // Switch,
    // TextField,
    // alpha,
    // useTheme,
    // Stack,
    // Typography,
    // IconButton,
    // Tabs,
    // Tab,
    // FormControl,
    // FormGroup,
    // Checkbox,
  } from '@mui/material';
  import { Box
    // styled, width
   } from '@mui/system';
  import { useSnackbar } from 'src/components/snackbar';
  // import {
  //   DateTimePicker,
  //   DesktopDatePicker,
  //   LocalizationProvider,
  //   StaticDatePicker,
  // } from '@mui/x-date-pickers';
  import { 
    // SetStateAction, useCallback, useContext, 
    useEffect, useState } from 'react';
  // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  // import Iconify from 'src/components/iconify';
  // import Label from 'src/components/label/Label';
  // import _mock from 'src/_mock/_mock';
  // import { m, AnimatePresence } from 'framer-motion';
  // import * as Yup from 'yup';
  // import { useForm } from 'react-hook-form';
  // import { yupResolver } from '@hookform/resolvers/yup';
  // import Upload from 'src/components/upload/Upload';
  // import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
  // import { fDate } from 'src/utils/formatTime';
  // import FileThumbnail from 'src/components/file-thumbnail';
  // import { varFade } from 'src/components/animate';
  // import dayjs from 'dayjs';
  // import { DatePicker } from '@mui/lab';
  // import TextMaxLine from 'src/components/text-max-line';
  // import { TextFieldProps } from '@material-ui/core';
  // import { RHFUploadAvatar } from 'src/components/hook-form/RHFUpload';
  // import { fData } from 'src/utils/formatNumber';
  import axios from 'src/utils/axios';
  import { useNavigate } from 'react-router';
import Editor from 'src/components/editor/Editor';
import { useAuthContext } from 'src/auth/useAuthContext';
  
  // type Signature = {
  //   Signature: string;
  // };

  // interface SignatureDTO {
  //   UserID: number;
  //   Signature: string ;
  //   EntryBy?: number ;
  // }
  interface ViewJobDialogProp {
    id: string;
    edit: boolean;
  }
  
  // interface TabPanelProps {
  //   children?: React.ReactNode;
  //   index: number;
  //   value: number;
  // }
  
  export default function SignatureTab({  id, edit }: ViewJobDialogProp) {
  
    // const [values, setValues] = useState<Date | null>(new Date());
    // const [image, setImage] = useState<any>();
    // const [lastLogin, setLastLogin] = useState<any>();
    // const [selectedCountryCode, setSelectedCountryCode] = useState('+61');
  
    const { enqueueSnackbar } = useSnackbar();
   
    const navigate = useNavigate();

    useEffect(() => {
        axios
    .get(`/core/getusersignbyid?Id=${id}`)
    .then((response) => {
      const value1 = response.data.Signature.replace(/<img[^>]*>/g, '<img src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/originalea_resize_paintjpg.jpg" alt="EA Logo" id="ea-logo-image" />');
        setEditor(value1);
        // setEditor(response.data.Signature)
    })
    .catch((err) => {
        console.error('Error fetching signature:', err);
    })
    }, [id]);

    const { user } = useAuthContext();

    const onSubmit = async () => {
      try {

            // Modify the editor content to add width and height to image tags
// Add height, width, and new attribute to img tags with base64 src
        // const updatedEditor = editor.replace(/<img[^>]*>/g, '<img src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/originalea_resize_paintjpg.jpg" alt="EA Logo" id="ea-logo-image" />');
        const desiredImgTag = '<img src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/originalea_resize_paintjpg.jpg" alt="EA Logo" id="ea-logo-image" />';

// Use regex to match <img> tags that do not match the exact desired tag
        const updatedEditor = editor.replace(/<img[^>]*>/g, (match) => {
            // Check if the current <img> tag matches the desired one
            if (!match.includes('src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/originalea_resize_paintjpg.jpg"') ||
                !match.includes('alt="EA Logo"') ||
                !match.includes('id="ea-logo-image"')) {
                // Replace non-matching <img> tags with the desired one
                return desiredImgTag;
            }
            // If the <img> tag matches the desired one, return it as is
            return match;
        });

        const payload = {
            UserID : parseInt(id,10),
            Signature:updatedEditor,
            EntryBy:parseInt(user?.ID as string,10)
        }
        console.log("Payload in update signature :", payload)
        axios
          .put(`/core/updateusersign`, payload)
          .then((response) => {
            enqueueSnackbar(response.data, { variant: 'success' });
          })
          .catch((error) => {
            enqueueSnackbar(error, { variant: 'error' });
          });
      } catch (error) {
        console.error(error);
      }
    };
    const back = () => {
      navigate('/assessorlist');
    };
    const [editor ,setEditor] = useState(``);
    const onValueChanges = (value:any) => {
      console.log("Inside onValueChanges")
      const value1 = value.replace(/<img[^>]*>/g, '<img src="https://eapublicbucket.s3.ap-southeast-2.amazonaws.com/originalea_resize_paintjpg.jpg" alt="EA Logo" id="ea-logo-image" />');
      setEditor(value1);
      console.log("Value 1 onValueChanges:", value1)
    }
    
  
    return (
      <>
        {/* <Container> */}
        <Grid container display="flow">
            <Grid container spacing={2} md={12} xl={12}>
              
              <Grid item xs={12} md={12} sx={{}}>
                {/* <Card sx={{ maxHeight:'100%', p: 3 }}> */}
                <Box
                  rowGap={3}
                  columnGap={5}
                  display="grid"
                  
                >
                    <Editor   value= {editor}
                        onChange={onValueChanges}
                        onchan={setEditor}
                        simple
                    />
                 
                  
                </Box>
                {/* </Card> */}
                <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
                  <Grid item>&nbsp;</Grid>
                  <Grid item>
                    {edit && (
                      <Button variant="contained" onClick={onSubmit} color="primary">
                        Update
                      </Button>
                    )}
  
                    <Button
                      variant="contained"
                      onClick={back}
                      sx={{ marginLeft: '10px' }}
                      color="secondary"
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
      </>
    );
  }
  
  // const publicDocuments = [
  //   { fileName: 'public_report', fileType: 'pdf' },
  //   { fileName: 'public_presentation', fileType: 'pptx' },
  //   { fileName: 'public_budget', fileType: 'xlsx' },
  //   { fileName: 'public_policy', fileType: 'docx' },
  // ];
  